import {
  atom,
} from 'recoil';

export const vehicleState = atom({
  key: 'vehicle_state',
  default: {
    page_number: 0,
    size: 10,
  },
});

export const vehicleResultState = atom({
  key: 'vehicle_result_state',
  default: {
    items: [],
    size: 10,
    count: 0,
  },
});