export default {
  DEFAULT: {
    fields: [
      {name: "vehicle_info", orderField: "", label: "VEHICLE INFO"},
      {name: "vehicle_name", orderField: "", label: "VEHICLE NAME"},
      {name: "license_plate", orderField: "", label: "LICENSE PLATE"},
      {name: "license_state", orderField: "", label: "LICENSE PLATE STATE"},
      {name: "vehicle_type", orderField: "", label: "VEHICLE TYPE"},
      {name: "action", orderField: "", label: ""},
    ],
  },
}