import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loading() {
  return (
    <div className="loading">
      <CircularProgress size={70} thickness={1} color="primary" />
    </div>
  );
}
