import { create } from 'apisauce'
import * as axios from 'axios';
axios.defaults.withCredentials = true;

export const api = create({
    baseURL: `${process.env.REACT_APP_API_ROOT}`,
    withCredentials: true
});

api.addResponseTransform(response => {
    if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
        window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?next=${encodeURI(window.location.href)}`;
    }
});

export const dashboardAPI = create({
    baseURL: `${process.env.REACT_APP_API_DASHBOARD}`,
    withCredentials: true
});

dashboardAPI.addResponseTransform(response => {
    if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
        window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?next=${encodeURI(window.location.href)}`;
    }
});

export const paymentAPI = create({
    baseURL: `${process.env.REACT_APP_API_PAYMENT}`,
    withCredentials: true
});

paymentAPI.addResponseTransform(response => {
    if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
        window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?next=${encodeURI(window.location.href)}`;
    }
});

export const API_REQUESTING = "REQUESTING";
export const API_REQUEST_SUCCEEDED = "SUCCEEDED";
export const API_REQUEST_ERROR = "API_REQUEST_ERROR";

/**
 * 
 * {
    "source_shipments": [4881939, 4881935, 4881937],
    "move_type": 0 == before, 1 == after,
    "target_shipment": 4881936,
    "is_preview": 1 == preview, 0 == save
}
 */
export const orderShipment = (id, data) => api.post(`/assignments/${id}/reorder-shipments`, data);
export const updateBankInfo = (paymentId, data) => paymentAPI.put(`/dsp-payments/${paymentId}/bank-info`, data);
export const resetAssignmentOrder = (id, data) => api.post(`/assignments/${id}/reset-sequence-id`, data);

export default api;