export default {
  container: {
    minHeight: '600px',
    marginTop: "50px",
  },
  highlightCell: {
    backgroundColor: 'rgba(136, 127, 255, 0.1)',
    color: '#5a5a5a',
  },
  alternative: {
    backgroundColor: '#d6d3ff',
    color: '#5a5a5a',
  },
  selected: {
    backgroundColor: '#efffef'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  tableStyle: {
    backgroundColor: '#fff'
  },
  disabledCell: {
    backgroundColor: '#999'
  },
  deduct: {
    color: '#f00',
  },
  oldTransaction: {
    color: '#48baff',
  },
  details: {
    color: '#4a90e2',
    fontWeight: 900,
    textDecoration: 'underline',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  detailWrapper: {
    padding: 20,
    backgroundColor: '#f5f5f5',
    color: '#5a5a5a',
    fontWeight: 900,
  }
}
