import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import { Container, Grid, Box, LinearProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import styles from './styles';
import AssignmentList from "./AssignmentList";
import moment from "moment";
import AssignmentsStats from "./AssignmentsStats";

class DispatchAssignmentListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  componentDidMount() {
    const { assignmentStore } = this.props.store;
    const { day, region, assignmentId } = this.props.match.params;

    if (day) {
      if (day === 'today') {
        assignmentStore.date = moment().format('YYYY-MM-DD')
      } else {
        assignmentStore.date = moment(day).format('YYYY-MM-DD')
      }
    }
    if (region && region !== 'all') {
      assignmentStore.regions = region.toUpperCase().split(',')
    }

    assignmentStore.loadAssignments();

    if (assignmentId) {
      assignmentStore.loadAssignment(parseInt(assignmentId))
    }
  }

  componentDidUpdate() {
    const { store } = this.props;
    const { loadingAssignments } = store.assignmentStore;
    if (this.state.collapse && loadingAssignments) {
      this.setState({collapse: false});
    }
  }

  onCollapse = () => {
    this.setState({collapse: !this.state.collapse})
  };

  render() {
    const { collapse } = this.state;
    const { classes, store } = this.props;
    const { loadingAssignments } = store.assignmentStore;
    const bottomHeight = collapse ? 50 : 330;

    if (loadingAssignments) {
      return (
        <Box className={classes.container}>
          <LinearProgress color="primary" />
        </Box>
      )
    }

    return (
      <Box className={classes.container}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item className={classes.gridSection}
                style={{maxHeight: `calc(100% - ${bottomHeight}px)`}}
          >
            <AssignmentList />
          </Grid>
          <Grid item className={clsx(classes.bottomSection, classes.gridSection)}
                style={{maxHeight: bottomHeight}}
          >
            <AssignmentsStats onCollapse={this.onCollapse} />
          </Grid>
        </Grid>
      </Box>
    )
  }
}

const DispatchAssignmentListContainerCompose = compose(
  inject("store"),
  observer
) (DispatchAssignmentListContainer);

export default withStyles(styles)(withRouter(DispatchAssignmentListContainerCompose));