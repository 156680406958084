import React, { Component } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import _ from 'lodash';
import { inject, observer } from "mobx-react";
import { toast } from 'react-toastify';
import { compose } from "recompose";
import validate from 'validate.js';

import DriverStepper from '../../components/drivers/DriverStepper';
import TextInput from '../../components/input/TextInput';
import { RULES } from '../../constants/rules';
import styles, * as S from './add-styles';

class AddDriverAccount extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      dataError: {},
      initDataError: {},
      confirmation: false
    }
  }

  componentDidMount() {
    const {store, history} = this.props;
    if (store.driverStore.step === 1) {
      history.push('/drivers/add-vehicle');
    } else if(store.driverStore.step < 1) {
      history.push('/drivers/add-account-info');
    }

    // init error
    const initDataError = {};
    for (var i = 0; i < this.accountFields.length; i++) {
      this.checkError(this.accountFields[i], "init_");
    }

    this.setState({initDataError});
  }

  validates = {
    password: ["notEmpty"],
    username: ["notEmpty"],    
  }

  accountFields = ["username", "password"];

  checkError = (field, prefix) => {
    const {store} = this.props;    
    const {driverData} = store.driverStore;
    if (!prefix) {
      const initErrorField = "init_" + field + "_error";
      this.setState({[initErrorField]: undefined});
    }

    const errorField = prefix ? prefix + field + "_error" : field + "_error";

    const validates = this.validates[field];
    
    if (!validates) return;    
    const value = driverData[field];

    var constraints = {
      [field]: _.assign({}, ..._.values(_.pick(RULES, validates)))
    };

    const result = validate({[field]: value}, constraints, {format: "flat"});

    // process current error 
    if (!result) this.setState({[errorField]: undefined})
    else {
      this.setState({[errorField]: result[0]})
    }   
  }

  handleClose = () => {
    const {store} = this.props;
    store.driverStore.clearDriverData();
    this.setState({confirmation: true})
  };

  onTextFieldChange = (field) => (event) => {
    const {store} = this.props;    
    
    store.driverStore.updateDriverData(field, event.target.value);

    // if (field === 'username') {
    //   const {account} = store.userStore;
    //   const prefix = account && account.code ? account.code.toLowerCase() + "-" : 'dsp-';
    //   store.driverStore.updateDriverData(field, prefix + event.target.value);
    // } else {
    //   store.driverStore.updateDriverData(field, event.target.value);
    // }    
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  }

  
  createDriver = () => {
    const {driverStore, userStore} = this.props.store;
    const {account} = userStore;
    const prefix = account && account.code ? account.code.toLowerCase() + "-" : 'dsp-'; 
    const { driverData } = driverStore;
    if (!driverData['birthday']) {
      toast.error("Birthday cannot empty");
      return;
    }

    if (!driverData['phone_number']) {
      toast.error("Phone number cannot empty");
      return;
    }

    this.checkUsername().then(resp => {         
      if (resp.status === 200 && resp.data && !resp.data.exist)  {
        driverStore.createDriver(prefix).then(response => {
          if (response.status === 200) {
            this.props.history.push('/drivers');
          } else {            
            if (response.data && response.data.errors) {              
              this.setState({errors: response.data.errors});
            }

            if(response.data && response.data.message) {
              toast.error(response.data.message);
            }
          }
        })
      }       
    })    
  }

  checkUsername = () => {
    const {driverStore, userStore} = this.props.store;
    const {driverData} = driverStore;
    const {account} = userStore;
    const prefix = account && account.code ? account.code.toLowerCase() + "-" : 'dsp-';           
    const username = prefix + driverData['username'];
    if (/\s/.test(driverData['username'])) {
      // It has any kind of whitespace
      this.setState({username_error: `${username} must not contain whitespace.`, username_ok: undefined})
      return;
    }
    return driverStore.checkDriverUsername(username).then(resp => {
      if (resp.status === 200) {
        if (resp.data.exist) {
          this.setState({username_error: `${username} is already taken. Please try another username.`, username_ok: undefined})
        } else {
          this.setState({username_ok: `${username} is available.`, username_error: undefined})
        }
      }
    
      return resp;
    });
  }

  goBack = () => {
    const {history, store} = this.props;
    store.driverStore.setStep(1.5);
    history.push('/drivers/add-vehicle');
  }

  getValue = (field, df) => this.props.store.driverStore.driverData[field] ? this.props.store.driverStore.driverData[field]: df ? df : '';

  closeDialog = () => {
    this.setState({confirmation: false});
  }

  closeAddModal = () => {
    const {history} = this.props;
    this.setState({confirmation: false});
    history.replace('/drivers');
  }

  render() {
    const {classes, store} = this.props;
    const {driverData, creatingDriver} = store.driverStore;    
    const {account} = store.userStore;  
    
    const hasError = this.accountFields.find(field => !!this.state[field + "_error"] || !!this.state["init_" + field + "_error"])    

    return (
      <Container className={classes.root}>      
          <Modal
            aria-labelledby="add-driver"
            aria-describedby="add-driver"
            open={true}
            onClose={this.handleClose}
            className={classes.modal}          
          >          
            <div className={classes.paper}>   
                <Dialog
                  open={this.state.confirmation}                
                  onClose={this.closeDialog}
                  maxWidth={'md'}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className={classes.confirmDialog}
                >
                <DialogTitle className={classes.confirmDialogTitle} id="alert-dialog-title">
                  <Box style={{fontWeight: 'bold'}}>
                    Are you sure you want to leave? 
                    <br/>
                    The driver being created will not save.
                  </Box>                
                </DialogTitle>
                <DialogActions>
                  <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                    <Button style={{marginLeft: '15px', marginBottom: '10px'}} className={classes.secondaryBtn} variant="contained" onClick={this.closeDialog} color="primary">
                      Back
                    </Button>
                    <Button style={{marginRight: '15px', marginBottom: '10px'}} className={classes.errorBtn} variant="contained"  onClick={this.closeAddModal} color="primary" autoFocus>
                      Leave
                    </Button>
                  </div>                
                </DialogActions>
              </Dialog>  
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                <Grid item xs={3}>
                  <S.Title>{`Create New Driver`}</S.Title>
                </Grid>
                <Grid item xs={6}>
                  <DriverStepper style={{width: '100%'}} currentStep={2} />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    bgcolor="background.paper"
                    color="text.primary"
                    p={2}
                    position="absolute"
                    top={0}
                    right="0"
                    zIndex="modal"
                  >
                  <IconButton onClick={this.handleClose} size="large"><CloseIcon /></IconButton> 
                  </Box>                    
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput width="50%" 
                      prefix={account && account.code ? account.code.toLowerCase() + "-": 'dsp-'}
                      error={this.state['username_error']}
                      okMessage={this.state['username_ok']}
                      value={this.getValue('username')} onChange={this.onTextFieldChange('username')} label='Username' 
                      button={<Button disableElevation className={classes.checkUsernameBtn} variant="contained">Check if Available</Button>}
                      btnClick={this.checkUsername} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput width="50%" 
                      type="password"
                      error={this.state['password_error']}                    
                      value={this.getValue('password')} onChange={this.onTextFieldChange('password')} label='Temporary Password' />
                </Grid>
                
              </Grid>
              <br />
              <br />
              <br />
              <br />
              {this.state.errors && this.state.errors.map(error => 
                <Alert severity="error" style={{marginTop: '5px', marginBottom: '5px'}}>{error}!</Alert>
              )}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
              <Grid item>
                <Button variant="contained" disableElevation className={classes.secondaryBtn} onClick={this.goBack}>
                  Back
                </Button>
              </Grid>
                <Grid item>
                  <Button disabled={!!hasError || creatingDriver} variant="contained" color="primary" className={classes.mainBtn} onClick={this.createDriver}>
                    {!creatingDriver ? 'Create' : <CircularProgress thickness={5} size={25} />}
                  </Button>                
                </Grid>
              </Grid>
            </div>
          </Modal>
        </Container>
    );
  }
}

const AddDriverAccountCompose = compose(
  inject("store"),
  observer
) (AddDriverAccount);

export default withStyles(styles)(AddDriverAccountCompose);
