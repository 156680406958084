import React from 'react';
import Rating from 'react-rating';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  AxlModal, AxlPanel
} from 'axl-reactjs-ui';
import {inject, observer} from "mobx-react";

import styles, * as E from './styles';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class DriverProfileInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driverInfo: null,
      unsubscribed: false,
    }
  }

  componentDidMount() {
    const {driver, driverStore} = this.props;
    if(_.has(driver, 'id')) {
      driverStore.checkUnsubscribed(driver.id, res => this.setState({unsubscribed: res.data.unsubscribed}));      
    }
  }

  render() {
    const {driver} = this.props;

    if (!driver)
      return <div>Loading...</div>;

    return <E.Container>
      <AxlPanel>
        <AxlPanel.Row>
          <AxlPanel.Col flex={0}>
            <E.DriverInfoContainer>
              <E.DriverAvatar>
                <E.Avatar src={driver.photo} />
              </E.DriverAvatar>
              <E.DriverSince>{`Driver since: ${driver.background_decision_ts ? moment(driver.background_decision_ts).format('MM/DD/YYYY') : ''}`}</E.DriverSince>
            </E.DriverInfoContainer>
          </AxlPanel.Col>
          <AxlPanel.Col>
            <AxlPanel.Row>
              <AxlPanel.Col>
                <E.DriverNameContainer>
                  <E.DriverName>{`${_.get(driver, 'first_name', '')} ${_.get(driver, 'last_name', '')}`}</E.DriverName>
                  <E.DriverId>{`#${driver.id}`}</E.DriverId>
                  {/*<E.DriverRegion>{`SFO`}</E.DriverRegion>*/}
                </E.DriverNameContainer>
              </AxlPanel.Col>
              <AxlPanel.Col flex={0}>
                <E.LabelStatus>{`Status: `}<E.Status>{driver.status}</E.Status></E.LabelStatus>
              </AxlPanel.Col>
            </AxlPanel.Row>
            <E.RatingContainer>
              <Rating readonly={true} initialRating={Math.round(driver.driverScore)} emptySymbol={<img src="/assets/images/star-2.png" />} fullSymbol={<img src="/assets/images/star-3.png" />} />
            </E.RatingContainer>
            <AxlPanel.Row style={styles.driverRow}>
              <AxlPanel.Col>
                <E.DriverLabel>{`Phone Number`}</E.DriverLabel>
                <E.Text_1>{driver.phone_number}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`E-mail`}</E.DriverLabel>
                <E.Text_1>{driver.email}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`Zipcode`}</E.DriverLabel>
                <E.Text_1>{_.get(driver, 'home_address.zipcode', '_')}</E.Text_1>
              </AxlPanel.Col>
            </AxlPanel.Row>
            <AxlPanel.Row style={styles.driverRow}>
              <AxlPanel.Col>
                <E.DriverLabel>{`Vehicle Info`}</E.DriverLabel>
                <E.Text_1>{`${_.defaultTo(driver.vehicle_color, '')} ${_.defaultTo(driver.vehicle_make, '')} ${_.defaultTo(driver.vehicle_model, '')}`}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`License Plate`}</E.DriverLabel>
                <E.Text_1>{`${driver.vehicle_license_plate_state ? driver.vehicle_license_plate_state + ' - ' : ''}${driver.vehicle_license_plate ? driver.vehicle_license_plate : ''}`}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`Drivers License`}</E.DriverLabel>
                <E.Text_1>{driver.driver_license}</E.Text_1>
              </AxlPanel.Col>
            </AxlPanel.Row>
            <AxlPanel.Row>
              <AxlPanel.Col>
                <E.DriverLabel>{`SMS Unsubscribed`}</E.DriverLabel>
                <E.Text_1>{this.state.unsubscribed ? 'YES' : 'NO'}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`Couriers`}</E.DriverLabel>
                <E.Text_1>{driver && driver.couriers && driver.couriers.map(c => c.company).join() || "N/A"}</E.Text_1>
              </AxlPanel.Col>
              <AxlPanel.Col>
                <E.DriverLabel>{`Regions`}</E.DriverLabel>
                <E.Text_1>{driver && driver.regions && driver.regions.join() || 'N/A'}</E.Text_1>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <E.RatingContainer>
        </E.RatingContainer>
      </AxlPanel>
    </E.Container>
  }
}
export default compose(
  withRouter,
  inject('driverStore'),
  observer
)(DriverProfileInformation);