import {observable, transaction} from 'mobx';
import {API_REQUEST_ERROR, API_REQUEST_SUCCEEDED, API_REQUESTING} from "./api";

class PaymentStore {
  @observable paymentDetails = null;
  @observable repayStatus = null;
  @observable payeeInfo = null;
  @observable earlyPayoutStatus = {};
  @observable advancedPayoutStatus = {};
  @observable customPayoutStatus = {};
  @observable unresolvedPayments = null;

  constructor(api) {
    this.api = api;
  }

  getPaymentDetails(paymentId) {
    this.api.get(`/driver-payments/${paymentId}`)
      .then(response => {
        if (response.status === 200) {
          this.paymentDetails = response.data;
        }
      })
  }

  getPayeeDetails(paymentId) {
    this.api.get(`/driver-payments/${paymentId}/payee-info`)
      .then(response => {
        if (response.status === 200) {
          this.payeeInfo = response.data;
        }
      })
  }

  getDspPaymentCSV(paymentId) {
    return this.api.get(`/dsp-payments/${paymentId}/csv`)
      .then(response => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
  }

  getDspPaymentDetails(paymentId) {
    this.api.get(`/dsp-payments/${paymentId}`)
      .then(response => {
        if (response.status === 200) {
          this.paymentDetails = response.data;
        }
      })
  }

  getDspDetails(paymentId) {
    this.api.get(`/dsp-payments/${paymentId}/payee-info`)
      .then(response => {
        if (response.status === 200) {
          this.payeeInfo = response.data;
        }
      })
  }


  getUnresolvedPayments(){
    this.api.get(`/driver-payments/unresolved`)
        .then(response => {
          if (response.status === 200) {
            this.unresolvedPayments = response.data;
          }
        })
  }

  resetStatuses(){
    this.advancedPayoutStatus = {};
  }
}

export default PaymentStore;
