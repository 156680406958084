import * as M from '@mui/material';
import { createTheme } from '@mui/material/styles'

import colors from "../../themes/colors";

export const mainTheme = createTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        color: colors.graySeventh,
        '&$checked': {
          color: colors.graySeventh,
        }
      },
      colorPrimary: {
        color: colors.gray,
        '&$checked': {
          color: colors.gray,
        }
      },
      colorSecondary: {
        color: colors.grayMain,
        '&$checked': {
          color: colors.periwinkle,
        }
      },
    },
    MuiFormControlLabel: {
      label: {},
      root: {}
    },
  }
})