export const RESUBMIT_FAILED = 'RESUBMIT_FAILED';
export const SUCCEEDED = 'SUCCEEDED';
export const FAILED = 'FAILED';
export const PROCESSING = 'PROCESSING';
export const RESUBMITTED = 'RESUBMITTED';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const CREATED = 'CREATED';
export const RESUBMIT_SUCCEEDED = 'RESUBMIT_SUCCEEDED';

export const UNSUCCESS_STATUSES = [REQUEST_ERROR, RESUBMIT_FAILED, FAILED]
