import React, { useEffect, useState, useCallback } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NotePopup from '../NotePopup';
import NoteContent from '../NoteContent';

import api from 'stores/api';
import styles from './styles';
import acl from 'constants/acl';

const useStyles = makeStyles({
  container: {
    padding: 0,
  },
});

function AssignmentNote(props) {
  const classes = useStyles();

  const { assignmentId, store } = props;
  const { permissionStore, userStore } = store;
  const { user } = userStore;
  const userId = user.id;

  const [content, setContent] = useState('');

  const [notes, setNotes] = useState([]);
  const [isEnableEdit, setIsEnableEdit] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const isDenied = permissionStore.isDenied(acl.ASSIGNMENTS.NOTE);

  const handleOpenDialog = () => {
    if (isDenied) return;

    setIsEditForm(false);
    setIsShowPopup(true);
    setContent('');
  };

  const handleOpenEditDialog = () => {
    if (isDenied) return;

    const lastNote = notes.find((note) => note.subject.uid === `US_${userId}`);
    if (lastNote) {
      setIsEditForm(true);
      setContent(lastNote.content);
      setIsShowPopup(true);
    }
  };

  const loadData = useCallback(() => {
    api.get(`/notes/AS_${assignmentId}`).then((response) => {
      if (response.status !== 200) return;

      const notes = response.data || [];
      const lastNote = notes.find((note) => note.subject.uid === `US_${userId}`);

      setNotes(notes);
      setIsEnableEdit(Boolean(lastNote));
    });
  }, [assignmentId, userId]);

  const editContent = (content) => {
    api.put(`/notes/AS_${assignmentId}`, content).then((resp) => {
      if (resp.ok) loadData();
      setIsShowPopup(false);
    });
  };

  const saveContent = (content) => {
    api.post(`/notes/AS_${assignmentId}`, content).then((resp) => {
      if (resp.ok) loadData();
      setIsShowPopup(false);
    });
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const dialogTitle = isEditForm ? 'Edit Last Assignment Note' : 'Assignment Note';

  return (
    <div style={styles.container}>
      <NoteContent title="Assignment Note" notes={notes} editFnc={handleOpenEditDialog} addFnc={handleOpenDialog} isEnableEdit={isEnableEdit} />
      <Dialog open={isShowPopup} maxWidth="sm" fullWidth onClose={() => setIsShowPopup(false)}>
        <DialogContent classes={{ root: classes.container }}>
          <NotePopup
            title={dialogTitle}
            isEditForm={isEditForm}
            content={content}
            closePopup={() => setIsShowPopup(false)}
            saveContent={saveContent}
            editContent={editContent}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default compose(inject('store'), observer)(AssignmentNote);
