import React, { Fragment, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormHelperText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { DatePicker } from '@mui/x-date-pickers';

export const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: 600,
  },
  disabled: {
    backgroundColor: '#ededed',
    color: '#8d8d8d',
  },
  typography: {
    color: '#707070',
    marginBottom: '5px',
  },
  inputBaseRoot: {
    border: 'none',
  },
  inputRoot: {
    '&::placeholder': {
      fontSize: 16,
      color: '#979797',
    },
  },
}));

function AxlDatePicker(props) {
  const classes = useStyles();
  const { label, disabled, errorMessage, allowClickInputOpen, ...otherProps } = props;
  const [open, setOpen] = useState(false);

  const sx = {
    ".MuiOutlinedInput-input": {
      padding: '9px 14px',
    } 
  }

  const newProps = !allowClickInputOpen
    ? {
        ...otherProps,
      }
    : {
        ...otherProps,
        open: !disabled && open,
      };

  return (
    <Fragment>
      {label && <Typography className={classes.typography}>{label}</Typography>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className={clsx(disabled && classes.disabled)}
          variant={props.variant || 'inline'}
          inputVariant={props.inputVariant || 'outlined'}
          autoOk
          fullWidth
          disabled={disabled}
          slotProps={{
            textField: {
              size: props.size || 'small',
              onClick: () => {
                if (!disabled) setOpen(true);
              },
              fullWidth: true,
              classes: {
                root: classes.inputBaseRoot,
              },
              placeholder: props.placeholder || 'MM/DD/YYYY',
            },
            field: { readOnly: true, },
            openPickerIcon: {
              onClick: () => {
                if (!disabled) setOpen(true);
              }
            }
          }}
          {...newProps}
          open={open}
          onClose={() => setOpen(false)}
          sx={sx}
        />
        {errorMessage && (
          <FormHelperText error={!!errorMessage} style={{ color: '#d0021b', fontWeight: 'bold' }}>
            {errorMessage}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </Fragment>
  );
}

export default AxlDatePicker;
