import styled from "styled-components";

export const Panel = styled.div`
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
`;

export const PanelMessengerInner = styled.div`
    box-sizing: border-box;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0.5px 1px 2px 0 rgba(155, 155, 155, 0.24), 0.5px 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    position: relative;
`;

export const PanelMessenger = styled(Panel)``;

const styles = theme => ({
  container: {
    backgroundColor: '#e9ecee',
    padding: '8px',
  },
  bodyContainer: {
    height: 'calc(100vh - 165px)',
  },
  panel: {
    height: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  assignmentListContainer: {
    width: 400,
  },
  assignmentDetailContainer: {
    width: 480,
    position: 'relative',
  },
  shipmentDetailContainer: {
    width: 'calc(100% - 880px)',
    minWidth: 400,
  },
  assignmentMapContainer: {
    width: 'calc(100% - 400px)',
    overflow: 'hidden',
    minWidth: 400,
  }
});

export default styles;
