const styles = theme => ({
  container: {
    color: '#3b3b3b',
  },
  error: {
    fontWeight: 600,
    color: '#f00',
  },
  listContainer: {
    height: 500,
    maxHeight: 'calc(100vh - 255px)',
    overflow: 'auto',
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  listWrapper: {
    backgroundColor: '#f1f1f1',
    padding: '8px 16px',
    // height: '100%',
    // maxHeight: 'calc(100% - 16px)',
    borderRadius: 3,
    marginTop: '12px',
    marginBottom: '12px',
    flex: 1,
    overflow: 'hidden',
  },
  listWrapperFlexible: {
    backgroundColor: '#f1f1f1',
    padding: '8px 16px',
    // height: '100%',
    maxHeight: '30%',
    borderRadius: 3,
    marginTop: '12px',
    marginBottom: '12px',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  listWrapperScroll: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 30px)',
  },
  list: {
    height: 410,
    overflowY: 'hidden',
    overflowX: 'hidden',
    maxHeight: 'calc(100% - 40px)',
    padding: 8,
  },
  cardContainer: {
    backgroundColor: '#fff',
    padding: '4px',
    cursor: 'pointer',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '3px',
    margin: '4px'
  },
  cardSelected: {
    borderColor: theme.palette.primary.periwinkle,
  },
  cardDisabled: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
  cardAvatar: {
    maxHeight: 50,
    display: 'block',
    maxWidth: 50,
    margin: 'auto',
    padding: '4px',
  },
  cardLabel: {
    fontWeight: '600',
    fontSize: 14,
  },
  cardSubtitle: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  grayText: {
    color: '#848484',
  },
  dialogPaper: {
    padding: '24px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  dialogContent: {
    margin: '8px 0px',
    color: '#bfbfbf',
  },
  dialogButton: {
    padding: '4px 48px',
  },
});

export default styles;
