import {observable, action, decorate, computed} from 'mobx';
import {STATUS} from "../constants/status";
import _ from 'lodash';
import polyline from 'google-polyline';
import {searchInObject} from 'axl-js-utils';
import moment from "moment";
import { toast } from 'react-toastify';

class AssignmentStore {
  constructor(appStore) {
    this.appStore = appStore;
    this.api = appStore.api;
    this.driverStore = appStore.driverStore;
  }

  assignments = [];
  completedAssignments = [];
  pendingAssignments = [];
  inProgressAssignments = [];
  unassignedAssignments = [];
  regions = [];
  selectedAssignment = null;
  selectedAssignmentId = null;
  date = moment().format('YYYY-MM-DD')
  loadingAssignments = false;
  loadingAssignment = false;
  assigning = false;
  unAssigning = false;
  assignmentsRequested = 0;
  sortBy = 'predicted_start_ts';
  inlineFilter = '';
  tagFilter = [];
  activeDriverLocations = null;
  driverCache = {};
  updateCache = {};
  filter = '';
  boundary = null;
  regionList = [];

  // not observable
  showingStopTypes = ['DROP_OFF', 'RETURN'];

  get searchFilter() {
    return {
      order: '-id',
      limit: 1000,
      region_codes: this.regions ? this.regions.join(',') : '',
      date: this.date,
      requested: this.assignmentsRequested
    }
  }

  setInlineFilter(q) {
    this.inlineFilter = q;
    this.refreshAssignmentList();
  }

  setDate(d) {
    if (d) {
      this.date = moment(d).format('YYYY-MM-DD')
    } else {
      this.date = null;
    }

    this.selectAssignment(null);
    this.loadAssignments();
  }

  setRegions(r) {
    this.regions = r;
    this.loadAssignments();
  }

  loadAssignments() {
    this.loadingAssignments = true;
    this.assignmentsRequested = Date.now();
    this.api.get(`/dsp/assignments/list-by-date?`, this.searchFilter).then(response => {
      if (response.ok) {
        if (response.config.params.requested !== this.assignmentsRequested)
          return;

        this.assignments = this.processAssignments(response.data);
        this.refreshAssignmentList_();
        this.updateCache = {};
        this.activeDriverLocations = this.processActiveDrivers(this.assignments, this.locations, 600000)
      }

      this.loadingAssignments = false;
    })
  }

  loadAllAssignments(cb) {
    const assignmentsRequested = Date.now();

    const filters = {
      order: '-id',
      limit: 1000,
      region_codes: this.regions ? this.regions.join(',') : '',
      date: this.date,
      requested: assignmentsRequested
    }

    this.api.get(`/dsp/assignments/list-by-date?`, filters).then(response => {
      if (response.ok) {
        if (response.config.params.requested !== assignmentsRequested)
          return;

        const assignments = this.processAssignments(response.data);
        
        if (cb) cb(assignments)
      }
    })
  }

  processAssignments(assignments) {
    let labelMap = {};
    let driverMap = {};
    let extraMap = {};
    if (assignments.labels) {
      assignments.labels.forEach(l => {
        labelMap[l.assignment_id] = l;
      })
    }
    if (assignments.drivers) {
      assignments.drivers.forEach(l => {
        this.driverCache[l.id] = l;
      })
    }
    if (assignments.extra) {
      assignments.extra.forEach(l => {
        extraMap[l.assignment_id] = l;
      })
    }
    let processed = assignments.assignments.map(a => {
      if (!a.label && labelMap[a.id])
        a.label = labelMap[a.id].prefix;
      a.driver = this.driverCache[a.driver_id];
      a.extra = extraMap[a.id];
      if (a.extra && a.extra.dropoff_status) {
        a.failed = a.extra.dropoff_status.split('|').filter(a => a === 'DF').length;
        a.complted = a.extra.dropoff_status.split('|').filter(a => a === 'DF' || a === 'DS').length;
        a.progress = a.extra.dropoff_status.split('|').filter(a => a.substr(0,1) === 'D').map(a => a.substr(1));
      }
      if (a.extra && a.extra.path) {
        a.path = polyline.decode(a.extra.path)
      }
      return a;
    });

    // get boundary
    const latlngs = processed.filter(a => a.path).flatMap(a => a.path);
    const lats = latlngs.map(l => l[0]);
    const lngs = latlngs.map(l => l[1]);
    if (latlngs.length < 2) {
      this.boundary = null
    } else {
      let boundary = [
        [_.min(lngs), _.min(lats)],
        [_.max(lngs), _.max(lats)]
      ];

      if (boundary[1][0] < boundary[0][0] + 0.001) {
        boundary[1][0] = boundary[0][0] + 0.001
      }
      if (boundary[1][1] < boundary[0][1] + 0.001) {
        boundary[1][1] = boundary[0][1] + 0.001
      }
      this.boundary = boundary;
    }

    return _.sortBy(processed, (a) => a.region_code + (a.label ? a.label : ''))
  }

  processInlineAssignments(assignments) {
    let sorted = this.sort(assignments);
    const q = this.inlineFilter ? this.inlineFilter.trim() : '';
    const u = q.toUpperCase();
    const filterLabel = (a) => (a.label && typeof(a.label) === 'string' && a.label.indexOf(u) === 0) || a.id.toString() === u || (a.label && a.label.prefix && a.label.prefix.indexOf(u) === 0);
    const filterZones = (a) => u.length > 2 && a.zones && a.zones.toUpperCase().indexOf(u) >= 0;
    const filterDriver = (a) => u.length > 2 && a.driver && ((a.driver.first_name.trim().toUpperCase() + ' ' + a.driver.last_name.trim().toUpperCase()).indexOf(u) >= 0 || (a.driver.last_name.trim().toUpperCase() + ' ' + a.driver.first_name.trim().toUpperCase()).indexOf(u) >= 0);
    const filterTag = (a) => {
      const aggregatedTags = a.aggregated_tags ? a.aggregated_tags.map(t => t.toLowerCase()): null;
      if (!this.tagFilter || this.tagFilter.length === 0) return true;
      if (!aggregatedTags) return false;

      const filterTags = this.tagFilter.map(t => t.toLowerCase());
      const diff = _.intersectionWith(aggregatedTags, filterTags, _.isEqual);

      return diff && diff.length > 0;
    };

    if (this.tagFilter) {
      sorted = sorted.filter(filterTag);
    }

    if (this.inlineFilter) {
      let q = this.inlineFilter.trim();
      return sorted.filter(a => filterLabel(a) || filterZones(a) || filterDriver(a));
    } else {
      return sorted;
    }
  }

  refreshAssignmentList_() {
    this.completedAssignments = this.processInlineAssignments(this.assignments.filter(a => a.status === STATUS.COMPLETED));
    this.inProgressAssignments = this.processInlineAssignments(this.assignments.filter(a => a.driver_id && a.is_active && a.status !== STATUS.COMPLETED));
    this.pendingAssignments = this.processInlineAssignments(this.assignments.filter(a => a.driver_id && a.status !== STATUS.COMPLETED && !a.is_active));
    this.unassignedAssignments = this.processInlineAssignments(this.assignments.filter(a => !a.driver_id && (a.status !== STATUS.COMPLETED)));
  }

  applyChange_() {
    if (!this.updateCache || _.isEmpty(this.updateCache)) {
      return;
    }

    const assignments = this.assignments.map(a => {
      if (this.updateCache.hasOwnProperty(a.id)) {
        return Object.assign(a, this.updateCache[a.id]);
      } else {
        return a;
      }
    });

    this.updateCache = {};
    this.assignments = assignments;

    this.refreshAssignmentList_();
  }

  refreshAssignmentList = _.debounce(this.refreshAssignmentList_, 1000, { 'maxWait': 2000 });
  applyChange = _.debounce(this.applyChange_, 15000, { 'maxWait': 20000 });

  selectAssignment(assignment) {
    if (!assignment) {
      this.selectedAssignment = null;
      this.selectedAssignmentId = null;
      return;
    }

    this.selectedAssignment = {
      assignment: assignment,
      driver: assignment.driver,
      assignmentLabel: assignment.label,
      lastUpdate: Date.now()
    };

    this.loadAssignment(assignment.id);
  }

  loadAssignment(id) {
    this.selectedAssignmentId = id;
    this.loadingAssignment = true;
    this.api.get(`/dsp/assignments/${id}/detail`)
      .then(response => {
        let assignmentDetail = response.data;
        if (assignmentDetail.assignment.id !== this.selectedAssignmentId) {
          // not the expected one, ignore
          return;
        }
        assignmentDetail.lastUpdate = Date.now();
        this.loadingAssignment = false;
        this.selectedAssignment = this.processAssignmentDetail(assignmentDetail);
        this.getAssignmentLocations(this.selectedAssignment);
        this.getDriverLocation(this.selectedAssignment);
      })
      .catch((e) => {
      this.loadingAssignment = false; // TODO: display error
    })
  }

  loadRegions() {
    this.api.get('/regions').then((response) => {
      const regionList = response.data.map(({ properties }) => ({ label: `[${properties.code}] ${properties.display_name}`, value: properties.code }));
      this.regionList = regionList;
    });
  }

  getDriverLocation(assignmentDetail) {
    if (!assignmentDetail.assignment || !assignmentDetail.assignment.driver_id) {
      return;
    }
    this.driverStore.getLastLocation(assignmentDetail.assignment.driver_id).then((l) => {
      if (!l) return;
      if (l.assignment_id !== assignmentDetail.assignment.id) return;

      if (moment(l.timestamp).unix() * 1000 < Date.now() - 1200000) {
        assignmentDetail.driverLocation = null;
      } else {
        assignmentDetail.driverLocation = l;
      }
    })
  }

  getAssignmentLocations(assignmentDetail) {
    const lastUpdated = assignmentDetail.locations && assignmentDetail.locations.length > 0 ? assignmentDetail.locations[assignmentDetail.locations.length - 1] : null;
    const url = `/dsp/assignments/${assignmentDetail.assignment.id}/locations` + (lastUpdated ? `?last=${lastUpdated.timestamp}` : '');
    this.api.get(url).then((res) => {
      if (res.data && res.data.length > 0)
        assignmentDetail.locations = assignmentDetail.locations.concat(res.data)
    })
  }

  processAssignmentDetail(assignmentDetail) {
    const shipmentMap = {};
    const shipmentLabelMap = {};
    const clientMap = {};
    const stopMap = {};
    assignmentDetail.shipments.forEach(s => {
      shipmentMap[s.id] = s;
    });
    assignmentDetail.shipmentLabels.forEach(s => {
      shipmentLabelMap[s.shipment_id] = s;
    });
    assignmentDetail.clients.forEach(s => {
      clientMap[s.id] = s;
    });

    if (assignmentDetail.driver && this.driverCache) {
      this.driverCache[assignmentDetail.driver.id] = assignmentDetail.driver;
    }

    assignmentDetail.stops.forEach(s => {
      stopMap[s.id] = s;
    });

    // calculate bbox. Will be part of assignment in the future
    let lats = assignmentDetail.shipments.map(s => s.dropoff_address.lat);
    let lngs = assignmentDetail.shipments.map(s => s.dropoff_address.lng);
    if (assignmentDetail.assignment.logistic_type === 'ON_DEMAND') {
      lats = lats.concat(assignmentDetail.shipments.map(s => s.pickup_address.lat));
      lngs = lngs.concat(assignmentDetail.shipments.map(s => s.pickup_address.lng));
    }
    assignmentDetail.bbox = [[_.min(lngs), _.min(lats)], [_.max(lngs), _.max(lats)]];

    assignmentDetail.stops.forEach(stop => {
      stop.shipment = shipmentMap[stop.shipment_id];
      stop.label = shipmentLabelMap[stop.shipment_id];

      //@TODO: move DROP_OFF into constant
      if (this.showingStopTypes.indexOf(stop.type) >= 0) {
        stop.corresponding_stop = stop.corresponding_stop_id && stopMap[stop.corresponding_stop_id] ? stopMap[stop.corresponding_stop_id] : null;
      }

      if (stop.shipment)
        stop.client = clientMap[stop.shipment.client_id];
    });

    assignmentDetail.isCompleted = assignmentDetail.assignment && assignmentDetail.assignment.status === 'COMPLETED';
    assignmentDetail.completable = assignmentDetail.assignment && assignmentDetail.assignment.driver_id && assignmentDetail.assignment.status !== 'COMPLETED'
      && _.every(assignmentDetail.stops
        .filter(s => ['PICK_UP', 'DROP_OFF'].indexOf(s.type) >= 0)
        .map(s => ['FAILED', 'SUCCEEDED'].indexOf(s.status) >= 0)
      );

    assignmentDetail.locations = [];
    return assignmentDetail;
  }

  processActiveDrivers(assignments, locations, duration) {
    if (!assignments || assignments.length < 1) return [];
    if (_.isNil(locations)) return [];

    const ts = Date.now();
    const limit = ts - duration;
    let activeLocations = [];
    let assignmentMap = {};
    assignments.forEach(a => {
      assignmentMap[a.id] = a
    });
    for (let driverId in locations) {
      const location = locations[driverId];
      // if (location.ts < limit) continue;
      let assignment = assignmentMap[location.assignmentId];
      if (!assignment) continue;
      activeLocations.push(Object.assign(location, {'assignment': assignment, 'driver': this.driverCache[location.driverId]}));
    }
    return activeLocations;
  }

  assign(assignment, driver, reason, driver_vehicle_id) {
    this.assigning = true;
    return this.assignDriver(assignment.assignment.id, driver.id, reason, driver_vehicle_id).then((r) => {
      if (r.ok) {
        assignment.driver = driver;
        this.assigning = false;
        let assignments = this.assignments.map(a => {
          if (a && a.id === assignment.assignment.id) {
            return Object.assign({}, a, {driver: driver, driver_id: driver.id});
          }
          return Object.assign({}, a);
        });
        this.assignments = assignments;
      }
      return r;
    })
  }

  reassign(assignment, driver, reason, driver_vehicle_id) {
    this.assigning = true;

    if (!assignment.driver) return;

    return this.reassignDriver(assignment.assignment.id, assignment.driver.id, driver.id, reason, driver_vehicle_id).then((r) => {
      if (r.ok) {
        assignment.driver = driver;
        this.assigning = false;
        let assignments = this.assignments.map(a => {
          if (a && a.id === assignment.assignment.id) {
            return Object.assign({}, a, {driver: driver, driver_id: driver.id});
          }
          return Object.assign({}, a);
        });
        this.assignments = assignments;
      }
      return r;
    })
  }

  unassign(assignment, reason) {
    this.unAssigning = true;

    if (!assignment.driver) return;

    return this.unassignDriver(assignment.assignment.id, assignment.driver.id, reason).then((r) => {
      if (r.ok) {
        assignment.driver = null;
        const assignments = this.assignments.map(a => {
          if (a && a.id === assignment.assignment.id) {
            return Object.assign({}, a, {driver: null, driver_id: null});
          }
          return Object.assign({}, a);
        });
        this.assignments = assignments;
        this.unAssigning = false;
      }
      return r;
    })
  }

  activate(assignment, callback) {
    if (!assignment.driver_id) {
      return
    }

    return this.activateAssignment(assignment.id).then((r) => {
      if(r.ok) {
        assignment.is_active = true;
        const assignments = this.assignments.map(a => {
          if (a && a.id === assignment.id) {
            return Object.assign({}, Object.assign(a, {is_active: true}));
          }
          return Object.assign({}, a);
        });
        this.assignments = assignments;
        callback(r);
      } else {
        callback(r);
      }
      return r;
    })
  }

  deactivate(assignment) {
    if (!assignment.driver_id) {
      return;
    }

    return this.deactivateAssignment(assignment.id).then((r) => {
      if (r.ok) {
        assignment.is_active = false;
        const assignments = this.assignments.map(a => {
          if (a && a.id === assignment.id) {
            return Object.assign({}, Object.assign(a, {is_active: false}));
          }
          return Object.assign({}, a);
        });
        this.assignments = assignments;
      }
      return r;
    })
  }

  assignDriver(assignment_id, driver_id, reason, driver_vehicle_id) {
    return this.api.put(`/assignments/${assignment_id}/assign`, {driver_id, reason, driver_vehicle_id});
  }

  unassignDriver(assignment_id, driver_id, reason) {
    return this.api.put(`/dsp/assignments/${assignment_id}/unassign`, {driver_id, reason});
  }

  reassignDriver(assignment_id, old_driver_id, driver_id, reason, driver_vehicle_id) {
    return this.api.put(`/assignments/${assignment_id}/reassign`, {driver_id, old_driver_id, reason, driver_vehicle_id});
  }

  activateAssignment(assignment_id) {
    return this.api.put(`/dsp/assignments/${assignment_id}/activate`);
  }

  deactivateAssignment(assignment_id) {
    return this.api.put(`/dsp/assignments/${assignment_id}/deactivate`);
  }

  appendSplitedAssignment(originalAssignmentId, newAssignment) {
    // try to find the orig route first
    const assignment = this.assignments.find(a => a.id === originalAssignmentId);
    // clone new assignment
    const cloneAssignment = _.cloneDeep(assignment);
    cloneAssignment.shipment_count = newAssignment.shipment_count;

    cloneAssignment.id = newAssignment.id;
    cloneAssignment.shipment_count = newAssignment.shipment_count;
    cloneAssignment.label = newAssignment.label;

    assignment.shipment_count = assignment.shipment_count - newAssignment.shipment_count;

    this.assignments = [cloneAssignment].concat(this.assignments.slice());
    this.refreshAssignmentList_();
    this.loadAssignment(cloneAssignment.id);
  }

  addShipment(assignmentId, shipmentId, cb) {
    this.api.post(`/dsp/assignments/${assignmentId}/add-shipment`, {shipment_id: shipmentId})
      .then(response => {
        if (response.ok) {
          this.loadAssignment(assignmentId);
        } else {
          toast.error(`Failed to add shipment: ${response.data.message}`)
        }
        if (cb) cb(response);
      });
  }

  sort(assignments) {
    if (this.sortBy === 'predicted_start_ts') {
      return _.sortBy(assignments, a => a.predicted_start_ts)
    }
    return assignments;
  }

  get assignmentShipmentStats() {
    let total = 0, unassigned = 0, pending = 0, inprogress = 0, failed = 0, succeeded = 0, late = 0, early = 0;
    if (this.assignments) {
      this.assignments.forEach(assignment => {
        if (assignment.extra && assignment.extra.stats) {
          for (let client_id in assignment.extra.stats) {
            let sub_stats = assignment.extra.stats[client_id];
            /*
            PP : pickup pending
            PF : pickup failed
            PS : pickup succeeded
            DF : dropoff failed
            DS : dropoff succeeded
            DP : dropoff pending
            */
            for (let k in sub_stats) {
              let v = sub_stats[k];
              if (k === 'DS') {
                succeeded += v;
              } else if (k === 'PF' || k === 'DF') {
                failed += v;
              } else if (k === 'PP') {
                pending += v;
              } else if (k === 'PS' || k === 'DP') {
                inprogress += v;
              } else if (k === 'late') {
                late += v;
              } else if (k === 'early') {
                early += v;
              }
            }
          }
        } else {
          let sc = assignment.shipment_count ? assignment.shipment_count : (assignment.number_of_stops / 2);
          pending += assignment.driver_id ? sc : 0;
          unassigned += assignment.driver_id ? 0 : sc;
        }
      })
    }
    total = unassigned + pending + inprogress + failed + succeeded;
    return {
      total,
      unassigned,
      pending,
      inprogress,
      failed,
      succeeded,
      late,
      early
    }
  }

  get filteredShowingStops() {
    if (!this.selectedAssignment || !this.selectedAssignment.stops)
      return [];

    const stops = this.selectedAssignment.stops
      .filter(s => this.showingStopTypes.indexOf(s.type) >= 0)

    // filter
    if (this.filter) {
      const searchFields = [
        "label.driver_label",
        "shipment.customer.name",
        "shipment.customer.phone_number",
        "shipment.customer.email",
        "shipment.dropoff_address.street",
        "shipment.dropoff_address.city",
        "shipment.dropoff_address.state",
        "shipment.dropoff_address.zipcode",
        "shipment.internal_id",
        "shipment.delivery_items",
        "shipment.tracking_code",
        "shipment.id",
      ];
      return stops.filter(s => searchInObject(s, this.filter, "i", searchFields))
    }

    return stops;
  }

  @action
  updateStop(stop, merge) {
    if (this.selectedAssignment && this.selectedAssignment.stops) {
      this.selectedAssignment.stops = this.selectedAssignment.stops.map(s => {
        if (s.id === stop.id) {
          if (!merge)
            return stop;
          else {
            Object.assign(s, stop);
            return s;
          }
        }
        else return s;
      })
    }
  }

  @action
  onFocus() {
    this.applyChange = _.debounce(this.applyChange_, 15000, { 'maxWait': 20000 })
    this.applyChange()
  }

  @action
  onBlur() {
    this.applyChange = _.debounce(this.applyChange_, 60000, { 'maxWait': 120000 })
  }
}

decorate(AssignmentStore, {
  assignments: observable,
  completedAssignments: observable,
  pendingAssignments: observable,
  inProgressAssignments: observable,
  unassignedAssignments: observable,
  selectedAssignment: observable,
  selectedAssignmentId: observable,
  regions: observable,
  regionList: observable,
  date: observable,
  assignmentsRequested: observable,
  loadingAssignments: observable,
  loadingAssignment: observable,
  assigning: observable,
  unAssigning: observable,
  sortBy: observable,
  inlineFilter: observable,
  tagFilter: observable,
  activeDriverLocations: observable,
  driverCache: observable,
  updateCache: observable,
  filter: observable,
  boundary: observable,
  setInlineFilter: action,
  setDate: action,
  setRegions: action,
  loadAssignments: action,
  loadRegions: action,
  processAssignments: action,
  processInlineAssignments: action,
  refreshAssignmentList_: action,
  applyChange_: action,
  selectAssignment: action,
  loadAssignment: action,
  processActiveDrivers: action,
  assign: action,
  unassign: action,
  reassign: action,
  activate: action,
  deactivate: action,
  appendSplitedAssignment: action,
  addShipment: action,
  processAssignmentDetail: action,
  onFocus: action,
  onBlur: action,
  searchFilter: computed,
  assignmentShipmentStats: computed,
});

export default AssignmentStore;
