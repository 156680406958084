import { isEmpty } from 'lodash';
import { observable, decorate, action, runInAction } from 'mobx';
import { isDeniedAction } from 'Utils/common';

class PermissionStore {
  deniedPatterns = [];
  permissionLoaded = false;

  constructor(api) {
    this.api = api;
  }

  getAclSettings() {
    this.api.get('acl/setting/denied').then((response) => {
      if (response.status === 200) {
        runInAction(() => {
          this.permissionLoaded = true;
          this.deniedPatterns = response.data;
        });
      }
    });
  }

  isDenied(patterns) {
    if (isEmpty(patterns) || isEmpty(this.deniedPatterns)) return false;

    return isDeniedAction(patterns, this.deniedPatterns);
  }
}

decorate(PermissionStore, {
  getAclSettings: action,
  deniedPatterns: observable,
  permissionLoaded: observable,
});

export default PermissionStore;
