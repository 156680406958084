import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';

import styles, * as E from './styles';
import DispatchSearchFilter from './DispatchSearchFilter';
import DispatchAssignmentListContainer from './DispatchAssignmentListContainer';
import AssignmentListMap from './AssignmentListMap';
import AssignmentDetailContainer from './AssignmentDetailContainer';
import ShipmentDetailContainer from './ShipmentDetailContainer';
import ShipmentMessenger from '../ShipmentMessenger';
import { HistoryListComponent } from 'components/HistoryList';
import AxlButton from 'AxlMUIComponent/AxlButton';


class DispatchScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isShowMessenger: false,
      showHistoryPanel: false,
    };
  }

  componentDidMount() {
    const {store} = this.props;
    const { assignmentStore } = store;

    window.onfocus = function () {
      assignmentStore.onFocus()
    };

    window.onblur = function () {
      assignmentStore.onBlur()
    };
  }

  openPanel = () => {
    this.setState({
      showHistoryPanel: !this.state.showHistoryPanel,
      _isShowMessenger: false
    })
  }

  closePanel = () => {
    this.setState({showHistoryPanel: false});
  }

  showAssignmentDetail(a) {
    const { store, history } = this.props;
    const { assignmentStore, shipmentStore } = store;
    const day = moment(assignmentStore.start).format('YYYY-MM-DD');
    const region = assignmentStore.regions.length > 0 ? assignmentStore.regions[0] : 'all';
    if (a)
      history.push(`/routes/${day}/${region}/${a.id}`);
    else
      history.push(`/routes/${day}/${region}/`);
    assignmentStore.selectAssignment(a);
    shipmentStore.unselectStop();
  }

  onShowMessenger = () => {
    const { store } = this.props;
    const { messengerStore, assignmentStore, userStore } = store;
    const { selectedAssignmentId } = assignmentStore;
    if(!this.state._isShowMessenger) {
      // Allow open topic when assign to driver
      this.setState({
        topicLoading: true,
        assignmentLoading: true
      });
      messengerStore.loadTopicByAssignmentId(selectedAssignmentId, (res) => {
        if(res.status === 200) {
          if(userStore.user &&
            messengerStore.topicSelected &&
            messengerStore.topicSelected.follower_ids.indexOf(userStore.user.id) !== -1) {
            messengerStore.isFollow = true;
          } else {
            messengerStore.isFollow = false;
          }
          this.setState({
            topicLoading: false,
            assignmentLoading: false
          });
        } else if(res.status === 404) {
          this.setState({
            newTopic: true,
            topicLoading: false,
            assignmentLoading: false
          });
        }
      });
    } else {
      messengerStore.topicSelected = null;
      messengerStore.topicSelectedId = null;
    }
    this.setState({
      showHistoryPanel: false,
      _isShowMessenger: !this.state._isShowMessenger
    });
  }
  render() {
    const { classes, store, history } = this.props;
    const { assignmentStore } = store;
    const {selectedAssignment} = assignmentStore;
    const _isActiveAssignmentSelected = selectedAssignment && selectedAssignment.assignment && selectedAssignment.assignment.is_active;
    const isShowAssignmentList =  !this.state._isShowMessenger;
    const isShowAssignmentHistory = !!( selectedAssignment || parseInt(_.get(this, 'props.match.params.assignmentId')) );

    
    return (
      <Box p={1}>
        <Grid container>
          <Grid item xs>
            <DispatchSearchFilter />
          </Grid>
          <Grid item>
            {isShowAssignmentHistory && <AxlButton icon bgcolor={'primary.white'} variant={'outlined'} onClick={this.openPanel} style={{borderColor: '#0000003b'}}>
              {this.state.showHistoryPanel ? <ClearIcon fontSize={'small'} /> : <HistoryIcon fontSize={'small'} />}
            </AxlButton>}
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" className={classes.bodyContainer}>
          {(isShowAssignmentList || !this.state.showHistoryPanel) && <Grid item className={clsx(classes.panel, classes.assignmentListContainer)}>
            <DispatchAssignmentListContainer />
          </Grid>}
          <Switch>
            <Route
              path="/routes/:date/:region/:assignment_id"
              render={(props) => {
                const selectedAssignmentId = parseInt(_.get(props, 'match.params.assignment_id'));

                return <Fragment>
                  <Grid item className={clsx(classes.panel, classes.assignmentDetailContainer)}>
                    <AssignmentDetailContainer onShowMessenger = {this.onShowMessenger} />
                  </Grid>
                  {isShowAssignmentList ? <Grid item className={clsx(classes.panel, classes.shipmentDetailContainer)}>
                    {(this.state.showHistoryPanel)
                      ?
                      <Box bgcolor={'primary.white'} height={1} p={2} overflow={'hidden'} boxSizing={'border-box'}>
                        <HistoryListComponent
                          baseUrl={`/events/assignments/${selectedAssignmentId}`}
                          type='assignment' closePanel={this.closePanel} />
                      </Box>
                      :
                      <ShipmentDetailContainer />}
                  </Grid> :  <E.PanelMessenger style={{...{width: 'calc(100% - 880px)', minWidth: '400px'}}}>
                    <E.PanelMessengerInner style={{...styles.box}}>
                      <ShipmentMessenger
                        history={history}
                        topicLoading={this.state.topicLoading}
                        triggerMsgLoading={this.state.triggerMsgLoading}
                        activeAssignment={_isActiveAssignmentSelected}
                        assignmentLoading={this.state.assignmentLoading} />
                    </E.PanelMessengerInner>
                  </E.PanelMessenger>}
                </Fragment>}
              }
            />
            <Route
              exact
              render={() => (
                <Grid item className={clsx(classes.panel, classes.assignmentMapContainer)}>
                   <AssignmentListMap showAssignmentDetail={(assignment, shipmentId) => this.showAssignmentDetail(assignment, shipmentId)} />
                </Grid>
              )}
            />
          </Switch>
        </Grid>
      </Box>
    )
  }
}

export default compose(withStyles(styles), withRouter, inject('store'), observer)(DispatchScreen);
