import React, { Component } from 'react';
import {inject, observer} from "mobx-react";
import { toJS } from 'mobx'
import _ from 'lodash';
import moment from "moment";
import { AxlModal, AxlButton } from 'axl-reactjs-ui';
import Event from './Event';
import PropTypes from 'prop-types';

// Components
import DriverProfileInformation from '../DriverProfileInformation';
import DriverProfileRoutingTabs from '../DriverProfileRoutingTabs';

// Styles
import styles, * as E from "./styles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class HistoryListComponentCompose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverData: {},
      showDriverProfile: false
    }
    this.onShowDriverProfile = this.onShowDriverProfile.bind(this);
  }
  componentDidMount() {
    const {type, baseUrl, keepData} = this.props;
    const {historyStore} = this.props.store;
    const store = type ? historyStore.getStore(type) : historyStore;

    if (baseUrl) {
      store.setBaseUrl(baseUrl);
    }

    store.search();
  }

  onShowDriverProfile = (driver) => {
    const that = this;

    if(driver && driver.id) {
      this.props.store.driverStore.get(driver.id, function(res) {
        if(res.status === 200) {
          that.setState({driverData: res.data, showDriverProfile: true});
        }
      });
    }
  }

  onHideDriverProfile = () => { this.setState({showDriverProfile: false}) }

  render() {
    const {type, baseUrl, keepData, viewDispatch, isShowTitle = true, disableScroll} = this.props;
    const {historyStore} = this.props.store;
    const store = type ? historyStore.getStore(type) : historyStore;
    const {result} = store;
    const params = result && result.params ? result.params : {};
    const { showDriverProfile, driverData } = this.state;

    const shipmentActionMap = {
      'update-shipment-pickup': 'Update Pickup Information',
      'update-shipment-dropoff': 'Update Dropoff Information',
      'update-shipment-customer': 'Update Customer Information',
      'add-bonus': 'Add Bonus',
      'update-bonus': 'Update Bonus',
    };

    const assignmentActionMap = {
      'assign': 'is assigned',
      'unassign': 'is un-assigned',
    };

    const render = {
      assignment: {
        title: (item) => `${_.capitalize(item.action)} Assignment`,
        content: (item) => {
          const driver = item.data.driver_id && params.drivers[item.data.driver_id] ? params.drivers[item.data.driver_id] : null;
          const assignmentLabel = item.ref_id && params.assignment_labels[item.ref_id] ? params.assignment_labels[item.ref_id] : null;

          return <span>
            Driver <E.DriverLink style={styles.driverName} onClick={() => this.onShowDriverProfile(driver)}>{driver ? `${driver.first_name} ${driver.last_name} [${driver.id}]`: `unknown`} </E.DriverLink>
            { _.defaultTo(assignmentActionMap[item.action], item.action)} route {assignmentLabel ? `${assignmentLabel.prefix}`: 'unknown'}
            { (item.action === 'assign' || item.action === 'unassign') &&
              item.username && <span> by <strong style={styles.driverName}>{item.username}</strong></span> }
            { (item.action === 'assign' || item.action === 'unassign' && item.data && item.data.reason) &&
              <span> with reason: <strong style={styles.driverName}>{item.data.reason}</strong></span>}
          </span>
        }
      },
      'assignment-add-bonus': {
        title: (item) => `${_.capitalize(item.action)}`,
        content: (item) => {
          const assignmentLabel = item.ref_id && params.assignment_labels[item.ref_id] ? params.assignment_labels[item.ref_id] : null;

          return <span>
            User <strong style={styles.driverName}>{item.username ? item.username: `unknown`} </strong>
            added <strong style={styles.driverName}>${item.data.new_bonus}</strong> to Assignment {assignmentLabel ? `${assignmentLabel.prefix}`: 'unknown'},
            with reason: <strong style={styles.driverName}>{item.data.reason ? item.data.reason: ''}</strong>
          </span>
        }
      },
      'assignment-update-bonus': {
        title: (item) => `${_.capitalize(item.action)}`,
        content: (item) => {
          const assignmentLabel = item.ref_id && params.assignment_labels[item.ref_id] ? params.assignment_labels[item.ref_id] : null;

          return <span>
            User <strong style={styles.driverName}>{item.username ? item.username: `unknown`} </strong>
            updated bonus of Assignment {assignmentLabel ? `${assignmentLabel.prefix}`: 'unknown'} from <strong style={styles.driverName}>${item.data.old_bonus}</strong> to <strong style={styles.driverName}>${item.data.new_bonus}</strong>,
             with reason: <strong style={styles.driverName}>{item.data.reason ? item.data.reason: ''}</strong>
          </span>
        }
      },
      shipment: {
        title: item => shipmentActionMap[item.action] ? shipmentActionMap[item.action]: _.startCase(item.action),
        content: item => {
          // get data
          const contentHeader = <span>User <strong>{item.username ? `${item.username}[${item.user_id}]`: `[${item.user_id}]`}</strong> updated data</span>
          return <div>{contentHeader}
            {item.data && <div><code><ul> {_.map(item.data, (v, k) => {
              const fieldHistory = JSON.parse(v);
              return <li key={k}><strong>{_.startCase(fieldHistory.name)}</strong>: from <strong>{fieldHistory.old ? fieldHistory.old: 'Null'}</strong> to <strong>{fieldHistory.new ? fieldHistory.new: 'Null'}</strong></li>
            })}</ul></code></div>}
          </div>;
        }
      },
      DATE: {
        title: (item) => 'Date',
        content: (item) => {
          return <span style={{ textDecoration: 'underline' }}>{item.date}</span>
        }
      }
    };

    let date = ''
    let events = _.reverse(_.sortBy(result.items, e => e.ts))
      .flatMap(e => {
        let d = moment(e.ts).format('dddd M/D/YYYY').toUpperCase()
        if (d === date)
          return [e]
        else {
          date = d
          return [{type: 'DATE', category: 'TIME', date: d, action: 'DATE'}, e]
        }
      })

    return <div style={styles.container}>
      {isShowTitle && <div style={styles.title}>
        <E.Title>{`Assignment ${_.get(this, 'props.store.assignmentStore.selectedAssignment.assignment.label', 'N/A')} - History`}</E.Title>
      </div>}
      {(result && result.items && result.items.length > 0) ? <div style={!disableScroll ? styles.items : {
        ...styles.items,
        overflowY: 'hidden'
      }}>
        <div style={styles.innerItems}>
          <div style={styles.line}></div>
          {events.map((item, index) => <Event event={toJS(item)} key={index} />)}
        </div>
      </div> : <div style={styles.noHistory}>{`No History`}</div>}
      {(showDriverProfile && driverData) && <AxlModal style={styles.modalDriverProfileContainer} onClose={this.onHideDriverProfile}>
          <DriverProfileInformation driver={driverData} />
          <DriverProfileRoutingTabs driver={driverData} onSave={this.onHideDriverProfile} history={this.props.history} />
      </AxlModal>}
    </div>
  }
}

export const HistoryListComponent = compose(
  withRouter,
  inject('store'),
  observer
)(HistoryListComponentCompose);

HistoryListComponent.propTypes = {
  viewDispatch: PropTypes.bool
};

HistoryListComponent.defaultProps = {
  viewDispatch: false
};
