import React, { Component } from 'react';
import styles from './styles';

import { AxlInput, AxlPanel, AxlReselect, AxlDateInput, AxlCheckbox, AxlButton, AlxFlatDateInput } from 'axl-reactjs-ui';
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class ShipmentDropoffForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  save = (e) => {
    const {shipmentStore, shipment, dropoff, closeForm} = this.props;
    shipmentStore.updateShipmentDropoff(shipment, dropoff, () => {
      closeForm();
    }, (error) => {
      this.setState({error: error.message})
    });
  };

  render() {
    const { shipment, dropoff, shipmentStore } = this.props;
    const { shipmentPickupForm, dropoffStopForm, shipmentDropoffForm, updatingShipmentDropoff } = shipmentStore;
    const stopStatuses = [
      { label: 'PENDING', value: "PENDING" },
      { label: 'SUCCEEDED', value: "SUCCEEDED" },
      { label: 'FAILED', value: "FAILED" },
      { label: 'REATTEMPT', value: "REATTEMPT" },
    ];
    const dropoffStatus = dropoffStopForm.data.status ? { label: dropoffStopForm.data.status, value: dropoffStopForm.data.status } : null;
    const dropoffTimeOptions = {
      dateFormat: 'MMM DD, Y HH:mm:SS A',
      placeHolder: 'Dropoff Time',
      enableTime: true,
      altInput: true,
      clickOpens: true,
      defaultValue: dropoffStopForm.data.actual_departure_ts ? dropoffStopForm.data.actual_departure_ts : null
    };
    const datePicker1 = {
      dateFormat: 'MMM DD, Y HH:mm:SS A',
      placeHolder: 'Earliest Dropoff',
      enableTime: true,
      altInput: true,
      clickOpens: true,
      defaultValue: shipment.dropoff_earliest_ts
    };
    const datePicker2 = {
      dateFormat: 'MMM DD, Y HH:mm:SS A',
      placeHolder: 'Latest Dropoff',
      enableTime: true,
      altInput: true,
      clickOpens: true,
      defaultValue: shipment.dropoff_latest_ts
    };

    return <div style={styles.Container}>
      <div style={styles.HeaderTitle}>{`Edit Dropoff`}</div>
      <AxlPanel>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <AxlPanel.Row align={`center`}>
              <AxlPanel.Col>
                <div style={styles.GroupTitle}>{`Address Line 1`}</div>
                <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('address.street', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`Address Line 1`} name={`address.street`} type={`text`} fluid /></div>
                <div style={styles.GroupTitle}>{`Address Line 2`}</div>
                <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('address.street2', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`Address Line 2`} name={`address.street2`} type={`text`} fluid /></div>
                <div style={styles.GroupField}>
                  <AxlPanel.Row>
                    <AxlPanel.Col>
                      <div style={styles.GroupTitle}>{`City`}</div>
                      <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('address.city', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`City`} name={`address.city`} type={`text`} fluid /></div>
                    </AxlPanel.Col>
                    <AxlPanel.Col>
                      <div style={styles.GroupTitle}>{`State`}</div>
                      <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('address.state', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`State`} name={`address.state`} type={`text`} fluid /></div>
                    </AxlPanel.Col>
                    <AxlPanel.Col>
                      <div style={styles.GroupTitle}>{`Zipcode`}</div>
                      <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('address.zipcode', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`Zipcode`} name={`address.zipcode`} type={`text`} fluid /></div>
                    </AxlPanel.Col>
                  </AxlPanel.Row>
                </div>
                <div style={styles.GroupTitle}>{`Dropoff Notes`}</div>
                <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('note', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`Dropoff Notes`} name={`note`} type={`text`} fluid /></div>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <div style={styles.GroupTitle}>{`TIME WINDOW`}</div>
            <div style={styles.GroupField}>
              <AxlPanel.Row align={`center`}>
                <AxlPanel.Col><div style={styles.Field}><AxlDateInput onChange={shipmentDropoffForm.handlerDateInput('dropoff_earliest_ts')} displayToday={false} options={datePicker1} theme={`main`} /></div></AxlPanel.Col>
                <AxlPanel.Col flex={0}><div style={{...styles.Field, ...styles.labelHours}}></div></AxlPanel.Col>
                <AxlPanel.Col><div style={styles.Field}><AxlDateInput onChange={shipmentDropoffForm.handlerDateInput('dropoff_latest_ts')} displayToday={false} options={datePicker2} theme={`main`} /></div></AxlPanel.Col>
              </AxlPanel.Row>
            </div>
          </AxlPanel.Col>
        </AxlPanel.Row>
        {this.state.error && <ul style={styles.listError}>
          <li style={styles.errorText}>{this.state.error}</li>
        </ul>}
        <AxlPanel.Row>
          <AxlPanel.Col>
            <AxlPanel.Row align={`flex-end`}>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`gray`} onClick={this.props.closeForm} style={styles.buttonControl}>{`Cancel`}</AxlButton></div>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton loading={updatingShipmentDropoff} compact bg={`pink`} onClick={this.save} style={styles.buttonControl}>{`Save`}</AxlButton></div>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
      </AxlPanel>
    </div>
  }
}
export default compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentDropoffForm);
