import React, {createRef, useCallback, useEffect, useState} from 'react';
import {Box, CircularProgress, Grid} from "@mui/material";
import AxlButton from "../../AxlMUIComponent/AxlButton";
import * as S from './styles';
import AxlConfirmation from "../../AxlMUIComponent/AxlConfirmation";
import AxlModal from "../../AxlMUIComponent/AxlModal";
import {AxlModalBox, AxlSimpleBox} from "../../AxlMUIComponent/AxlBox";
import AxlSelect from "../../AxlMUIComponent/AxlSelect";
import Dropzone, {useDropzone} from "react-dropzone";
import _ from 'lodash';
import api from "../../stores/api";
import moment from 'moment';
import {DOSSIER_MAP_TO_DOCUMENT_TYPE} from "../../constants/dossier";
import Utils from "../../Utils/downloadFile";

export function DriverProfileDocuments({
                                         driver,
                                         ...props
}) {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dossiers, setDossiers] = useState([]);
  const [files, setFiles] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [isCloseUploadModal, setCloseUploadModal] = useState(true);
  const isDisabled = (!files.length || !documentType);

  // options
  const UPLOAD_OPTIONS = _.defaultTo(Object.keys(DOSSIER_MAP_TO_DOCUMENT_TYPE), []).map(d => ({
    label: DOSSIER_MAP_TO_DOCUMENT_TYPE[d],
    value: d
  }));

  useEffect(() => {
    onLoadDocument();
  }, []);

  const upload = () => {
    if (!_.has(driver, 'id')) {
      return;
    }
    if (!files.length) {
      alert('File is required');
    } else if (!documentType) {
      alert('Document Type is required');
    } else {
      setUploading(true);
      // form data
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('category', documentType);

      api.post(`/dsp/drivers/upload-document/${_.get(driver, 'id')}`, formData).then(res => {
        if (res.ok || res.status === 204) {
          setCloseUploadModal(true);
          onLoadDocument();
        } else {
          alert(_.defaultTo(_.get(res, 'message'), 'There is a problem uploading your document'));
        }
        setUploading(false);
      });
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setFiles(acceptedFiles);
  }, []);

  const onChangeFileType = ({target: {name, value}}) => {
    setDocumentType(value);
  };

  const onLoadDocument = (showLoading = true) => {
    if (!_.has(driver, 'id')) {
      return;
    }

    // clear form data for each loading
    clearFormData();

    const id = _.get(driver, 'id');

    if (showLoading) {
      setLoading(true);
    }

    const params = {
      "owner": {
        "uid": `DR_${id}`
      },
      "show_only_valid": false,
      "show_only_outdated": false
    };
    api.post(`/dossiers/list_by_owner`, params).then(res => {
      if (res.ok || res.status === 200) {
        setDossiers(res.data);
      } else {
        alert('There is a problem loading your image.');
      }
      setLoading(false);
    });
  };

  const clearFormData = () => {
    setFiles([]);
    setDocumentType(null);
  };

  const onClose = () => {
    clearFormData();
    setCloseUploadModal(true);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return <Box px={2} py={4} height={1} boxSizing={'border-box'}>
    <Box height={1}>
      <Box height={282} style={{overflowX: 'hidden', overflowY: 'scroll'}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <S.Label>{`DOCUMENT NAME`}</S.Label>
          </Grid>
          <Grid item xs={3}>
            <S.Label>{`DOCUMENT TYPE`}</S.Label>
          </Grid>
          <Grid item xs={3}>
            <S.Label>{`ADDED DATE`}</S.Label>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        {loading ? <Box height={'calc(100% - 40px)'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress size={75} thickness />
        </Box> : <Box mt={3}>
          {dossiers.length ? dossiers.map((d, i) =>
            <DocumentItem key={i}
                          driver={driver} dossier={d}
                          onLoadDocument={onLoadDocument}
                          clearFormData={clearFormData} />) : 'No Document'}
        </Box>}
      </Box>
      <AxlModal trigger={<AxlButton variant={'outlined'} bgcolor={'primary.gray400'} color={'primary.graySecond'}>{`Upload Document`}</AxlButton>}
                isClose={isCloseUploadModal} onRendered={() => setCloseUploadModal(false)} onClose={onClose}>
        <AxlSimpleBox bgcolor={"primary.white"} width={650} maxWidth={'100%'}>
          <Box mb={3}>
            <S.ModalTitle>{`Upload document`}</S.ModalTitle>
          </Box>
          <Box mb={1}>
            <S.DropZoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? <p>{`Drop the files here ...`}</p> : (files && files.length) ? <p>{_.get(files, '[0].name')}</p> : <p>{`Drop the files here ...`}</p>}
            </S.DropZoneContainer>
          </Box>
          <Box mb={1}>
            <Box mb={1}>
              <S.Label_1 component={"span"}>{`Document Type`}</S.Label_1>
            </Box>
            <AxlSelect theme={'main'} options={UPLOAD_OPTIONS}
                       name={'DOCUMENT_TYPE'} value={documentType} onChange={onChangeFileType} />
          </Box>
          <Box>
            <Grid container>
              <Grid item xs />
              <AxlButton
                minWidth={120} variant={'outlined'} bgcolor={'primary.white'} color={'primary.gray'}
                onClick={onClose}>{`Cancel`}</AxlButton>
              <AxlButton
                minWidth={120} disabled={isDisabled} variant={'contained'} loading={uploading}
                bgcolor={(isDisabled || uploading) ? 'primary.gray400' : 'primary.periwinkleSecondary'} color={'primary.white'}
                onClick={upload}>{`Upload`}</AxlButton>
            </Grid>
          </Box>
        </AxlSimpleBox>
      </AxlModal>
    </Box>
  </Box>
}

export function DocumentItem({driver, dossier, onLoadDocument = () => {}, ...props}) {
  const [removing, setRemoving] = useState(false);
  const [isCloseRemoveModal, setCloseRemoveModal] = useState(true);
  const photoPreviewUrl = _.get(dossier, 'public_path') || _.get(dossier, 'path') || null;
  const isShowImagePreview = photoPreviewUrl.match(/\.png|\.jpg|.jpge|\.gif/ig);
  const driverFullName =  _.get(driver, 'first_name', '') +
                          _.get(driver, 'middle_name', '') +
                          _.get(driver, 'last_name', '') +
                          _.defaultTo(' [id:' + _.get(driver, 'id', 'N/A') + ']', '');

  const onRemove = (driverId, dossierId) => {
    if (!driverId || !dossierId) {
      return;
    }
    setRemoving(true);
    api.delete(`/dsp/drivers/dossier/${driverId}/${dossierId}`).then(res => {
      if (res.ok || res.status === 204) {
        setCloseRemoveModal(true);
        onLoadDocument(false);
      } else {
        alert('There is a problem uploading your image.')
      }
      setRemoving(false);
    })
  };

  const fileName = _.get(dossier, 'name');

  return <Box>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <S.Text>{_.get(dossier, 'name', 'N/A')}</S.Text>
      </Grid>
      <Grid item xs={3}>
        <S.Text>{_.has(dossier, 'category') ? DOSSIER_MAP_TO_DOCUMENT_TYPE[_.get(dossier, 'category')] : 'N/A'}</S.Text>
      </Grid>
      <Grid item xs={3}>
        <S.Text>{_.has(dossier, '_created') ? moment(_.get(dossier, '_created')).format('MM/DD/YYYY - HH:mmA') : 'N/A'}</S.Text>
      </Grid>
      <Grid item xs={2}>
        {isShowImagePreview ? <AxlModal trigger={<S.ViewText>{`View`}</S.ViewText>}>
          <AxlModalBox>
            {photoPreviewUrl ? <img src={photoPreviewUrl} style={{maxWidth: '100%', maxHeight: '75vh'}} /> : 'No Image'}
          </AxlModalBox>
        </AxlModal> : <S.ViewText onClick={() => Utils.downloadRemoteFile(photoPreviewUrl, fileName)}>{`View`}</S.ViewText>}
        <AxlModal trigger={<S.RemoveText>{`Remove`}</S.RemoveText>}
                  isClose={isCloseRemoveModal} onRendered={() => setCloseRemoveModal(false)}>
          <AxlConfirmation
            title={`Are you sure to remove [${_.get(dossier, 'category', 'N/A Document Type')}] document from driver ${driverFullName}?`}
            doText={`Remove`}
            onDo={() => onRemove(_.get(driver, 'id'), _.get(dossier, 'id'))}
            loading={removing}
            onClose={() => setCloseRemoveModal(true)}
            bgcolor={'primary.white'} doProps={{bgcolor: 'primary.periwinkle', color: 'primary.white'}}
            style={{width: 470}} />
        </AxlModal>
      </Grid>
    </Grid>
  </Box>
}