const styles = theme => ({
  container: {
    fontSize: 13,
    width: 240,
    textAlign: 'center',
    margin: 0,
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  col_left: {
    width: '40%',
    textAlign: 'right',
    paddingRight: '8px',
    marginBottom: '3px'
  },
  col_right: {
    width: '60%',
    fontWeight: 'bold',
    textAlign: 'left'
  },
});

export default styles;