import React, { Component } from 'react';
import propTypes from 'prop-types';
import downloadFile from "../../../Utils/downloadFile";

import styles, * as E from './styles';

export default class ShareFileDetail extends Component {
  render() {
    return <E.Container>
      <E.ImageContainer>
        <E.Image src={this.props.imgUrl} />
      </E.ImageContainer>
      <E.DownloadContainer>
        <E.DownloadButton>
          <E.DownloadButtonImage
            src={`/assets/images/download.png`}
            onClick={() => downloadFile.download(this.props.imgUrl, 'download', 'image/jpeg')} />
        </E.DownloadButton>
      </E.DownloadContainer>
    </E.Container>;
  }
}

// ShareFileDetail.PropTypes = {
//   imgUrl: propTypes.string
// };
//
// ShareFileDetail.defaultProps = {
//   imgUrl: null
// };