import React, { Component, } from 'react';
import c3 from 'c3';
import BarChart from './BarChart';
import _ from 'lodash';
import { AxlButton } from 'axl-reactjs-ui';
import moment from "moment";
import clsx from "clsx";

// Styles
import styles, { Statuses } from './styles';
import { Box, Grid } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

class AssignmentChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otd_stats: [],
      isCloseCharts: false,
      isOpenAssignmentDetail: false
    }
  }

  renderChart(stats) {
    const { unassigned = 0, pending = 0, inprogress = 0, failed = 0, succeeded = 0 } = stats
    if (!this.chart)
      this.chart = c3.generate({
        bindto: "#chart-1",
        size: {
          width: 160,
          height: 200
        },
        data: {
          labels: true,
          x: undefined,
          order: null,
          columns: [
            ['unassigned', unassigned],
            ['pending', pending],
            ['in_progress', inprogress],
            ['failed', failed],
            ['succeeded', succeeded]
          ],
          type: 'donut',
          colors: {
            unassigned: '#bebfc0',
            pending: '#fa6725',
            in_progress: '#fbc04f',
            succeeded: '#66b752',
            failed: '#d63031',
          },
          names: {
            unassigned: 'Unassigned',
            pending: 'Pending',
            in_progress: 'In Progress',
            succeeded: 'Succeeded',
            failed: 'Failed',
          }
        },
        tooltip: {
          grouped: false,
          format: {
            value: (value, ratio, id, index) => `${(ratio * 100).toFixed(1)}% [${value}]`
          }
        },
        donut: {
          label: {
            show: false
          }
        },
        legend: {
          // amount of padding to put between each legend element
          padding: 5,
          item: {
            tile: {
              width: 10,
              height: 10
            }
          }
        }
      });
    else {
      this.chart.load({columns:[
          ['unassigned', unassigned],
          ['pending', pending],
          ['in_progress', inprogress],
          ['succeeded', succeeded],
          ['failed', failed],
        ]})
    }
  }

  componentDidMount () {
    const { stats = {} } = this.props;
    const { unassigned = 0, pending = 0, inprogress = 0, early = 0, late = 0, failed = 0, succeeded = 0 } = stats;
    this.setState({
      otd_stats: [{
        value: early,
        title: 'Early',
        color: '#a5ccec'
      },{
        value: succeeded - early - late,
        title: 'On Time',
        color: '#66b752'
      },{
        value: late,
        title: 'Late',
        color: '#8447f6'
      },{
        value: failed,
        title: 'Failed',
        color: '#d63031'
      }
      ]
    });
    this.renderChart(stats);
  }

  toggleAssignmentDetail = () => {
    const { stats = {} } = this.props;
    this.setState({isOpenAssignmentDetail: !this.state.isOpenAssignmentDetail})
  };

  onToggleChart = () => {
    this.props.onCollapse();
    this.setState({isCloseCharts: !this.state.isCloseCharts})
  };

  render() {
    const { isOpenAssignmentDetail, isCloseCharts } = this.state;
    const {classes, date, stats} = this.props;
    const statsList = [
      {statusColor: 'default', label: 'TOTAL SHIPMENTS', value: stats.total},
      {statusColor: 'default', label: 'TOTAL COMPLETED', value: stats.succeeded + stats.failed},
      {statusColor: 'early', label: 'EARLY', value: stats.early},
      {statusColor: 'failed', label: 'FAILED', value: stats.failed},
      {statusColor: 'in_progress', label: 'IN PROGRESS', value: stats.inprogress},
      {statusColor: 'late', label: 'LATE', value: stats.late},
      {statusColor: 'pending', label: 'PENDING', value: stats.pending},
      {statusColor: 'succeeded', label: 'SUCCEEDED', value: stats.succeeded},
      {statusColor: 'unassigned', label: 'UNASSIGNED', value: stats.unassigned},
    ];

    return (
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <Grid item className={classes.title}>
          {!isCloseCharts && (
            <AxlButton tiny bg={`${isOpenAssignmentDetail ? 'none' : 'gray'}`}
                       className={classes.toggleButton}
                       ico={{className: 'fa fa-info', style: {fontSize: '20px'}}}
                       onClick={this.toggleAssignmentDetail} />
          )}
          <Box className={classes.innerTitle} style={{textAlign: !isCloseCharts ? 'center' : 'left'}}>
            {`Shipment Breakdown`}
            <span className={classes.dateTitle} style={{display: !isCloseCharts ? 'block' : 'inline-block'}}>
              {moment(date).format('ddd MMMM DD')}
            </span>
          </Box>
          <Box onClick={this.onToggleChart} className={classes.toggleChartLink}>
            {!isCloseCharts ? `Hide chart` : `Show chart`}
          </Box>
        </Grid>
        <Grid item style={{...{display: !isCloseCharts ? 'block' : 'none'}}}>
          <Grid container className={classes.panelContainer} style={{display: isOpenAssignmentDetail ? 'flex' : 'none'}}>
            <div className={classes.innerPanelContainer}>
              {statsList.map((stat, idx) => (
                <div className={classes.rowTitle} key={idx}>
                  <label className={classes.rowLabel} style={{color: Statuses[stat.statusColor]}}>
                    {stat.label}:
                  </label>
                  {stat.value}
                </div>
              ))}
            </div>
          </Grid>
          <Grid className={classes.panelContainer} style={{display: isOpenAssignmentDetail ? 'none' : 'flex'}}>
            <Box className={classes.pane}>
              <div className={classes.info}>
                total shipments<br />
                <span className={classes.shipmentTotal}>{stats.total}</span>
              </div>
              <div id="chart-1" className={classes.chart} />
            </Box>
            <Box className={classes.pane}>
              <div className={classes.info}>
                total completed<br />
                <span className={classes.shipmentTotal}>{stats.succeeded + stats.failed}</span>
              </div>
              <BarChart height='160px' width={30} data = {this.state.otd_stats} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AssignmentChart);
