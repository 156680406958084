import React from "react";
import _ from 'lodash';
import { AxlSearchBox, AxlMessageTitle, AxlMessageTitleGroup } from 'axl-reactjs-ui';
// styles
import styles, * as E from './styles.js';
import {inject, observer} from "mobx-react";
import moment from "moment";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
// Components

export class MessengerActiveTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEndTopics: props.isEndTopics
    }
    this._handleInputText = _.debounce(this._handleInputText.bind(this),300)
  }

  componentWillUnmount() {
    const { messengerStore, handleTopicSelected } = this.props.store;
    this.setState({
      isUpdatedUnattended: false,
      isUpdatedAttended: false,
      isUpdatedFollowing: false
    });
    // messengerStore.topicSelectedId = null;
    // messengerStore.topicSelected = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isEndTopics !== this.props.isEndTopics)  {
      this.setState({isEndTopics: this.props.isEndTopics});
    }
  }

  _handleSelectedTopic = (topic) => {
    this.props.handleTopicSelected(topic.id);
  }

  _handleInputText = (e) => {
    this.props.onSearch(e);
  }

  render() {
    const { isUpdatedFollowing,
      isUpdatedAttended, isUpdatedUnattended,
      sectionFollowing, sectionAttended,
      sectionUnattended,
    } = this.props;
    const { messengerStore, userStore } = this.props.store;
    // const groupFollowing = _.sortBy(sectionFollowing, ['assignment.label'], ['asc']);
    const groupAttended = _.sortBy(sectionAttended, ['assignment.label'], ['asc']);
    const groupUnattened = _.sortBy(sectionUnattended, ['assignment.label'], ['asc']);
    const followingUnreadCounter = sectionFollowing.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const unattendedUnreadCounter = sectionUnattended.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const attendedUnreadCounter = sectionAttended.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const isUpdated = isUpdatedFollowing && isUpdatedAttended && isUpdatedUnattended;

    const groupData = {
      'FOLLOWING': {
        title: 'FOLLOWING',
        unread: followingUnreadCounter,
        topics: sectionFollowing || []
      },
      'UNATTENDED': {
        title: 'UNATTENDED',
        unread: unattendedUnreadCounter,
        topics: groupUnattened || []
      },
      'ATTENDED': {
        title: 'ATTENDED',
        unread: attendedUnreadCounter,
        topics: groupAttended || []
      },
    };

    return <E.Container>
      <E.Inner>
        <E.SearchContainer>
          <AxlSearchBox
            theme={`default`}
            placeholder='Search by assignment label'
            defaultValue={``}
            style={{width: '100%'}}
            onChange={this._handleInputText} />
        </E.SearchContainer>
        <E.Scrollable>
          <AxlMessageTitleGroup
            showHeadCounter={true}
            userStore={userStore}
            isUpdated={isUpdated}
            group={groupData}
            isEndTopics={this.props.isEndTopics}
            handleSelectedTopic={this._handleSelectedTopic}
            topicSelected={messengerStore.topicSelected}
            loadMore={this.props.loadMore} />
        </E.Scrollable>
      </E.Inner>
    </E.Container>;
  }
}

export default compose(
  withRouter,
  inject("store"),
  observer
)(MessengerActiveTab);