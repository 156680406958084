export default {
  main: '#887fff',
  whiteTwo: '#fafafa',
  white: '#fff',
  blueEyes: "#f4f3ff",
  black: '#000000',
  black35: 'rgba(0,0,0,0.35)',
  blackSecondary: '#5a5a5a',
  blackThird: '#2a2444',
  textSecondary: '#393060',
  gray: '#8d8d8d',
  gray400: '#cccccc66',
  grayMain: '#CCCCCC',
  graySecond: '#9b9b9b',
  grayThird: '#96979a',
  grayFourth: '#f7f7f7',
  grayFifth: '#eaeaea',
  graySixth: '#eaeaea',
  graySeventh: '#979797',
  grayEighth: '#f9f9f9',
  grayNinth: '#707070',
  grayTenth: '#a9a9a9',
  grayEleventh: '#f4f4f4',
  grayTwelfth: '#aeaeae',
  grayThirteenth: '#e4e4e4',
  grayFourteenth: '#f6f6f6',
  periwinkleSecondary: '#6c62f5',
  periwinkleThird: '#aca6f5',
  periwinkleFourth: '#e6e5fe',
  red: '#d63031',
  redSecond: '#d0021b',
  redThird: '#ffdfe3',
  green: '#4abc4e',
  greenSecondary: '#edffed',
  greenThird: '#75c31e',
  greenFourth: '#5eb661',
  cream: '#fff0cf',
  link: '#4a90e2',
  blackMain: '#4a4a4a',
  blackdrop: '#4a4a4a59',
  blueLight: '#41376b',
  blueNight: '#5c558e',
  blueNightTwo: '#68648b',
  transparent: '#00000000',
  yellow: '#f2f307',
  yellowSecondary: '#f0a323',
  yellowDark: '#f5a623',
  brownNight: '#5e5b78',
  ['dark']: '#2a2444',
  ['slate-grey']: '#5e5c78',
  ['greyish-brown']: '#4a4a4a',
  ['light-periwinkle']: '#dae2ec',
  ['apple-green']: '#76c520',
  ['light-grey-blue']: '#a1b1c2',
  ['periwinkle']: '#887fff',
  ['greyblue']: '#8192a7',
  ['very-light-pink']: '#d5d5d5',
  ['very-light-pink']: '#bfbfbf',
  ['pale-grey']: '#fcfcff',
  ['black-20']: '#887fff33',
  ['dark']: '#0f0c1b',
  ['brown-grey']: '#838383',
  ['dark']: '#13101f',
  ['pale-grey']: '#f6f5f8',
  ['light-periwinkle']: '#ccc8ff',
  ['brownish-grey']: '#626262',
  ['periwinkle-20']: '#887fff33',
  ['pale-grey']: '#eceef7',
}