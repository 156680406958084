import React, {useEffect, useState} from 'react';
import {Box, IconButton} from "@mui/material";
import AxlTable from "../../AxlMUIComponent/AxlTable";
import schema from "./schema";
import _ from 'lodash';
import {Text, Title} from './styles';
import moment from "moment-timezone";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import utils from "../../Utils/downloadFile";
import {getFile} from "../../repositories";

export default function VehicleDocument(props) {
  // local
  const [item, setItem] = useState(props.item);
  // variables
  const {fields} = schema['DEFAULT'];
  const insurance_card_url = _.get(item, 'insurance_card_url');
  const registration_record_url = _.get(item, 'registration_record_url');
  const insurance_card_expired_ts = _.get(item, 'insurance_card_expired_ts') ? moment(_.get(item, 'insurance_card_expired_ts')).format('MM/DD/YYYY') : 'N/A';
  const registration_record_expired_ts = _.get(item, 'registration_record_expired_ts') ? moment(_.get(item, 'registration_record_expired_ts')).format('MM/DD/YYYY') : 'N/A';
  const result = {
    items: [
      insurance_card_url ? {'document': insurance_card_url, "ts": insurance_card_expired_ts, 'expired': _.get(item, 'insurance_card_expired')} : null,
      registration_record_url ? {'document': registration_record_url, "ts": registration_record_expired_ts, 'expired': _.get(item, 'registration_record_expired')} : null,
    ].filter(i => i)
  };

  useEffect(() => {
    if(item && item.id) {
      getFile(item.id).then(res => {
        if(res.ok && res.status === 200) {
          setItem(_.assign({}, props.item, res.data));
        }
      });
    }
  }, [props.item]);

  const trimFileName = (name) => {
    if(!name)
      return 'N/A';
    return name.split(/.*[\/|\\]/)[1].split(/\?/)[0];
  }

  const rerender = {
    'document': (value, item, result) => value ? <span>{trimFileName(value)}</span> : 'N/A',
    'ts': (value, item, result) => value ? <span style={item['expired'] ? {color: 'red'} : {}}>{value}</span> : 'N/A',
    'expired': () => null,
    'action': (value, item, result) => item && <IconButton
      onClick={() => utils.downloadRemoteFile(item.document, trimFileName(item.document))}
      size="large">
      <CloudDownloadIcon fontSize={'small'} />
    </IconButton>
  };

  return <Box>
    <Box mb={3}><Title>{`Vehicle Documents`}</Title></Box>
    <Box><Text>{`${_.get(item, 'car.year', '')} ${_.get(item, 'car.make', '')} ${_.get(item, 'car.model', '')} ${_.get(item, 'car.submodel', '')}`}</Text></Box>
    <Box><Text>{_.get(item, 'vehicle_name', 'N/A Name')}</Text></Box>
    <Box mt={1}>
      <AxlTable theme={'dark'} fields={fields}  result={result} rerender={rerender} isPagination={false} />
    </Box>
  </Box>
}