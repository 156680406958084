const styles = theme => ({
  container: {
    height: '100%',
    boxSizing: 'border-box',
  },
  label: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 10,
    fontWeight: '600',
    color: '#bebfc0',
    marginBottom: '3px'
  },
  title: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 16,
    fontWeight: '600',
    color: '#4a4a4a'
  },
  text: {
    fontFamily: 'AvenirNext',
    fontSize: '10px',
    color: '#000000'
  },
  highlightCode: {
    backgroundColor: 'rgba(136, 127, 255, 0.5)',
    display: 'inline-block',
    padding: '4px 8px',
    margin: '2px 4px',
    borderRadius: 3,
    color: '#3b3b3b',
    fontSize: 12,
    verticalAlign: 'middle',
  },
  statusBox: {
    fontFamily: 'AvenirNext-Italic',
    fontSize: 11,
    color: '#848484',
    fontStyle: 'italic',
    fontWeight: '300'
  },
  status: {
    fontWeight: '400',
    fontSize: 12,
  },
  driverSection: {
    height: 'calc(100% - 70px)',
  },
  dialogPaper: {
    padding: '24px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  dialogContent: {
    margin: '8px 0px',
    color: '#bfbfbf',
  },
  dialogButton: {
    padding: '4px 48px',
  },
  footerScroll: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingBottom: 10,
  },
});

export default styles;