import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AxlPanel, AxlButton, AxlModal, Colors, AxlTabSimple } from 'axl-reactjs-ui';

// Utils

// Components
import ShipmentHistoryList from '../ShipmentHistoryList';

// Styles
import styles from './styles';
import ShipmentDetailPanel from "../../containers/ShipmentMessenger/ShipmentDetailPanel";
import AddressHistoryList from "../AddressHistoryList";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

export default class ShipmentHistoryInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPanel: this.props.isOpen
    }
  }

  render() {
    const { shipmentHistory = {}, shipment } = this.props;
    return  <AxlPanel style={styles.panelContainer}>
      <AxlTabSimple disableRipple={true} titleStyle={{textAlign: 'center'}} activedTab={0} items={[
        {title: 'Shipment History', component: <ShipmentHistoryTab shipment={shipment} shipmentHistory={shipmentHistory} />},
        {title: 'Address History', component: <AddressHistoryListContainer shipment={shipment} />},
      ]} />
    </AxlPanel>
  }
}

class ShipmentHistoryTab extends Component {
  render() {
    const { shipmentHistory = {}, shipment } = this.props;

    return <AxlPanel.Row style={styles.panelContent}>
      <AxlPanel.Col flex={1}>
        <ShipmentHistoryList shipment={shipment} shipmentHistory={shipmentHistory} />
      </AxlPanel.Col>
    </AxlPanel.Row>
  }
}

class AddressHistoryListContainerCompose extends Component {

  componentDidMount() {
    const {messengerStore, shipment} = this.props;
    if(shipment && shipment.dropoff_address) {
      const q = `${_.get(shipment, 'dropoff_address.street', '')} ${_.get(shipment, 'dropoff_address.city', '')} ${_.get(shipment, 'dropoff_address.state', '')} ${_.get(shipment, 'dropoff_address.zipcode', '')}`;
      messengerStore.addressSearchFilter = {
        "from": 0,
        "size": 10,
        "q": q,
        "filters": {},
        "sorts": [
          "-dropoff_earliest_ts"
        ]
      };
      messengerStore.searchAddress();
    }
  }

  render() {
    const {messengerStore} = this.props;
    const {addressSearchResults} = messengerStore;

    return <AddressHistoryList results={addressSearchResults} />;
  }
}
export const AddressHistoryListContainer=  compose(
  withRouter,
  inject('messengerStore'),
  observer
)(AddressHistoryListContainerCompose);
