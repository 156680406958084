const styles = theme => ({
  container: {
    height: '100%',
    backgroundColor: '#f4f4f4',
    border: '1px solid #cfcfcf',
  },
  gridContainer: {
    height: '100%',
    flexWrap: 'nowrap',
  },
  assignmentDetailMap: {
    // flex: 1,
    height: 225,
    maxWidth: '100%',
    overflow: 'auto',
    minHeight: 225,
  },
  shipmentDetail: {
    // flex: 3,
    maxWidth: '100%',
    // maxHeight: 330,
    borderTop: '1px solid #cfcfcf',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
});

export default styles;