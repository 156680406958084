import React, { Component } from 'react';
import ReactMapGL, { Popup, LinearInterpolator } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import mapboxgl from 'mapbox-gl';
import DeckGL, { PathLayer, IconLayer, ScatterplotLayer } from 'deck.gl';
import {compose} from "recompose";
import { inject, observer } from 'mobx-react';
import {withRouter} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import _ from 'lodash';

import styles from "./styles";
import WarehouseIcon from '../../../../assets/images/warehouses.svg';
import CarIcon from '../../../../assets/images/car.svg';
import MARKERS from '../../../../assets/images/markers.svg';
import { CAR_ICON_MAPPING, DETECT_CAR_COLOR } from '../../../../constants/carIconMapping';

class AssignmentDetailMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 13,
      viewport: {
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 11
      },
      mapStyle: `https://api.maptiler.com/maps/${process.env.REACT_APP_MAP_TILER_STYLE}/style.json?key=${process.env.REACT_APP_MAP_TILER_KEY}`,
      hoveredStop: null,
      driverLocation: [],
    };

    this.iconMapping = {};
    this.iconMapping['green'] = {
      x: 0, y: 0, width: 160, height: 210, anchorY: 210
    };
    this.iconMapping['red'] = {
      x: 160, y: 0, width: 160, height: 210, anchorY: 210
    };
    this.iconMapping['yellow'] = {
      x: 320, y: 0, width: 160, height: 210, anchorY: 210
    };
    this.iconMapping['cyan'] = {
      x: 480, y: 0, width: 160, height: 210, anchorY: 210
    };
    this.iconMapping['purple'] = {
      x: 640, y: 0, width: 160, height: 210, anchorY: 210
    };
    for (let i = 0; i < 130; i++) {
      let w = i % 10;
      let h = (i - w) / 10;
      this.iconMapping['number-' + i] = {
        x: w * 40, y: 236 + h *40, width: 40, height: 40, anchorY: 52
      }
    }
  }

  gotMap = (map) => {
    if (!map || map === this.mapRef) return;
    this.mapRef = map;
    // setTimeout(this.initMap, 50);
  };

  initMap = () => {
    // let scale = new mapboxgl.ScaleControl({
    //   maxWidth: 90,
    //   unit: 'imperial'
    // });
    const myMap = this.mapRef.getMap();
    if (myMap)
      // myMap.addControl(scale);
    if (this.props.assignment || this.props.shipment)
      this.onAssignmentUpdate(this.props.assignment, this.props.shipment)
  };

  componentWillReceiveProps(props, ctx) {
    const { assignment, shipment, previewShipment } = props;
    if (!this.props.assignment
      || !props.assignment
      || assignment.assignment !== this.props.assignment.assignment
      || shipment !== this.props.shipment
      || previewShipment !== this.props.previewShipment
    ) {
      this.onAssignmentUpdate(assignment, shipment, previewShipment);
    }

    let driverLocation =
      (!assignment || !assignment.driverLocation) ? [] : [{
        location: [assignment.driverLocation.longitude, assignment.driverLocation.latitude],
        heading: assignment.driverLocation.heading,
        angle: assignment.driverLocation.heading ? (assignment.driverLocation.heading < 90 ? 90 - assignment.driverLocation.heading : (450 - assignment.driverLocation.heading)) : 90,
        icon: DETECT_CAR_COLOR(assignment.driver),
        ts: assignment.driverLocation.timestamp
      }];

    this.setState({ stop: null, driverLocation });
  }

  onAssignmentUpdate = (assignment, shipment, previewShipment) => {
    if (shipment && shipment.dropoff_address.lat) {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: shipment.dropoff_address.lat,
          longitude: shipment.dropoff_address.lng,
          zoom: 15,
          transitionDuration: 50
        }
      });
      return;
    }

    if (!assignment || !assignment.bbox) return;

    if (!assignment.shipments || assignment.shipments.length < 1) return;
    if (!this.state.viewport) return;
    const viewport = new WebMercatorViewport(this.state.viewport);
    let minLng = assignment.bbox[0][0];
    let maxLng = assignment.bbox[1][0];
    let minLat = assignment.bbox[0][1];
    let maxLat = assignment.bbox[1][1];

    if (previewShipment) {
      minLng = minLng <= previewShipment.dropoff_address.lng ? minLng : previewShipment.dropoff_address.lng;
      maxLng = maxLng >= previewShipment.dropoff_address.lng ? maxLng : previewShipment.dropoff_address.lng;
      minLat = minLat <= previewShipment.dropoff_address.lat ? minLat : previewShipment.dropoff_address.lat;
      maxLat = maxLat >= previewShipment.dropoff_address.lat ? maxLat : previewShipment.dropoff_address.lat;
    }
    // too small box
    const vw = maxLng -  minLng;
    const vh = maxLat -  minLat;
    const gapw = Math.max(vw * 0.1, 0.0004);
    const gaph = Math.max(vh * 0.1, 0.0004);
    const bbox = [
      [minLng - gapw, minLat - gaph],
      [maxLng + gapw, maxLat + gaph]
    ];

    if (previewShipment) bbox.push([previewShipment.dropoff_address.lng, previewShipment.dropoff_address.lat]);


    let { longitude, latitude, zoom } = viewport.fitBounds(bbox);
    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 50
      }
    });
  };

  showStopTooltip = (stop) => {
    this.setState({ hoveredStop: stop })
  };

  _renderTrajectory = () => {
    const { locations } = this.props;
    if (!locations || locations.length < 1) return [];
    const data = [{
      path: locations.map(d => [d.longitude, d.latitude]),
      color: [150,250,150, 150]
    }];
    const layer = new PathLayer({
      id: 'trajectory-layer',
      data,
      pickable: true,
      widthScale: 5,
      jointRounded: true,
      widthMinPixels: 2,
      getPath: d => d.path,
      getColor: d => d.color,
      getWidth: d => 1,
    });

    /* dots */
    const dotMarkers = locations.map(d => {
      return {
        location: [d.longitude, d.latitude],
        ts: d.timestamp,
        angle: d.heading ? (360 - d.heading) : 0
      }
    });

    const dotIconlayer = new ScatterplotLayer({
      id: 'location-icon-layer',
      data: dotMarkers,
      pickable: true,
      opacity: 1,
      stroked: true,
      filled: true,
      radiusScale: 1,
      radiusMinPixels: 4,
      radiusMaxPixels: 4,
      lineWidthMinPixels: 0.5,
      getPosition: d => d.location,
      getRadius: d => 4,
      getFillColor: d => [50, 210, 250, 160],
      getLineColor: d => [0, 140, 250, 200],
      onHover: ({object, x, y}) => {
        this.setState({ displayLocation: object })
      }
    });

    return [layer, dotIconlayer]
  };

  _renderPath = () => {
    const { assignment, previewShipment, shipment } = this.props;
    if (!assignment || !assignment.stops) {
      if (!shipment)
        return [];
      const shipmentMarkers = [{
        location: [shipment.dropoff_address.lng, shipment.dropoff_address.lat],
        size: 42,
        icon: 'red'
      }];
      return [
        new IconLayer({
          id: 'dropoff-icon-layer',
          data: shipmentMarkers,
          pickable: true,
          // iconAtlas and iconMapping are required
          // getIcon: return a string
          iconAtlas: MARKERS,
          iconMapping: this.iconMapping,
          getIcon: d => d.icon,
          sizeScale: 1,
          opacity: 1,
          visible: true,
          getPosition: d => d.location,
          getSize: d => d.size,
          onHover: ({object}) => {
            if (object && object.stop)
              this.showStopTooltip(object ? object.stop : null)
            if (!object)
              this.showStopTooltip(null)
          }
        })
      ]
    }

    let pickupFailed = assignment.stops.filter(s => s.type === 'PICKUP' && s.status === 'FAILED')
      .map(s => s.shipment.id)
    const p = _.sortBy(assignment.stops, (s) => s.sequence_id)
      .filter(s => s.type === 'PICK_UP' || s.type === 'DROP_OFF')
      .filter(s => s.shipment)
      .filter(s => pickupFailed.indexOf(s.shipment.id) < 0)
      .map(s => s.type === 'DROP_OFF' ? s.shipment.dropoff_address : s.shipment.pickup_address)
      .map(s => [s.lng, s.lat])
    const data = [{
      path: p,
      color: [20,20,0, 100]
    }];
    const layer = new PathLayer({
      id: 'path-layer',
      data: data,
      pickable: true,
      widthScale: 5,
      jointRounded: true,
      widthMinPixels: 2,
      getPath: d => d.path,
      getColor: d => d.color,
      getWidth: d => 1,
      onHover: ({object, x, y}) => {}
    });

    /* pickup */
    const pickupMarkers = _.uniqBy(assignment.stops
      .filter(s => s.type === 'PICK_UP')
      .filter(s => s.shipment)
      .map(s => s.shipment.pickup_address), 'street')
      .map(s => {
        return {
          location: [s.lng, s.lat],
          icon: 'warehouse'
        }
      });

    const pickupIconlayer = new IconLayer({
      id: 'pickup-icon-layer',
      data: pickupMarkers,
      pickable: true,
      iconAtlas: WarehouseIcon,
      iconMapping: {warehouse: {x: 0, y: 0, width: 150, height: 150, anchorY: 150}},
      getIcon: d => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      getPosition: d => d.location,
      getSize: d => 48,
    });

    /* dropoff */
    let stopColorEncode = (s) => {
      if (!s.status || s.status === 'PENDING' || s.status === 'REATTEMPT') return 'yellow'
      if (s.status === 'FAILED') return 'red'
      if (moment(s.actual_departure_ts).isAfter(moment(s.shipment.dropoff_latest_ts))) return 'purple'
      if (moment(s.actual_departure_ts).isBefore(moment(s.shipment.dropoff_earliest_ts))) return 'cyan'
      return 'green'
    };

    const dropoffMarkers = _.sortBy(assignment.stops, (s) => s.sequence_id)
      .filter(s => s.type === 'DROP_OFF')
      .filter(s => s.shipment)
      .flatMap(s => [
        {
          location: [s.shipment.dropoff_address.lng, s.shipment.dropoff_address.lat],
          stop: s,
          sequence: (s.label ? _.reverse(s.label.driver_label.split('-'))[0].split('.')[0] : '0'),
          icon: stopColorEncode(s),
          size: 42
        },
        {
          location: [s.shipment.dropoff_address.lng, s.shipment.dropoff_address.lat],
          icon: 'number-' + (s.label ? _.reverse(s.label.driver_label.split('-'))[0].split('.')[0] : '0'),
          size: 28
        }
      ]);

    if (previewShipment) {
      dropoffMarkers.push(
        {
          location: [previewShipment.dropoff_address.lng, previewShipment.dropoff_address.lat],
          size: 42,
          icon: 'red'
        }
      )
    }

    const dropoffIconlayer = new IconLayer({
      id: 'dropoff-icon-layer',
      data: dropoffMarkers,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: MARKERS,
      iconMapping: this.iconMapping,
      getIcon: d => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      getPosition: d => d.location,
      getSize: d => d.size,
      onHover: ({object, x, y}) => {
        if (object && object.stop)
          this.showStopTooltip(object ? object.stop : null);
        if (!object)
          this.showStopTooltip(null);
      }
    });

    return [layer, pickupIconlayer, dropoffIconlayer]
  };

  _renderDriverLocation = () => {
    const driverIconlayer = new IconLayer({
      id: 'driver-icon-layer',
      data: this.state.driverLocation,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: CarIcon,
      iconMapping: CAR_ICON_MAPPING,
      getIcon: d => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      getAngle: d => d.angle,
      getPosition: d => d.location,
      getSize: d => 25,
      onHover: ({object, x, y}) => {
        this.setState({lastDriverUpdate: object})
      }
    });

    return [driverIconlayer]
  };

  _renderTooltip() {
    const { hoveredStop } = this.state;
    const isDropoff = hoveredStop && hoveredStop.type === 'DROP_OFF'
    return hoveredStop && (
      <Popup tipSize={5}
             anchor="bottom"
             closeButton={false}
             longitude={ isDropoff ? hoveredStop.shipment.dropoff_address.lng : hoveredStop.shipment.pickup_address.lng}
             latitude={ isDropoff ? hoveredStop.shipment.dropoff_address.lat : hoveredStop.shipment.pickup_address.lat }
             closeOnClick={true}
             offsetTop={-30}
             className={'map-tooltip'}
             onClose={() => this.setState({ hoveredStop: null })}
      >
        <div style={{fontSize: '13px', width: '140px', textAlign: 'center', margin: 0}}>
          <div>
            ETA: <span>{moment(hoveredStop.predicted_departure_ts).format('hh:mm:ss a')}</span>
          </div>
          {hoveredStop.actual_departure_ts && <div>
            Actual: <span>{moment(hoveredStop.actual_departure_ts).format('hh:mm:ss a')}</span>
          </div>}
        </div>
      </Popup>
    );
  }

  render() {
    const { viewport, mapStyle } = this.state;
    return (
      <ReactMapGL
        {...viewport}
        mapStyle={mapStyle}
        transitionInterpolator={new LinearInterpolator()}
        onViewportChange={(viewport) => this.setState({ viewport })}
        width={'100%'}
        height={'100%'}
        ref={map => this.gotMap(map)}
      >
        <DeckGL
          initialViewState={viewport}
          layers={[...this._renderTrajectory(), ...this._renderPath(),...this._renderDriverLocation()]}
        />
        { this._renderTooltip() }
      </ReactMapGL>
    );
  }
}

const AssignmentDetailMapCompose = compose(
  inject("store"),
  observer
) (AssignmentDetailMap);

export default withStyles(styles)(withRouter(AssignmentDetailMapCompose));
