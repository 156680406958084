import React from 'react';
import * as M from '@mui/material';
import { createTheme } from '@mui/material/styles'

import {lightTheme} from "../../themes";
import {placeholder} from "../../themes/pseudo";
import colors from "../../themes/colors";

export const TextError = M.styled(M.Typography)(({theme, spacing}) => ({
  fontSize: 10,
  padding: '5px 0 0',
  fontStyle: 'italic',
}));

export const mainTheme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        borderRadius: 30,
        border: `1px solid ${lightTheme.palette.primary.grayTwelfth}`,
        padding: '7px 15px',
      },
      input: {
        padding: 0,
        height: 'auto',
        lineHeight: 1.3,
        ...placeholder({
          fontFamily: 'AvenirNext-Regular',
          fontSize: 12,
          fontWeight: 500,
          color: lightTheme.palette.primary.grayTwelfth,
          opacity: 1,
        })
      },
      inputMultiline: {
        ...placeholder({
          fontFamily: 'AvenirNext-Regular',
          fontSize: 10,
          fontWeight: 500,
          color: lightTheme.palette.primary.grayTwelfth,
          opacity: 1,
        })
      },
      colorSecondary: {
        backgroundColor: lightTheme.palette.primary.whiteTwo,
      },
    },
    MuiTypography: {
      root: {
        fontSize: 11,
        color: colors.red,
      }
    }
  }
})