import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from 'lodash';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { DesktopDatePicker, DesktopDateTimePicker, DesktopTimePicker, MobileDatePicker, MobileDateTimePicker, MobileTimePicker } from '@mui/x-date-pickers';

class DateTimePkr extends Component {
  render () {
    const {timestamp, value, dateOnly, timeOnly, useKeyboard, size = 'small', variant = 'outlined', placeholderInput,
      hideIconCalendar = false, styleOutlinedInput = null, styleInputRoot = null, styleInput = null, clearable = false, slotTextField, addProps
    } = this.props;

    let props = _.omit(Object.assign({}, this.props), ['timestamp', 'useKeyboard']);

    if (!value) props["value"] = null;

    if (dateOnly) {
      props['format'] = !!props["format"] ? props["format"] : "yyyy/MM/dd";
    } else if (timeOnly) {
      props['format'] = "hh:mm a";
    }

    const slotProps = {
      textField: {
        size,
        variant,
        placeholder: placeholderInput,
        fullWidth: true,
        ...slotTextField
      },
      field: { 
        clearable
      }
    }

    const sx = {
      '.MuiInputAdornment-root': hideIconCalendar && {
        display: 'none',
      },
      '.MuiInput-root': styleInputRoot || {
        border: 'none',
        color: '#887fff',
        fontFamily: 'AvenirNext',
        fontSize: 13,
        fontWeight: 900,
      },
      '.MuiInput-input': styleInput || {
        padding: '4px 0',
        color: '#887fff',
        fontFamily: 'AvenirNext',
        fontSize: 13,
        fontWeight: 900,
        "::placeholder": {
          opacity: 1,
          color: '#887fff',
          fontFamily: 'AvenirNext',
          fontSize: 13,
          fontWeight: 900,
        }
      },
      ".MuiOutlinedInput-root": styleOutlinedInput
    }

    props = {
      ...props,
      value: value && typeof value === 'string' ? moment(value) : null,
      ...addProps
    }

    let picker = useKeyboard ? <MobileDateTimePicker {...props} slotProps={slotProps} sx={sx} /> : <DesktopDateTimePicker {...props} slotProps={slotProps} sx={sx} />;

    if (dateOnly) {
      picker = useKeyboard ? <MobileDatePicker {...props} slotProps={slotProps} sx={sx} /> : <DesktopDatePicker {...props} slotProps={slotProps} sx={sx} />;
    } else if (timeOnly) {
      picker = useKeyboard ? <MobileTimePicker {...props} slotProps={slotProps} sx={sx} /> : <DesktopTimePicker {...props} slotProps={slotProps} sx={sx} />
    }

    return <LocalizationProvider dateAdapter={AdapterMoment}>
      {picker}
    </LocalizationProvider>
  }
}

DateTimePkr.defaultProps = {
  format: "yyyy/MM/dd hh:mm a",
  timestamp: false
};

DateTimePkr.propsType = {
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  format: PropTypes.string,
  timestamp: PropTypes.bool,
  useKeyboard: PropTypes.bool,
};

export default DateTimePkr;