import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  CREATED,
  GEOCODE_FAILED,
  GEOCODED,
  ASSIGNED,
  PICKUP_EN_ROUTE,
  PICKUP_DELAY,
  CANCELLED_BEFORE_PICKUP,
  PICKUP_READY,
  PICKUP_FAILED,
  PICKUP_SUCCEEDED,
  DROPOFF_EN_ROUTE,
  DROPOFF_DELAY,
  CANCELLED_AFTER_PICKUP,
  DROPOFF_READY,
  DROPOFF_FAILED,
  DROPOFF_SUCCEEDED,
  RETURN_EN_ROUTE,
  RETURN_DELAY,
  RETURN_READY,
  RETURN_FAILED,
  RETURN_SUCCEEDED,
  RECEIVED_OK,
  RECEIVED_DAMAGED,
  MISSING,
  INFORM_RECIPIENT_SHIPMENT_CONFIRMED,
  INFORM_RECIPIENT_PICKUP_SUCCEEDED,
  INFORM_RECIPIENT_PICKUP_FAILED,
  INFORM_RECIPIENT_COMING_SOON,
  INFORM_RECIPIENT_NEXT_IN_LINE,
  INFORM_RECIPIENT_DROPOFF_SUCCEEDED,
  INFORM_RECIPIENT_DROPOFF_FAILED,
  NOT_FOUND,
  LEAKING,
  DAMAGED,
  DROPOFF_REATTEMPTING,
  GEOCODE_FAILED_PICKUP,
  GEOCODE_FAILED_DROPOFF
} from '../../constants/shipmentStatus';

import EventObject from './event';
import {AxlSearchBox} from 'axl-reactjs-ui';
// Styles
import styles, { Container, Inner, List, Item, Circle, Date, Text, RemarkText, SearchContainer } from './styles';
import { inject, observer } from 'mobx-react';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class ShipmentHistoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outboundEvents: []
    }
  }

  componentDidMount() {
    const { shipmentHistory = [] } = this.props;

    if(shipmentHistory.length) {
      this.setState({outboundEvents: shipmentHistory});
    }
  }

  getHistoryStatus(status, options) {
    let result = '';
    switch (status) {
      // PLANNING
      case CREATED: result = `Shipment created`; break;
      case GEOCODE_FAILED: result = `Geocode failed`; break;
      case GEOCODE_FAILED_PICKUP: result = `Geocode failed`; break;
      case GEOCODE_FAILED_DROPOFF: result = `Geocode failed`; break;
      case GEOCODED: result = `Geocode completed`; break;

      // DISPATCH
      case ASSIGNED: result = `Shipment assigned to <b>${options['DRIVER_NAME']}<b>`; break;

      // CANCELLATION
      case CANCELLED_BEFORE_PICKUP: result = `Shipment cancelled`; break;
      case CANCELLED_AFTER_PICKUP: result = `Shipment cancelled`; break;

      // INBOUND
      case RECEIVED_OK: result = `Inbound received OK`; break;
      case RECEIVED_DAMAGED: result =`Inbound received DAMAGED`; break;
      case MISSING: result = `Inbound MISSING`; break;

      // OUTBOUND
      case PICKUP_EN_ROUTE: result = `<b>${options['DRIVER_NAME']}</b> en route to <i>${options['PICKUP_ADDRESS_LINE_1']}</i>`; break;
      case PICKUP_DELAY: result = `Pickup delayed by <u>${options['REMARK']}</u>`; break;
      case PICKUP_READY: result = `Pickup ready at <i>${options['PICKUP_ADDRESS_LINE_1']}</i>`; break;
      case PICKUP_FAILED: result = `Pickup failed because <u>${options['REMARK']}</u>`; break;
      case PICKUP_SUCCEEDED: result = `Pickup successful`; break;
      case DROPOFF_EN_ROUTE: result = `<b>${options['DRIVER_NAME']}</b> en route to <i>${options['DROPOFF_ADDRESS_LINE_1']} ${_.defaultTo(options['DROPOFF_ADDRESS_LINE_2'], '')}</i>`; break;
      case DROPOFF_DELAY: result = `Dropoff delayed by <u>${options['REMARK']}</u>`; break;
      case DROPOFF_READY: result = `Dropoff ready at <i>${options['DROPOFF_ADDRESS_LINE_1']} ${_.defaultTo(options['DROPOFF_ADDRESS_LINE_2'], '')}</i>`; break;
      case DROPOFF_FAILED: result = `Dropoff failed because <u>${options['REMARK']}</u>`; break;
      case DROPOFF_SUCCEEDED: result = `<div>Shipment successfully delivered ${options['GEOLOCATION'] ? ' at [' + options['GEOLOCATION']['latitude'].toFixed(6)  + ',' + options['GEOLOCATION']['longitude'].toFixed(6) + '] ' : ''}  ${options['REMARK'] ? 'with remark:' : ''}</div><div><i><small>${options['REMARK'] ? options['REMARK'] : ''}</small></i></div>`; break;
      case RETURN_EN_ROUTE: result = `<b>${options['DRIVER_NAME']}</b> returning to <i>${options['PICKUP_ADDRESS_LINE_1']}</i>`; break;
      case RETURN_DELAY: result = `Return delayed by <u>${options['REMARK']}</u>`; break;
      case RETURN_READY: result = `Return ready at <i>${options['PICKUP_ADDRESS_LINE_1']}</i>`; break;
      case RETURN_FAILED: result = `Return failed because <u>${options['REMARK']}<u>`; break;
      case RETURN_SUCCEEDED: result = `Return successfully completed`; break;
      case NOT_FOUND: result = `Outbound flagged NOT_FOUND`; break;
      case LEAKING: result = `Outbound flagged LEAKING`; break;
      case DAMAGED: result = `Outbound flagged DAMAGED`; break;
      case DROPOFF_REATTEMPTING: result = `Driver initialized dropoff reattempt`; break;

      // COMMUNICATION
      case INFORM_RECIPIENT_SHIPMENT_CONFIRMED: result = `Send SMS - confirm processing`; break;
      case INFORM_RECIPIENT_PICKUP_SUCCEEDED: result = this.renderSMSEvent(status, options); break;
      case INFORM_RECIPIENT_PICKUP_FAILED: result = this.renderSMSEvent(status, options); break;
      case INFORM_RECIPIENT_COMING_SOON: result = this.renderSMSEvent(status, options); break;
      case INFORM_RECIPIENT_NEXT_IN_LINE: result = this.renderSMSEvent(status, options); break;
      case INFORM_RECIPIENT_DROPOFF_SUCCEEDED: result = this.renderSMSEvent(status, options); break;
      case INFORM_RECIPIENT_DROPOFF_FAILED: result = this.renderSMSEvent(status, options); break;
      default: result = 'Invalid'; break;
    }

    return result;
  }

  renderSMSEvent(status, options) {
    return `<div>Send SMS - ${status ? status.replace("RECIPIENT","").replace(/__/g, " ").replace(/_/g, "-").toLowerCase(): null}</div><div style="color: brown;"><small>${options['REMARK'] ? JSON.parse(options['REMARK']).body : ''}</small></></div>`;
  }

  _handleSearch = (e) => {
    const { shipmentHistory = {} } = this.props;

  }

  render() {
    const { outboundEvents } = this.state;
    const { locationStore } = this.props
    const { status } = styles;
    const { shipmentHistory = {}, shipment } = this.props
    const outbound_events = shipmentHistory;

    if (!outboundEvents || outboundEvents.length < 1) {
      return <div></div>
    }

    let date = ''
    let events = _.sortBy(outboundEvents, e => e.ts)
      .flatMap(e => {
        const d = moment(e.ts).format('dddd M/D/YYYY').toUpperCase()
        if (d === date)
          return [e]
        else {
          date = d
          return [{signal: 'DATE', date: d}, e]
        }
      })

    return <Container>
      <SearchContainer>
        <AxlSearchBox theme={`default`}
                      placeholder='Search...'
                      defaultValue={``}
                      style={{width: '100%'}}
                      onChange={this._handleSearch} />
      </SearchContainer>
      <Inner>
        <List>
          { events.map((e,i) => {
            const options = {
              'REMARK': e.remark,
              'GEOLOCATION': e.geolocation,
              'DRIVER_NAME': 'driver',
              'PICKUP_ADDRESS_LINE_1': shipment.pickup_address.street,
              'DROPOFF_ADDRESS_LINE_1': shipment.dropoff_address.street,
              'DROPOFF_ADDRESS_LINE_2': shipment.dropoff_address.street2
            };
            if (e.signal === 'DATE')
              return <Item key={i}><Circle style={{backgroundColor: status['STARTED']}}/><Text>{e.date}</Text></Item>;
            if (e.action === 'sms')
              return eventSMS({e})

            if (e.category === 'SHIPMENT') {
              if (e.type === 'INBOUND') return eventInbound({e})
              if (e.action === 'update_status') return eventUpdateStatus({e})
              if (e.action === 'un-route') return eventUnroute({e})
              if (e.type === 'MODIFIER') return eventModifyShipment({e})
              return <Item key={e.id}>
                <Circle style={{backgroundColor: status[e.action]}}/>
                <Text>
                  <div>
                    <EventObject obj={e.subject} /> {e.action} <EventObject obj={e.object} />
                  </div>
                </Text>
                <Date>{moment(e.ts).format('hh:mm A')}</Date>
              </Item>
            }
            if (e.category === 'STOP' && e.type === 'OUTBOUND') {
              return eventStopOutboundUpdate({e,locationStore})
            }
            if (e.category === 'STOP' && e.type === 'POD') {
              console.log(e)
              return eventStopPOD({e})
            }
            if (e.category === 'STOP' && e.type === 'MODIFIER')
              return eventStopModify({e})
            return <div key={i}></div>
          })}
        </List>
      </Inner>
    </Container>
  }
}
export default compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentHistoryList);


function eventSMS(props) {
  const { e } = props
  const { status } = styles
  return (
    <Item key={e.id}>
      <Circle style={{backgroundColor: status['STARTED']}}/>
      <Text>
        <div>Send SMS - {e.fact && e.fact.sms_type ? e.fact.sms_type.replace("RECIPIENT","").replace(/__/g, " ").replace(/_/g, "-").toLowerCase(): null}</div>
        <div style={{color: 'brown'}}><small>{e.evidence.text}</small></div>
      </Text>
      <Date>{moment(e.ts).format('hh:mm A')}</Date>
    </Item>
  );
}

function eventInbound(props) {
  const { e } = props
  const { status } = styles
  return <Item key={e.id}>
    <Circle style={{backgroundColor: '#259'}}/>
    <Text>
      <div>
        { (e.action !== 'update-inbound' || !e.state) && <Fragment>
            <EventObject obj={e.subject} /> { e.action } <EventObject obj={e.object} />
          </Fragment>}
        { e.action === 'update-inbound' && e.state && <Fragment>
          <EventObject obj={e.subject} /> Update Inbound Status of <EventObject obj={e.object} /> to <b style={styles.strong}>{e.state.inbound_status}</b>
            { e.state.indbound_notes ? <RemarkText>{e.state.indbound_notes}</RemarkText> : '' }
          </Fragment>}
      </div>
      { e.location && e.location.geolocation && <div>
        At location [ {e.location.geolocation.latitude.toFixed(6)}, {e.location.geolocation.longitude.toFixed(6)} ] <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${e.location.geolocation.latitude.toFixed(6)},${e.location.geolocation.longitude.toFixed(6)}`}>Google Map</a>
      </div>}
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventUpdateStatus(props) {
  const { e } = props
  const { status } = styles
  return <Item key={e.id}>
    <Circle style={{backgroundColor: status[e.state.status]}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> update <EventObject obj={e.object} /> status to <b style={styles.strong}>{e.state.status}</b>
        { e.state.remark ? <RemarkText>{e.state.remark}</RemarkText> : '' }
      </div>
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventUnroute(props) {
  const { e } = props
  const { status } = styles
  return <Item key={e.id}>
    <Circle style={{backgroundColor: status['STARTED']}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> un-route <EventObject obj={e.object} /> from <EventObject obj={e.ref} />, with label <b style={styles.strong}>{e?.fact?.label}</b>
      </div>
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventStopOutboundUpdate(props) {
  const { e, locationStore } = props
  const { status } = styles

  let latitude = null, longitude = null
  if( e.location && e.location.geolocation ){
    latitude = e.location.geolocation.latitude.toFixed(6)
    longitude = e.location.geolocation.longitude.toFixed(6)
  }

  return <Item key={e.id}>
    <Circle style={{backgroundColor: status[e.state.status]}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> {e.action.replace('_', ' ')} <EventObject obj={e.object} />{ e.action !== 'reattempt' && <span> status to <b style={styles.strong}>{e.state.status}</b></span>}{ e.action === 'reattempt' && <span> from Stop {e.fact.previous_stop_id} </span>}
        { e.state.remark ? <RemarkText>{e.state.remark}</RemarkText> : '' }
      </div>
      { latitude && <div style={styles.updateStopWrap}>
        At location
        <div
          style={styles.coordinatesWrap}
          onMouseEnter={() => { locationStore.updateLocation([longitude, latitude])}}
          onMouseLeave={() => { locationStore.updateLocation(null) }}
        >
          [ {latitude}, {longitude} ]
        </div> <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}>Google Map</a>
      </div>}
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventStopModify(props) {
  const { e } = props
  const { status } = styles
  if (!e.state) return <div></div>
  const keys = Object.keys(e.state)
  return <Item key={e.id}>
    <Circle style={{backgroundColor: status[e.state.status]}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> {e.action.replace('_', ' ')} <EventObject obj={e.object} />
      </div>
      { keys.map(key => <div key={key}>
        <span>+</span> <b style={styles.strong}>{key}</b> <span style={{color: '#822'}}>&#64;</span> <RemarkText style={{display: 'inline'}}>{e.state[key]}</RemarkText>
      </div>) }
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventStopPOD(props) {
  const { e } = props
  const { status } = styles
  return <Item key={e.id}>
    <Circle style={{backgroundColor: status[e.action]}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> upload {e.action.replace('_', ' ')} for <EventObject obj={e.object} />
        { _.get(e, 'state.remark') ? <RemarkText>{e.state.remark}</RemarkText> : '' }
      </div>
      { e.location && e.location.geolocation && <div>
        At location [ {e.location.geolocation.latitude.toFixed(6)}, {e.location.geolocation.longitude.toFixed(6)} ] <a target='_blank' href={`https://www.google.com/maps/search/?api=1&query=${e.location.geolocation.latitude.toFixed(6)},${e.location.geolocation.longitude.toFixed(6)}`}>Google Map</a>
      </div>}
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;
}

function eventModifyShipment(props) {
  const { e } = props
  const { status } = styles
  const fields = e.evidence && e.evidence.fields ? e.evidence.fields.split(',') : []
  const withValueFields = fields.filter(f => e.state[f])
  // console.log(fields, withValueFields)
  if (withValueFields.length < 1) return <div></div>
  return <Item key={e.id}>
    <Circle style={{backgroundColor: '#ddd'}}/>
    <Text>
      <div>
        <EventObject obj={e.subject} /> update <EventObject obj={e.object} /> set
      </div>
      { withValueFields.map(field => <div>
        <span>+</span> <b style={styles.strong}>{field.replace('.',' ').replace('_', ' ')}</b> <span style={{color: '#822'}}>&#64;</span> <RemarkText style={{display: 'inline'}}>{e.state[field]}</RemarkText>
      </div>)}
    </Text>
    <Date>{moment(e.ts).format('hh:mm A')}</Date>
  </Item>;

}
