import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AxlDateInput } from 'axl-reactjs-ui';
import { Container, Grid, Box, Menu, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { GetApp as GetAppIcon } from '@mui/icons-material';

import styles from './styles';
import AxlMUISelect from 'AxlMUIComponent/AxlMUISelect';

class DispatchSearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuElm: null,
    };
  }

  componentDidMount() {
    const { assignmentStore } = this.props.store;
    assignmentStore.loadRegions();
  }

  setDate = (newDate) => {
    const { history, store } = this.props;
    const { assignmentStore } = store;

    const selectedDate = newDate ? newDate : new Date();

    const day = moment(selectedDate).format('YYYY-MM-DD');
    const region = assignmentStore.regions.length > 0 ? assignmentStore.regions.join(',') : 'all';
    history.push(`/routes/${day}/${region}`);

    assignmentStore.setDate(selectedDate);
  };

  setRegions(regions) {
    const { history, store } = this.props;
    const { assignmentStore } = store;

    const day = assignmentStore.date;
    const region = regions.length > 0 ? regions : [];
    history.push(`/routes/${day}/${region}`);
    assignmentStore.setRegions(region);
  }

  openDownloadMenu = (e) => {
    this.setState({ menuElm: e.currentTarget });
  };

  closeDownloadMenu = () => {
    this.setState({ menuElm: null });
  };

  downloadReport = (name) => {
    const { assignmentStore } = this.props.store;
    const day = assignmentStore.date;
    let url = `${process.env.REACT_APP_API_ROOT}/dsp/reports/${name}?date=${day}`;
    if (assignmentStore.regions.length > 0) {
      url += `&regions=` + assignmentStore.regions.join(',');
    }
    window.open(url, '_blank');
  };

  render() {
    const { menuElm } = this.state;
    const { classes, store } = this.props;

    const { assignmentStore } = store;
    const { date, regions, regionList } = assignmentStore;

    return (
      <Container maxWidth={false} disableGutters className={classes.container}>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item>
                <Box>Overview for</Box>
              </Grid>
              <Grid item>
                <AxlDateInput
                  arrow
                  clear="true"
                  onChange={(d) => this.setDate(d)}
                  options={{
                    defaultValue: moment(date).unix() * 1000,
                    defaultDate: 'today',
                    dateFormat: 'MMM DD, Y',
                    placeHolder: 'today',
                    enableTime: false,
                    altInput: true,
                    clickOpens: false,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item>
                <Box>at</Box>
              </Grid>
              <Grid item>
                {regionList.length && (
                  <AxlMUISelect
                    defaulValue={regionList.filter((option) => regions.indexOf(option.value) >= 0)}
                    placeholderButtonLabel="all regions"
                    searchPlaceholder="Search Regions..."
                    placeholder="All regions"
                    placeholderSize={18}
                    options={regionList}
                    onChange={(v) => this.setRegions(v)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box>
              <GetAppIcon onClick={this.openDownloadMenu} color="inherit" className={classes.downloadIcon} />
              <Menu
                anchorEl={menuElm}
                open={Boolean(menuElm)}
                onClose={this.closeDownloadMenu}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <MenuItem onClick={() => this.downloadReport('detail-tips')}>Monthly Tips - Detail</MenuItem>
                <MenuItem onClick={() => this.downloadReport('total-tips')}>Monthly Tips - Total</MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const DispatchSearchFilterCompose = compose(inject('store'), observer)(DispatchSearchFilter);

export default withStyles(styles)(withRouter(DispatchSearchFilterCompose));
