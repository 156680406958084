import React, { Component } from 'react';
import styles from './styles';

import { AxlInput, AxlPanel, AxlReselect, AxlDateInput, AxlCheckbox, AxlButton } from 'axl-reactjs-ui';
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class PickupStatusFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  save = (e) => {
    const {shipmentStore, shipment, pickup, closeForm} = this.props;
    this.setState({error: null});
    shipmentStore.updatePickupStop(pickup, () => {
      closeForm()
    }, (resp) => {
      if (resp.errors && resp.errors.length > 0) {
        this.setState({error: resp.errors[0]});
      }
    });
  };

  render() {
    const { shipment, dropoff, shipmentStore } = this.props;
    const { pickupStopForm } = shipmentStore;

    console.log('actual ts is: ', pickupStopForm.actual_departure_ts);

    const stopStatuses = [
      { label: 'PENDING', value: "PENDING", color: '#fa6725' },
      { label: 'SUCCEEDED', value: "SUCCEEDED", color: '#4abc4e' },
      { label: 'FAILED', value: "FAILED", color: '#d63031' }
    ];
    const dropoffStatus = pickupStopForm.data.status ? { label: pickupStopForm.data.status, value: pickupStopForm.data.status } : null;
    const dropoffTimeOptions = {
      dateFormat: 'MMM DD, Y HH:mm:SS A',
      placeHolder: 'Pickup Time',
      enableTime: true,
      altInput: true,
      clickOpens: true,
      defaultValue: pickupStopForm.data.actual_departure_ts ? pickupStopForm.data.actual_departure_ts : null
    };

    return <div style={styles.Container}>
      <div style={styles.HeaderTitle}>{`Edit pickup remark`}</div>
      <AxlPanel>
      {this.state.error && <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <AxlPanel.Row align={`center`}>
              <AxlPanel.Col>
                <span style={{color: 'red', paddingLeft: '15px'}}>{this.state.error}</span>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>}
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <AxlPanel.Row align={`center`}>
              <AxlPanel.Col>
                <div style={styles.GroupTitle}>{`Pickup Status`}</div>
                <div style={styles.Field}><AxlReselect value={dropoffStatus} placeholder="Shipment Status" onChange={pickupStopForm.handlerReactSelect("status")} options={stopStatuses} theme={`main`} /></div>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <div style={styles.GroupTitle}>{`Pickup Time`}</div>
            <div style={styles.GroupField}>
              <AxlPanel.Row align={`center`}>
                <AxlPanel.Col><div style={styles.Field}><AxlDateInput onChange={pickupStopForm.handlerDateInput('actual_departure_ts')} displayToday={false} options={dropoffTimeOptions} theme={`main`} /></div></AxlPanel.Col>
              </AxlPanel.Row>
            </div>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <div style={styles.GroupTitle}>{`Remark`}</div>
            <div style={styles.GroupField}>
              <AxlPanel.Row align={`center`}>
                <AxlPanel.Col><div style={styles.Field}><AxlInput value={pickupStopForm.getField('remark', '')} onChange={pickupStopForm.handlerInput} placeholder={`Remark`} name={`remark`} type={`text`} fluid /></div></AxlPanel.Col>
              </AxlPanel.Row>
            </div>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col>
            <AxlPanel.Row align={`flex-end`}>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`gray`} onClick={this.props.closeForm} style={styles.buttonControl}>{`Cancel`}</AxlButton></div>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`pink`} disabled={!pickupStopForm.data.actual_departure_ts} onClick={this.save} style={styles.buttonControl}>{`Save`}</AxlButton></div>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
      </AxlPanel>
    </div>
  }
}
export default compose(
  withRouter,
  inject('store'),
  observer
)(PickupStatusFrom);
