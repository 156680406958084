import React from 'react';
import { inject, observer } from 'mobx-react';
import { AxlInput, AxlPanel, AxlButton, AxlTextArea } from 'axl-reactjs-ui';

// Styles
import styles, * as E from './styles';
import {compose} from "recompose";

class AssignmentAssign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    }
  }

  componentDidUpdate(prevProps) {}

  onUpdate = (name) => (e) => {
    if (e.target.value !== undefined) {
      this.setState({[name]: e.target.value});
      this.props.updateReason(e.target.value);
    }
  }

  updateBonus = (assignment) => () => {}

  render() {
    const { assignment , store} = this.props;
    const {assignmentStore} = store;
    const { assigning, unAssigning } = assignmentStore;

    return <E.Container style={styles.Container}>
      <div style={styles.HeaderTitle}>{this.props.text}</div>
      <AxlPanel>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <div style={styles.GroupField}>
              <div style={styles.Field}>
                <AxlTextArea  value={this.state.reason} onChange={this.onUpdate('reason')} name='reason' placeholder={`Reason...`} style={styles.TextField} fluid />
              </div>
            </div>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col>
            <AxlPanel.Row align={`center`}>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`gray`} onClick={this.props.onClose} style={styles.buttonControl}>{`Cancel`}</AxlButton></div>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`pink`} disabled={!this.state.reason} loading={assigning || unAssigning} onClick={this.props.onClick} style={styles.buttonControl}>{`Save`}</AxlButton></div>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
      </AxlPanel>
    </E.Container>
  }
}
export default compose(
  inject("store"),
  observer
) (AssignmentAssign);
