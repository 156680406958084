import React,{Component} from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import AssignmentSpecificChart from '../AssignmentSpecificChart/index.js'
import styles from './styles';

class AssignmentBreakdown extends Component {
  constructor(props){
    super(props)
    this.state = {
      pickupTime: null,
      firstDropoffTime: null,
      lastDropoffTime: null,
      stats:{
        unassigned:0,
        pending:0,
        inprogress:0,
        failed:0,
        succeeded:0,
        early:0,
        late:0
      },
      reattemptStopLabels:[]
    }
  }

  componentWillReceiveProps(props){
    this.processAndUpdateData(props)
  }

  componentDidMount(){
    this.processAndUpdateData(this.props)
  }

  processAndUpdateData(props){
    if(props.selectedAssignment && props.selectedAssignment.extra){
      const { assignment, stops, shipments, extra } = props.selectedAssignment
      let stats = {unassigned:0, pending:0, inprogress:0, failed:0, succeeded:0, early:0, late:0}
      let pickupTime = null, firstDropoffTime = null, lastDropoffTime = null
      let reattemptStopLabels = []

      //get list of labels of reattempted shipments
      if(props.filteredStops != null && props.filteredStops.length !== 0){
        reattemptStopLabels = props.filteredStops.filter(s => s.shipment && s.shipment.attempt_count && s.shipment.attempt_count > 1)
          .map(s => s.label.driver_label.split('-')[1]);
      }

      if(assignment.driver_id === undefined){
        //not assigned
        stats.unassigned=1
        this.setState({stats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels})
      }
      else if(assignment.status === "" || !assignment.status){
        //assigned, but not active aka pending
        stats.pending=1
        this.setState({stats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels})
      }
      else{
        //assigned and active

        //get actual pickup time
        if(assignment.actual_start_ts !== null)
          pickupTime = assignment.actual_start_ts

        //get all drop-off stops
        const dropoffStops = stops.filter(s => s.type === 'DROP_OFF');

        // sort all drop-off stops by date (earliest to latest)
        const sortedStops = dropoffStops.filter(s => !!s.actual_departure_ts)
          .sort((s1, s2) => new Date(s1.actual_departure_ts) - new Date(s2.actual_departure_ts))

        if(sortedStops.length!==0 && extra.stats){
          // stats available, dropped off at least 1 shipment
          firstDropoffTime = moment(sortedStops[0].actual_departure_ts).format('LT')

          if(assignment.status === 'COMPLETED'){
            //assignment completed
            lastDropoffTime = moment(sortedStops[sortedStops.length-1].actual_departure_ts).format('LT')
          }
          this.updateChartData(extra.stats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels)
        }
        else{
          // stats not available, have not dropped off yet
          stats.inprogress=1
          this.setState({stats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels})
        }
      }
    }
  }

  updateChartData(stats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels){
    let totalStats={
      unassigned:0,
      pending:0,
      inprogress:0,
      failed:0,
      succeeded:0,
      early:0,
      late:0
    }

    const values = Object.values(stats)

    values.forEach(element =>{
      const {DS=0, DP=0, DF=0,late=0,early=0,PF=0} = element
      totalStats.succeeded +=DS
      totalStats.failed +=DF+PF
      totalStats.early +=early
      totalStats.late +=late
      totalStats.inprogress += DP
    })

    this.setState({stats:totalStats, pickupTime, firstDropoffTime, lastDropoffTime, reattemptStopLabels})
  }

  render(){
    const { selectedAssignment } = this.props
    if(!selectedAssignment)
      return <div/>
    const { firstDropoffTime, lastDropoffTime } = this.state
    const { prefix } = selectedAssignment && selectedAssignment.assignment ? selectedAssignment.assignment.label : ''

    return(
      <div style={styles.container}>
        <div style={styles.titleWrap}>
          <div style={styles.titleText}>Assignment {prefix} - Breakdown</div>
        </div>

        <div style={styles.timeWrap}>
          <div style={styles.timeColumnWrap}>
            <div style={styles.timeTitleText}>
              Actual pickup time
            </div>
            <div style={styles.timeText}>
              {this.state.pickupTime ===null?
                <div>Not available</div>
                :
                <Moment format="LT">{this.state.pickupTime}</Moment>
              }
            </div>
          </div>

          <div style={styles.timeColumnWrap}>
            <div style={styles.timeTitleText}>
              First drop-off time
            </div>
            <div style={styles.timeText}>
              {firstDropoffTime===null?'Not available':firstDropoffTime}
            </div>
          </div>

          <div style={styles.timeColumnWrap}>
            <div style={styles.timeTitleText}>
              Last drop-off time
            </div>
            <div style={styles.timeText}>
              {lastDropoffTime===null?'Not available':lastDropoffTime}
            </div>
          </div>
        </div>

        <div style={styles.grayLine1}/>

        <AssignmentSpecificChart selected={selectedAssignment} stats={this.state.stats}/>

        <div style={styles.grayLine2}/>
        <div style={styles.reattemptWrap}>
          <div style={styles.reattemptText}>No. of Re-attempts:</div>
          <div style={styles.reattemptNumber}>{this.state.reattemptStopLabels.length}</div>
          {this.state.reattemptStopLabels.length!==0 ?
            <div style={styles.reattemptText}>( {prefix} - {this.state.reattemptStopLabels.join(", ")} )</div>
            :
            null
          }
        </div>
      </div>
    )
  }
}

export default AssignmentBreakdown;
