import {observable, action, decorate} from 'mobx';
import {ASSIGNMENT_STATUS} from '../constants/status';

class DriverStore {
    constructor(api) {
        this.api = api;
    }

    driverSearchResult = [];
    driverAssignmentFilter = {
      'size': 10,
      'page': 1,
      'status[]': 'COMPLETED',
      'have_stop_statuses': 'true',
    };
    
    search(keyword) {
        this.api.get(`/drivers/advanced-search/${keyword}`)
            .then(response => {
                if (response.status === 200) {
                    this.driverSearchResult = response.data;
                }
            })
    }
    
    driverSearch(q, cb) {
      if(!q) return false;

      this.api.get(`/drivers/search?page=0&size=20&order_by=id&desc=true&q=${q}`).then(res => {
        console.log(res)
        if(res.status === 200 || res.ok) {}

        if(cb) cb(res);
      });
    }
    
    get(id, cb) {
        return this.api.get(`/drivers/${id}`)
            .then(response => {
                if(cb) cb(response);
                if (response.status === 200) {
                    return response.data;
                } else {
                    return null;
                }
            })
    }

    getLastLocation(id) {
        return this.api.get(`/drivers/${id}/location`)
            .then(response => {
                if (response.status === 200) {
                    return response.data.length > 0 ? response.data[0] : null;
                } else {
                    return null
                }
            })
    }

    quit(id, query, callback) {
      return this.api.post(`/drivers/${id}/quit`, query).then(callback)
    }

    suspend(id, query, callback) {
      return this.api.post(`/drivers/${id}/suspend`, query).then(callback)
    }

    reactivate(id, query, callback) {
      return this.api.post(`/drivers/${id}/re-activate`, query).then(callback)
    }

    getSuspensions(driverId) {
      return this.api.get(`/drivers/${driverId}/suspensions`);
    }

    getActiveAssignment(driverId) {
      const status = [];
      const active = true;
      return this.api.get(`/assignments/drivers/${driverId}`, {active});
    }

    getAssignmentHistory(assignmentId) {
      return this.api.get(`/assignments/${assignmentId}/history`);
    }

    getPendingAssignments(driverId) {
      const status = [ASSIGNMENT_STATUS.CREATED, ASSIGNMENT_STATUS.PENDING, ASSIGNMENT_STATUS.NULL, ASSIGNMENT_STATUS.IN_PROGRESS, ''];
      return this.api.get(`/assignments/drivers/${driverId}`, {status, active: false});
    }

    getPastAssignments(driverId, page) {
      const status = [ASSIGNMENT_STATUS.COMPLETED];
      return this.api.get(`/assignments/drivers/${driverId}`, {status, size: 10, page, have_stop_statuses: true});
    }

    getStatistics(driverId) {
      return this.api.get(`/drivers/${driverId}/statistics`);
    }

    checkUnsubscribed(driverId, callback) {
      return this.api.get(`/drivers/${driverId}/is-unsubscribed`).then(response => {
        if (response.ok) {
          if (callback) callback(response);
        }
      });
    }

    getDriverActivity(driverId, limit = 50, offset = 0) {
      return this.api.get(`/events/drivers/${driverId}?limit=${limit}&offset=${offset}`)
    }

    getDriverAssignmentsgetDriverAssignments(driverId, callback) {
      return this.api.get(`/assignments/drivers/${driverId}`, this.driverAssignmentFilter).then(res => {
        console.log(res)
      });
    }

    getPaymentHistory(query) {
      let params = new URLSearchParams();

      Object.keys(query).map(t => {
        params.append(t, query[t]);
      });

      return this.api.get(`/finance/payments?${params}`)
    }

    getDueHistory(query) {
      let params = new URLSearchParams();

      Object.keys(query).map(t => {
        params.append(t, query[t]);
      });

      return this.api.get(`/finance/pending_transactions?${params}`);
    }
}

decorate(DriverStore, {
  driverSearchResult: observable,
  driverAssignmentFilter: observable,
  search: action,
  driverSearch: action,
  get: action,
  getLastLocation: action,
  quit: action,
  suspend: action,
  reactivate: action,
  getSuspensions: action,
  getActiveAssignment: action,
  getAssignmentHistory: action,
  getPendingAssignments: action,
  getPastAssignments: action,
  getStatistics: action,
  checkUnsubscribed: action,
  getDriverActivity: action,
  getDriverAssignmentsgetDriverAssignments: action,
  getPaymentHistory: action,
  getDueHistory: action,
});

export default DriverStore;
