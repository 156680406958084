import React, {Component, useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import MaskedInput from 'react-text-mask';
import moment from "moment";

import {
  Container,
  Step,
  Stepper,
  StepLabel,
  Grid,
  Button,
  Input,
  InputLabel,
  InputAdornment,
  Paper,
  InputBase,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spacer,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Alert from '@mui/material/Alert';

import DriverProfileRoutingTabs from '../../components/DriverProfileRoutingTabs';
import DriverProfileInformation from "../../components/DriverProfileInformation";
import { useHistory } from "react-router-dom";
import styles, {FormControl, Label, Title} from './styles/details';
import AxlButton from "../../AxlMUIComponent/AxlButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AxlConfirmation from "../../AxlMUIComponent/AxlConfirmation";
import _ from "lodash";
import AxlModal from "../../AxlMUIComponent/AxlModal";
import {AxlModalBox, AxlSimpleBox} from "../../AxlMUIComponent/AxlBox";
import AxlInput from "../../AxlMUIComponent/AxlInput";
import api, {dashboardAPI} from "../../stores/api";
import PropTypes from "prop-types";
import {RULES} from "../../constants/rules";
import validate from "validate.js";

class DriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driver: null
    };
  }
  componentDidMount() {
    this.loadDriver();
  }

  loadDriver = () => {
    const {driverStore} = this.props;
    const {id} = this.props.match.params;

    driverStore.get(id, (res) => {
      if (res.ok && res.status === 200) {
        this.setState({driver: res.data});
      }
    });
  };

  searchDriver = (e) => {
    const {store} = this.props;
    store.driverStore.getDrivers();
  };

  setDeleteClose = (value) => {
    this.setState({isDeleteClose: value});
  }

  back = () => {
    const {store} = this.props;
    store.driverStore.getDrivers();
    this.props.history.goBack();
  }

  render() {
    const {classes, store} = this.props;
    const {driver} = this.state;
    const {userStore} = store;

    return <Box>
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item>
            <AxlButton style={{minWidth: 120}} padding={'3px 10px'} variant="outlined" bgcolor="primary.white" color={'primary.blackSecondary'}
            onClick={this.back}>{`BACK`}</AxlButton>
          </Grid>
          <Grid item xs />
          {/* <Grid item>
            <EditDriverComponent data={driver} reloadDriver={this.loadDriver} />
          </Grid>
          <Grid item>
            <ResetPasswordComponent data={{ driver }} />
          </Grid>
          <Grid item>
            <RemoveDriverFromDsp
              data={{externalId: _.get(userStore, 'user.externalId'), driver: driver}}
              reloadDrivers={this.searchDriver} />
          </Grid> */}
        </Grid>
      </Box>
      <div className={classes.driverBox}>
        <DriverProfileInformation driver={driver} />
        <DriverProfileRoutingTabs driver={driver} isShowAction={false} />
      </div>
    </Box>
  }
}

function RemoveDriverFromDsp({data = null, ...props}) {
  const history = useHistory();
  const [removing, setRemoving] = useState(false);
  const [isDeleteClose, setDeleteClose] = useState(false);
  const driverFullName =  _.defaultTo(' ' + _.get(data, 'driver.first_name', ''), '') +
                          _.defaultTo(' ' + _.get(data, 'driver.middle_name', ''), '') +
                          _.defaultTo(' ' + _.get(data, 'driver.last_name', ''), '') +
                          _.defaultTo(' [id:' + _.get(data, 'driver.id', 'N/A') + ']', '');

  const remove = (driverId) => {
    setRemoving(true);
    api.delete(`/dsp/drivers/${driverId}`).then(res => {
      if (res.ok || res.status === 204) {
        setDeleteClose(true);
        history.push('/drivers');
        props.reloadDrivers();
      }
      setRemoving(false);
    })
  };

  return <Box>
    <AxlModal isClose={isDeleteClose} onRendered={() => setDeleteClose(false)} bgcolor={'primary.white'} trigger={<AxlButton padding={'3px 10px'} variant="contained" bgcolor="primary.red" color={'primary.white'}
    tooltip={{title: `Delete driver from DSP`}}>{`DELETE DRIVER`}</AxlButton>}>
      <AxlConfirmation
        title={`Are you sure to delete driver profile of ${driverFullName}?`}
        doText={`Delete`} loading={removing} disabled={removing} doProps={{ color: 'primary.white', minWidth: '120px', variant: 'outlined', bgcolor: removing ? 'primary.gray400' : 'primary.main' }}
        onClose={() => setDeleteClose(true)} closeProps={{minWidth: '120px'}}
        onDo={() => remove(_.get(data, 'driver.id'))}
        bgcolor={'primary.white'} />
    </AxlModal>
  </Box>
}

function ResetPasswordComponent({data = null, ...props}) {
  const [isClose, setClose]             = useState(false);
  const [field, setField]               = useState(data);
  const [updateField, setUpdateField]   = useState({});
  const [loading, setLoading]           = useState(false);

  useEffect(() => {
    setField(data);
  }, [data]);

  const onChange = ({target: {name, value}}) => {
    setUpdateField(_.assign({}, updateField, {[name]: value}));
  };

  const update = () => {
    if (!_.has(field, 'driver.id') || !Object.keys(updateField).length) {
      return;
    }

    const payload = {
      "username": _.get(field, 'driver.username'),
      "new_password":  _.get(updateField, 'new_password'),
      "new_password1":  _.get(updateField, 'new_password1'),
    };

    setLoading(true);

    api.put(`/dsp/drivers/user/${_.get(field, 'driver.user_id')}/password`, payload).then(res => {
      if (res.ok || res.status === 200) {
        setClose(true);
      }
      setLoading(false);
    });
  };

  const validPasswordField = (key, compare) => {
    if (!key || !compare)
      return false;
    return _.isEqual(key, compare);
  };

  // is checked
  const isValidPassword       = validPasswordField(_.get(updateField, 'new_password'), _.get(updateField, 'new_password1'));
  const isPasswordNotMatch    = !isValidPassword && (Object.values(updateField).filter(e => e).length > 1);
  const isValidReset          = isValidPassword && !!Object.values(updateField).filter(e => e).length; // no values in the fields

  return <AxlModal
    isClose={isClose} onRendered={() => setClose(false)}
    trigger={
      <AxlButton padding={'3px 10px'} variant="contained" bgcolor="primary[light-grey-blue]" color={'primary.white'}>{`RESET PASSWORD`}</AxlButton>
    }>
    <AxlSimpleBox width={740} maxWidth={'100%'} bgcolor={'primary.white'} borderRadius={5}>
      <Box mb={2} mx={1}><Title>{`Reset Driver Password`}</Title></Box>
      <Box m={1}><Label>{`Username`}</Label></Box>
      <Box mx={1}>{_.get(data, 'driver.username', 'N/A')}</Box>
      <Box>
        <Box m={1}><Label>{`New Password`}</Label></Box>
        <FormControl>
          <AxlInput name={`new_password`} type={'password'} spacing={0} onChange={onChange} value={_.get(field, 'license_plate')}/>
        </FormControl>
      </Box>
      <Box>
        <Box m={1}><Label>{`Confirm New Password`}</Label></Box>
        <FormControl>
          <AxlInput name={`new_password1`} type={'password'} spacing={0} onChange={onChange} value={_.get(field, 'license_plate')}/>
        </FormControl>
      </Box>
      <Box pt={2}>
        <Grid container spacing={1}>
          <Grid item xs>
            {isPasswordNotMatch && <Alert severity="error">{`Password does not match`}</Alert>}
          </Grid>
          <Grid item>
            <AxlButton
              padding={'5px 10px'} minWidth={120}
              variant={`outlined`} bgcolor={'primary.white'} color={'primary.graySeventh'}
              onClick={() => setClose(true)}>{`Cancel`}</AxlButton>
          </Grid>
          <Grid item>
            <AxlButton
              loading={loading}
              padding={'5px 10px'} minWidth={120} disabled={!isValidReset || loading}
              variant={`contained`} bgcolor={(isValidReset && !loading) ? 'primary.main' : 'primary.gray400'} color={'primary.white'}
              onClick={update}>{`Reset`}</AxlButton>
          </Grid>
        </Grid>
      </Box>
    </AxlSimpleBox>
  </AxlModal>
}

function EditDriverComponent({data = null, reloadDriver = () => {}, ...props}) {
  const [field, setField]               = useState(data);
  const [errors, setErrors]             = useState({});
  const [mgsErrors, setMgsErrors]       = useState({});
  const [loading, setLoading]           = useState(false);
  const [isClose, setClose]             = useState(false);
  const fieldNeedToUpdate               = ['first_name', 'last_name', 'phone_number', 'birthday', 'driver_license', 'email'];
  const validates = {
    first_name: ["notEmpty"],
    last_name: ["notEmpty"],
    phone_number: ["notEmpty", "phone"],
    birthday: ["birthday", "notEmpty"],
    driver_license: ["notEmpty"],
    email: ["notEmpty", "email"]
  };

  // is checked
  const isError                         = !!Object.values(errors).filter(e => e).length;

  useEffect(() => {
    setField(data);
  }, [data]);

  // extension for validate.js
  validate.extend(validate.validators.datetime, {
    parse: function(value, options) {
      return +moment.utc(value);
    },
    format: function(value, options) {
      var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
      return moment.utc(value).format(format);
    }
  });

  const onChange = (e) => {
    const { target: {required, name, value, type} } = e;

    if (_.includes(['phone_number'], name)) {
      setField(_.assign({}, field, {[name]: value.replace(/[\s\-()]/g, '')}));
    } else {
      setField(_.assign({}, field, {[name]: value}));
    }
    valid(e);
  };

  const update = (id) => {
    if (!id) {
      return;
    }
    const updatedFields = _.pick(field, fieldNeedToUpdate);
    setLoading(true);

    api.patch(`/dsp/drivers/${id}`, updatedFields).then(res => {
      if (res.ok && res.status === 200) {
        reloadDriver();
        setClose(true);
      }
      setLoading(false);
    });
  };
  
  const valid = ({target: {required, name, value, type}}) => {
    let _errors               = errors;

    var constraints = {
      [name]: _.assign({}, ..._.values(_.pick(RULES, validates[name])))
    };

    const hasErrors = validate({[name]: value}, constraints);

    if(hasErrors) {
      setErrors(_.assign({}, _errors, hasErrors));
    } else {
      setErrors(_.assign({}, _errors, {[name]: null}));
    }
  };

  return <AxlModal
    isClose={isClose} onRendered={() => setClose(false)}
    trigger={<AxlButton padding={'3px 10px'} variant="contained" bgcolor="primary[light-grey-blue]"color={'primary.white'}>{`EDIT`}</AxlButton>}>
    <AxlSimpleBox width={740} maxWidth={'100%'} bgcolor={'primary.white'} borderRadius={5}>
      <Box mb={2} mx={1}><Title>{`Edit Driver`}</Title></Box>
      <Box>
        <Grid container>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`First Name`}</Label></Box>
              <FormControl>
                <AxlInput
                  errors={errors[`first_name`]} required
                  name={`first_name`} spacing={0} onChange={onChange} value={_.get(field, 'first_name')}/>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`Last Name`}</Label></Box>
              <FormControl>
                <AxlInput
                  errors={errors[`last_name`]} required
                  name={`last_name`} spacing={0} onChange={onChange} value={_.get(field, 'last_name')}/>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`Date of Birth`}</Label></Box>
              <FormControl>
                <AxlInput
                  type="date" errors={errors[`birthday`]} required
                  name={`birthday`} spacing={0} onChange={onChange} value={_.get(field, 'birthday')}/>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`Driver’s License Number`}</Label></Box>
              <FormControl>
                <AxlInput
                  errors={errors[`driver_license`]} required
                  name={`driver_license`} spacing={0} onChange={onChange} value={_.get(field, 'driver_license')}/>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`Phone Number`}</Label></Box>
              <FormControl>
                <AxlInput
                  type="phone" errors={errors[`phone_number`]} name={`phone_number`} spacing={0}
                  onChange={onChange} value={_.get(field, 'phone_number')}
                  inputComponent={TextMaskPhone}
                  {...props} />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <Box m={1}><Label>{`Email Address`}</Label></Box>
              <FormControl>
                <AxlInput
                  type="email" errors={errors[`email`]} required
                  name={`email`} spacing={0} onChange={onChange} value={_.get(field, 'email')}/>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box pt={2}>
        <Grid container spacing={1}>
          <Grid item xs>
            {(_.get(mgsErrors, 'errors', []).length > 1) && <ul>
              {_.get(mgsErrors, 'errors', []).map((m, i) => <li key={i} style={{color: 'red'}}>{m}</li>)}
            </ul>}
          </Grid>
          <Grid item>
            <AxlButton
              loading={loading}
              padding={'5px 10px'} minWidth={120} disabled={isError || loading}
              variant={`contained`} bgcolor={(isError || loading) ? 'primary.gray400' : 'primary.main'} color={'primary.white'}
              onClick={() => update(_.get(data, 'id'))}>{`Save`}</AxlButton>
          </Grid>
        </Grid>
      </Box>
    </AxlSimpleBox>
  </AxlModal>
}

function TextMaskPhone(props) {
  const { inputRef, masks, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const DriverDetailsCompose = compose(
  inject("driverStore", "store"),
  observer
) (DriverDetails);

export default withStyles(styles)(DriverDetailsCompose);
