import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { inject, observer } from "mobx-react";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { compose } from "recompose";

import DspPreAuthorizedEventList from "./DspPreAuthorizedEventList";
import DspTransactionList from "./DspTransactionList";
import DspTransactionInfo from "./DspTransactionInfo";
import { BrowserRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    padding: '16px'
  },
  rootBox: {
    padding: '16px',
    backgroundColor: "#fff",
    width: '100%'
  },
  root: {
    flexGrow: 1,
  },
  tab: {
    "& .MuiTab-root": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#5a5a5a",
      textTransform: "capitalize",
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      backgroundColor: "#6c62f5",
    },
  },
});
const Payments = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { store, contractorStore } = props;
  const dspId = store.userStore.accountData?.id;

  const [value, setValue] = React.useState("events");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (dspId) contractorStore.getDspDetails(dspId);
  }, [dspId]);


  useEffect(() => {
    if (history.location.pathname.includes("transactions")) {
      setValue("transactions");
    } else setValue("events");
  }, [history.location.pathname]);
  
  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered className={classes.tab}>
        <Tab label="Pre-Authorized" value="events" onClick={() => history.push("/payments/events") } />
        <Tab label="Transaction History" value="transactions" onClick={() => history.push("/payments/transactions") } />
      </Tabs>
      <Grid container justifyContent="center"className={classes.container}>
        <Box className={classes.rootBox}>
          <Switch>
            <Route exact path="/payments/transactions" component={DspTransactionList} />
            <Route path="/payments/transactions/:paymentId" component={DspTransactionInfo} />
            <Route component={DspPreAuthorizedEventList} />
          </Switch>
        </Box>
      </Grid>
    </div>
  );
};

export default compose(inject("contractorStore", "dspEventStore", "searchDspPaymentStore", "paymentStore", "store"), observer)(Payments);
