import React, { Component } from 'react';
import { STATUS_COLOR_CODE } from 'axl-reactjs-ui';
import {compose} from "recompose";
import { inject, observer } from 'mobx-react';
import {withRouter} from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import _ from 'lodash';
import styles from "./styles";
import DriverInfoCard from "../../../../components/DriverInfoCard";
import AssignDriver from "../../../../components/AssignDriver";
import ReasonDialog from "../../../../components/ReasonDialog";
import AssignmentNote from "../../../../components/AssignmentNote";
import ReorderShipment from '../../../../components/ReorderShipment';
import AxlButton from "../../../../AxlMUIComponent/AxlButton";
import { convertMeterToMile } from '../../../../constants/common';
import acl from 'constants/acl';

class AssignmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToggleActivateAssignmentModal: false,
      showAssignDriver: false,
      showReasonDialog: false,
      driver: null,
      reason: '',
      error: '',
      isAssigning: false,
    }
  }

  onUnAssignDriver = (reason) => {
    const { assignmentStore } = this.props.store;
    const {selectedAssignment: assignmentInfo} = assignmentStore;

    assignmentStore.unassign(assignmentInfo, reason).then((r) => {
      assignmentStore.loadAssignments();
      assignmentStore.loadAssignment(assignmentStore.selectedAssignmentId);
    })
  };

  onShowAssignDriver = () => {
    this.setState({showAssignDriver: true});
  };

  onHideAssignDriver = () => {
    this.setState({showAssignDriver: false});
  };

  onAssignDriver = (driver, reason, driver_vehicle_id, callback) => {
    const { assignmentStore } = this.props.store;
    const { selectedAssignment: assignmentInfo  } = assignmentStore;

    this.setState({isAssigning: true}, () => {
      if (assignmentInfo.assignment.driver_id) {
        assignmentStore.reassign(assignmentInfo, driver, reason, driver_vehicle_id).then((r) => {
          if (r.ok) {
            this.onHideAssignDriver();
            assignmentStore.loadAssignments();
            assignmentStore.loadAssignment(assignmentStore.selectedAssignmentId);
          } else {
            this.showResponseError(r);
          }
          this.setState({isAssigning: false});
          if (callback) callback(r);
        })
      } else {
        assignmentStore.assign(assignmentInfo, driver, reason, driver_vehicle_id).then((r) => {
          if (r.ok) {
            this.onHideAssignDriver();
            assignmentStore.loadAssignments();
            assignmentStore.loadAssignment(assignmentStore.selectedAssignmentId);
          } else {
            this.showResponseError(r);
          }
          this.setState({isAssigning: false});
          if (callback) callback(r);
        })
      }
    });
  };

  onConfirmAssignReason = () => {
    const {driver, reason} = this.state;
    const driver_vehicle_id = 26;
    this.onAssignDriver(driver, reason, driver_vehicle_id, (res) => {
      this.onCloseReasonDialog();
      if (res.ok) {
        this.onHideAssignDriver();
      } else {
        this.showResponseError(res);
      }
    });
  };

  showResponseError = (r) => {
    this.setState({error: r.data ? r.data.message : ''});
    setTimeout(() => this.setState({error: ''}), 1500);
  }

  onConfirmAssign = (driver) => {
    this.setState({showReasonDialog: true, reason: '', driver});
  };

  onCloseReasonDialog = () => {
    this.setState({showReasonDialog: false, reason: '', driver: null});
  };

  onCloseToggleActivateAssignmentModal = () => {
    this.setState({showToggleActivateAssignmentModal: false});
  };

  onConfirmToggleActivateAssignment = (isActivated) => {
    const { assignmentStore } = this.props.store;
    const {selectedAssignment: assignmentInfo} = assignmentStore;

    if (!isActivated) {
      assignmentStore.activate(assignmentInfo.assignment, (r) => {
        if(!r.ok) {
          if(!r.data.message) {
            alert('Cannot active driver!');
          } else {
            alert(r.data.message);
          }
        } else {
          this.onCloseToggleActivateAssignmentModal();
          assignmentStore.loadAssignments();
        }
      })
    } else {
      assignmentStore.deactivate(assignmentInfo.assignment).then((r) => {
        this.onCloseToggleActivateAssignmentModal();
        assignmentStore.loadAssignments();
      })
    }
  };

  render() {
    const {showToggleActivateAssignmentModal, showAssignDriver, showReasonDialog, reason, error} = this.state;
    const { classes, store } = this.props;
    const { assignmentStore, userStore, permissionStore } = store;
    const { selectedAssignment: assignmentInfo } = assignmentStore;

    if (!assignmentInfo || !assignmentInfo.assignment) return null;

    const { user } = userStore;
    const {assignment, driver, isCompleted } = assignmentInfo;

    const isActivated = assignment && assignment.driver_id && assignment.is_active;
    const isShowDriverAction = assignment && assignment.driver_id && assignment.status !== 'COMPLETED';

    const statusColor = assignment.status ? STATUS_COLOR_CODE[assignment.status] : STATUS_COLOR_CODE['PENDING'];
    const isAvaiableMessageButton = false; // Disabled in this release
    const isDeniedActive = permissionStore.isDenied(acl.ASSIGNMENTS.ACTIVE);
    const isDeniedReorder = permissionStore.isDenied(acl.ASSIGNMENTS.ORDER_SHIPMENTS);
    const isDeniedMessenger = permissionStore.isDenied(acl.ASSIGNMENTS.MESSENGER);

    return (
      <Box p={1} className={classes.container}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box className={classes.title}>
              {`Assignment ${_.get(assignment, 'label', 'N/A')} Details`}
              {assignment && <code className={classes.highlightCode}>{assignment.id}</code>}
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.statusBox}>
              Status: <span className={classes.status} style={{color: statusColor}}>{assignment.status || '-'}</span>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" style={{marginTop: 16}}>
          <Grid item sm={6}>
            <Box>
              <div className={classes.label}>EST. TIME WINDOW</div>
              <div className={classes.text}>
                {`${moment(assignment.predicted_start_ts).format('hh:mm a')} - ${moment(assignment.predicted_end_ts).format('hh:mm a')}`}
              </div>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box>
              <div className={classes.label}>EST. DISTANCE</div>
              <div className={classes.text}>
                {`${convertMeterToMile(assignment.travel_distance)} mi`}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="space-around" className={classes.driverSection}>
          <Grid item xs style={{height: '100%', overflowY: 'scroll'}}>
            <Box mt={1}>
              <AssignmentNote assignmentId={assignmentInfo.assignment.id} userId={user.id}/>
              <DriverInfoCard
                history={this.props.history}
                assignment={assignmentInfo}
                driver={driver}
                driverScore={assignmentInfo.driverScore}
                isActivated={isActivated}
                isCompleted={isCompleted}
                onUnAssignDriver={this.onUnAssignDriver}
                showDriverAssign={this.onShowAssignDriver} />
            </Box>
          </Grid>
          <Box width={1} pt={1}>
            <div className={classes.footerScroll}>
              {!isDeniedActive && isShowDriverAction && (
                <AxlButton
                  bgcolor="primary.main"
                  color="primary.white"
                  ico={!isActivated && { className: 'fa fa-rocket' }}
                  icon
                  disabled={(!isActivated && !assignment.driver_id) || isCompleted}
                  onClick={() => this.setState({ showToggleActivateAssignmentModal: true })}
                >
                  <i className={`fa fa-rocket`} />
                  {isActivated ? 'De-Activate' : 'Activate'}
                </AxlButton>
              )}
              <Grid item xs />
              {!isDeniedReorder && !isCompleted && <ReorderShipment assignmentStore={assignmentStore}/>}
              {!isDeniedMessenger && isAvaiableMessageButton && <AxlButton
                variant="outlined"
                bgcolor="primary.whiteTwo"
                color="primary.graySecond"
                icon
                ico={{className: 'fa fa-comment-o'}}
                disabled={(!isActivated && !assignment.driver_id) || isCompleted}
                onClick={this.props.onShowMessenger}>
                <i className={`fa fa-comment-o`} />&nbsp;{`Driver`}
              </AxlButton>}
            </div>
          </Box>
        </Grid>

        <Dialog
          maxWidth="lg"
          open={showToggleActivateAssignmentModal}
          onClose={this.onCloseToggleActivateAssignmentModal}
          transitionDuration={{exit: 0}}
          PaperProps={{className: classes.dialogPaper}}
        >
          <DialogTitle className={classes.dialogTitle}>
            {isActivated ? 'Deactivate Assignment' : 'Activate Assignment'}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {`Please confirm that you want to ${isActivated ? 'deactivate' : 'activate'} assignment?`}
          </DialogContent>
          <DialogActions style={{justifyContent: 'space-evenly'}}>
            <Button
              variant="contained"
              disableElevation
              className={classes.dialogButton}
              onClick={this.onCloseToggleActivateAssignmentModal}>
              NO
            </Button>
            <Button variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.dialogButton}
                    onClick={() => this.onConfirmToggleActivateAssignment(isActivated)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          maxWidth="lg"
          open={showAssignDriver}
          onClose={this.onHideAssignDriver}
          PaperProps={{className: classes.dialogPaper, style: {width: 900}}}
        >
          <DialogTitle style={{textAlign: 'left'}} className={classes.dialogTitle}>
            {!assignment.driver_id ? 'Assign Driver' : 'Reassign Driver'}
          </DialogTitle>
          <DialogContent>
            <AssignDriver assignment={assignment}
                          onSelect={this.onAssignDriver}
                          onCancel={this.onHideAssignDriver}
                          driverIdSelected={assignment.driver_id}
                          selectedVehicleId={assignment.selectedVehicleId}
                          error={error} loading={this.state.isAssigning}
            />
          </DialogContent>
        </Dialog>

        <ReasonDialog
          title={assignment.driver_id ? "Reassign Driver" : "Assign Driver"}
          open={showReasonDialog}
          reason={reason}
          onChange={(reason) => this.setState({reason})}
          onClose={this.onCloseReasonDialog}
          onConfirm={this.onConfirmAssignReason}
        />
      </Box>
    );
  }
}

export default compose(withStyles(styles), withRouter, inject("store"), observer)(AssignmentDetail);
