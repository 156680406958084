export const DOSSIER_CATEGORY = {
  DRIVER_CONTRACT: 'DRIVER_CONTRACT',
  DRIVER_LICENSE_BACK: 'DRIVER_LICENSE_BACK',
  DRIVER_LICENSE_FRONT: 'DRIVER_LICENSE_FRONT',
  VEHICLE_INSURANCE_CARD: 'VEHICLE_INSURANCE_CARD',
  VEHICLE_REGISTRATION_RECORD: 'VEHICLE_REGISTRATION_RECORD',
};

export const DOSSIER_MAP_TO_DOCUMENT_TYPE = {
  DRIVER_CONTRACT: 'Driver Contract',
  DRIVER_LICENSE_BACK: 'Driver License Back',
  DRIVER_LICENSE_FRONT: 'Driver License Front',
  VEHICLE_INSURANCE_CARD: 'Vehicle Insurance Card',
  VEHICLE_REGISTRATION_RECORD: 'Vehicle Registration Record',
};
