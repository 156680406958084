import React from 'react';
import clsx from 'clsx';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { NavLink, useLocation } from 'react-router-dom';
import { AppBar, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ACL_PATTERN from 'constants/acl';

const useStyles = makeStyles({
  secondAppBar: {
    height: '50px',
    backgroundColor: '#2a2444',
  },
  menuItem: {
    position: 'relative',
    color: '#f4f4f4',
    margin: '0 5px',
    padding: '0 15px',
    height: '100%',
    textDecoration: 'none',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  menuItemActive: {
    fontWeight: 700,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      right: 0,
      height: 3,
      backgroundColor: '#fff',
    },
  },
});

export const TABS = [
  { label: 'Dispatch', link: '/routes', deniedPatterns: ACL_PATTERN.ASSIGNMENTS.LIST },
  { label: 'Vehicle', link: '/vehicles', deniedPatterns: ACL_PATTERN.VEHICLES.LIST },
  { label: 'Drivers', link: '/drivers', deniedPatterns: ACL_PATTERN.DRIVERS.LIST },
  { label: 'Payment', link: '/payments', deniedPatterns: [ACL_PATTERN.PAYMENTS.EVENT_LIST, ACL_PATTERN.PAYMENTS.TRANSACTION_LIST].flat() },
];

function Navigation(props) {
  const classes = useStyles();
  const location = useLocation();

  const { store } = props;
  const { permissionStore } = store;

  const filteredTabs = TABS.filter((tab) => permissionStore.isDenied(tab.deniedPatterns) === false);

  return (
    <AppBar className={classes.secondAppBar} position="relative">
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        {filteredTabs.map((tab, index) => (
          <NavLink
            key={index}
            className={clsx({ [classes.menuItem]: true, [classes.menuItemActive]: location.pathname.startsWith(tab.link) })}
            to={tab.link}
          >
            {tab.label}
          </NavLink>
        ))}
      </Grid>
    </AppBar>
  );
}

export default compose(inject('store'), observer)(Navigation);
