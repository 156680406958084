import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  min-height: 100px;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 10px;
  padding: 5px;
`;

export const Value = styled.span`
  margin-left: 10px;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: inline-block;
  width: 100%;
  word-break: break-word;
  color: ${props => props.status ? "#4abc4e" : "#4a4a4a"};
`;
