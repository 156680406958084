import styled from "styled-components";
import {AxlPanel} from "axl-reactjs-ui";

export const StyledAxlPanel = styled(AxlPanel)`
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 20px;
`;

export const StyledLabel = styled.span`
  font-family: AvenirNext-Bold;
  font-size: 20px
`;

export const StyledLink = styled.span`
  cursor: pointer;
  color: #887fff;
  font-size: 16px;
`;

export const StyledBold = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
`;

export const StyledAmount = styled.span`
  font-size: 16px;
  margin-right: 5px;
  margin-left: 5px;
`;