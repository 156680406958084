import React, {useEffect, useState} from "react";
import _ from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AxlMiniAssignmentBox } from 'axl-reactjs-ui';
import withStyles from '@mui/styles/withStyles';
import { Box, CardMedia, Grid, Tooltip } from '@mui/material';

import AxlButton from 'AxlMUIComponent/AxlButton';
import AxlModal from 'AxlMUIComponent/AxlModal';
import AxlRadio from 'AxlMUIComponent/AxlRadio';
import AxlInput from 'AxlMUIComponent/AxlInput';
import { AxlSimpleBox } from 'AxlMUIComponent/AxlBox';

import styles, * as S from './styles';
import acl from 'constants/acl';
import { STATUS } from 'constants/status';
import { images } from 'constants/images';

function RerouteShipment({ selectedShipment = {}, containerStyled = {}, selectedStop = {}, keySearch = ['label', 'id', 'zones', 'code'], store, ...props }) {
  const { assignmentStore, shipmentStore, permissionStore } = store;

  const [selectedMoveAssignmentId, setSelectedMoveAssignmentId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isClose, setClose] = useState(false);
  const {label} = selectedStop;
  const {classes} = props;
  const {selectedAssignment} = assignmentStore;
  const selectedAssignmentId = selectedAssignment && selectedAssignment.assignment ? selectedAssignment.assignment.id : 0;
  const [rerouteAssignments, setRerouteAssignments] = useState([]);
  const [assignments, setAssignments] = useState([])

  const [movableAssignmentsCount, setMovableAssignmentsCount] = useState(0);

  useEffect(() => {
    assignmentStore.loadAllAssignments((assignments) => {
      const inProgressAssignments = assignments.filter(a => a.driver_id && a.is_active && a.status !== STATUS.COMPLETED)
      const pendingAssignments = assignments.filter(a => a.driver_id && a.status !== STATUS.COMPLETED && !a.is_active)
      const unassignedAssignments = assignments.filter(a => !a.driver_id && (a.status !== STATUS.COMPLETED))

      const processedAssignments = [
        { 'status': 'UNASSIGNED', 'assignments': unassignedAssignments },
        { 'status': 'PENDING', 'assignments': pendingAssignments },
        { 'status': 'IN PROGRESS', 'assignments': inProgressAssignments },
      ]
      setRerouteAssignments(processedAssignments)
      setAssignments(processedAssignments)
    })
  }, [assignmentStore])

  useEffect(() => {
    let movableAssignments = [];
    rerouteAssignments.forEach(data => {
      movableAssignments = movableAssignments.concat(data.assignments.filter(as => as.id !== selectedAssignmentId))
    });
    setMovableAssignmentsCount(movableAssignments.length);
  }, [rerouteAssignments, selectedAssignmentId]);

  const moveShipment = (e) => {
    const {selectedAssignment} = assignmentStore;
    const {selectedShipment} = shipmentStore;

    if (!selectedAssignment || !selectedShipment) {
      return;
    }
    setLoading(true);
    assignmentStore.addShipment(selectedMoveAssignmentId, selectedShipment.id, res => {
      if(res.ok && res.status === 200) {
        setTimeout(function() {
          assignmentStore.loadAssignments();
          setClose(true);
        }, 1000);
      }
      setLoading(false);
    });
  };

  const selectAssignmentBox = (id) => {
    if(id === selectedMoveAssignmentId) {
      setSelectedMoveAssignmentId(null);
    } else {
      setSelectedMoveAssignmentId(id);
    }
  };

  const onSearch = ({ target: { value } }) => {
    const search = _.debounce((v) => {
      let newItems = [];
      if(v) {
        newItems = _.map(assignments, (data, idx) => {
          const result = data.assignments.map(as => {
            const keySearchMap = keySearch.map(key => _.get(as, key, '')).filter(key => key).join(" ").toLowerCase();

            return _.includes(keySearchMap, v.toLowerCase()) ? as : null;
          }).filter(a => a);

          return ({
            ...data,
            assignments: result
          });
        }).filter(data => data.assignments.length);
      } else {
        newItems = assignments;
      }

      setRerouteAssignments(newItems);
    }, 300);

    search(value);
  };

  const reinitialState = () => {
    setSelectedMoveAssignmentId(null);
    setRerouteAssignments(assignments)
    setClose(false)
  };

  const isDeniedReroute = permissionStore.isDenied(acl.ASSIGNMENTS.RE_ROUTE);

  if (isDeniedReroute) return null;

  return <S.Container>
    <AxlModal
      onClose={reinitialState} style={{width: 900}} isClose={isClose}
      trigger={(
        <Tooltip title="Reroute shipment to another assignment">
          <Box>
            <AxlButton color="primary.graySeventh" spacing={0.5} icon disableElevation variant="outlined" size="small" disabled={selectedShipment.status === 'DROPOFF_SUCCEEDED'}>
              <CardMedia className={classes.footerBtnIcon2} image={images.icon.rerouter} />
            </AxlButton>
          </Box>
        </Tooltip>
      )}
    >
      <Box className={classes.container}>
        <Box py={2} mb={1}>
          Reroute shipment <strong>{_.get(label, 'driver_label', '', 'N/A')}</strong>
        </Box>
        <ToastContainer />
        <Box py={1}>
          <AxlInput onChange={onSearch} spacing={0} placeholder={`Search an assignment to move shipment ${_.get(label, 'driver_label', '', 'N/A')} to...`} />
        </Box>
        <AxlSimpleBox>
          <Box p={1} className={classes.body}>
            {!movableAssignmentsCount && <Box p={1} align="center">No assignments to move</Box>}
            {!!movableAssignmentsCount && rerouteAssignments.map((data) => data.assignments.filter(as => as.id !== selectedAssignmentId).map((a, i) => (
              <Grid container key={i} spacing={1} alignItems={'center'}>
                <Grid item>
                  <AxlRadio color={'primary'} checked={selectedMoveAssignmentId === a.id} onClick={() => selectAssignmentBox(a.id)} />
                </Grid>
                <Grid item xs>
                  <AxlMiniAssignmentBox
                    key={a.id}
                    onClick={() => selectAssignmentBox(a.id)}
                    assignment={a}
                    status={rerouteAssignments.status}
                    style={{opacity: !selectedAssignmentId || selectedMoveAssignmentId === a.id ? 1.0 : 0.5 }}
                  />
                </Grid>
                <Grid item xs>
                  <S.StatusText style={{color: data.color}}>{`- ${data.status}`}</S.StatusText>
                </Grid>
              </Grid>
            )))}
          </Box>
        </AxlSimpleBox>
        <Grid container>
          <Grid item xs/>
          <AxlButton
            variant="outlined"
            color="primary.graySeventh"
            bgcolor="primary.white"
            disableElevation
            spacing={0.5}
            className={classes.btnControl}
            onClick={() => setClose(true)}
          >
            {`Cancel`}
          </AxlButton>
          <AxlButton
            variant="contained"
            color="primary.white"
            bgcolor={(loading || !selectedMoveAssignmentId) ? `primary.gray400` : `primary.periwinkle`}
            disableElevation
            disabled={loading || !selectedMoveAssignmentId}
            loading={loading}
            spacing={0.5}
            className={classes.btnControl}
            onClick={moveShipment}
          >
            {`Confirm`}
          </AxlButton>
        </Grid>
      </Box>
    </AxlModal>
  </S.Container>
}

export default compose(withStyles(styles), withRouter, inject('store'), observer)(RerouteShipment);
