import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {Box, styled, Typography} from "@mui/material";
import fonts from "../../../themes/fonts";
import colors from "../../../themes/colors";

const styles = theme => ({
  driverBox: {
    backgroundColor: '#fff',
    padding: '20px'
  },  
});

export const Text = styled(Typography)(({theme}) => ({
  fontSize: 15,
  fontFamily: fonts.Medium,
  color: colors['greyish-brown'],
}));

export const Title = styled(Text)(({theme}) => ({
  fontSize: 20,
  fontFamily: fonts.Bold,
  color: colors['greyish-brown'],
}));

export const Label = styled(Text)(({theme}) => ({
  fontSize: 13,
  color: colors.graySecond,
}));

export const FormControl = styled(Box)(({theme}) => ({
  margin: '4px 8px',
}));

export default styles;    