const styles = theme => ({
    root: {  
      backgroundColor: '#fff',
      padding: '20px'    
    },  
    mainBtn: {
      padding: '2px 15px',
      backgroundColor: '#887fff',
      color: '#fff',
      textTransform: 'none',
      width: '100px',
      "&:hover": {
        background: '#887fff',
      }
    },
    secondaryBtn: {
      padding: '2px 15px',
      backgroundColor: '#bec6d8',
      color: '#fff',
      textTransform: 'none',
      width: '100px',
      "&:hover": {
        background: '#bec6d8',
      }
    },
    errorBtn: {
      padding: '2px 15px',
      backgroundColor: '#d0021b',
      color: '#fff',
      textTransform: 'none',
      width: '100px',
      "&:hover": {
        background: '#d0021b',
      }
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      position: "absolute",
      inset: 0,
      textAlign: "center",
      paddingTop: 100
    },
    flexItem: {
      marginRight: 5
    }
});

export default styles;