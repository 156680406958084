import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import AppContainer from './containers';

import { stores } from './stores';
import AppStore from './stores/AppStore';

function App() {
  const store = new AppStore();

  useEffect(() => {
    store.permissionStore.getAclSettings();
  }, [store.permissionStore]);

  return (
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <Provider store={store} {...stores}>
          <ToastContainer />
          <Router>
            <Switch>
              <Route path="/" component={AppContainer} />
            </Switch>
          </Router>
        </Provider>
      </RecoilRoot>
    </StyledEngineProvider>
  );
}

export default App;
