import React, {Component} from "react";
import { Box, Link, Grid, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {ArrowBackIos as ArrowBackIcon} from "@mui/icons-material";
import {AxlMiniStopBox, AxlModal, AxlPanel} from "axl-reactjs-ui";
import clsx from "clsx";

import styles from "./styles";
import {inject, observer} from "mobx-react";
import AssignmentNote from "../../components/AssignmentNote";
import Moment from "react-moment";
import moment from "moment";
import AssignmentAssign from "../../components/AssignmentAssign";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import { convertMeterToMile } from "../../constants/common";

class AssignmentMiniDetailCompose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnassignDriver: false,
      reason: '',
    };

    const {shipments, stops, clients, shipmentLabels} = props.assignmentInfo;
    this.stops = stops.filter(st => st.type === 'DROP_OFF').map(st => {
      st.shipment = shipments.filter(sh => sh.id === st.shipment_id).shift();
      st.client = clients?.filter(cl => cl.id === st.client_id).shift();
      st.label = shipmentLabels?.filter(lb => lb.shipment_id === st.shipment_id).shift();

      return st;
    });
  }

  hideUnassignDriver = () => {
    this.setState({showUnassignDriver: false});
  }

  updateReason = () => (e) => {
    this.setState({reason: e});
  }

  onUnAssignDriver() {
    const {reason} = this.state;
    if (!reason) return;
    const { assignmentInfo, onBack, reloadData, store } = this.props;
    const { assignmentStore, messengerStore } = this.props.store;
    const { assignmentSummeries } = assignmentStore;
    // const topicSelectedId = (assignmentSummeries[assignmentInfo.assignment.id] && assignmentSummeries[assignmentInfo.assignment.id].topic_id) || null;

    assignmentStore.unassign(assignmentInfo, reason).then((r) => {
      if (r.ok) {
        onBack(reloadData);
        // unfollow driver into a topic
        if(assignmentInfo.assignment && assignmentInfo.assignment.driver_id) {
          // if(topicSelectedId) {
          //   messengerStore.topicSelectedId = topicSelectedId;
          //   messengerStore.driverUnfollow(assignmentInfo.assignment.driver_id);
          // }
        }
      }
    })
  }

  render() {
    const {showUnassignDriver} = this.state;
    const {assignmentInfo, onBack, classes, store} = this.props;
    const {assignment, shipments, driver} = assignmentInfo;
    const {user = {}} = store.userStore;
    const status = assignment.status || 'PENDING';

    const unAssignOptions = {
      props: {
        bg: 'none',
        tiny: true
      },
      onClick: () => {
        this.hideUnassignDriver();
        this.onUnAssignDriver();
      },
      text: `Unassign Driver - [${driver.id}] ${driver.first_name} ${driver.last_name}`,
      title: 'Unassign Driver'
    };

    return (
      <Box p={2} className={classes.container}>
        <Box mb={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <ArrowBackIcon className={classes.backItem} color="primary" onClick={() => onBack()} fontSize="small" />
              <Link className={classes.backItem} onClick={() => onBack()} underline="always" color="primary">Back to Pending Route List</Link>
            </Grid>
            <Grid item>
              <Box mx={1} component="span">
                <Button variant="contained" size="small"
                        disableElevation color="secondary"
                        onClick={() => this.setState({showUnassignDriver: true})}
                >
                  Unassign
                </Button>
              </Box>
              <Box component="span">
                <Button variant="contained" size="small"
                        target="_blank" disableElevation
                        href={`/routes/${moment(assignment.predicted_end_ts).format('YYYY-MM-DD')}/all/${assignment.id}`}
                >
                  View in dispatch
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.infoContainer}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Box component="span" className={classes.label}>{assignment.label}</Box>
              <code className={classes.code}>{assignment.id}</code>
            </Grid>
            <Grid item>
              <Box>Assignment Status</Box>
              <Box className={clsx(classes.bold, classes[status.trim().toLowerCase()])}>{status}</Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.assignmentInfo}>
            <Grid item xs={12} sm={6}>
              <Box py={0.5}>
                <span>Type: </span>
                <span className={classes.bold}>{shipments[0].service_level}</span>
              </Box>
              <Box py={0.5}>
                <span>Volume: </span>
                <span className={classes.bold}>{shipments.length}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box py={0.5}>
                <span>Est. Time Window: </span>
                <span className={classes.bold}>
                  <Moment interval={0} format='hh:mm a'>{assignment.predicted_start_ts}</Moment> - <Moment interval={0} format='hh:mm a'>{assignment.predicted_end_ts}</Moment>
                </span>
              </Box>
              <Box py={0.5}>
                <span>Est. Distance: </span>
                <span className={classes.bold}>{convertMeterToMile(assignment.travel_distance)} {`mi`}</span>
              </Box>
            </Grid>
          </Grid>
          <Box py={1}>
            <AssignmentNote assignmentId={assignment.id} userId={user.id}/>
          </Box>
        </Box>
        <Box>
          <Box pb={1} align="left" className={classes.bold}>Shipment List:</Box>
          <Box p={2} className={classes.shipmentList}>
            {this.stops.map(stop => (
              <AxlMiniStopBox
                onClick={() => null}
                key={stop.id}
                stop={stop}
              />
            ))}
          </Box>
        </Box>
        {showUnassignDriver && <AxlModal onClose={this.hideUnassignDriver} style={styles.modalStyle} containerStyle={styles.modalContainer}>
          <AssignmentAssign {...unAssignOptions} updateReason={this.updateReason()} onClose={this.hideUnassignDriver} />
        </AxlModal>}
      </Box>
    );
  }
}

const AssignmentMiniDetail = compose(
  withRouter,
  inject('store'),
  observer
)(AssignmentMiniDetailCompose);

export default withStyles(styles)(AssignmentMiniDetail);