import React, {Component, Fragment} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import { Container, Grid, Box } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import moment from "moment";
import ShipmentList from "./ShipmentList";
import AssignmentDetail from "./AssignmentDetail";

class AssignmentDetailContainer extends Component {
  constructor(props) {
    super(props);
  }

  onSelectStop = (s) => {
    const { store, history } = this.props;
    const { shipmentStore, assignmentStore } = store;
    const { regions, selectedAssignmentId, date } = assignmentStore;
    const region = regions.length > 0 ? regions.join(',') : 'all';
    if (s.id === shipmentStore.selectedStopId) {
      history.push(`/routes/${date}/${region}/${selectedAssignmentId ? selectedAssignmentId : ''}`);
      shipmentStore.selectStop(null);
    } else {
      history.push(`/routes/${date}/${region}/${selectedAssignmentId ? selectedAssignmentId : '-'}/stops/${s.id}`);
      shipmentStore.selectStop(s);
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <Box className={classes.container}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item className={classes.shipmentsList}>
            <ShipmentList onSelectStop={(s) => this.onSelectStop(s)} />
          </Grid>
          <Grid item className={classes.assignmentDetail}>
            <AssignmentDetail onShowMessenger = {this.props.onShowMessenger} />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const AssignmentDetailContainerCompose = compose(
  inject("store"),
  observer
) (AssignmentDetailContainer);

export default withStyles(styles)(withRouter(AssignmentDetailContainerCompose));