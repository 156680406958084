import React from "react";
import {
  ThemeProvider, StyledEngineProvider, Select, MenuItem,
  FormControl,
} from "@mui/material";
import { mainTheme } from "./themes";

const mapToTheme = {
  // light: lightTheme,
  main: mainTheme,
};

export default function AxlSelect({ theme = "main", ...props }) {
  const [value, setValue] = React.useState(props.defaultValue);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    if (props.onChange) props.onChange(value);
  };

  // useEffect(() => {
  //   props.triggerOnchange(value);
  // });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mapToTheme[theme]}>
        <FormControl fullWidth size="small">

        <Select
          value={value}
          onChange={handleChange}
          variant="outlined"
          {...props}
        >
          {props.options.length ? (
            props.options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0}>{`No value`}</MenuItem>
          )}
        </Select>
        </FormControl>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
