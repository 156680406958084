import React from 'react';
import Pagination from '@mui/material/Pagination';
import { Box, ThemeProvider, StyledEngineProvider } from "@mui/material";
import * as themes from "./theme";
import THEME_DEFINED from "../../constants/theme";

export default function AxlPagination({theme = 'main', ...props}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes[THEME_DEFINED[theme]]}>
        <Box>
          <Pagination {...props} />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
