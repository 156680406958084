import {Typography, styled} from "@mui/material";
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";

export const FileName = styled(Typography)(({theme}) => ({
  fontSize: 11,
  fontFamily: fonts.MediumItalic,
  margin: '8px 0px',
  color: colors.link,
}));
