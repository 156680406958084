import React from "react";
import {Grid} from "@mui/material";
import {Label, Value} from "../LabelValue/styles";
import {MomentValue} from "./styles";

class LabelDateValue extends React.Component {
  render() {
    return (
        <Grid item>
            <Label>{this.props.label}
              <span>: </span>
              {this.props.value ? <MomentValue interval={0} format='YYYY-MM-DD HH:MM'>{this.props.value}</MomentValue> : ''}
            </Label>
        </Grid>
    )
  }
}

export default LabelDateValue;
