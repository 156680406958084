import React, {Component} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import withStyles from '@mui/styles/withStyles';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {AxlPagination} from 'axl-reactjs-ui';
import AxlButton from "../../AxlMUIComponent/AxlButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import styles, {
  SearchText, OptionLabelText,
  SearchSelect, BootstrapInput,
  SearchOptionContainer, SearchInput
} from './styles';
import AddAccountInfo from './AddAccountInfo';
import AddVehicle from './AddVehicle';
import AddAccount from './AddAccount';
import DriverDetails from './Details';

import acl from 'constants/acl';

class DriverList extends Component {
    constructor(props) {
        super(props); 
        this.state = {
          searchField: 'name',
          openFilter: false,
          searching: false,
        }            
    }

    statusColorMap = {
      CREATED: '#bebfc0',
      BACKGROUND_APPROVED: '#4a90e2',
      APPROVED: '#4a90e2',
      ACTIVE: '#7ed321',
      INACTIVE: '#d0021b',
      SUSPENDED: '#d0021b'
    }

    componentDidMount() {
      const { store } = this.props;
      const driverDetailsMatch = this.props.location.pathname.match(/\/drivers\/[\d]+\/info/gi);

      if (!driverDetailsMatch)
        store.driverStore.getDrivers();
    }

    handleSearchFieldChange = event => {    
      const {driverStore} = this.props.store;
      driverStore.searchField = event.target.value.trim();
      driverStore.page = 0;
    };

    handleSearchKeywordChange = event => {
      const {driverStore} = this.props.store;
      driverStore.searchKeyword = event.target.value.trim();
      driverStore.page = 0;
    }

    clearSearch = event => {
      const {driverStore} = this.props.store;
      driverStore.searchKeyword = '';
      driverStore.page = 0;
      this.setState({
        searching: true
      }, () => {
        driverStore.getDrivers((res) => {
          this.setState({searching: false});
        });
      });
    }

    onSelectPage = (page) => {
      const {driverStore} = this.props.store;
      driverStore.page = page - 1;
      driverStore.getDrivers()
    }

    addDriver = () => {
      this.props.history.push('/drivers/add-account-info');
    }

    gotoDetails = (id) => () => {
      this.props.history.push(`/drivers/${id}/info`);
    }

    searchDriver = (e) => {      
      const {store} = this.props;
      this.setState({
        searching: true
      }, () => {
        store.driverStore.getDrivers((res) => {
          this.setState({searching: false});
        });
      });
      e.preventDefault();
      return false;
    };

    setOpen = () => this.setState({openFilter: true});

    setClose = () => this.setState({openFilter: false});

    toggle = () => this.setState({openFilter: !this.state.openFilter});

    onSearchEnter = (e) => {
      if(e.keyCode === 13) {
        this.searchDriver(e);
      }
    }

    render() {
        const { classes, store } = this.props;
        const { permissionStore } = store;
        const {data, searchKeyword, searchField} = store.driverStore;
        let totalPage = 0;

        if (data && data.count && data.count > 0) {
          totalPage = Math.floor(data.count/20) + 1;
          if (totalPage === 1) totalPage = 0;
        }

        const driverDetailsMatch = this.props.location.pathname.match(/\/drivers\/[\d]+\/info/gi);
        const isDeniedCreateDriver = permissionStore.isDenied(acl.DRIVERS.CREATE);

        return (
          <Container maxWidth={false} className={classes.root}>
            {!driverDetailsMatch && <Box>
              <Grid justifyContent="flex-end" container spacing={3}>
                <Grid item>
                  {!isDeniedCreateDriver && <Button variant="contained" onClick={this.addDriver} className={classes.addDriverBtn}>{`+ NEW`}</Button>}
                </Grid>
              </Grid>
              <Box my={2}>
                <Grid container spacing={1}>
                  <Grid item>
                    <SearchOptionContainer onClick={this.toggle}>
                      <OptionLabelText>{`Search by`}</OptionLabelText>
                      {this.state.openFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      <SearchSelect open={this.state.openFilter} value={searchField}
                                    onChange={this.handleSearchFieldChange}
                                    onOpen={() => this.setOpen()}
                                    onClose={() => this.setClose()}
                                    input={<BootstrapInput />}
                                    style={{border: 'none', background: 'transparent'}}
                                    IconComponent={() => null}>
                        <MenuItem value={'name'}>{`Name`}</MenuItem>
                        <MenuItem value={'phone_number'}>{`Phone Number`}</MenuItem>
                        <MenuItem value={'id'}>{`Driver ID`}</MenuItem>
                      </SearchSelect>
                    </SearchOptionContainer>
                  </Grid>
                  <Grid item xs>
                    <Paper className={classes.searchText} component="div" elevation={0}>
                      <form onSubmit={this.searchDriver}>
                        <Grid container alignItems={'center'}>
                          <Grid item>
                            <IconButton color="primary.gray" aria-label="directions" size="large">
                              <SearchIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                          <Grid item xs>
                            <SearchInput
                              fullWidth
                              placeholder="Search drivers here..."
                              value={searchKeyword}
                              onChange={this.handleSearchKeywordChange}
                              inputProps={{ 'aria-label': 'search driver' }} />
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={this.clearSearch}
                              color="primary.gray"
                              aria-label="directions"
                              size="large">
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <AxlButton
                      loading={this.state.searching}
                      style={{minHeight: 48}}
                      onClick={this.searchDriver}
                      variant="contained"
                      bgcolor="primary.main"
                      color="primary.white"
                      spacing={0} minWidth={170}>
                      <SearchText>{`SEARCH`}</SearchText>
                    </AxlButton>
                  </Grid>
                </Grid>
              </Box>
              {data.drivers && data.drivers.length > 0 && <Container maxWidth={false} style={{padding: 0}}>
                <Grid container justifyContent="space-between" direction="row" alignItems="stretch" spacing={2}>
                  {data.drivers.map((driver, i) => {
                    const statusColor = this.statusColorMap[driver.status] ? this.statusColorMap[driver.status] : "#bebfc0";

                    return (
                      <Grid item xs={6} key={driver.id} className={classes.driverItemWrapper} onClick={this.gotoDetails(driver.id)} alignItems={'center'}>
                        <Paper className={classes.driverItem} elevation={0} square>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item><Avatar variant="square" alt={driver.first_name} className={classes.avatar} src={driver.photo} /></Grid>
                                <Grid item className={`${classes.itemColumn} ${classes.itemDriverName}`}>{driver.first_name} {driver.last_name}</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={2} className={`${classes.itemColumn} ${classes.columnPhone}`}>{driver.phone_number}</Grid>
                            <Grid item xs={4} className={`${classes.itemColumn} ${classes.columnStatus}`}>
                              <span style={{color: statusColor}} className={classes.driverStatus}>{driver.status}</span> <IconButton aria-label="delete" disabled color="primary" size="large">
                              <ArrowForwardIosIcon style={{color: '#bfbfbf', fontSize: '12px'}} />
                            </IconButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                })}
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item style={{padding: '10px 0'}}>
                    <AxlPagination onSelect={this.onSelectPage} current={data.page + 1} total={totalPage}></AxlPagination>
                  </Grid>
                </Grid>
              </Container>}
            </Box>}
            <Switch>
              <Route exact path={`/drivers/:id/info`} component={DriverDetails} />
              <Route exact path={`/drivers/add-account-info`} component={AddAccountInfo} />
              <Route exact path={`/drivers/add-vehicle`} component={AddVehicle} />
              <Route exact path={`/drivers/add-account`} component={AddAccount} />
            </Switch>
          </Container>
        );
    }
}

export default compose(withStyles(styles), withRouter, inject("store"), observer)(DriverList);
