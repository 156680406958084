export const shipmentRedefinedStatus = {
  PICKUP_SUCCEEDED: 'SUCCEEDED',
  DROPOFF_SUCCEEDED: 'SUCCEEDED',
  DROPOFF_READY: 'PENDING',
  PENDING: 'PENDING',
  DELIVERED: 'DELIVERED',
  PICKUP_FAILED: 'FAILED',
  CANCELLED_AFTER_PICKUP: 'FAILED',
}

// Created
export const CREATED = 'CREATED';

// Geocoded
export const GEOCODE_FAILED = 'GEOCODE_FAILED';
export const GEOCODE_FAILED_PICKUP = 'GEOCODE_FAILED_PICKUP';
export const GEOCODE_FAILED_DROPOFF = 'GEOCODE_FAILED_DROPOFF';
export const GEOCODED = 'GEOCODED';

// Assigned
export const ASSIGNED = 'ASSIGNED';
export const PENDING = 'PENDING';

// Pickup
export const PICKUP_EN_ROUTE = 'PICKUP_EN_ROUTE';
export const PICKUP_DELAY = 'PICKUP_DELAY';
export const CANCELLED_BEFORE_PICKUP = 'CANCELLED_BEFORE_PICKUP';
export const PICKUP_READY = 'PICKUP_READY';
export const PICKUP_FAILED = 'PICKUP_FAILED';
export const PICKUP_SUCCEEDED = 'PICKUP_SUCCEEDED';

// Dropoff
export const DROPOFF_EN_ROUTE = 'DROPOFF_EN_ROUTE';
export const DROPOFF_DELAY = 'DROPOFF_DELAY';
export const CANCELLED_AFTER_PICKUP = 'CANCELLED_AFTER_PICKUP';
export const DROPOFF_READY = 'DROPOFF_READY';
export const DROPOFF_FAILED = 'DROPOFF_FAILED';
export const DROPOFF_SUCCEEDED = 'DROPOFF_SUCCEEDED';
export const DROPOFF_REATTEMPTING = 'DROPOFF_REATTEMPTING';

// Return
export const RETURN_EN_ROUTE = 'RETURN_EN_ROUTE';
export const RETURN_DELAY = 'RETURN_DELAY';
export const RETURN_READY = 'RETURN_READY';
export const RETURN_FAILED = 'RETURN_FAILED';
export const RETURN_SUCCEEDED = 'RETURN_SUCCEEDED';

// Inbound
export const RECEIVED_OK = 'RECEIVED_OK';
export const RECEIVED_DAMAGED = 'RECEIVED_DAMAGED';
export const MISSING = 'MISSING';

// Outbound
export const DAMAGED = 'DAMAGED';
export const LEAKING = 'LEAKING';
export const NOT_FOUND = 'NOT_FOUND';

// COMMUNICATION
export const INFORM_RECIPIENT_SHIPMENT_CONFIRMED = 'INFORM_RECIPIENT_SHIPMENT_CONFIRMED';
export const INFORM_RECIPIENT_PICKUP_SUCCEEDED = 'INFORM_RECIPIENT_PICKUP_SUCCEEDED';
export const INFORM_RECIPIENT_PICKUP_FAILED = 'INFORM_RECIPIENT_PICKUP_FAILED';
export const INFORM_RECIPIENT_COMING_SOON = 'INFORM_RECIPIENT_COMING_SOON';
export const INFORM_RECIPIENT_NEXT_IN_LINE = 'INFORM_RECIPIENT_NEXT_IN_LINE';
export const INFORM_RECIPIENT_DROPOFF_SUCCEEDED = 'INFORM_RECIPIENT_DROPOFF_SUCCEEDED';
export const INFORM_RECIPIENT_DROPOFF_FAILED = 'INFORM_RECIPIENT_DROPOFF_FAILED';
