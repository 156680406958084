import { observable, action, decorate } from 'mobx';

class LocationStore {
  location = null
  
  updateLocation(location) {
    this.location = location
  }
  
  getLocation(){
    return this.location
  }

  activeDriver = null
  lastLocation = null

  constructor(logger, api) {
    this.logger = logger;
    this.api = api;
  }
  
  setDriver(id) {
    if (id === this.activeDriver) return;

    this.activeDriver = id
    this.lastLocation = null
    if (this.activeDriver) {
      this.loadLocation();
      if (!this.timer) {
        this.timer = setInterval(() => this.loadLocation(), 10000)
      }
    } else {
      if (this.timer)
        clearInterval(this.timer);
    }
  }
  
  loadLocation() {
    if (!this.activeDriver) {
      this.lastLocation = null;
      return;
    }
    this.api.get(`/drivers/${this.activeDriver}/last-known-location?past=600000`).then((r) => {
      if (r.status == 200) {
        if (r.data.driver_id === parseInt(this.activeDriver))
          this.lastLocation = r.data
      }
    })  
  }
}

decorate(LocationStore, {
  location: observable,
  activeDriver: observable,
  lastLocation: observable,
  updateLocation: action,
  getLocation: action,
  setDriver: action,
  loadLocation: action,
});

export default LocationStore
