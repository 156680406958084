import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import _ from 'lodash';
import { AxlButton } from 'axl-reactjs-ui';
import ReactMapGL, { Popup, LinearInterpolator } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import DeckGL, { PathLayer, IconLayer } from 'deck.gl';
import withStyles from '@mui/styles/withStyles';

import styles from '../styles';
import MARKERS from 'assets/images/dots.svg';
import CarIcon from 'assets/images/car.svg';
import StopAssignmentTooltip from 'components/StopAssignmentTooltip';
import { CAR_ICON_MAPPING, DETECT_CAR_COLOR } from 'constants/carIconMapping';

export const COLOR_PALETTE = [
  [15, 48, 217],
  [213, 85, 53],
  [212, 106, 106],
  [161, 195, 73],
  [239, 143, 119],
  [75, 78, 77],
  [35, 63, 136],
  [170, 116, 51],
  [68, 152, 165],
  [60, 122, 74],
  [88, 133, 176],
  [176, 23, 28],
  [53, 173, 141],
  [246, 84, 106],
  [44, 75, 37],
  [0, 128, 128],
  [246, 84, 106],
  [102, 17, 65],
  [30, 75, 0],
  [113, 141, 165],
  [56, 112, 18],
  [68, 0, 38],
  [8, 141, 165],
  [19, 7, 58],
  [85, 0, 0],
  [38, 23, 88],
  [128, 21, 21],
];

const mapStyle = `https://api.maptiler.com/maps/${process.env.REACT_APP_MAP_TILER_STYLE}/style.json?key=${process.env.REACT_APP_MAP_TILER_KEY}`;
class AssignmentListMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redeliverShipment: null,
      hoveredStop: null,
      hoveredDriver: null,
      viewport: {
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 11,
      },
    };

    this.iconMapping = {};
    const iconW = 36,
      iconH = 36,
      iconAnchor = 18;
    this.iconMapping['green'] = {
      x: 0,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
    this.iconMapping['red'] = {
      x: iconW,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
    this.iconMapping['yellow'] = {
      x: iconW * 2,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
    this.iconMapping['cyan'] = {
      x: iconW * 3,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
    this.iconMapping['purple'] = {
      x: iconW * 4,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
    this.iconMapping['white'] = {
      x: iconW * 5,
      y: 0,
      width: iconW,
      height: iconH,
      anchorY: iconAnchor,
    };
  }

  componentDidMount() {
    this.boundaryDisposer = autorun(
      () => {
        const { assignmentStore } = this.props.store;
        const { boundary } = assignmentStore;

        if (!boundary) return;

        this.fitBoundary(boundary);
      },
      { delay: 100 },
    );

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 90,
      unit: 'imperial',
    });
    setTimeout(() => {
      if (this.mapRef) {
        const myMap = this.mapRef.getMap();
        if (myMap) {
          myMap.addControl(scale, 'bottom-right');
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.boundaryDisposer) this.boundaryDisposer();
  }

  fitBoundary(boundary) {
    if (this.currentBoundary && this.currentBoundary === boundary) {
      return;
    }
    if (!this.state.viewport) return;

    this.currentBoundary = boundary;
    // too small box
    let isTooSmall = boundary[1][0] - boundary[0][0] < 0.0004;
    let c = { longitude: boundary[0][1], latitude: boundary[0][0], zoom: 15 };
    const viewport = new WebMercatorViewport(this.state.viewport);

    let { longitude, latitude, zoom } = isTooSmall
      ? c
      : viewport.fitBounds(
          boundary.slice().map((b) => b.slice()),
          { padding: 40 },
        );

    this.setState({
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 50,
      },
    });
  }

  handleLayerClick = (info) => {
    if (!info || !info.object) {
      this.setState({ hoveredStop: null, hoveredDriver: null });
    }
  };

  stopColorEncode(s) {
    if (s === 'DF' || s === 'FF') return 'red';
    if (s === 'DL') return 'purple';
    if (s === 'DE') return 'cyan';
    if (s === 'DS') return 'green';
    return 'yellow';
  }

  showAssignmentTooltip(stop) {
    this.setState({ hoveredStop: stop, hoveredDriver: null });
  }

  showDriverLocationToolTip(location) {
    this.setState({ hoveredDriver: location, hoveredStop: null });
  }

  gotoAssignmentDetails = (assignment) => {
    if (this.props.showAssignmentDetail) {
      this.props.showAssignmentDetail(assignment);
    }
  };

  _renderPath() {
    const { assignmentStore } = this.props.store;
    const { assignments, activeDriverLocations } = assignmentStore;

    if (!assignments) return [];

    const data = assignments
      .slice()
      .filter((a) => a.path)
      .map((a) => {
        return {
          path: a.path
            .slice()
            .map((l) => l.slice())
            .map((l) => [l[1], l[0]]),
          label: a.label,
          color: COLOR_PALETTE[a.id % COLOR_PALETTE.length],
          id: a.id,
        };
      });

    const layer = new PathLayer({
      id: 'path-layer',
      data: data,
      widthScale: 5,
      jointRounded: true,
      widthMinPixels: 2,
      getPath: (d) => d.path,
      getColor: (d) => d.color,
      getWidth: (d) => 1,
    });

    /* dropoff */
    let dropoffMarkers = null;
    if (assignments && assignments.length > 0) {
      dropoffMarkers = assignments
        .slice()
        .filter((a) => a.path)
        .flatMap((p) => {
          let st = p.extra && p.extra.dropoff_status ? p.extra.dropoff_status.split('|').filter((d) => d && d.length > 0 && d[0] === 'D') : null;
          return p.path.slice().map((d, i) => {
            return {
              location: _.reverse(d.slice()),
              assignment: p,
              size: 18,
              icon: !p.driver_id ? 'white' : st ? this.stopColorEncode(st[i]) : 'yellow',
            };
          });
        });
    }
    const dropoffIconlayer = new IconLayer({
      id: 'dropoff-icon-layer',
      data: dropoffMarkers,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: MARKERS,
      iconMapping: this.iconMapping,
      getIcon: (d) => d.icon,
      sizeScale: 1,
      opacity: 1,
      visible: true,
      getPosition: (d) => d.location,
      getSize: (d) => d.size,
      onClick: (info) => {
        const { object } = info;
        if (object && object.assignment) this.showAssignmentTooltip(object);
        if (!object) this.showAssignmentTooltip(null);
      },
    });

    const driverIconlayer = new IconLayer({
      id: 'driver-icon-layer',
      data: activeDriverLocations,
      pickable: true,
      iconAtlas: CarIcon,
      iconMapping: CAR_ICON_MAPPING,
      getAngle: (d) => d.geolocation.angle,
      getIcon: (d) => DETECT_CAR_COLOR(d.driver),
      sizeScale: 1,
      opacity: 1,
      visible: true,
      getPosition: (d) => d.geolocation.lnglat.slice(),
      getSize: (d) => 24,
      onClick: (info) => {
        const { object } = info;
        if (object && object.assignment) this.showDriverLocationToolTip(object);
        if (!object) this.showDriverLocationToolTip(null);
      },
    });

    return [layer, dropoffIconlayer, driverIconlayer];
  }

  _renderAssignmentTooltip() {
    const { hoveredStop } = this.state;
    return (
      hoveredStop && (
        <Popup
          tipSize={5}
          anchor="bottom"
          longitude={hoveredStop.location[0]}
          latitude={hoveredStop.location[1]}
          offsetTop={-18}
          captureClick={true}
          className={'map-tooltip'}
          closeButton={true}
          closeOnClick={false}
          onClose={() => this.setState({ hoveredStop: null })}
        >
          <StopAssignmentTooltip stop={hoveredStop} showAssignmentDetail={this.gotoAssignmentDetails} />
        </Popup>
      )
    );
  }

  _renderDriverTooltip() {
    const { hoveredDriver } = this.state;
    const { showAssignmentDetail } = this.props;

    return (
      hoveredDriver && (
        <Popup
          tipSize={5}
          anchor="bottom"
          longitude={hoveredDriver.geolocation.lnglat[0]}
          latitude={hoveredDriver.geolocation.lnglat[1]}
          offsetTop={-18}
          captureClick={true}
          className={'map-tooltip'}
          closeButton={true}
          closeOnClick={false}
          onClose={() => this.setState({ hoveredDriver: null })}
        >
          <div style={{ textAlign: 'left', fontSize: '13px', minWidth: '200px' }}>
            <div>
              {hoveredDriver.driver && hoveredDriver.driver.photo && (
                <div
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '48px',
                    height: '48px',
                    borderRadius: '24px',
                    marginRight: '8px',
                    float: 'left',
                    overflow: 'hidden',
                  }}
                >
                  <img alt="driver" src={hoveredDriver.driver.photo} style={{ width: '100%' }} />
                </div>
              )}
              <code>[{hoveredDriver.driverId}]</code>
              {hoveredDriver.driver && (
                <span>
                  {' '}
                  {hoveredDriver.driver.first_name} {hoveredDriver.driver.last_name}
                </span>
              )}
            </div>
            <div>Assignment ID: {hoveredDriver.assignmentId}</div>
            {hoveredDriver.assignment && (
              <div>
                {hoveredDriver.assignment.label && <div>Assignment Label: {hoveredDriver.assignment.label}</div>}
                <div style={{ textAlign: 'center' }}>
                  <AxlButton
                    compact={true}
                    style={{ width: '160px' }}
                    onClick={() => showAssignmentDetail && showAssignmentDetail(hoveredDriver.assignment)}
                  >
                    SHOW ASSIGNMENT
                  </AxlButton>
                </div>
              </div>
            )}
          </div>
        </Popup>
      )
    );
  }

  render() {
    const { viewport } = this.state;

    return (
      <ReactMapGL
        {...viewport}
        mapStyle={mapStyle}
        transitionInterpolator={new LinearInterpolator()}
        onViewportChange={(viewport) => this.setState({ viewport })}
        width="100%"
        height="100%"
        ref={(map) => (this.mapRef = map)}
      >
        <DeckGL initialViewState={viewport} onClick={(info) => this.handleLayerClick(info)} layers={[...this._renderPath()]} />
        {this._renderAssignmentTooltip()}
        {this._renderDriverTooltip()}
      </ReactMapGL>
    );
  }
}

export default compose(withStyles(styles), withRouter, inject('store'), observer)(AssignmentListMap);
