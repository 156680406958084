import React, { Component, Fragment } from 'react';
import {compose} from "recompose";
import { inject, observer } from 'mobx-react';
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {ExpandMore as ExpandMoreIcon} from "@mui/icons-material";
import moment from "moment";
import _ from "lodash";

import styles, {Status} from "./styles";

class ShipmentPickupInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { pickup, shipment, classes } = this.props;
    const pickupStatus = (pickup && pickup.status) ? pickup.status : '';
    const statusColor = !!pickupStatus ? Status[pickupStatus] : Status['DEFAULT'];
    const dtFormat = moment(shipment.dropoff_latest_ts).isBefore(moment()) ? 'MM/DD hh:mm A' : 'hh:mm A';

    return (
      <Box mb={2}>
        <Accordion className={classes.panelContainer}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.panelHeader}
            IconButtonProps={{
              className: classes.panelToggleIcon,
              disableRipple: true,
              disableFocusRipple: true,
              disableTouchRipple: true,
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box className={classes.panelHeaderTitle}>Pickup Info:</Box>
              </Grid>
              <Grid item>
                <Box style={{color: statusColor}} className={classes.panelHeaderStatus}>
                  <span>{pickupStatus}</span>
                  {pickup && pickup.actual_departure_ts && <span> @ {moment(pickup.actual_departure_ts).format(dtFormat)}</span>}
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.text}>{shipment.pickup_address.street}</Box>
                    <Box className={classes.text}>{shipment.pickup_address.street2}</Box>
                    <Box className={classes.text}>{shipment.pickup_address.city}, {shipment.pickup_address.state} {shipment.pickup_address.zipcode}</Box>
                  </Grid>
                  {shipment.pickup_note && (
                    <Grid item>
                      <Box className={classes.noteLabel}>NOTES</Box>
                      <Box className={classes.noteContent}>{shipment.pickup_note}</Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center"
                      style={{color: statusColor}} className={classes.remarkHeader}
                >
                  <Grid item>
                    <Box className={classes.remarkHeaderStatus}>PICKUP REMARK</Box>
                  </Grid>
                  {pickupStatus && (
                    <Grid item>
                      <Box className={classes.remarkHeaderTime}>
                        {_.capitalize(pickupStatus)}
                        {pickup && pickup.actual_departure_ts && <span> @ {moment(pickup.actual_departure_ts).format(dtFormat)}</span>}}
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Box className={classes.remarkContent} style={{borderColor: statusColor}}>{(pickup && pickup.remark) ? pickup.remark : '-'}</Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

const ShipmentPickupInfoCompose = compose(
  inject("store"),
  observer
) (ShipmentPickupInfo);

export default withStyles(styles)(ShipmentPickupInfoCompose);