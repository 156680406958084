import * as M from '@mui/material';
import colors from "../../themes/colors";
import fonts from "../../themes/fonts";
import { withStyles } from '@mui/styles';

export const OptionLabelText = M.styled(M.Typography)(() => ({
  font: fonts.Medium,
  color: colors.blackSecondary,
}));

export const SearchOptionContainer = M.styled(M.Box)((theme) => ({
  display: 'flex',
  height: 50,
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: colors.white,
  borderRadius: 3,
  border: `1px solid ${colors.grayMain}`,
  padding: '0 15px',
  boxSizing: 'border-box',
}));

export const SearchText = M.styled(M.Typography)(() => ({
  fontSize: 18,
  color: colors.white,
  fontFamily: fonts.Bold,
}));

export const SearchFormSelect = withStyles(({palette, spacing}) => ({
  root: {
    border: 'none',
  }
}))(M.FormControl);

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '24px',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(M.InputBase);

export const SearchSelect = withStyles(({palette, spacing}) => ({
  root: {
    backgroundColor: colors.transparent,
    border: 'none',
    '&:active': {
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: colors.transparent,
    },
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: colors.transparent,
    },
  }
}))(M.Select);

export const SearchInput = withStyles(({palette, spacing}) => ({
  root: {
    border: 'none',
    fontSize: 20,
    '&::placeholder': {
      fontSize: 20,
    },
  },
  input: {
    fontSize: 20,
    '&::placeholder': {
      fontSize: 14,
      color: colors.graySecond,
      fontFamily: fonts.Medium,
    },
  }
}))(M.InputBase);

const styles = theme => ({
    root: {
        width: '100%',
        paddingTop: '20px',        
        backgroundColor: '#f0f0f0',
      },
      inline: {
        display: 'inline',
      },
      searchInput: {
        borderRadius: '4px',
        border: 'solid 1px #bebfc0',
        width: '100%'
      },
      searchField: {
        width: '100%',
        backgroundColor: '#ffffff',
        border: '1px solid #bebfc0',
        height: '50px',
        borderRadius: '4px',
        paddingLeft: '5px',
        '&::before': {
          borderBottom: 'none'
        }
      },
      searchBtn: {
        float: 'right',
        width: '100%',
        height: '50px',
        backgroundColor: '#887fff'
      },
      searchText: {
        border: '1px solid #bebfc0',
      },
      addDriverBtn: {
        backgroundColor: '#75c31e',
        color: '#ffffff',
        fontWeight: 'bold'
      },
      driverList: {
        paddingTop: '10px'
      },
      driverItem: {        
        border: 'solid 1px #bebfc0',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '&:hover': {
          borderColor: colors.black35,
        }
      },
      itemDriverName: {
        fontSize: '14px',
        fontWeight: 500,
        paddingLeft: '10px',
        fontFamily: 'AvenirNext-Bold'
      },
      itemColumn: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        display: 'flex',
        alignItems: 'center'
      },
      columnStatus: {        
        justifyContent: 'flex-end',
        fontSize: '12px',
      },
      columnPhone: {        
        justifyContent: 'flex-end',
        fontSize: '12px',
      },
      driverItemWrapper: {
        padding: 0,
        cursor: 'pointer',
        backgroundColor: '#f0f0f0',
      },
      avatar: {
        width: '60px',
        height: '60px'
      },
      driverStatus: {
        fontFamily: 'AvenirNext-Bold',
        fontWeight: 400,
        fontSize: '11px'
      }
});

export default styles;