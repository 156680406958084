import * as M from "@mui/material";
import {lightTheme, defaultTheme} from "../../themes";
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";
import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  palette: {
    primary: colors,
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: 650,
          backgroundColor: colors.white,
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {},
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors['greyish-brown'],
          fontFamily: fonts.Medium,
          fontSize: 14,
        },
        head: {
          color: colors.gray,
        },
        body: {
        }
      }
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: colors,
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: 650,
          backgroundColor: colors.blueEyes,
          borderRadius: 3,
          overflow: 'hidden',
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {},
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors['greyish-brown'],
          fontFamily: fonts.Medium,
          fontSize: 14,
          borderBottom: 'none',
        },
        head: {
          color: colors.white,
          backgroundColor: colors.blueNightTwo,
        },
        body: {
        }
      }
    },
  },
});

export {
  lightTheme, defaultTheme, mainTheme, darkTheme
}
