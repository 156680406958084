import React from "react";
import { AxlSearchBox, AxlLoading, AxlMessageTitleGroup } from 'axl-reactjs-ui';
import moment from "moment-timezone";
import _ from 'lodash';
import {inject, observer} from "mobx-react";
// styles
import styles, * as E from './styles.js';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {MessengerActiveTab} from "../MessengerActiveTab";
// Components

export class MessengerGeneralTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEndTopics: props.isEndTopics,
    }
    this._handleInputText = _.debounce(this._handleInputText.bind(this),300)
  }

  componentWillUnmount() {
    const { messengerStore, handleTopicSelected } = this.props.store;
    this.setState({
      isUpdatedSolving: false,
      isUpdatedSolved: false,
      isUpdatedUnsolved: false
    });
    // messengerStore.topicSelectedId = null;
    // messengerStore.topicSelected = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isEndTopics !== this.props.isEndTopics)  {
      this.setState({isEndTopics: this.props.isEndTopics});
    }
  }

  _handleSelectedTopic = (topic) => {
    this.props.handleTopicSelected(topic.id);
  }

  _handleInputText = (e) => {
    this.props.onSearch(e);
  }

  render() {
    const { messengerStore } = this.props.store;
    const { sectionSolved, sectionSolving, sectionUnsolved, isUpdatedSolving, isUpdatedSolved, isUpdatedUnsolved, isOrdered, sectionNoPrior } = this.props;
    const solvedUnreadCounter = sectionSolved.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const solvingUnreadCounter = sectionSolving.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const unsolvedUnreadCounter = sectionUnsolved.map(a => a.unviewed_messages_count > 0).filter(a => a).length;
    const isUpdated = (isUpdatedSolving && isUpdatedSolved && isUpdatedUnsolved) || isOrdered;

    const groupData = {
      'SOLVING': {
        title: 'ATTENDING',
        unread: solvingUnreadCounter,
        topics: sectionSolving,
        option: {
          filter: {
            placeHolder: 'Alphabetical',
            options: [
              {name: 'A → Z', value: 'asc'},
              {name: 'Z → A', value: 'desc'},
            ]
          }
        }
      },
      'UNSOLVED': {
        title: 'UNATTENDED',
        unread: unsolvedUnreadCounter,
        topics: sectionUnsolved,
        option: {
          filter: {
            placeHolder: 'Latest',
            options: [
              {name: 'Latest → Oldest', value: 'last'},
              {name: 'Oldest → Latest', value: 'oldest'},
              {name: 'A → Z', value: 'asc'},
              {name: 'Z → A', value: 'desc'},
            ]
          }
        }
      },
      'SOLVED': {
        title: 'RESOLVED',
        unread: solvedUnreadCounter,
        topics: sectionSolved,
        option: {
          filter: {
            placeHolder: 'Latest',
            options: [
              {name: 'Latest → Oldest', value: 'last'},
              {name: 'Oldest → Latest', value: 'oldest'},
              {name: 'A → Z', value: 'asc'},
              {name: 'Z → A', value: 'desc'},
            ]
          }
        }
      },
      'NO_PRIOR': {
        title: 'NO PRIOR CHAT',
        topics: sectionNoPrior
      }
    }

    return <E.Container>
      <E.Inner>
        <E.SearchContainer>
          <AxlSearchBox theme={`default`} placeholder='Search by driver name/id/user_id' defaultValue={``} style={{width: '100%'}}
                        onChange={this._handleInputText} />
        </E.SearchContainer>
        <E.Scrollable>
          <AxlMessageTitleGroup
            topicSelected={messengerStore.topicSelected}
            orderBy={this.props.orderBy}
            isUpdated={isUpdated}
            group={groupData}
            isEndTopics={this.props.isEndTopics}
            handleSelectedTopic={this._handleSelectedTopic}
            loadMore={this.props.loadMore} />
        </E.Scrollable>
      </E.Inner>
    </E.Container>;
  }
}

export default compose(
  withRouter,
  inject('store'),
  observer
)(MessengerGeneralTab);