import React from 'react';
import { AxlPanel, AxlTooltip, AxlLoading, AxlPagination, AxlButton, AxlTabSimple } from 'axl-reactjs-ui';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import _ from 'lodash';

// Utils
import TYPE_MAPPING from '../../constants/driverSuspensionType';
import {SHIPMENT_STOP_TYPE} from '../../constants/type';
import {STOP_STATUS} from '../../constants/status';

// Components
import DriverHistoryList from '../DriverHistoryList';
import ActivityDriver from '../DriverHistoryList/ActivityDriver';

// Styles
import styles, * as E from './styles';
import AssignmentMiniDetail from "../../containers/AssignmentMiniDetail";
import TicketMiniDetail from "../../containers/TicketMiniDetail";
import ShipmentDetailPanel from "../../containers/ShipmentMessenger/ShipmentDetailPanel";
import ChatBoxContainer from "../../containers/ShipmentMessenger/ShipmentSupportPanel/ChatBoxContainer";
import {mapPaymentStatusToColor, PAYMENT_TYPE} from "../../constants/payment";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class ActiveCompose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRoutes: [],
      loadingActiveRoute: false
    }
  }

  componentDidMount() {
    const {driverStore, driver} = this.props;
    this.setState({loadingActiveRoute: true});
    driverStore.getActiveAssignment(driver.id).then(response => {
      this.setState({loadingActiveRoute: false});
      if (response.status === 200) {
        const activeRoutes = response.data.items;
        const historyMap = [];
        for (var i = 0; i < activeRoutes.length; i++) {
          const staticI = i;
          driverStore.getAssignmentHistory(activeRoutes[i].assignment.id).then(response => {
            historyMap.push(activeRoutes[staticI]);
            if (response.status === 200) {
              const pickupFailedStops = response.data.filter(item =>
                item.type === SHIPMENT_STOP_TYPE.PICKUP && item.status === STOP_STATUS.FAILED);

              const pickupFailedShipmentIds = pickupFailedStops ? pickupFailedStops.map(s => s.shipment_id): [];

              const dropoffWithoutPickupFailedStops = response.data.filter(item =>
                item.type === SHIPMENT_STOP_TYPE.DROPOFF && !pickupFailedShipmentIds.includes(item.shipment_id));

              const histories = dropoffWithoutPickupFailedStops.concat(pickupFailedStops);
              activeRoutes[staticI]['histories'] = histories;
            }

            if (historyMap.length === activeRoutes.length) {
              this.setState({activeRoutes});
            }
          })
        }
      }
    });
  }

  render() {
    // const { history } = this.props;
    const { activeRoutes, loadingActiveRoute } = this.state;
    const { assignmentLabel, assignment } = activeRoutes[0] || {};

    return <E.Container>
      { loadingActiveRoute && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <AxlPanel>
        {activeRoutes.length > 0 ? <div>
          <AxlPanel.Row style={styles.Row_1}>
            <AxlPanel.Col>
              <E.AssignmentName>{`Assignment ${assignment ? assignment.label : 'undefined'}`}</E.AssignmentName>
              <E.Text_2>{`${assignment.shipment_count} ${assignment.shipment_count > 1 ? 'Shipments' : 'Shipment'}`}</E.Text_2>
            </AxlPanel.Col>
            <AxlPanel.Col>
              <E.Label>{`TIME WINDOWS`}</E.Label>
              <E.Text_1>{`${moment(assignment.predicted_start_ts).format('hh:mm A')} - ${moment(assignment.predicted_end_ts).format('hh:mm A')}`}</E.Text_1>
            </AxlPanel.Col>
            <AxlPanel.Col>
              <E.Label>{`ZONES`}</E.Label>
              <E.Text_1 className={`break`}><ZoneMore>{assignment.zones}</ZoneMore></E.Text_1>
            </AxlPanel.Col>
            <AxlPanel.Col flex={0}>
              <E.ViewDispatchButton href={`/routes/${moment(assignment.predicted_end_ts).format('YYYY-MM-DD')}/all/${assignment.id}`} target={`_blank`}>{`View in Dispatch`}</E.ViewDispatchButton>
            </AxlPanel.Col>
          </AxlPanel.Row>
          <DriverHistoryList history={activeRoutes[0].histories} />
        </div> : <E.NoActiveSuspension>
          <E.Text_6>{`No Row!`}</E.Text_6>
        </E.NoActiveSuspension>}
      </AxlPanel>
    </E.Container>
  }
}

export const Active = compose(
  withRouter,
  inject('driverStore'),
  observer
)(ActiveCompose);

class PendingCompose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingRoutes: [],
      pendingTickets: [],
      loadingPendingRoute: false,
      loadingPendingTicket: false,
      selectedRoute: null,
      selectedTicket: null,
    }

    this.deselect = this.deselect.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const {driverStore, driver} = this.props;
    const {ticketStore} = this.props.store;
    this.setState({loadingPendingRoute: true, loadingPendingTicket:  true});
    driverStore.getPendingAssignments(driver.id).then(response => {
      this.setState({loadingPendingRoute: false});
      if (response.status === 200) {
        this.setState({pendingRoutes: response.data.items});
      }
    });
    ticketStore.getOpenTicketsByHolder(`DR_${driver.id}`).then(res => {
      this.setState({loadingPendingTicket: false});
      if (res.ok) {
        this.setState({pendingTickets: res.data.filter(t => t.type === 'assignment')});
      }
    })
  }

  deselect(callback) {
    this.setState({selectedRoute: null, selectedTicket: null}, () => {
      if (callback) callback();
    });
  }

  render() {
    const {
      pendingRoutes, loadingPendingRoute, selectedRoute,
      pendingTickets, loadingPendingTicket, selectedTicket
    } = this.state;
    const { driver } = this.props
    const assignmentInfo = Object.assign({}, selectedRoute, {driver})
    const pendingRoutesGroupByDate = _(pendingRoutes).groupBy(p => moment(p.assignment.predicted_start_ts).format('MM/DD/YYYY')).map(p => p).orderBy(d => d[0].assignment.predicted_start_ts, ['desc']).value();

    if (selectedRoute) {
      return <AssignmentMiniDetail assignmentInfo={assignmentInfo} reloadData={this.loadData} onBack={this.deselect} />
    }

    if (selectedTicket) {
      return <TicketMiniDetail ticket={selectedTicket} reloadData={this.loadData} onBack={this.deselect} />
    }

    return <E.Container>
      { loadingPendingRoute && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      { pendingRoutesGroupByDate && pendingRoutesGroupByDate.length > 0 ? pendingRoutesGroupByDate.map((pendingRoute, index) => (
        <E.PendingItems key={index}>
          <E.PanelTitle>{moment(pendingRoute[0].assignment.predicted_start_ts).format('dddd - MM/DD/YYYY')}</E.PanelTitle>
          { pendingRoute.map((p, i) => (
            <E.PendingItem key={i}>
              <AxlPanel>
                <AxlPanel.Row style={styles.Row_1}>
                  <AxlPanel.Col>
                    <E.AssignmentName>{`Assignment ${_.get(p, 'assignment.label', 'N/A')}`}</E.AssignmentName>
                    <E.Text_2>{`${_.defaultTo(p.assignment.shipment_count, 0)} ${p.assignment.shipment_count > 1 ? 'Shipments' : 'Shipment'} - ${moment(p.assignment.predicted_end_ts).format('MM/DD/YYYY')}`}</E.Text_2>
                  </AxlPanel.Col>
                  <AxlPanel.Col>
                    <E.Label>{`TIME WINDOWS`}</E.Label>
                    <E.Text_1>{`${moment(p.assignment.predicted_start_ts).format('hh:mm A')} - ${moment(p.assignment.predicted_end_ts).format('hh:mm A')}`}</E.Text_1>
                  </AxlPanel.Col>
                  <AxlPanel.Col>
                    <E.Label>{`ZONES`}</E.Label>
                    <E.Text_1 className={`break`}><ZoneMore>{p.assignment.zones}</ZoneMore></E.Text_1>
                  </AxlPanel.Col>
                  <AxlPanel.Col flex={0}>
                    <AxlButton
                      bg="none" compact
                      onClick={() => this.setState({selectedRoute: p, selectedTicket: null})}
                    >
                      View
                    </AxlButton>
                  </AxlPanel.Col>
                </AxlPanel.Row>
              </AxlPanel>
            </E.PendingItem>
          ))}
        </E.PendingItems>
      )) : (
        <E.NoActiveSuspension>
          <E.Text_6>{`No Routes!`}</E.Text_6>
        </E.NoActiveSuspension>
      )}
      <E.PanelTitle>Pending Route Tickets</E.PanelTitle>
      <div style={{position: 'relative'}}>
        {loadingPendingTicket && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} />}
        <E.PendingItems>
          { pendingTickets && pendingTickets.length > 0 ? pendingTickets.map((pendingTicket, index) => (
            <E.PendingItem key={index}>
              <AxlPanel>
                <AxlPanel.Row style={styles.Row_1}>
                  <AxlPanel.Col>
                    <E.AssignmentName>{pendingTicket.name}</E.AssignmentName>
                    <E.Text_2>
                      <span>{pendingTicket.attributes.shipment_count_min} - {pendingTicket.attributes.shipment_count_max} Parcels</span>
                      <E.TicketPrice>(${pendingTicket.attributes.tour_cost_min} - ${pendingTicket.attributes.tour_cost_max})</E.TicketPrice>
                    </E.Text_2>
                  </AxlPanel.Col>
                  <AxlPanel.Col>
                    <E.Label>{`TARGET DATE`}</E.Label>
                    <E.Text_1>{moment(pendingTicket.target_start_ts).format('MM/DD/YYYY HH:mm')}</E.Text_1>
                  </AxlPanel.Col>
                  <AxlPanel.Col>
                    <E.Label>{`ZONES`}</E.Label>
                    <E.Text_1 className={`break`}><ZoneMore>{pendingTicket.attributes.zones}</ZoneMore></E.Text_1>
                  </AxlPanel.Col>
                  <AxlPanel.Col flex={0}>
                    <AxlButton
                      bg="none" compact
                      onClick={() => this.setState({selectedRoute: null, selectedTicket: pendingTicket})}
                    >
                      View
                    </AxlButton>
                  </AxlPanel.Col>
                </AxlPanel.Row>
              </AxlPanel>
            </E.PendingItem>
          )) : (
            <E.NoActiveSuspension>
              <E.Text_6>{`No Tickets!`}</E.Text_6>
            </E.NoActiveSuspension>
          )}
        </E.PendingItems>
      </div>
    </E.Container>
  }
}

export const Pending = compose(
  withRouter,
  inject('driverStore', 'store'),
  observer
)(PendingCompose);

class PastCompose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastRoutes: [],
      currentPage: 1,
      totalResult: 0,
      loadingPastRoute: false
    }
  }

  componentDidMount() {
    const {currentPage} = this.state;
    const {driverStore, driver} = this.props;
    this.setState({loadingPastRoute: true});
    driverStore.getPastAssignments(driver.id, currentPage).then(response => {
      this.setState({loadingPastRoute: false});
      if (response.status === 200) {
        this.setState({pastRoutes: response.data.items, totalResult: Math.ceil(response.data.count / 10)});
      }
    });
  }

  onSelectPage = (page) => {
    const {driverStore, driver} = this.props;
    this.setState({loadingPastRoute: true});
    driverStore.getPastAssignments(driver.id, page).then(response => {
      this.setState({loadingPastRoute: false});
      if (response.status === 200) {
        this.setState({pastRoutes: response.data.items, currentPage: response.data.page});
      }
    });
  }

  render() {
    const { pastRoutes, loadingPastRoute, currentPage, totalResult } = this.state;

    return <E.Container>
      { loadingPastRoute && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <E.PendingItems>
        { (pastRoutes && pastRoutes.length > 0) ? pastRoutes.map((p, index) => {
          let successPercent = 0;
          if (p.stops && p.stops.length > 0) {
            const dropoffStops = p.stops.filter(st => st.type === "DROP_OFF");
            const succeededDropoff = dropoffStops.filter(st => st.status === "SUCCEEDED");
            successPercent = (succeededDropoff.length / dropoffStops.length) * 100;
            successPercent = successPercent > 0 && successPercent < 100 ? successPercent.toFixed(1) : successPercent;
          }

          return <E.PendingItem key={index}>
            <AxlPanel>
              <AxlPanel.Row style={styles.Row_1}>
                <AxlPanel.Col>
                  {p.assignment && <E.AssignmentName>{`Assignment ${_.get(p, 'assignment.label', 'N/A')}`}</E.AssignmentName>}
                  <E.Text_2>{`${_.defaultTo(p.shipments.length, 0)} ${p.shipments.length > 1 ? 'Shipments' : 'Shipment'} - ${moment(p.assignment.predicted_end_ts).format('MM/DD/YYYY')}`}</E.Text_2>
                </AxlPanel.Col>
                <AxlPanel.Col>
                  <E.Label>{`TIME WINDOWS`}</E.Label>
                  <E.Text_1>{`${moment(p.assignment.predicted_start_ts).format('hh:mm A')} - ${moment(p.assignment.predicted_end_ts).format('hh:mm A')}`}</E.Text_1>
                </AxlPanel.Col>
                <AxlPanel.Col style={{padding: '0 15px'}}>
                  <E.Label>{`ZONES`}</E.Label>
                  <E.Text_1 className={`break`}><ZoneMore>{p.assignment.zones}</ZoneMore></E.Text_1>
                </AxlPanel.Col>
                {<AxlPanel.Col center>
                  <E.Text_3>{`${successPercent}%`}</E.Text_3>
                  <E.Text_4>{`SUCCESSFUL`}</E.Text_4>
                </AxlPanel.Col>}
                <AxlPanel.Col flex={0}>
                  <E.ViewDispatchButton href={`/routes/${moment(p.assignment.predicted_end_ts).format('YYYY-MM-DD')}/all/${p.assignment.id}`} target={`_blank`}>{`View in Dispatch`}</E.ViewDispatchButton>
                </AxlPanel.Col>
              </AxlPanel.Row>
            </AxlPanel>
          </E.PendingItem>}) : <E.NoActiveSuspension>
            <E.Text_6>{`No Row!`}</E.Text_6>
        </E.NoActiveSuspension>}
        {totalResult > 1 && <div style={{textAlign: 'center', padding: '10px 15px'}}>
          <AxlPagination onSelect={this.onSelectPage} current={currentPage} total={totalResult} />
        </div>}
      </E.PendingItems>
    </E.Container>
  }
}

export const Past = compose(
  withRouter,
  inject('driverStore'),
  observer
)(PastCompose);

class SuspensionsCompose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSuspensions: [],
      pastSuspensions: [],
      loadingSuspensions: false
    }
  }

  componentDidMount() {
    const {driverStore, driver} = this.props;
    if(_.has(driver, 'id')) {
    this.setState({loadingSuspensions: true});
      driverStore.getSuspensions(driver.id).then(response => {
        this.setState({loadingSuspensions: false});
        if (response.status === 200) {
          // process active suspensions and past suspensions
          const currentTime = (new Date()).getTime();
          const activeSuspensions = response.data.filter(s =>
            s.start_time < currentTime && s.end_time >= currentTime
          );

          const pastSuspensions = response.data.filter(s =>
            s.end_time < currentTime
          );

          this.setState({activeSuspensions, pastSuspensions});
          this.props.update(activeSuspensions && activeSuspensions.length > 0)
        }
      });
    }
  }

  render() {
    const isActiveSuspension = true;
    const { activeSuspensions, pastSuspensions, loadingSuspensions } = this.state;
    const TYPE_DESC_MAPPING = {
      black_out: 'No scheduling during suspension period',
      client_blacklist: 'Can not deliver route having orders from a specific client',
      limited_reservation: 'Maybe limit him to 1/2 routes only',
      reduced_route: 'He can only book *max_reservation - 1* routes',
      delayed: 'He can book normally, but booking time is delayed for some duration',
      limited_capacity: 'Limited Capacity'
    };

    return <E.Container>
      { loadingSuspensions && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <E.PendingItems>
        <E.PanelTitle>{`ACTIVE PROBATIONS`}</E.PanelTitle>
        {(activeSuspensions && activeSuspensions.length > 0) && activeSuspensions.map(suspension => <E.PendingItem>
          <AxlPanel>
            <AxlPanel.Row style={styles.Row_1}>
              <AxlPanel.Col style={styles.ColPadd}>
                <AxlTooltip title={TYPE_DESC_MAPPING[suspension.suspension_type]}>
                  <E.AssignmentName style={styles.textRed}>{TYPE_MAPPING[suspension.suspension_type] ? TYPE_MAPPING[suspension.suspension_type]: suspension.suspension_type}</E.AssignmentName>
                </AxlTooltip>
                <E.Text_5 className={`break`}>{`Due to “${suspension.reason}”`}</E.Text_5>
              </AxlPanel.Col>
              <AxlPanel.Col style={styles.ColPadd}>
                <E.Label>{`EFFECTIVE PERIOD`}</E.Label>
                <E.Text_1>
                  <strong><Moment format="MM/DD/YYYY">{suspension.start_time}</Moment></strong> @ <Moment format="HH:mmA">{suspension.start_time}</Moment> -
                  <strong> <Moment format="MM/DD/YYYY">{suspension.end_time}</Moment></strong> @ <Moment format="HH:mmA">{suspension.end_time}</Moment>
                </E.Text_1>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel>
        </E.PendingItem>)}
        {(!activeSuspensions || activeSuspensions.length < 1) && <E.NoActiveSuspension>
          <E.Text_6>{`No Active Probations!`}</E.Text_6>
        </E.NoActiveSuspension>}
      </E.PendingItems>
      <E.PendingItems>
        <E.PanelTitle>{`PAST PROBATIONS`}</E.PanelTitle>
        {(!pastSuspensions || pastSuspensions.length < 1) && <E.NoActiveSuspension>
          <E.Text_6>{`No Past Probations!`}</E.Text_6>
        </E.NoActiveSuspension>}
        {(pastSuspensions && pastSuspensions.length > 0) && pastSuspensions.map(suspension => <E.PendingItem style={styles.disabled}>
          <AxlPanel>
            <AxlPanel.Row style={styles.Row_1}>
              <AxlPanel.Col style={styles.ColPadd}>
                <AxlTooltip title={TYPE_DESC_MAPPING[suspension.suspension_type]}>
                  <E.AssignmentName>{TYPE_MAPPING[suspension.suspension_type] ? TYPE_MAPPING[suspension.suspension_type]: suspension.suspension_type}</E.AssignmentName>
                </AxlTooltip>
                <E.Text_5 className={`break`}>{`Due to “${suspension.reason}”`}</E.Text_5>
              </AxlPanel.Col>
              <AxlPanel.Col style={styles.ColPadd}>
                <E.Label>{`EFFECTIVE PERIOD`}</E.Label>
                <E.Text_1>
                  <strong><Moment format="MM/DD/YYYY">{suspension.start_time}</Moment></strong> @ <Moment format="HH:mmA">{suspension.start_time}</Moment> -
                  <strong> <Moment format="MM/DD/YYYY">{suspension.end_time}</Moment></strong> @ <Moment format="HH:mmA">{suspension.end_time}</Moment>
                </E.Text_1>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel>
        </E.PendingItem>)}
      </E.PendingItems>
    </E.Container>
  }
}

export const Suspensions = compose(
  withRouter,
  inject('driverStore'),
  observer
)(SuspensionsCompose);

class ZoneMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.show = this.show.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.children !== nextProps.children) {
      this.setState({show: false});
    }
  }

  show() { this.setState({show: true })}

  render() {
    if(!this.props.children || this.props.children === '') return '-';

    const first = this.props.children.split(',');
    const last = first.filter((x, i) => i !== 0);

    return <span>{first[0]}{last.length > 0 && (!this.state.show ? <E.MoreLink onClick={this.show}>{`+${last.length} more`}</E.MoreLink> : <span>{', ' + last.join(', ')}</span>)}</span>;
  }
}

class ActivityCompose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      loadingActivity: false
    }
  }

  componentDidMount() {
    const {driverStore, driver} = this.props;
    this.setState({loadingActivity: true});
    driverStore.getDriverActivity(driver.id).then(response => {
      this.setState({loadingActivity: false});
      if (response.status === 200) {
        const activities = response.data;
        this.setState({activities});
      }
    });
  }

  render() {
    const { activities, loadingActivity } = this.state;
    return <E.Container>
      { loadingActivity && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <AxlPanel>
        {activities.length > 0 ? <div>
          <ActivityDriver activities={activities} />
        </div> : <E.NoActiveSuspension>
          <E.Text_6>{`No Row!`}</E.Text_6>
        </E.NoActiveSuspension>}
      </AxlPanel>
    </E.Container>
  }
}

export const Activity = compose(
  withRouter,
  inject('driverStore'),
  observer
)(ActivityCompose);

class Payment extends React.Component {
  render() {
    const {driver} = this.props;

    return <E.PaymentTabs>
      <AxlTabSimple disableRipple align={'center'} titleStyle={{textAlign: 'center', minWidth: 'inherit'}} items={[
        {title: 'History', component: <PaymentHistory driver={driver} />},
        {title: 'Due', component: <PaymentDue driver={driver} />},
      ]} />
    </E.PaymentTabs>
  }
}

class PaymentHistoryCompose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalResult: 0,
      loading: false,
      query: {
        driver_id: _.get(props, 'driver.id'),
        from_ts: 1202060954897,
        to_ts: 1902060954897,
        status: '',
        order_by: 'id',
        desc: true,
        page: 1,
        size: 10,
      },
      data: {
        "count": 0,
        "size": 0,
        "page": 0,
        "paymentDetails": []
      },
    }
  }

  componentDidMount() {
    // Initial load page
    this.loadPage();
  }

  onSelectPage = (page) => {
    this.loadPage(page);
  }

  loadPage = (currentPage = 1) => {
    const {query} = this.state;
    const params = Object.assign({}, query, {page: currentPage});
    const {driverStore, driver} = this.props;
    this.setState({
      loading: true,
      currentPage: currentPage,
    });
    // Load history
    driverStore.getPaymentHistory(params).then(res => {
      this.setState({loading: false});
      if (res.status === 200) {
        this.setState({
          data: res.data,
        });
      }
    });
  }

  convertStringToDate(string) {

  }

  convertPaymentHistorySummaryToDetail(objs = []) {
    const result = objs
      .filter(obj => [PAYMENT_TYPE.OUTVOICE_ROUTE_DRIVING, PAYMENT_TYPE.OUTVOICE_TIP].indexOf(obj.type) !== -1)
      .map(obj => {
        const mount = _.get(obj, 'mount', 'N/A');
        const count = _.get(obj, 'count', 'N/A');
        const type = _.get(obj, 'type', 'N/A');

        switch (type) {
          case PAYMENT_TYPE.OUTVOICE_ROUTE_DRIVING: return `${count} ${count > 1 ? 'Tasks' : 'Task'}`;break;
          case PAYMENT_TYPE.OUTVOICE_TIP: return `${count} ${count > 1 ? 'Tips': 'Tip'}`;break;
          default: return 'N/A';break;
        }
      })

    return result.join(' + ')
  }

  render() {
    const {
      data, loading,
      currentPage
    }                     = this.state;
    const items           = _.defaultTo(data.paymentDetails, []);
    const totalResult     = Math.ceil(data.count / 10);
    const isViewPaystub   = _.get(this, 'props.userStore.user.scopes', []).indexOf('finance-manager') !== -1;

    return <E.Container>
      { loading && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <E.PendingItems>
        { (items.length > 0) ? items.map((item, index) => {
          return <E.PendingItem key={index}>
            <AxlPanel>
              <AxlPanel.Row style={styles.Row_1}>
                <AxlPanel.Col>
                  <E.AssignmentName>{item.memo}</E.AssignmentName>
                  {/*{console.log(JSON.parse(item.summary)['details'])}*/}
                  {this.convertPaymentHistorySummaryToDetail(JSON.parse(item.summary)['details'])}
                </AxlPanel.Col>
                <AxlPanel.Col>
                  <E.Label>{`AMOUNT`}</E.Label>
                  <E.AmountText>{`$${item.amount}`}</E.AmountText>
                </AxlPanel.Col>
                <AxlPanel.Col style={{padding: '0 15px'}}>
                  <E.Label>{`STATUS`}</E.Label>
                  <E.Text_1 className={`break`} style={{color: mapPaymentStatusToColor(item.status)}}>{item.status}</E.Text_1>
                </AxlPanel.Col>
                <AxlPanel.Col flex={0}>
                  {isViewPaystub && <E.ViewDispatchButton href={`${process.env.REACT_APP_API_PAYMENT}/driver-payments/${item.id}/summary`} target={`_blank`}>{`View Paystub`}</E.ViewDispatchButton>}
                </AxlPanel.Col>
              </AxlPanel.Row>
            </AxlPanel>
          </E.PendingItem>}) : <E.NoActiveSuspension>
          <E.Text_6>{`No Row!`}</E.Text_6>
        </E.NoActiveSuspension>}
        {totalResult > 1 && <div style={{textAlign: 'center', padding: '10px 15px'}}>
          <AxlPagination onSelect={this.onSelectPage} current={currentPage} total={totalResult} />
        </div>}
      </E.PendingItems>
    </E.Container>
  }
}

export const PaymentHistory = compose(
  withRouter,
  inject('driverStore', 'paymentStore', 'userStore'),
  observer
)(PaymentHistoryCompose);

class PaymentDueCompose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      totalResult: 0,
      loading: false,
      items: [],
      query: {
        driver_id: _.get(props, 'driver.id'),
      },
    }
    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    // Initial load page
    this.loadPage();
  }

  loadPage(currentPage = 1) {
    const that  = this;
    const {query} = this.state;
    const params = Object.assign({}, query);
    const {driverStore, driver} = this.props;
    this.setState({
      loading: true,
      currentPage: currentPage,
    });
    // Load history
    driverStore.getDueHistory(params).then(res => {
      that.setState({loading: false});
      if (res.status === 200) {
        this.setState({items: res.data});
      }
    });
  }

  renderTransactionDetail(item) {
    const label         = _.get(item, 'assignment_label.prefix', 'N/A');
    const actualTs      = _.get(item, 'transaction.actual_start_ts');
    const amount        = _.get(item, 'transaction.amount', 0);
    const assignmentId  = _.get(item, 'assignment_label.assignment_id', 'N/A');
    const type          = _.get(item, 'transaction.type');
    const textType      = _.isEqual(type, PAYMENT_TYPE.OUTVOICE_TIP) ? 'Tip of Assignment' : _.isEqual(type, PAYMENT_TYPE.PAYMENT_DEDUCT) ? 'Deduct of Assignment' : 'Assignment';

    return <E.PaymentDueDetail dangerouslySetInnerHTML={{__html: `${textType} ${label} <span>${assignmentId}</span> ${actualTs ? moment(actualTs).format('MM/DD/YYYY') : 'N/A'} - <b>$${amount}</b>`}} />
  }

  renderTransactions(items) {
    const itemsGrouped = _.groupBy(items, (item) => {
      const ts = _.get(item, 'transaction._created');
      return moment(item.transaction._created).startOf('day')
    })
    const taskCounter = _.filter(items, item => _.isEqual(_.get(item, 'transaction.type'), PAYMENT_TYPE.OUTVOICE_ROUTE_DRIVING)).length;
    const tipCounter  = _.filter(items, item => _.isEqual(_.get(item, 'transaction.type'), PAYMENT_TYPE.OUTVOICE_TIP)).length;
    const textTask = `${taskCounter} ${taskCounter > 1 ? 'Tasks' : 'Task'}`;
    const tipTask = `${tipCounter} ${tipCounter > 1 ? 'Tips' : 'Tip'}`;
    const amount = _.sumBy(items, function(item){
      if(_.includes([PAYMENT_TYPE.OUTVOICE_ROUTE_DRIVING, PAYMENT_TYPE.OUTVOICE_TIP], _.get(item, 'transaction.type'))) {
        return _.get(item, 'transaction.amount');
      }
    });
    const deduct = _.sumBy(items, function(item){
      if(_.includes([PAYMENT_TYPE.PAYMENT_DEDUCT], _.get(item, 'transaction.type'))) {
        return _.get(item, 'transaction.amount');
      }
    });
    const total = amount - deduct;

    return <AxlPanel.Row style={{...styles.Row_1, ...styles.Row_2}}>
      <AxlPanel.Col>
        <E.Label>{`DETAIL`}</E.Label>
        <E.Text_2>{`${textTask} + ${tipTask}`}</E.Text_2>
      </AxlPanel.Col>
      <AxlPanel.Col>
        <E.Label>{`AMOUNT`}</E.Label>
        <E.AmountText>{`$${total}`}</E.AmountText>
      </AxlPanel.Col>
      <AxlPanel.Col style={{padding: '0 15px'}}>
        <E.Label>{`STATUS`}</E.Label>
        <E.Text_1 className={`break`}>N/A</E.Text_1>
      </AxlPanel.Col>
      <AxlPanel.Col flex={0}></AxlPanel.Col>
    </AxlPanel.Row>
  }

  render() {
    const { items, loading } = this.state;

    return <E.Container>
      { loading && <AxlLoading color={`#CCC`} thin={1} size={80} style={styles.loadingStyle} /> }
      <E.DueItems>
        <E.DueItem>
          <AxlPanel>
            {this.renderTransactions(items)}
            <E.PaymentDueDetailContainer>
              <E.Text_7>{`Detailed List:`}</E.Text_7>
              {items.length > 0 ? items.map((item, index) => <div key={index}>
                {this.renderTransactionDetail(item)}
              </div>) : <div>No one</div>}
            </E.PaymentDueDetailContainer>
          </AxlPanel>
        </E.DueItem>
      </E.DueItems>
    </E.Container>
  }
}

export const PaymentDue = compose(
  withRouter,
  inject('driverStore'),
  observer
)(PaymentDueCompose);

export default { Active, Pending, Past, Suspensions, Activity, Payment }
