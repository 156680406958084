import React, { Component } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  CREATED,
  GEOCODE_FAILED,
  GEOCODED,
  ASSIGNED,
  PICKUP_EN_ROUTE,
  PICKUP_DELAY,
  CANCELLED_BEFORE_PICKUP,
  PICKUP_READY,
  PICKUP_FAILED,
  PICKUP_SUCCEEDED,
  DROPOFF_EN_ROUTE,
  DROPOFF_DELAY,
  CANCELLED_AFTER_PICKUP,
  DROPOFF_READY,
  DROPOFF_FAILED,
  DROPOFF_SUCCEEDED,
  RETURN_EN_ROUTE,
  RETURN_DELAY,
  RETURN_READY,
  RETURN_FAILED,
  RETURN_SUCCEEDED,
  RECEIVED_OK,
  RECEIVED_DAMAGED,
  MISSING,
  INFORM_RECIPIENT_SHIPMENT_CONFIRMED,
  INFORM_RECIPIENT_PICKUP_SUCCEEDED,
  INFORM_RECIPIENT_PICKUP_FAILED,
  INFORM_RECIPIENT_COMING_SOON,
  INFORM_RECIPIENT_NEXT_IN_LINE,
  INFORM_RECIPIENT_DROPOFF_SUCCEEDED,
  INFORM_RECIPIENT_DROPOFF_FAILED, NOT_FOUND, LEAKING, DAMAGED, DROPOFF_REATTEMPTING
} from '../../constants/shipmentStatus';

// Styles
import styles, { Container, Inner, List, Item, Circle, Date, Text, RemarkText } from './styles';

export default class DriverHistoryList extends Component {
  getHistoryStatus(status, type, options) {
    let result = '';
    if(type === 'pickupShipment') {
      if(status === 'FAILED') {
        result = `<div>Driver <b>${options['DRIVER_NAME']}</b> unsuccessfully pickup shipment ${options['DRIVER_LABEL']}${options['REMARK'] ? ' with remark:' : ''}</div><div><i><small>${options['REMARK'] ? options['REMARK'] : ''}</small></i></div>`;
      }
    } else if(type === 'deliverShipment') {
      if(status === 'SUCCEEDED') {
        result = `<div>Driver <b>${options['DRIVER_NAME']}</b> successfully delivered shipment ${options['DRIVER_LABEL']}${options['REMARK'] ? ' with remark:' : ''}</div><div><i><small>${options['REMARK'] ? options['REMARK'] : ''}</small></i></div>`;
      } else if(status === 'FAILED') {
        result = `<div>Driver <b>${options['DRIVER_NAME']}</b> was unsuccessful delivering shipment ${options['DRIVER_LABEL']}${options['REMARK'] ? ' with remark:' : ''}</div><div><i><small>${options['REMARK'] ? options['REMARK'] : ''}</small></i></div>`;
      } else if(status === 'PENDING') {
        result = `<div>${options['DRIVER_NAME']} is delivering shipment to ${options['CUSTOMER_NAME']}`;
      }
    } else {
      result = 'Invalid';
    }

    return result;
  }

  render() {
    const { status } = styles;
    let events = this.props.history || [];

    return <Container>
      <Inner>
        <List>
          { events.map((h,i) => {
            const options = {
              'REMARK': h.remark,
              'DRIVER_NAME': h.driver_name || 'unknow',
              'DRIVER_LABEL': h.driver_label || 'unknow',
              'CUSTOMER_NAME': h.customer_name || 'unknow'
            };

            return <Item key={i}>
              <Circle  style={{backgroundColor: status[h.status]}}/>
              <Text dangerouslySetInnerHTML={{__html: this.getHistoryStatus(h.status, h.type, options)}} />
              <Date>{moment(h.actual_departure_ts).format('dddd M/D/YYYY - HH:mm:ss a')}</Date>
            </Item>
          })}
        </List>
      </Inner>
    </Container>
  }
}
