const styles = theme => ({
  container: {
    height: '100%',
    backgroundColor: '#f4f4f4',
    border: '1px solid #cfcfcf',
  },
  gridContainer: {
    height: '100%',
  },
  shipmentsList: {
    flex: 1,
    maxWidth: '100%',
    overflow: 'auto',
  },
  assignmentDetail: {
    flex: 1,
    maxWidth: '100%',
    maxHeight: 330,
    borderTop: '1px solid #cfcfcf',
    backgroundColor: '#fff',
  },
});

export default styles;