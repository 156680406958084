const styles = theme => ({
  container: {
    padding: '8px 24px',
  },
  downloadIcon: {
    display: 'block',
    cursor: 'pointer',
  },
});

export default styles;