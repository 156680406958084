import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import { AxlModalBox } from "../AxlBox";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function AxlModal({
                                   trigger,
                                   isOpen = false,
                                   disabled,
                                   onClose = () => {},
                                   onRendered = () => {},
                                   isClose = true,
                                   ...props}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if(isClose !== undefined && isClose) {
      handleClose();
    }
  }, [isClose]);

  return (
    <>
      {React.cloneElement(trigger, {onClick: () => {
        if(!disabled) {
          handleOpen();
          onRendered();
          trigger.props.onClick && trigger.props.onClick();
        } else {
          return false;
        }
      }})}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <AxlModalBox onClose={handleClose} bgcolor={props.bgcolor} {...props}>{props.children}</AxlModalBox>
      </Modal>
    </>
  );
}
