import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import styles, { Status } from './styles';
import acl from 'constants/acl';

class ShipmentDropoffInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleOpenDropOffDialog = () => {
    const { store } = this.props;
    const { permissionStore } = store;
    const isDenied = permissionStore.isDenied(acl.ASSIGNMENTS.UPDATE_DROP_OFF);

    if (isDenied) return;

    this.props.openForm('AccessCode')();
  };

  render() {
    const { dropoff, shipment, classes, pickup, store } = this.props;
    const { permissionStore } = store;
    const dropoffStatus = (dropoff && dropoff.status) ? dropoff.status : '';
    const statusColor = !!dropoffStatus ? Status[dropoffStatus] : Status['DEFAULT'];
    const dtFormat = moment(shipment.dropoff_latest_ts).isBefore(moment()) ? 'MM/DD hh:mm A' : 'hh:mm A';
    const isHideEdit = shipment.dropoff_access_code || shipment.dropoff_additional_instruction;
    const isDisableAddNote = pickup && dropoff && !(['SUCCEEDED'].includes(dropoff.status) && ['SUCCEEDED'].includes(pickup.status))
    const isShowAccessCode = isHideEdit || isDisableAddNote;
    const isDeniedEdit = permissionStore.isDenied(acl.ASSIGNMENTS.UPDATE_DROP_OFF);

    return (
      <Box>
        <Accordion className={classes.panelContainer} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.panelHeader}
            IconButtonProps={{
              className: classes.panelToggleIcon,
              disableRipple: true,
              disableFocusRipple: true,
              disableTouchRipple: true,
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Box className={classes.panelHeaderTitle}>Dropoff Info:</Box>
              </Grid>
              <Grid item>
                <Box style={{color: statusColor}} className={classes.panelHeaderStatus}>
                  <span>{dropoffStatus}</span>
                  {dropoff && dropoff.actual_departure_ts && <span> @ {moment(dropoff.actual_departure_ts).format(dtFormat)}</span>}
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.text}>{shipment.dropoff_address.street}</Box>
                    <Box className={classes.text}>{shipment.dropoff_address.street2}</Box>
                    <Box className={classes.text}>{shipment.dropoff_address.city}, {shipment.dropoff_address.state} {shipment.dropoff_address.zipcode}</Box>
                  </Grid>
                  {shipment.dropoff_note && (
                    <Grid item>
                      <Box className={classes.noteLabel}>NOTES</Box>
                      <Box className={classes.noteContent}>{shipment.dropoff_note}</Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container wrap="nowrap" justifyContent="space-between" className={classes.timeWindow}>
                  <Grid item>
                    <Box className={classes.timeLabel}>Time Window:</Box>
                    <Box>{moment(shipment.dropoff_earliest_ts).format('hh:mm A')} - {moment(shipment.dropoff_latest_ts).format('hh:mm A')}</Box>
                  </Grid>
                  <Grid item>
                    <Box className={classes.timeLabel}>ETA:</Box>
                    <Box>{dropoff && dropoff.predicted_departure_ts && <span>{ moment(dropoff.predicted_departure_ts).format('hh:mm A') }</span>}</Box>
                  </Grid>
                  {dropoff && dropoff.actual_departure_ts && (
                    <Grid item>
                      <Box className={classes.timeLabel}>Actual Dropoff:</Box>
                      <Box>{moment(dropoff.actual_departure_ts).format('hh:mm A')}</Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center"
                      style={{color: statusColor}} className={classes.remarkHeader}
                >
                  <Grid item>
                    <Box className={classes.remarkHeaderStatus}>DROPOFF REMARK</Box>
                  </Grid>
                  {dropoffStatus && (
                    <Grid item>
                      <Box className={classes.remarkHeaderTime}>
                        {_.capitalize(dropoffStatus)}
                        {dropoff && dropoff.actual_departure_ts && <span> @ {moment(dropoff.actual_departure_ts).format(dtFormat)}</span>}
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Box className={classes.remarkContent} style={{borderColor: statusColor}}>{(dropoff && dropoff.remark) ? dropoff.remark : '-'}</Box>
              </Grid>
              {isShowAccessCode && <Grid item>
                <Grid container>
                  <div className={classes.accessCodeContainer}>
                    <div className={classes.codeBlock} >
                      <div style={{cursor: isHideEdit ? 'default' : 'pointer', flex: 1}}>
                        <div style={styles.dropoffAccessContentLabel}>
                          {
                            shipment.dropoff_access_code ? (
                              <span className={classes.accessLabel}>{`Access code:`} <span style={{color: '#000'}}>{shipment.dropoff_access_code}</span></span>
                            ) : shipment.dropoff_additional_instruction ? '' : isDisableAddNote && <div className={classes.accessLabel} onClick={this.handleOpenDropOffDialog}>{'+ Access Code / Additional Instruction'}</div>
                          }
                        </div>
                      </div>
                      { isHideEdit && (dropoff && !dropoff._deleted) && !isDeniedEdit && <Button size="small" className={classes.grayBtn} onClick={this.handleOpenDropOffDialog}>{`Edit`}</Button>}
                    </div>
                    <div>
                      { shipment.dropoff_additional_instruction && <div>
                        <span className={classes.accessLabel}>{`Additional Instruction:`}</span>
                        <span>{shipment.dropoff_additional_instruction}</span>
                      </div> }
                    </div>
                  </div>
                </Grid>
              </Grid>}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

const ShipmentDropoffInfoCompose = compose(
  inject("store"),
  observer
) (ShipmentDropoffInfo);

export default withStyles(styles)(ShipmentDropoffInfoCompose);
