export const MESSENGER_TYPE = {
  MESSENGER_NEW_MESSAGE: 'MESSENGER_NEW_MESSAGE',
  DRIVER_GENERAL_SUPPORT: 'DRIVER_GENERAL_SUPPORT',
  ASSIGNMENT_CONVERSATION: 'ASSIGNMENT_CONVERSATION',
}

export const SECTION_CONSTANT = {
  SOLVED: 'SOLVED',
  SOLVING: 'SOLVING',
  UNSOLVED: 'UNSOLVED',
  ATTENDED: 'ATTENDED',
  FOLLOWING: 'FOLLOWING',
  UNATTENDED: 'UNATTENDED'
}

export const FIREBASE_NOFIFICATION_ACTION_EVENT = {
  NEW_UNATTENDED_TOPICS: 'NEW_UNATTENDED_TOPICS',
  NEW_ATTENDED_TOPICS: 'NEW_ATTENDED_TOPICS',
  NEW_UNSOLVED_TOPICS: 'NEW_UNSOLVED_TOPICS',
  NEW_SOLVED_TOPICS: 'NEW_SOLVED_TOPICS',
  NEW_SOLVING_TOPICS: 'NEW_SOLVING_TOPICS',
  NEW_CLOSED_TOPICS: 'NEW_CLOSED_TOPICS'
}

export const FIREBASE_NOTIFICATION_ACTION_CHANGE_FOLLOW_EVENT = {
  CHANGE_FOLLOWERS_MESSENGER_TOPIC: 'CHANGE_FOLLOWERS_MESSENGER_TOPIC',
}

export const FIREBASE_NOFIFICATION_ACTION_EVENT_MAP_TOPIC_SECTION = {
  NEW_FOLLOWING_TOPICS: SECTION_CONSTANT.FOLLOWING,
  NEW_UNATTENDED_TOPICS: SECTION_CONSTANT.UNATTENDED,
  NEW_ATTENDED_TOPICS: SECTION_CONSTANT.ATTENDED,
  NEW_UNSOLVED_TOPICS: SECTION_CONSTANT.UNSOLVED,
  NEW_SOLVED_TOPICS: SECTION_CONSTANT.SOLVED,
  NEW_SOLVING_TOPICS: SECTION_CONSTANT.SOLVING
}

export const FIREBASE_NOTIFICATION_TOPIC_MAP_TO_TOAST = {
  NEW_MESSAGE: 'NEW_MESSAGE',
  CLOSE_MESSENGER_TOPIC: 'CLOSE_MESSENGER_TOPIC',
  OPEN_MESSENGER_TOPIC: 'OPEN_MESSENGER_TOPIC',
  REOPEN_MESSENGER_TOPIC: 'REOPEN_MESSENGER_TOPIC',
  NEW_ATTENDED_TOPICS: 'NEW_ATTENDED_TOPICS',
  NEW_UNATTENDED_TOPICS: 'NEW_UNATTENDED_TOPICS',
  NEW_UNSOLVED_TOPICS: 'NEW_UNSOLVED_TOPICS',
  CHANGE_FOLLOWERS_MESSENGER_TOPIC: 'CHANGE_FOLLOWERS_MESSENGER_TOPIC',
}