import React, { Component, createRef } from 'react';

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { inject, observer } from "mobx-react";
import Dropzone from 'react-dropzone';
import { compose } from "recompose";
import validate from 'validate.js';

import AxlDatePicker from '../../AxlMUIComponent/AxlDatePicker';
import DriverStepper from '../../components/drivers/DriverStepper';
import TextInput from '../../components/input/TextInput';
import { RULES } from '../../constants/rules';
import styles, * as S from './add-styles';

class AddAccountInfo extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      dataError: {},
      initDataError: {},
      confirmation: false,
      checking: false
    }    
  }

  componentDidMount() {
    const {store} = this.props;        
    // init error
    const initDataError = {};
    for (var i = 0; i < this.accountInfoFields.length; i++) {
      this.checkError(this.accountInfoFields[i], "init_");
    }

    this.setState({initDataError});
  }

  validates = {
    first_name: ["notEmpty"],
    last_name: ["notEmpty"],
    phone_number: ["notEmpty", "phone"],
    birthday: ["notEmpty"],
    driver_license: ["notEmpty"],
    background_document: ["notEmpty"],
    email: ["notEmpty", "email"]
  }

  accountInfoFields = ["first_name", "last_name", "birthday", "driver_license", "phone_number", "email", "background_document"];

  checkError = (field, prefix) => {
    const {store} = this.props;    
    const {driverData} = store.driverStore;
    if (!prefix) {
      const initErrorField = "init_" + field + "_error";
      this.setState({[initErrorField]: undefined});
    }

    const errorField = prefix ? prefix + field + "_error" : field + "_error";

    const validates = this.validates[field];
    
    if (!validates) return;    
    const value = driverData[field];

    var constraints = {
      [field]: _.assign({}, ..._.values(_.pick(RULES, validates)))
    };

    const result = validate({[field]: value}, constraints, {format: "flat"});

    // process current error 
    if (!result) this.setState({[errorField]: undefined})
    else {
      this.setState({[errorField]: result[0]})
    }   
  }

  handleClose = () => {
    const {store} = this.props;
    store.driverStore.clearDriverData();
    this.setState({confirmation: true})
  };

  onTextFieldChange = (field) => (event) => {
    const {store} = this.props;       

    store.driverStore.updateDriverData(field, event.target.value);
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  }

  onDateFieldChange = (field) => (date) => {
    const {store} = this.props;

    let value = date;
    if (date.target && date.target.value) {
      value = date.target.value;
    }

    store.driverStore.updateDriverData(field, value);
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  }

  onUpload = (acceptedFiles) => {
    const {store} = this.props;        
    store.driverStore.updateDriverData('background_document', acceptedFiles[0]);
    store.driverStore.updateDriverData('background_document_url', undefined);
    
    const fieldValidates = this.validates['background_document'];

    if (fieldValidates) {
      this.checkError('background_document');
    }
  }

  goNext = () => {
    const {history, store} = this.props;
    this.checkExist().then(resp => {
      if (resp.status === 200 && !Object.values(resp.data).find(r => !!r)) {
        store.driverStore.upload();
        store.driverStore.setStep(1);
        history.push('/drivers/add-vehicle');
      }
    });    
  }

  getValue = (field, df) => this.props.store.driverStore.driverData[field] ? this.props.store.driverStore.driverData[field]: df !== '' ? df : '';

  closeDialog = () => {
    const {store} = this.props;
    const {driverStore} = store;
    driverStore.clearDriverData();
    this.setState({confirmation: false});
  }

  closeAddModal = () => {
    const {history, store} = this.props;
    const {driverStore} = store;
    driverStore.clearDriverData();
    this.setState({confirmation: false})
    history.replace('/drivers');
  }
  
  checkExist = (target) => {
    const {driverStore, userStore} = this.props.store;
    const {driverData} = driverStore;  
        
    this.setState({checking: true});
    return driverStore.checkExist().then(resp => {
      if (resp.status === 200) {
        Object.keys(resp.data).forEach(k => {          
          if (resp.data[k]) {
            this.setState({[k + "_error"]: `${driverData[k]} is not available, please take another one.`})
          }
        })
      }
      
      this.setState({checking: false});
      return resp;
    });
  }

  render() {
    const {classes, store} = this.props;
    const {driverData} = store.driverStore;    
    const hasError = this.accountInfoFields.find(field => !!this.state[field + "_error"] || !!this.state["init_" + field + "_error"]);
    const dropzoneRef = createRef();

    return (
      <Container className={classes.root}>            
          <Modal
            aria-labelledby="add-driver"
            aria-describedby="add-driver"
            open={true}
            onClose={this.handleClose}
            className={classes.modal}          
          >          
          
            <div className={classes.paper}>   
              <Dialog                
                  open={this.state.confirmation}            
                  onClose={this.closeDialog}
                  maxWidth={'md'}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className={classes.confirmDialog}
                >
                <DialogTitle className={classes.confirmDialogTitle} id="alert-dialog-title">
                  <Box style={{fontWeight: 'bold'}}>
                    Are you sure you want to leave? 
                    <br/>
                    The driver being created will not save.
                  </Box>                
                </DialogTitle>
                <DialogActions>
                  <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                    <Button style={{marginLeft: '15px', marginBottom: '10px'}} className={classes.secondaryBtn} variant="contained" onClick={this.closeDialog} color="primary">
                      Back
                    </Button>
                    <Button style={{marginRight: '15px', marginBottom: '10px'}} className={classes.errorBtn} variant="contained"  onClick={this.closeAddModal} color="primary" autoFocus>
                      Leave
                    </Button>
                  </div>                
                </DialogActions>
              </Dialog>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                <Grid item xs={3}>
                  <S.Title>{`Create New Driver`}</S.Title>
                </Grid>
                <Grid item xs={6}>
                  <DriverStepper style={{width: '100%'}} currentStep={0} />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    bgcolor="background.paper"
                    color="text.primary"
                    p={2}
                    position="absolute"
                    top={0}
                    right="0"
                    zIndex="modal"
                  >
                  <IconButton onClick={this.handleClose} size="large"><CloseIcon /></IconButton> 
                  </Box>                    
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput                   
                    onChange={this.onTextFieldChange('first_name')} 
                    error={this.state['first_name_error']}
                    value={this.getValue('first_name')} 
                    label='First Name' />
                </Grid>
                <Grid item xs={6}>
                  <TextInput 
                    error={this.state['last_name_error']}
                    onChange={this.onTextFieldChange('last_name')} 
                    value={this.getValue('last_name')} label='Last Name' />
                </Grid>
                <Grid item xs={6}>
                  <Box my={0.5}>
                    <Box fontFamily={'AvenirNext'} fontSize={17} color={'#4a4a4a'} fontWeight={600} mb={0.5}>Date of Birth</Box>
                    <AxlDatePicker
                      errorMessage={this.state['birthday_error']}
                      onChange={this.onDateFieldChange('birthday')}
                      format="MM/DD/YYYY"
                      value={this.getValue('birthday', null)}
                      placeholder="MM/DD/YYYY"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <TextInput 
                    value={this.getValue('driver_license')} 
                    error={this.state['driver_license_error']}  
                    onChange={this.onTextFieldChange('driver_license')} label='Driver License Number' />
                </Grid>
                <Grid item xs={6}>
                  <TextInput 
                    // onMouseLeave={this.checkPhone}
                    isPhone
                    placeholder="+1 (555) 495-3947"
                    error={this.state['phone_number_error']}  
                    value={this.getValue('phone_number')} onChange={this.onTextFieldChange('phone_number')} label='Phone Number' />
                </Grid>
                <Grid item xs={6}>
                  <TextInput 
                    // onMouseLeave={this.checkEmail}
                    error={this.state['email_error']}
                    value={this.getValue('email')} onChange={this.onTextFieldChange('email')} label='Email Address' />
                </Grid>                            
                <Grid item xs={6} style={{paddingTop: '30px'}}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <span style={{color: '#737273', fontSize: '13px'}}>Background Check</span>
                    </Grid>
                    <Grid item xs={6}>
                      <Dropzone ref={dropzoneRef} onDrop={this.onUpload}>
                        {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button variant="outlined" className={classes.uploadDocument} style={{border: '1px solid #ccc'}}>Upload Document</Button>
                          </div>
                        )}
                      </Dropzone>                    
                    </Grid>
                    {driverData && driverData['background_document'] && 
                      <Grid item xs={12}><FormHelperText id="component-error-text">{driverData['background_document']['name']}</FormHelperText></Grid>}
                    {this.state['background_document_error'] && 
                      <Grid item xs={12}><FormHelperText>{this.state['background_document_error']}</FormHelperText></Grid>}  
                  </Grid>
                </Grid>
              </Grid>
              <br/>
              <br/>
              <br/>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button disabled={!!hasError || this.state.checking} size='medium' className={classes.mainBtn} disableElevation={true} variant="contained" color="primary" onClick={this.goNext}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </Container>
    );
  }
}

const AddAccountInfoCompose = compose(
  inject("store"),
  observer
) (AddAccountInfo);

export default withStyles(styles)(AddAccountInfoCompose);