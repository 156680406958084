import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

import { TABS } from 'containers/Navigation';

export function PermissionRoute({ children, store, deniedPatterns = [], ...rest }) {
  const history = useHistory();
  const { permissionStore } = store;
  const isDenied = permissionStore.isDenied(deniedPatterns);

  if (!isDenied) return <Route {...rest} render={() => (children)} />;

  const filteredRoutes = TABS.filter((tab) => permissionStore.isDenied(tab.deniedPatterns) === false);
  const [filteredRoute] = filteredRoutes;

  if (filteredRoute) history.replace(filteredRoute.link);
  return null;
}

export const ProtectedRoute = compose(inject('store'), observer)(PermissionRoute);
