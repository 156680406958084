import React, { Component } from 'react';
import { AxlButton } from 'axl-reactjs-ui';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import moment from "moment";
import { convertMeterToMile } from '../../constants/common';

class StopAssignmentTooltip extends Component {
  render() {
    const { stop, classes, showAssignmentDetail } = this.props;
    const { assignment } = stop;
    const rows = [
      {label: 'ID', value: assignment.id},
      {label: 'Predicted', value: moment(assignment.predicted_start_ts).format('hh:mm a') + ' - ' + moment(assignment.predicted_end_ts).format('hh:mm a')},
      {label: 'Driving', value: convertMeterToMile(assignment.travel_distance) + ' mi / ' +  (assignment.travel_time / 3600).toFixed(1) + ' hours'},
      {label: 'Assigned', value: assignment.driver && assignment.driver.first_name + ' ' + assignment.driver.last_name},
      {label: 'Status', value: assignment.status},
    ];

    return (
      <div className={classes.container}>
        <div className={classes.label}>Assignment {assignment.label}</div>
        {rows.map((row, idx) => (
          <div className={classes.row} key={idx}>
            <div className={classes.col_left}>{row.label}:</div>
            <div className={classes.col_right}>{row.value}</div>
          </div>
        ))}
        <div>
          <AxlButton compact={true}
                     style={{width: '160px'}}
                     onClick={ () => showAssignmentDetail && showAssignmentDetail(assignment) }
          >
            DETAIL
          </AxlButton>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(StopAssignmentTooltip);