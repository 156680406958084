const styles = theme => ({
  container: {
    height: '100%',
  },
  search: {

  },
  list: {
    padding: '8px',
    width: '100%',
    boxSizing: 'border-box',
    height: 'calc(100% - 51px)',
    overflow: 'auto',
  }
});

export default styles;