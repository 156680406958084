import React, { Component, Fragment } from 'react'
import {inject, observer} from "mobx-react"
import styles, * as E from "./styles"
import moment from 'moment'
import DriverProfileInformation from '../DriverProfileInformation';
import DriverProfileRoutingTabs from '../DriverProfileRoutingTabs';
import { AxlModal } from 'axl-reactjs-ui';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

const object_types = {
    DR: 'Driver',
    AS: 'Assignment',
    SH: 'Shipment',
    ST: 'Stop',
    US: 'User'
}

class EventObjectCompose extends Component {
    constructor(props) {
        super(props);
        this.state = {
          driverData: {},
          showDriverProfile: false
        }
        this.onShowDriverProfile = this.onShowDriverProfile.bind(this);
    }

    onShowDriverProfile = (driver) => {
        const that = this;
    
        if(driver) {
            this.props.driverStore.get(driver, function(res) {
                if(res.status === 200) {
                    that.setState({driverData: res.data, showDriverProfile: true});
                }
            });
        }
    }
    onHideDriverProfile = () => { this.setState({showDriverProfile: false}) }
    
    render() {
        const { obj } = this.props
        if (!obj)
            return <span></span>
        const { uid } = obj
        const type = uid ? object_types[uid.split("\_")[0]] : ''
        const object_id = uid.split("\_")[1]
        const { showDriverProfile, driverData } = this.state;
        const name = obj.attributes && obj.attributes.name ? obj.attributes.name : ''
        if (type === 'Driver')
            return <Fragment><E.DriverLink onClick={() => this.onShowDriverProfile(object_id)}>
                {type} <strong style={styles.strong}>{name}</strong> [{object_id}]
            </E.DriverLink>
            {(showDriverProfile && driverData) && <AxlModal style={styles.modalDriverProfileContainer} onClose={this.onHideDriverProfile}>
                <DriverProfileInformation driver={driverData} />
                <DriverProfileRoutingTabs driver={driverData} onSave={this.onHideDriverProfile} history={this.props.history} />
              </AxlModal>}
            </Fragment>
        return <span>
            {type} <strong style={styles.strong}>{name}</strong> [{object_id}]
        </span>
    }
}
export const EventObject = compose(
  withRouter,
  inject('store'),
  observer
)(EventObjectCompose);

export default class Event extends Component {
    render() {
        const { event } = this.props
        const reason = event.evidence ? event.evidence.reason : null
        const reasonCode = event.evidence ? event.evidence.reason_code : null
        const amount = event.state ? (event.state.amount || event.state.bonus) : null
        const key = (event.category || '') + "." + (event.type || '') + "." + (event.action || '')

        if (event.type === 'DATE')
            return <div style={styles.item}>
                <span style={{...styles.car, backgroundColor: styles.status[event.action]}} />
                <span style={{ textDecoration: 'underline' }}>{event.date}</span>
            </div>

        if (event.subject && event.subject.uid === 'WO_AssignmentRefresher')
            return <span></span>

        if (key === 'ASSIGNMENT.FINANCE.transaction')
            return <div style={styles.item}>
                <span style={{...styles.car, backgroundColor: styles.status.system}} />
                <div style={styles.inner}>
                    <div style={styles.notes}>
                        Payment of $<strong style={styles.strong}>{ amount }</strong> has been added to <EventObject obj={event.ref} /> for <EventObject obj={event.object} />
                    </div>
                    {event.ts && <div style={styles.time}>{moment(event.ts).format('HH:mm:ss A')}</div> }
                </div>
            </div>

      if (key === 'ASSIGNMENT.FINANCE.payment'){
        return <div style={styles.item}>
          <span style={{...styles.car, backgroundColor: styles.status.system}}/>
          <div style={styles.inner}>
            <div style={styles.notes}>
              Amount of $<strong style={styles.strong}>{amount}</strong> has been paid to <EventObject
                obj={event.ref}/> for <EventObject obj={event.object}/>
            </div>
            {event.ts && <div style={styles.time}>{moment(event.ts).format('HH:mm:ss A')}</div>}
          </div>
        </div>
      }


            if (key === 'ASSIGNMENT.PLANNING.add-delivery')
            return <div style={styles.item}>
                <span style={{...styles.car, backgroundColor: styles.status.system}} />
                <div style={styles.inner}>
                    <div style={styles.notes}>
                        <EventObject obj={event.subject} /> add <EventObject obj={event.ref} /> to <EventObject obj={event.object} /> with label <strong style={styles.strong}>{event.evidence.label}</strong>
                    </div>
                    {event.ts && <div style={styles.time}>{moment(event.ts).format('HH:mm:ss A')}</div> }
                </div>
            </div>

        if (key === 'ASSIGNMENT.PLANNING.announce-sms')
            return <div style={styles.item}>
                <span style={{...styles.car, backgroundColor: styles.status.system}} />
                <div style={styles.inner}>
                    <div style={styles.notes}>
                    <EventObject obj={event.subject} /> send SMS to { event.evidence.driver_count } drivers regarding <EventObject obj={event.object} />.
                    </div>
                    {event.ts && <div style={styles.time}>{moment(event.ts).format('HH:mm:ss A')}</div> }
                </div>
            </div>


        return <Fragment>
            <div style={styles.item}>
                <span style={{...styles.car, backgroundColor: styles.status[event.action] || styles.status.system}} />
                <div style={styles.inner}>
                    <div style={styles.notes}>
                        <EventObject obj={event.subject} /> {event.action} <EventObject obj={event.object} />
                        { event.ref && <span> from <EventObject obj={event.ref} /></span> }
                        { amount && <span> of $<strong style={styles.strong}>{ amount }</strong> </span>}
                        { reasonCode && <span> with reason code: <strong style={styles.strong}>{ reasonCode }</strong> </span>}
                        { reason && <span>, reason: <strong style={styles.strong}>{ reason }</strong> </span>}
                    </div>
                    {/* <div style={styles.notes}>{render[`${item.ref_type}-${item.action}`] ? render[`${item.ref_type}-${item.action}`].content(item) : render[item.ref_type].content(item)}</div> */}
                    {event.ts && <div style={styles.time}>{moment(event.ts).format('HH:mm:ss A')}</div> }
                </div>
                <div style={styles.clear}></div>
            </div>
        </Fragment>
    }
}