import { Colors } from 'axl-reactjs-ui';

export const Status = {
  DEFAULT: Colors.veryLightPink,
  PENDING: Colors.reddishOrange,
  DELIVERED: Colors.reddishOrange,
  DROPOFF_SUCCEEDED: Colors.darkPastelGreen,
  SUCCEEDED: Colors.darkPastelGreen,
  FAILED: Colors.dustyRed
};

const styles = theme => ({
  accessLabel: {
    fontFamily: 'AvenirNext',
    fontSize: '12px',
    color: '#887fff'
  },
  accessCodeContainer: {
    padding: '7px 10px',
    borderRadius: '5px',
    border: '1px solid #887fff',
    flex: 1,
  },
  codeBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grayBtn: {
    border: '1px solid #ccc',
    color: '#8d8d8d',
  },
  container: {
    marginBottom: '16px',
  },
  panelContainer: {
    backgroundColor: '#f4f4f4',
    boxShadow: 'none',
  },
  panelHeader: {
    padding: '8px',
    minHeight: 'auto !important',
    '& > .MuiExpansionPanelSummary-content': {
      margin: 0,
    }
  },
  panelHeaderTitle: {
    flex: 1,
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '14px',
    color: '#000'
  },
  panelHeaderStatus: {
    fontSize: '12px',
    fontStyle: 'italic',
    margin: '0 5px'
  },
  panelToggleIcon: {
    padding: '4px 12px',
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  panelBody: {
    padding: '8px',
    fontSize: 12,
  },
  noteLabel: {
    fontFamily: 'AvenirNext',
    fontSize: '10px',
    color: '#bec6d8'
  },
  noteContent: {
    borderRadius: '5px',
    border: 'solid 1px #bec6d8',
    padding: '7px 10px',
    fontStyle: 'italic',
    fontSize: 12,
  },
  text: {
    fontSize: '12px',
    color: '#000'
  },
  text2: {
    fontSize: '9px'
  },
  remarkHeader: {
    fontSize: 10,
  },
  remarkHeaderStatus: {
    fontFamily: 'AvenirNext-DemiBold',
    flex: 1
  },
  remarkHeaderTime: {
    fontFamily: 'AvenirNext-Medium'
  },
  remarkContent: {
    padding: '8px 12px',
    borderRadius: '5px',
    border: '1px solid #cfcfcf',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
  timeWindow: {
    backgroundColor: '#FFF',
    padding: '7px 15px',
    borderRadius: '5px',
    margin: '12px 0px',
  },
  timeLabel: {
    fontFamily: 'AvenirNext-DemiBold',
    color: '#96979a',
  }
});

export default styles;