import React from "react";
import * as M from "@mui/material";
import * as S from "./styles";
import AxlButton from "../AxlButton";
import {Grid, ThemeProvider} from "@mui/material";
import * as themes from "./theme";
import THEME_DEFINED from "../../constants/theme";
import _ from 'lodash';

export default function AxlConfirmation({
                                          theme = 'main',
                                          onClose = () => {},
                                          onDo = () => {},
                                          title = "CONFIRMATION",
                                          subTitle,
                                          closeText = "Cancel",
                                          doText = "Save",
                                          controlTextAlign = 'center',
                                          loading = false,
                                          disabled = false,
                                          style = {},
                                          ...props
}) {
  const closeProps = _.assign({}, {
      padding: '5px 24px',
      minWidth: 120,
      bgcolor: 'primary.white',
      color: 'primary.graySecond',
      variant: 'outlined',
    }, props.closeProps);
    const doProps = _.assign({}, {
      padding: '5px 25px',
      minWidth: 120,
      bgolor: 'primary.greenThird',
      color: 'primary.white',
      variant: 'outlined',
    }, props.doProps);


  return(<ThemeProvider theme={themes[THEME_DEFINED[theme]]}>
    <S.ResolvedContainer style={style}>
      <S.TitleModal>{title}</S.TitleModal>
      <S.SubTitleModal>{subTitle}</S.SubTitleModal>
      <Grid container justifyContent={controlTextAlign} alignItems={'center'} pt={2}>
        <AxlButton onClick={onClose} {...closeProps}>{closeText}</AxlButton>
        <AxlButton loading={loading} disabled={disabled} onClick={onDo} {...doProps}>
          {loading ? <M.CircularProgress size={15} thickness={1} /> : doText}
        </AxlButton>
      </Grid>
    </S.ResolvedContainer>
  </ThemeProvider>);
}