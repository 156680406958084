import React from "react";
import {Grid} from "@mui/material";

import {Label, Value} from "./styles";

class LabelValue extends React.Component {
  render() {
    return (
        <Grid item>
            <Label>{this.props.label}
              <span>: </span>
              <Value status={this.props.status}>{this.props.value}</Value>
            </Label>
        </Grid>
    )
  }
}

export default LabelValue;
