import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${'8px'} ${'16px'}`,
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: 0,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, align, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box>
        <Typography variant="h6" align={align}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '16px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '8px',
  },
}))(MuiDialogActions);

function AxlDialog({ isOpen, childrenTitle, childrenAction, children, handleClose, maxWidth = 'md', alignTitle = 'inherit', DialogContentProps }) {
  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth={maxWidth}>
      <DialogTitle onClose={handleClose} align={alignTitle}>
        {childrenTitle}
      </DialogTitle>
      <DialogContent dividers {...DialogContentProps}>{children}</DialogContent>
      {childrenAction && <DialogActions>{childrenAction}</DialogActions>}
    </Dialog>
  );
}

export default AxlDialog;
