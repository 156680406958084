import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const styles = theme => ({
  root: {
    
  },
});

export default styles;    