import {Box, styled, Typography} from "@mui/material";
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";

export const Text = styled(Typography)(({theme}) => ({
  fontFamily: fonts.DemiBold,
  fontSize: 17,
  color: colors.blackSecondary,
}));
export const Title = styled(Text)(({theme}) => ({
  fontFamily: fonts.Bold,
  fontSize: 20,
  color: colors["greyish-brown"],
}));
