import {observable, action, decorate, computed} from 'mobx';
import _ from 'lodash';
import validate from 'validate.js';
import {RULES} from '../constants/rules';
import moment from 'moment';
import {ASSIGNMENT_STATUS} from '../constants/status';
import DriverStore from "./DriverStore";

class VehicleStore {
  constructor(appStore) {
    this.appStore = appStore;
  }

  vehicleSelected = null;

  getPersonalVehicles(driverId, query, cb) {
    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.appStore.api.get(`/vehicles/driver/${driverId}?${params}`)
  }

  getCompanyVehicles(courierId, query, cb) {
    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.appStore.api.get(`/vehicles/courier/${courierId}?${params}`)
  }

  assignVehicle = (vehicleId, driverId) => this.appStore.api.put(`/vehicles/${vehicleId}/assign/${driverId}`);

  unassignVehicle = (vehicleId, driverId) => this.appStore.api.delete(`/vehicles/${vehicleId}/unassign/${driverId}`);
}

decorate(VehicleStore, {
  vehicleSelected: observable,
  getPersonalVehicles: action,
  getCompanyVehicles: action,
});

export default VehicleStore;