import React, { useState } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { useRecoilState } from 'recoil';
import { Container, Grid } from '@mui/material';

import AxlButton from 'AxlMUIComponent/AxlButton';
import AxlModal from 'AxlMUIComponent/AxlModal';
import TOOLTIP_DEFINED from 'constants/tooltip';
import VehicleAddNew from 'containers/VehicleAddNew';
import VehiclesList from 'containers/VehicleList';
import AxlSearchInput from 'AxlMUIComponent/AxlSearchInput';
import { vehicleState } from 'containers/VehicleList/state';

import acl from 'constants/acl';

function VehicleScreen(props) {
  const { store } = props;
  const { permissionStore } = store;
  const [query, setQuery] = useRecoilState(vehicleState);
  const [isClose, setClose] = useState(true);

  const onSearch = (value) => {
    const _query = _.assign({}, query, { search_text: value, page_number: 0 });
    setQuery(_query);
  };

  const reloadSearch = (value) => {
    const _query = _.assign({}, query, { search_text: value });
    setQuery(_query);
  };

  const onClose = () => {
    setClose(true);
    reloadSearch('');
  };

  const isDeniedCreateVehicle = permissionStore.isDenied(acl.VEHICLES.EDIT);

  return (
    <Container maxWidth={false}>
      <Grid container>
        {!isDeniedCreateVehicle && (
          <AxlModal
            isClose={isClose}
            onRendered={() => setClose(false)}
            trigger={
              <AxlButton
                color="primary.white"
                bgcolor="primary.green"
                spacing={'16px 16px 16px 0px'}
                tooltip={{ title: TOOLTIP_DEFINED.ADD_NEW_VEHICLE }}
              >{`+NEW`}</AxlButton>
            }
          >
            <VehicleAddNew onClose={onClose} />
          </AxlModal>
        )}
        <Grid item xs>
          <AxlSearchInput placeholder={'Search vehicles by vehicle name, vehicle info...'} searchAction={onSearch} />
        </Grid>
      </Grid>
      <VehiclesList />
    </Container>
  );
}

export default compose(inject('store'), observer)(VehicleScreen);
