export const STATUS = {
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    UNASSIGNED: 'UNASSIGNED'
};

export const DRIVER_STATUS = {
  QUIT: 'QUIT',
  CREATED: 'CREATED',
  SUSPENDED: 'SUSPENDED'
};

export const DRIVER_BACKGROUND_STATUS = {
  AUTO_APPROVED: 'AUTO_APPROVED',
  MANUAL_APPROVED: 'MANUAL_APPROVED',
  REJECTED: 'REJECTED',
  DRIVER_CONSENTED: 'DRIVER_CONSENTED',
  PENDING: 'PENDING'
};

export const DRIVER_REGISTRATION_STATUS = {
  QUIZ_SENT: 'QUIZ_SENT',
  QUIZ_COMPLETED: 'QUIZ_COMPLETED'
};

export const NOT_ALLOW_APPROVE_DRIVER_STATUS = [
  DRIVER_BACKGROUND_STATUS.AUTO_APPROVED,
  DRIVER_BACKGROUND_STATUS.MANUAL_APPROVED
];

export const NOT_ALLOW_REQUIZ_DRIVER_STATUS = [
  DRIVER_REGISTRATION_STATUS.QUIZ_COMPLETED,
];

export const NOT_ALLOW_RESEND_BACKGROUND_REQUEST_URL = [
  DRIVER_BACKGROUND_STATUS.AUTO_APPROVED, DRIVER_BACKGROUND_STATUS.MANUAL_APPROVED, DRIVER_BACKGROUND_STATUS.REJECTED
];

export const ASSIGNMENT_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NULL: null
};

export const STOP_STATUS = {
  PENDING: "PENDING",
  SUCCEEDED: "SUCCEEDED",
  REATTEMPT: "REATTEMPT",
  FAILED: "FAILED"
}

export const TOOLTIP_STATUS = {
  CREATED: "Payment is created but not sent to bank to process yet.",
  REQUESTED: "Payment request is sent to the bank.",
  PENDING: "Payment is sent to SVB and waiting to be processed.",
  PROCESSING: "Payment sent to SVB, no action needed.",
  APPROVED: "Payment is approved and waiting for bank to process.",
  SUCCEEDED: "Payment is successful.",
  CORRECTED: "Payment is successfully corrected and receiver bank info is auto corrected by bank.",
  RESUBMITTED: "Payment has been re-sent to SVB to processed.",
  RESUBMIT_FAILED: "Resubmitted payment failed to be processed.",
  RESUBMIT_SUCCEEDED: "Resent payment is successful.",
  CANCELED: "Payment is canceled.",
  CANCELLED: "Payment is cancelled.",
  HOLD: "Payment is held.",
  ERROR: "There is a problem with the payment, see error description on page for further details.",
  FAILED: "There is a problem with the payment, see error description on page for further details.",
  REQUEST_ERROR: "There is a problem with the payment, see error description on page for further details.",
}
