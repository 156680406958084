import React from 'react';
import { useTheme } from '@mui/material/styles';

export const EVENT_STATUSES = {
  FAILED: 'FAILED',
};

export function EVENT_STATUS_TO_COLORS() {
  const theme = useTheme();

  return ({
    CREATED: theme.palette.primary.periwinkle,
    FAILED: theme.palette.primary.red,
    CANCELLED: theme.palette.primary.red,
    DELIVERED: theme.palette.primary.green,
    UNDELIVERABLE_SH: theme.palette.primary.red,
    DROPOFF_SUCCEEDED: theme.palette.primary.green,
  });
}