import _ from 'lodash';

export const isMatchPattern = ({ uri, compareUri, method, compareMethod }) => {
  const splitted = _.trim(uri, '/').split('/');

  const splittedPattern = _.trim(compareUri, '/').split('/');

  if (splitted.length !== splittedPattern.length) return false;

  const isVariablePattern = /^{.*}$/;

  const regexList = splittedPattern.map((path) => {
    if (isVariablePattern.test(path)) return new RegExp('.', 'g');

    return new RegExp(`^${path}$`, 'g');
  });

  const isMatched = regexList.every((regex, index) => regex.test(splitted[index]));

  return method && compareMethod ? isMatched && method.toUpperCase() === compareMethod.toUpperCase() : isMatched;
};

export const isDeniedAction = (actionPatterns, deniedPatterns) => {
  if (_.isEmpty(actionPatterns) || _.isEmpty(deniedPatterns) || !_.isArray(actionPatterns) || !_.isArray(deniedPatterns)) return false;

  return actionPatterns.some((pattern) =>
    deniedPatterns.some((deniedPattern) =>
      isMatchPattern({ uri: pattern.path, compareUri: deniedPattern.path, method: pattern.method, compareMethod: deniedPattern.method }),
    ),
  );
};
