import React, {Component, Fragment} from 'react';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import { Container, Grid, Box, LinearProgress } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { AxlSearchBox, AxlMiniStopBox } from 'axl-reactjs-ui';

import styles from "./styles";

class ShipmentList extends Component {
  constructor(props) {
    super(props);
  }

  selectStop = (stop) => {
    this.props.onSelectStop && this.props.onSelectStop(stop);
  };

  changeSearch = (v) => {
    const { assignmentStore } = this.props.store;
    assignmentStore.filter = v;
  };

  render() {
    const { store, classes } = this.props;
    const { assignmentStore, shipmentStore } = store;
    const { filteredShowingStops, loadingAssignment } = assignmentStore;
    const { selectedStopId } = shipmentStore;

    if (loadingAssignment) {
      return (
        <Box className={classes.container}>
          <LinearProgress color="primary" />
        </Box>
      )
    }

    return (
      <Box className={classes.container}>
        <Box p={1} pb={0}>
          <AxlSearchBox
            placeholder="Search Shipment"
            style={{width: '100%'}}
            theme="periwinkle"
            onChange={this.changeSearch}
          />
        </Box>
        <Box className={classes.list}>
          { filteredShowingStops && filteredShowingStops.map((stop) => {
            const tags = stop.shipment.tags ? stop.shipment.tags.map((tag, i) => {
              return {
                text: <span className={classes.text}>{`${tag}`}</span>,
                value: i
              };
            }) : [];
            return (
              <AxlMiniStopBox
                onClick={() => this.selectStop(stop)}
                key={stop.id}
                style={{opacity: !selectedStopId || selectedStopId === stop.id ? 1.0 : 0.5}}
                stop={stop}
                tags={tags}
              />
            )
          })}
        </Box>
      </Box>
    )
  }
}

const ShipmentListCompose = compose(
  inject("store"),
  observer
) (ShipmentList);

export default withStyles(styles)(ShipmentListCompose);