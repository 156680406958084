export const TICKET_STATUS = {
  CLAIMED: {
    color: '#77b45c'
  },
  READY: {
    color: '#f2c063'
  },
  IN_PROGRESS: {
    color: '#eb7035'
  },
  PENDING: {
    color: '#bebebe'
  },
  UNBOOKED: {
    color: '#f23'
  },
  COMPLETED: {
    color: '#297fc9'
  }
}

export const BANK_REQUEST_STATUS = {
  SUBMITTED: "#eb7035",
  APPROVED: "#77b45c",
  UPDATED: "#77b45c",
}

export const MAP_SHIPMENT_STATUS_TO_COLORS = {
  PICKUP_SUCCEEDED: '#77b45c',
  DROPOFF_SUCCEEDED: '#77b45c',
  DROPOFF_READY: '#f2c063',
  IN_PROGRESS: '#ecb54b',
  PENDING: '#bebebe',
  DELIVERED: '#77b45c',
  PICKUP_FAILED: '#f23',
  CANCELLED_AFTER_PICKUP: '#f23',
};