import {observable, action, decorate, computed} from 'mobx';
import _ from 'lodash';
import validate from 'validate.js';
import {RULES} from '../constants/rules';
import moment from 'moment';
import {ASSIGNMENT_STATUS} from '../constants/status';

class DriverStore {
  constructor(appStore) {
    this.appStore = appStore;
  }

  driverData = {};
  step = 0;
  creatingDriver = false;
  // list data
  searchField = 'name';
  searchKeyword = '';
  page = 0;
  data = {};

  // get driver data
  driver = {};
  drivers = [];
  driverAssignmentsCount = {};
  loadingDrivers = false;

  getDrivers(cb = () => {}) {
    const params = {};
    if (this.page) params['page'] = this.page;
    if (this.searchField) params['search_field'] = this.searchField;
    if (this.searchKeyword) params['search_keyword'] = this.searchKeyword;

    this.appStore.api.get(`/dsp/drivers/search`, params).then(response => {
      if (response.status === 200) {
        this.data = response.data;
      }
      if(cb)
        cb(response);
    });
  }

  getDriver(id) {
    this.appStore.api.get(`/dsp/drivers/${id}`).then(response => {
      if (response.status === 200) {        
        this.driver = response.data;
      }
    });
  }

  createDriver(prefix) {
    this.creatingDriver = true;
    // process birthday first
    const data = Object.assign({}, this.driverData);
    data['birthday'] = moment(this.driverData['birthday']).format('YYYY-MM-DD')
    data['username'] = prefix + data['username'];
    data['phone_number'] = prefix + data['phone_number'].replace(/[\s\(\)-]/g, "");

    return this.appStore.api.post(`/dsp/drivers/create_driver`, data).then(response => {
      this.creatingDriver = false;
      if (response.status === 200) {
        this.driverData = {};
        this.data.drivers = [response.data].concat(this.data.drivers);
      }

      return response;
    });
  }

  checkDriverUsername(username) {
    return this.appStore.api.post(`/dsp/drivers/validate`, {username});
  }

  // checkDriverEmail(email) {
  //   return this.appStore.api.post(`/dsp/drivers/validate`, {email});
  // }

  // checkDriverPhone(phone_number) {
  //   return this.appStore.api.post(`/dsp/drivers/validate`, {phone_number});
  // }

  checkExist() {
    const payload = {
      username: this.driverData['username'],
      email: this.driverData['email'],
      phone_number: this.driverData['phone_number'] ? this.driverData['phone_number'].replace(/[\s\(\)-]/g, ""): '',
      driver_license: this.driverData['driver_license'],
    }
    return this.appStore.api.post(`/dsp/drivers/check-exist`, payload);
  }

  listActiveDrivers() {
    this.loadingDrivers = true;
    this.appStore.api.get(`/dsp/drivers/list`).then(response => {
      if (response.status === 200) {
        this.drivers = response.data;
      }

      this.loadingDrivers = false;
    });
  }

  getLastLocation(id) {
    return this.appStore.api.get(`/dsp/drivers/${id}/location`)
      .then(response => {
        if (response.status === 200) {
          return response.data.length > 0 ? response.data[0] : null;
        } else {
          return null
        }
      })
  }

  updateDriverData (field, value) {
    this.driverData[field] = value;
  }

  clearDriverData () {
    this.driverData = {};
  }

  getActiveAssignment(driverId) {
    const status = [];
    const active = true;
    return this.appStore.api.get(`/dsp/assignments/drivers/${driverId}`, {active});
  }

  getAssignmentHistory(assignmentId) {
    return this.appStore.api.get(`/dsp/assignments/${assignmentId}/history`);
  }

  getPendingAssignments(driverId) {
    const status = [ASSIGNMENT_STATUS.PENDING, ASSIGNMENT_STATUS.NULL, ASSIGNMENT_STATUS.IN_PROGRESS, ''];
    return this.appStore.api.get(`/dsp/assignments/drivers/${driverId}`, {status, active: false});
  }

  getPastAssignments(driverId, page) {
    const status = [ASSIGNMENT_STATUS.COMPLETED];
    return this.appStore.api.get(`/dsp/assignments/drivers/${driverId}`, {status, size: 10, page, have_stop_statuses: true});
  }

  getStatistics(driverId) {
    return this.appStore.api.get(`/dsp/drivers/${driverId}/statistics`);
  }

  setStep(step) {
    if (step) this.step = step;
    else this.step += this.step;
  }

  upload () {
    if (!this.driverData['background_document'] || this.driverData['background_document_url']) return;
    
    const formData = new FormData();
    formData.append('background_document', this.driverData['background_document']);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    this.uploading = true;
    this.appStore.api.post(`/dsp/drivers/doc/upload`, formData, config)
      .then(response => {
        this.uploading = false;        
        if (response.status === 200) {
          this.driverData['background_document_url'] = response.data;
        }
      })
  }
}

decorate(DriverStore, {
  drivers: observable,
  driverAssignmentsCount: observable,
  loadingDrivers: observable, 
  creatingDriver: observable,
  clearDriverData: action,
  getLastLocation: action,
  data: observable,
  driverData: observable,
  driver: observable,
  page: observable,
  step: observable,
  searchField: observable,
  searchKeyword: observable,
  getDrivers: action,
  createDriver: action,
  setStep: action,
  listActiveDrivers: action,
  upload: action,
});

export default DriverStore;
