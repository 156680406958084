const styles = theme => ({
  container: {
    height: '100%',
    backgroundColor: '#f4f4f4',
    border: '1px solid #cfcfcf',
  },
  gridContainer: {
    height: '100%',
  },
  gridSection: {
    maxWidth: '100%',
    flex: 1,
    overflow: 'auto',
  },
  bottomSection: {
    borderTop: '1px solid #cfcfcf',
  }
});

export default styles;