import React, { Component, Fragment } from 'react';
import { AxlTable, AxlPagination, AxlPanel} from 'axl-reactjs-ui';
import styles from "./styles";
import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import moment from "moment";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndexes: [],
      detailedItem: {},
    };
  }

  componentDidMount() {
    const {store, baseUrl, keepData} = this.props;
    if (!keepData) {
      store.reset();
    }

    if (baseUrl) {
      store.setBaseUrl(baseUrl);
    }

    // store.search();
  }

  selectItem = (item) => (e) => {
    const {multipleSelect, store} = this.props;

    if (!store) return null;
    const {result, idField} = store;

    const {selectedIndexes, prevBatch} = this.state;
    const inSelectedIndex = store.selectedItems.indexOf(item[store.idField]);
    const isShift = e.shiftKey;
    const currentIndex = result.items.indexOf(item);

    if (isShift) {
      if (selectedIndexes.length > 0 && multipleSelect) {
        const lastSelectedIndex = selectedIndexes[selectedIndexes.length - 1];

        const allIds = toJS(result.items.map(i => i[idField]));
        let tobeAddIds = [];
        if (currentIndex > lastSelectedIndex) {
          tobeAddIds = allIds.slice(lastSelectedIndex, currentIndex + 1);
        } else {
          tobeAddIds = allIds.slice(currentIndex, lastSelectedIndex + 1);
        }

        if (prevBatch && prevBatch.selectedIndex && prevBatch.selectedIndex === lastSelectedIndex) {
          store.selectedItems = store.selectedItems.filter(id => !prevBatch.addedIds.includes(id));
        }

        tobeAddIds = tobeAddIds.filter(id => !store.selectedItems.includes(id));

        store.selectedItems = store.selectedItems
          .concat(tobeAddIds);

        this.setState({prevBatch: {
          selectedIndex: lastSelectedIndex,
          addedIds: tobeAddIds
        }});
      } else {
        this.setState({selectedIndexes: selectedIndexes.concat([currentIndex])});
        if (multipleSelect) {
          store.selectedItems.push(item[store.idField]);
        } else {
          store.selectedItems = [item[store.idField]];
        }
      }
    } else {
      if (inSelectedIndex >= 0) {
        store.selectedItems.splice(inSelectedIndex, 1);
        // remove prev shift index
        this.setState({selectedIndexes: selectedIndexes.splice(selectedIndexes.indexOf(currentIndex), 1)});
      } else {
        this.setState({selectedIndexes: selectedIndexes.concat([currentIndex])});
        if (multipleSelect) {
          store.selectedItems.push(item[store.idField]);
        } else {
          store.selectedItems = [item[store.idField]];
        }
      }
    }
  };

  onSelectPage = (page) => {
    const {store} = this.props;

    store.filters.page = page;
    store.search();
  };

  viewDetail = (item) => {
    console.log('hello', item);
    const {detailedItem} = this.state;
    this.setState({detailedItem: item.id === detailedItem.id ? {} : item});
  }

  render() {
    const {detailedItem} = this.state;
    const {
      store, alt, renderer, allowSelect, disabledIds,
      pagination, hiddenIfEmpty, header, styleContainer
    } = this.props;

    const {result, fields, filters, idField, details} = store;
    if (hiddenIfEmpty && (!result || !result.items || result.items.length < 1)) {
      return null;
    }

    return <Fragment>
      {header && header}
      <div style={{...styles.list, ...styleContainer}}>
        <AxlTable style={styles.tableStyle}>
          <AxlTable.Header>
            <AxlTable.Row>
              {fields && fields.map((field, i) => {
                const props = Object.assign({}, field);
                props['filters'] = filters;
                props['alt'] = alt;

                return (
                  <List.HeaderCell key={i} {...props} />
                )
              })}
            </AxlTable.Row>
          </AxlTable.Header>
          <AxlTable.Body>
            {result.items && result.items.length > 0 &&
            result.items.map((item, index) => {
              const style = {};
              const isDisabled = disabledIds && disabledIds.length > 0 && disabledIds.includes(item[idField]);
              if (isDisabled) {
                Object.assign(style, styles.disabledCell)
              }
              else if (store.isSelected(item, index)) Object.assign(style, styles.selected);

              if (item.type === 'PAYMENT - DEDUCT') {
                Object.assign(style, styles.deduct);
              }

              if (item.actual_end_ts && moment().diff(moment(item.actual_end_ts), "days") > 45) {
                Object.assign(style, styles.oldTransaction);
              }

              let statusColor = 'inherit';
              if (item.status) {
                if (item.status.indexOf('ERROR') > -1 || item.status.indexOf('FAILED') > -1) {
                  statusColor = '#f00';
                } else if (item.status.indexOf('SUCCEEDED') > -1) {
                  statusColor = '#4abc4e';
                } else if (item.status.indexOf('PROCESSING') > -1) {
                  statusColor = '#2c93ff';
                } else if (item.status.indexOf('APPROVED') > -1) {
                  statusColor = '#887fff';
                }
              }

              const props = {style};
              if (allowSelect && !isDisabled) {
                Object.assign(style, {cursor: 'pointer'});
                props['onClick'] = this.selectItem(item, index)
              }

              return (
                <>
                  <AxlTable.Row key={`row-${index}`} {...props}>
                    {fields.map((field, i2) => {
                        const style = {color: 'inherit', textAlign:'center'};
                        if (field.name === 'status') Object.assign(style, {color: statusColor, fontWeight: 700});
                        if (field.name === 'amount') Object.assign(style, {fontFamily: 'AvenirNext-DemiBold'});
                        if (field.highlight) Object.assign(style, styles.highlightCell);
                        if (field.nowrap) Object.assign(style, styles.nowrap);

                        if (field.name === 'row_detail' && item.assignment_id != null && ['OUTVOICE - ROUTE_DRIVING'].includes(item.type)) {
                          Object.assign(style, styles.details);
                          return (
                            <AxlTable.Cell key={`field_${index}${i2}`} style={style}>
                               <div onClick={() => {this.viewDetail(item)}} >{detailedItem.id === item.id ? 'Hide' : 'Show'} Details </div>
                            </AxlTable.Cell>
                          )
                        }

                        return (
                          <AxlTable.Cell key={`field_${index}${i2}`} style={style}>
                            {!renderer[field.name] ? item[field.name] : renderer[field.name](item[field.name], item, result)}
                          </AxlTable.Cell>
                        )
                      }
                    )}
                  </AxlTable.Row>
                  {detailedItem.id === item.id && (
                    <AxlTable.Row>
                      <AxlTable.Cell colSpan={8} key={`field_detail`} style={style}>
                        <AxlPanel.Row>
                          {details.map(detail => (
                            <AxlPanel.Col style={{...styles.detailWrapper, ...detail.styles}}>
                              <div style={{marginBottom: 8}}>{detail.label}</div>
                              <div style={{color: '#000'}}>{!renderer[detail.name] ? item[detail.name] : renderer[detail.name](item[detail.name], item, result)}</div>
                            </AxlPanel.Col>
                          ))}
                        </AxlPanel.Row>
                      </AxlTable.Cell>
                    </AxlTable.Row>
                  )}
                </>
              )
            })}
          </AxlTable.Body>
        </AxlTable>
      </div>
      {pagination && result.total_pages > 1 && <div style={{textAlign: 'center'}}>
        <AxlPagination onSelect = {this.onSelectPage} current={filters.page} total={result.total_pages} />
      </div>}
    </Fragment>
  }

  static HeaderCell = class extends Component {
    render() {
      const {label, filters, orderField, highlight, nowrap, toggleOrder, alt} = this.props;

      const props = {};
      // style
      const style = {fontWeight: 900, whiteSpace: 'nowrap'};
      if (highlight) Object.assign(style, styles.highlightCell);
      if (nowrap) Object.assign(style, styles.nowrap);
      if (alt) Object.assign(style, styles.alternative);
      props['style'] = style;

      if (orderField) {
        props['isActive'] = filters && filters.order_by === orderField;
        props['up'] = !(filters && filters.desc);
        if (toggleOrder) props['onClick'] = toggleOrder(orderField);
      }

      return <AxlTable.HeaderCell {...props}>{label}</AxlTable.HeaderCell>
    }
  }
}

const ListCompose = compose(
    inject("store"),
    observer
) (List);


export default ListCompose;
