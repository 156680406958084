import styled from 'styled-components';
import React from "react";

export const Container = styled.div`
  position: relative;
`;

export const Text = styled.div`
  font-family: 'AvenirNext';
  font-size: 13px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.break {
    white-space: normal;
    word-break: break-all;
  }
`;

export const MoreLink = styled.span`
  cursor: pointer;
  color: #887fff;
  &:hover {
    color: #1453e4;
  }
`;

export const Table = styled.div`
  margin-top: 15px
`;

export default {
  Row_1: {
    padding: '15px 15px 15px 30px',
    textAlign: 'left',
    alignItems: 'flex-start',
    borderBottom: 'solid 1px #e4e4e4'
  },
  ColPadd: {
    paddingLeft: 5,
    paddingRight: 5
  },
  textRed: {
    color: '#d63031'
  },
  disabled: {
    opacity: 0.3
  },
  loadingStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto'
  },
}
