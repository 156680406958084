import colors from "../../themes/colors";
import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.periwinkle}`,
          color: colors.periwinkle,
        },
        label: {},
        text: {
          padding: 0,
        },
        textPrimary: {
          color: 'red'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {},
        label: {}
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.periwinkle,
        }
      }
    }
  }
})