import * as M from '@mui/material';
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";
import {Box, styled} from "@mui/material";

export const Text = M.styled(M.Typography)(() => ({
  fontFamily: fonts.Medium,
  fontSize: 17,
  color: colors['greyish-brown'],
}));
export const Title = M.styled(Text)(() => ({
  fontSize: 20,
}));
export const Label = M.styled(Text)(() => ({
  fontSize: 17,
}));
export const StepText = M.styled(Text)(() => ({
  fontFamily: fonts.Regular,
  fontSize: 16,
  color: colors.grayThird,
}));
export const StepActived = M.styled(StepText)(() => ({
  color: colors.periwinkle,
  fontFamily: fonts.DemiBold,
}));
export const ButtonText = M.styled(Text)(() => ({
  color: colors.white,
  fontFamily: fonts.DemiBold,
  fontSize: 18,
}));
export const LabelStrong = styled(Text)(({theme}) => ({
  color: colors['greyish-brown'],
  fontFamily: fonts.DemiBold,
}));
export const FormControl = styled(Box)(({theme}) => ({
  margin: '4px 0px',
}));
export const TextOption = styled('i')(({theme}) => ({
  fontFamily: fonts.MediumItalic,
  fontSize: 13,
  color: colors.gray,
  marginLeft: '8px',
}));

const styles = theme => ({
  root: {
    height: 600,
    flexGrow: 1,
    minWidth: 600,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '16px 32px 24px',
    outline: 'none',
  },
  uploadDocument: {
    color: '#4a4a4a', 
    fontSize: '13px', 
    textTransform: 'none', 
    float: 'right',
    padding: '2px 15px'
  },
  mainBtn: {
    padding: '6px 10px',
    backgroundColor: '#887fff',
    color: '#fff',
    textTransform: 'none',
    minWidth: 150,
    "&:hover": {
      background: '#887fff',
    }
  },
  secondaryBtn: {
    padding: '6px 10px',
    backgroundColor: '#bec6d8',
    color: '#fff',
    textTransform: 'none',
    minWidth: 150,
    "&:hover": {
      background: '#bec6d8',
    }
  },
  errorBtn: {
    padding: '6px 10px',
    backgroundColor: '#d0021b',
    color: '#fff',
    textTransform: 'none',
    minWidth: 150,
    "&:hover": {
      background: '#d0021b',
    }
  },
  checkUsernameBtn: {
    padding: '6px 10px',
    backgroundColor: '#f4f4f4',
    border: 'solid 1px #cfcfcf',
    color: '#4a4a4a',
    textTransform: 'none',
    width: '150px',
    marginLeft: '10px',
    "&:hover": {
      background: '#f4f4f4',
    }
  },
  
  confirmDialog: {
    
  },
  confirmDialogTitle: {
    width: '600px',
    fontSize: '20px',
    color: '#4a4a4a',    
    paddingBottom: '40px',    
  },
  vehicleInfoText: {
    fontSize: '13px',
    paddingRight: '10px'
  }
});

  export default styles;    