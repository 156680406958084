import {observable, action, decorate} from 'mobx';
import _ from 'lodash';
import AssignmentStore from "./AssignmentStore";

export default class FormStore {
  data = {};
  error = {};
  errors = [];

  constructor(parentStore) {
    this.parentStore = parentStore;
  }
  
  handlerInput = (event) => {
    if (event.target && event.target.name && event.target.checked) {
      _.set(this.data, event.target.name, event.target.checked);
    } else if (event.target && event.target.name && event.target.value !== undefined) {
      const v = event.target.name.endsWith('variants') ? event.target.value.split(',') : event.target.value;
      _.set(this.data, event.target.name, v);
    }
  };
  
  handlerTextarea = (event) => {
    if (event.target && event.target.name && event.target.value !== undefined) {
      const v = event.target.name.endsWith('variants') ? event.target.value.split(',') : event.target.value;
      _.set(this.data, event.target.name, v);
    }
  };
  
  handlerCheckbox = (event) => {
    _.set(this.data, event.target.name, event.target.checked);
  };
  
  handlerCheckboxes = (event) => {
    const currentValue = this.getField(event.target.name, []);
    if (event.target.checked === true && !currentValue.includes(event.target.value)) {
      currentValue.push(event.target.value);
      _.set(this.data, event.target.name, currentValue);
    } else if (event.target.checked === false && currentValue.includes(event.target.value)) {
      _.remove(currentValue, function(str) {
        return str === event.target.value;
      });

      _.set(this.data, event.target.name, currentValue);
    }
  };
  
  handlerRating = (name) => (value) => {
    _.set(this.data, name, value);
  };
  
  handlerReactSelect = (name) => (option) => {
    this.data[name] = option.value;
  };
  
  handlerDateInput = (name) => (date) => {
    this.data[name] = date;
  };
  
  handlerTimestamp = (name) => (date) => {
    if (date) {
      this.data[name] = date.getTime();
    }
  };

  getField(name, defautValue) {
    return _.get(this.data, name, defautValue);
  }

  arrayContains(name, value) {
    return _.get(this.data, name, []).includes(value);
  }

  setField(name, defautValue) {
    return _.set(this.data, name, defautValue);
  }

  addError(error) {
    this.errors = [error];
  }

  getErrors() {
    return this.errors;
  }
}

decorate(FormStore, {
  parentStore: observable,
  data: observable,
  error: observable,
  errors: observable,
  handlerInput: action,
  handlerTextarea: action,
  handlerCheckbox: action,
  handlerCheckboxes: action,
  handlerRating: action,
  handlerReactSelect: action,
  handlerDateInput: action,
  // getField: action,
  // arrayContains: action,
  handlerTimestamp: action,
  // setField: action,
  // addError: action,
  // getErrors: action,
});
