import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';

import { saveAs } from 'file-saver';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Box,
  Tooltip,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardMedia,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { INBOUND_STATUS_COLOR_CODE, AxlModal } from 'axl-reactjs-ui';
import HistoryIcon from '@mui/icons-material/History';

import ShipmentPickupInfo from 'components/ShipmentPickupInfo';
import ShipmentDropoffInfo from 'components/ShipmentDropoffInfo';
import ShipmentNote from 'components/ShipmentNote';
import { ShipmentPODRequirement } from 'components/ShipmentPODRequirement';
import ShipmentFeedback from 'components/ShipmentFeedback';
import ShipmentTags from 'components/ShipmentTags';
import AxlButton from 'AxlMUIComponent/AxlButton';
import ShipmentHistoryInfo from 'components/ShipmentHistoryInfo';
import RerouteShipment from 'components/RerouteShipment';
import ShipmentDropoffDispatchForm from 'components/ShipmentDropoffDispatchForm';

import styles from './styles';
import acl from 'constants/acl';
import { images } from 'constants/images';

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: [],
      showDialog: false,
      showSplitRouteConfirm: false,
      showMoveShipmentConfirm: false,
      showImageUploadPreView: false,
      showShipmentHistory: false,
      fileUpload: null,
      imageUploadPreviewUrl: '',
      splitAssignmentLabel: '',
      selectedMoveAssignmentId: 0,
      loading: false,
      showShipmentTag: false,
      showShipmentPicture: false,
      showSignaturePicture: false,
      showShipmentFeedbackPanel: false,
      showCancelShipmentModal: false,
      cancellingShipment: false,
      cancelShipmentError: '',
      cancelShipmentReasonMsg: '',
      splitError: '',
      stopId: null,
    }
  }

  componentDidMount() {
    const { match, store } = this.props;
    const { stopId } = match.params;
    store.shipmentStore.loadStop(stopId);
    this.setState({stopId})
  }

  componentDidUpdate() {
    const { match, store } = this.props;
    const { stopId } = match.params;
    
    if (this.state.stopId === stopId) return
    
    this.setState({stopId})
    store.shipmentStore.loadStop(stopId);
  }

  openForm = (name) => () => {
    const formStateName = `show${name}Form`;
    this.setState({[formStateName]: true});
  };

  closeForm = (name) => () => {
    const formStateName = `show${name}Form`;
    this.setState({[formStateName]: false});
  };

  onOpenDialog = (data) => {
    this.setState({
      showDialog: data.length > 0,
      dialogData: data,
    })
  };

  onCloseDialog = () => {
    this.setState({
      showDialog: false,
      dialogData: [],
    });
  };

  onCloseUploadImage = () => {
    this.setState({
      showImageUploadPreView: false,
      fileUpload: null,
      imageUploadPreviewUrl: '',
    });
  };

  onUploadPreview = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        fileUpload: file,
        imageUploadPreviewUrl: reader.result
      })
    };

    reader.readAsDataURL(file)
  };

  addImage = (shipmentId) => () => {
    const {fileUpload} = this.state;
    const {shipmentStore} = this.props.store;
    shipmentStore.addImage(shipmentId, fileUpload, (res) => {
      this.setState({
        showImageUploadPreView: false,
        fileUpload: null,
        imageUploadPreviewUrl: '',
      });
    });
  };

  openMoveShipmentDialog = () => {
    this.setState({
      showMoveShipmentConfirm: true,
    })
  }

  closeMoveShipmentDialog = () => {
    this.setState({
      showMoveShipmentConfirm: false,
    })
  }

  moveShipment = (e) => {
    const {selectedMoveAssignmentId} = this.state;
    const {assignmentStore, shipmentStore} = this.props.store;
    const {selectedAssignment} = assignmentStore;
    const {selectedShipment} = shipmentStore;

    if (!selectedAssignment || !selectedShipment) return;
    this.setState({loading: true});
    assignmentStore.addShipment(selectedMoveAssignmentId, selectedShipment.id, (stops) => {
      assignmentStore.loadAssignments();
      this.closeMoveShipmentDialog();
      this.setState({loading: false});
    });
  };

  openSplitRouteConfirm = () => {
    const { assignmentStore } = this.props.store;
    const { selectedAssignment } = assignmentStore;
    const assignmentLabel = selectedAssignment && selectedAssignment.assignmentLabel ? selectedAssignment.assignmentLabel.prefix : (selectedAssignment.label || '');
    const assignmentLabelSuffix = assignmentLabel.substr(assignmentLabel.indexOf('-') + 1);

    this.setState({
      splitError: "",
      showSplitRouteConfirm: true,
      splitAssignmentLabel: assignmentLabelSuffix,
    });
  }

  closeSplitRouteConfirm = () => {
    this.setState({
      showSplitRouteConfirm: false,
    });
  }

  splitRoute = () => {
    const {splitAssignmentLabel} = this.state;
    const {store, history} = this.props;
    const {shipmentStore, assignmentStore} = store;
    const {selectedAssignment} = assignmentStore;
    const {selectedShipment} = shipmentStore;
    const assignmentLabel = selectedAssignment ? selectedAssignment?.assignment?.label : '';
    const assignmentLabelPrefix = assignmentLabel ? assignmentLabel.substr(0, assignmentLabel.indexOf('-') + 1) || '' : '';
    const newLabel = assignmentLabelPrefix + splitAssignmentLabel;

    const data = {
      assignment_label: newLabel,
      relabel_shipment: false,
    };

    this.setState({loading: true, splitError: ""});
    shipmentStore.splitRoute(selectedShipment.assignment_id, selectedShipment.id, data, (resp) => {
      if (resp.code === 400) {
        this.setState({
          splitError: resp.message ? resp.message : "split error",
          loading: false,
        });
        return
      }
      assignmentStore.appendSplitedAssignment(selectedShipment.assignment_id, resp);

      // process to new path
      const path = history.location.pathname;
      const newPath = path.replace(/[\d]+\/stops/ig, `${resp.id}/stops`);
      history.push(newPath);
      this.setState({
        showSplitRouteConfirm: false,
        splitAssignmentLabel: '',
        loading: false,
      });
    });
  }

  renderPrevArrow = (props) => {
    const {classes} = this.props;
    const {onClick} = props;
    return (
      <div className={clsx(classes.arrowButton, classes.arrowPrev)} onClick={onClick}>
        <i className={clsx(classes.arrowIcon, 'fa fa-angle-left')} />
      </div>
    );
  };

  renderNextArrow = (props) => {
    const {classes} = this.props;
    const {onClick} = props;
    return (
      <div className={clsx(classes.arrowButton, classes.arrowNext)} onClick={onClick}>
        <i className={clsx(classes.arrowIcon, 'fa fa-angle-right')} />
      </div>
    );
  };

  toggleFeedback = () => {
    this.setState({
      showShipmentTag: false,
      showShipmentFeedbackPanel: !this.state.showShipmentFeedbackPanel
    });
  }

  download = (format) => (e) => {
    const {store} = this.props;
    const {shipmentStore} = store;
    const { selectedStop } = shipmentStore;
    const { shipment } = selectedStop;
    if (!shipment || !selectedStop) return;
    shipmentStore.getLabel(shipment.id, format, (response) => {
      if (response.status === 200) {
        let contentType = 'text/plain';
        let fileName = `order-${shipment.id}-label.txt`;
        let blob;
        const data = response.data.label;
        if (format === 'PDF') {
          contentType = 'application/pdf';
          fileName = `order-${shipment.id}-label.pdf`;
          blob = this.b64toBlob(data, contentType);
        } else if (format === 'PNG') {
          contentType = 'image/png';
          fileName = `order-${shipment.id}-label.png`;
          blob = this.b64toBlob(data, contentType);
        } else {
          blob = new Blob([data], {type: "text/plain;charset=utf-8"});
        }

        saveAs(blob, fileName);
      }
    })
  };

  b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };

  toggleTags = () => {
    this.setState({
      showShipmentTag: !this.state.showShipmentTag,
      showShipmentFeedbackPanel: false
    })
  }

  render() {
    const {
      showShipmentFeedbackPanel, showShipmentTag,
      showDialog, dialogData, showImageUploadPreView, showShipmentHistory, splitAssignmentLabel,
      fileUpload, imageUploadPreviewUrl, showSplitRouteConfirm, loading, splitError
    } = this.state;
    const { store, classes, userStore } = this.props;
    const { shipmentStore, assignmentStore, permissionStore } = store;
    const { uploadingImage, selectedStop, selectedShipment } = shipmentStore;
    const { selectedAssignment, inProgressAssignments, pendingAssignments, unassignedAssignments, filteredShowingStops } = assignmentStore;

    if (!selectedStop || !selectedShipment) {
      return null;
    }

    const shipmentHistory = selectedShipment && selectedShipment.history ? selectedShipment.history : [];
    const { shipment, label, client, corresponding_stop } = selectedStop;
    const dropoff = selectedStop;
    const pickup = corresponding_stop;
    const inboundStatus = shipment.inbound_status || 'UNSCANNED';
    const inboundStatusColor = INBOUND_STATUS_COLOR_CODE[inboundStatus];
    const assignmentLabel = selectedAssignment ? selectedAssignment?.assignment?.label : '';
    const assignmentLabelPrefix = assignmentLabel ? assignmentLabel.substr(0, assignmentLabel.indexOf('-') + 1) || '' : '';

    const SliderNextArrow = this.renderNextArrow;
    const SliderPrevArrow = this.renderPrevArrow;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };
    const modalStyle = {
      width: '480px',
      display: 'block',
      overflow: 'initial',
      backgroundColor: 'transparent',
      margin: 'auto',
      boxShadow: 'none'
    };
    const isAssignmentCompleted = selectedAssignment?.assignment?.status === "COMPLETED";
    let isHideButton = false;
    if(isAssignmentCompleted) {
      isHideButton = true;
    } else if(dropoff && dropoff?.status ==='SUCCEEDED'){
      isHideButton = true;
    }
    const assignmentsData = [
      { 'status': 'UNASSIGNED', 'color': '#bebfc0', 'assignments': unassignedAssignments },
      { 'status': 'PENDING', 'color': '#fa6725', 'assignments': pendingAssignments },
      { 'status': 'IN PROGRESS', 'color': '#fbc04f', 'assignments': inProgressAssignments },
    ];
    
    const disableSplitBtn = !filteredShowingStops || filteredShowingStops.length === 0 || filteredShowingStops[0]?.id === selectedStop.id

    const invalidLabel = (label) => {
      if (!label) return true

      return /[^A-Z|0-9|-]/.test(label);
    }
    const disableConfirmBtn = loading || invalidLabel(this.state.splitAssignmentLabel)
    const isDeniedSplitRoute = permissionStore.isDenied(acl.ASSIGNMENTS.SPLIT_ROUTE);
    const isDeniedFeedback = permissionStore.isDenied(acl.ASSIGNMENTS.FEEDBACK);

    return (
      <Grid container justifyContent="space-between" direction="column" r className={classes.container}>
        <Grid item className={classes.shipmentDetail}>
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box className={classes.inboundInfo}>Inbound Status:</Box>
                    <Box className={classes.status} style={{color: inboundStatusColor}}>{inboundStatus.replace(/[_-]/g, " ")}</Box>
                  </Grid>
                  <Grid item style={{textAlign: 'center'}}>
                    <Box className={classes.name}>{ label ? label.driver_label : '' }: {shipment.customer ? shipment.customer.name : '-'}</Box>
                    <Box className={classes.company}><code className={classes.code}>{shipment.id}</code></Box>
                  </Grid>
                  <Grid item>
                    <AxlButton
                      icon
                      variant={'outlined'}
                      color={'primary.graySeventh'}
                      bgcolor={'primary.grayFourth'}
                      padding={'6px 8px'}
                      onClick={() => this.setState({showShipmentHistory: !showShipmentHistory})}>
                      <HistoryIcon style={{fontSize: 16, borderColor: '#0000003b'}} />
                    </AxlButton>
                  </Grid>
                </Grid>
              </Grid>
              {!showShipmentHistory && (
                <Fragment>
                  <Grid item>
                    <Grid container justifyContent="space-between" wrap={'nowrap'}>
                      <Grid item className={classes.email}>
                        <Box className={classes.label2}>{`Client Info:`}</Box>
                        <Box className={classes.company}>{ client ? client.company : '' }</Box>
                        <Box className={classes.company} style={{textDecoration: 'underline', wordBreak: 'break-all'}}>{shipment.internal_id || ''}</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ShipmentPODRequirement shipment={shipment} />
                    <ShipmentNote shipmentId={shipment.id} userId={userStore.user.id}/>
                    <ShipmentPickupInfo pickup={pickup} dropoff={dropoff} shipment={shipment} />
                    <ShipmentDropoffInfo pickup={pickup} dropoff={dropoff} shipment={shipment} openForm={this.openForm} />
                  </Grid>
                </Fragment>
              )}
              {showShipmentHistory && (
                <Box>
                  <ShipmentHistoryInfo shipment={shipment} shipmentHistory={shipmentHistory} isOpen />
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item className={classes.shipmentActions}>
          <Box p={1} className={classes.actionsWrapper}>
            <div className={classes.footerScroll}>
              {!isDeniedFeedback && (
                <Tooltip title="Feedback" aria-label="Feedback">
                  <Button disableElevation variant="outlined" onClick={this.toggleFeedback} size="small" className={classes.footerButton}>
                    {`Feedback`}
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Download full label" aria-label="Download full label">
                <Button disableElevation variant="outlined" onClick={this.download('PDF')} size="small" className={classes.footerButton}>
                  <CardMedia className={classes.footerBtnIcon} image={images.icon.label} />{`Full Label`}
                </Button>
              </Tooltip>
              {/* <Tooltip title="Customer support chat" aria-label="Customer support chat">
                <Button disableElevation variant="outlined" onClick={() => {}} size="small" className={classes.footerButton}>
                  <CardMedia className={classes.footerBtnIcon} image={images.icon.message} />{`Customer`}
                </Button>
              </Tooltip> */}
              <Tooltip title="Show shipment tags" aria-label="Show shipment tags">
                <Button disableElevation variant="outlined" onClick={this.toggleTags} size="small" className={classes.footerButton}>
                  <CardMedia className={classes.footerBtnIcon2} image={images.icon.tag} />
                </Button>
              </Tooltip>
              <Grid item xs/>
              {!isHideButton && <RerouteShipment
                selectedStop={selectedStop}
                assignments={assignmentsData}
                assignmentStore={assignmentStore}
                shipmentStore={shipmentStore}
                selectedShipment={selectedShipment}/>
              }
              {!isHideButton && !isDeniedSplitRoute && (
                <Tooltip title="Split route from this shipment" aria-label="Split route">
                  <Button disableElevation variant="outlined" disabled={disableSplitBtn} onClick={this.openSplitRouteConfirm} size="small" className={classes.footerButton}>
                    <CardMedia className={classes.footerBtnIcon2} image={images.icon.split} />
                  </Button>
                </Tooltip>
              )}
            </div>
            {(showShipmentFeedbackPanel || showShipmentTag) && <div className={classes.body}>
              {showShipmentFeedbackPanel && <ShipmentFeedback onClose={() => this.setState({showShipmentFeedbackPanel: false})} toggleFeedback={this.toggleFeedback} />}
              {showShipmentTag && <ShipmentTags shipment={selectedShipment} onClose={() => this.setState({showShipmentTag: false})} toggleTags={this.toggleTags} />}
            </div>}
            {showDialog && dialogData.length > 0 && (
              <AxlModal onClose={this.onCloseDialog}
                        style={modalStyle}
                        containerStyle={{display: 'flex'}}>
                <Box className={classes.wrapImages}>
                  {!!dialogData.length && (
                    <Slider {...settings}>
                      {dialogData.map((img, index) => (
                        <div style={{height: '100%'}} key={index}>
                          <img src={img.url || img.image} alt="Images" className={classes.imageShipment} />
                        </div>
                      ))}
                    </Slider>
                  )}
                </Box>
              </AxlModal>
            )}
            <Dialog open={showImageUploadPreView}
                    maxWidth="lg"
                    onClose={this.onCloseUploadImage}
                    PaperProps={{className: classes.dialogPaper}}>
              <DialogTitle className={classes.dialogTitle}>Upload POD</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Box py={2}>
                  {!imageUploadPreviewUrl && "Please select image to upload"}
                  {!!imageUploadPreviewUrl && <img style={{display: 'block', width: '100%'}} src={imageUploadPreviewUrl} alt="POD Preview" />}
                </Box>
                <Box>
                  <input type="file" accept="image/*"
                         style={{display: 'none'}}
                         id="upload-pod"
                         onChange={this.onUploadPreview}
                  />
                  <label htmlFor="upload-pod">
                    <AxlButton
                      variant="contained"
                      color="primary.periwinkle"
                      component="span" disableElevation>{`Choose image`}</AxlButton>
                  </label>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="secondary" disableElevation onClick={this.onCloseUploadImage}>{`Cancel`}</Button>
                <Box className={classes.buttonWrapper}>
                  <AxlButton
                    variant="contained"
                    bgcolor="primary.periwinkle"
                    disableElevation
                    disabled={!fileUpload || !!uploadingImage}
                    onClick={this.addImage(shipment.id)}>{`Upload`}</AxlButton>
                  {!!uploadingImage && <CircularProgress color="primary" size={30} className={classes.buttonLoading} />}
                </Box>
              </DialogActions>
            </Dialog>

            <Dialog
              open={showSplitRouteConfirm}
              maxWidth="md"
              onClose={this.closeSplitRouteConfirm}
              PaperProps={{className: classes.dialogPaper}}>
              <DialogTitle className={classes.dialogTitle}>Split Route</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Box py={2}>
                  Do you want to split assignment <strong>{assignmentLabel}</strong> from shipment <strong>{label ? label.driver_label: ''}</strong>?
                </Box>
                <Box py={2}>
                  <TextField variant="outlined"
                             fullWidth
                             label="New Assignment Label"
                             value={splitAssignmentLabel}
                             onChange={e => this.setState({splitAssignmentLabel: e.target.value.toUpperCase()})}
                             InputProps={{
                               startAdornment: <InputAdornment position="start">{assignmentLabelPrefix}</InputAdornment>,
                             }}
                             inputProps={{ maxLength: assignmentLabelPrefix ? 3 : 10 }}
                  />
                </Box>
                <Box py={2}>
                  <strong>NOTE</strong>: We provide the ability to adjust and reconfigure routes as an advanced optional feature for our DSP partners.
                  Use of this feature cannot be used to increase compensation.
                </Box>
                <Typography component={'p'} style={{color: "#dc3545"}}>{splitError}</Typography>
              </DialogContent>
              <DialogActions>
                <AxlButton
                  variant="outlined"
                  bgcolor={'primary.white'}
                  color="primary.graySeventh"
                  disableElevation
                  className={classes.btnControl}
                  spacing={0.5}
                  onClick={this.closeSplitRouteConfirm}>{`Cancel`}</AxlButton>
                <Box className={classes.buttonWrapper}>
                  <AxlButton
                    variant="contained"
                    bgcolor="primary.periwinkle"
                    color="primary.white"
                    opacity={disableConfirmBtn ? 0.5 : 1}
                    disableElevation
                    disabled={disableConfirmBtn}
                    spacing={0.5}
                    className={classes.btnControl}
                    onClick={this.splitRoute}>{`Confirm`}</AxlButton>
                  {loading && <CircularProgress color="primary" size={30} className={classes.buttonLoading} />}
                </Box>
              </DialogActions>
            </Dialog>
            {this.state.showAccessCodeForm && <AxlModal style={{padding: 15}} onClose={this.closeForm('AccessCode')}>
              <ShipmentDropoffDispatchForm shipment={shipment} pickup={pickup} dropoff={dropoff} closeForm={this.closeForm('AccessCode')} />
            </AxlModal>}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles), withRouter, inject("store", "userStore"), observer)(ShipmentDetail);
