import React, { useState } from 'react';
import { IconButton, Box, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Checkbox, Radio, StyledEngineProvider } from '@mui/material';
import _ from 'lodash';
import { ThemeProvider } from '@mui/material/styles';

import { mainTheme } from './themes';
import useStyles, * as S from './styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AxlInput from '../AxlInput';

export default function AxlMUISelect({
  theme = 'main',
  single = false,
  showAll = false,
  onChange = () => {},
  placeholder = 'Select value',
  searchPlaceholder = 'Search here...',
  placeholderSize = 14,
  ...props
}) {
  // style
  const classes = useStyles();
  // global
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [options, setOptions] = useState(props.options || []);
  // Defined
  const _isValues = options.filter(option => _.includes(checkedValues, option.value)).map(op => op.label);
  const _isShow = showAll ? _isValues.join(", ") : `${_isValues.length} selected`;

  function handleCheck(checkedName) {
    let newNames = [];
    if(single) {
      newNames = [checkedName];
    } else {
      newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter(name => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];
    }
    setCheckedValues(newNames);
    if(onChange)
      onChange(newNames);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onSearch = ({ target: { value } }) => {
    const search = _.debounce((v) => {
      let newItems = props.options;
      if(v) {
        newItems = _.filter(options, (option) => _.includes(option.label.toLowerCase(), v.trim().toLowerCase()));
      setOptions(newItems);      }

  }, 300);

    search(value);
  };

  const clearValues = () => {
    setCheckedValues([]);
    setOptions(props.options);
    onChange([])
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <div className={classes.root}>
          <S.SelectButtonContainer
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <S.SelectButton fontSize={placeholderSize}>
              {(checkedValues.length && options.length) ? _isShow : placeholder}
            </S.SelectButton>
            <ArrowDropUpIcon style={{display: !open ? 'none' : 'block'}} color="primary"/>
            <ArrowDropDownIcon style={{display: open ? 'none' : 'block'}} color="primary" />
          </S.SelectButtonContainer>
          <IconButton className={classes.clearButton} onClick={clearValues} size="small">
            <S.ClearButton fontSize={'small'} />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} className={classes.menuList}>
                      <Box>
                        <AxlInput onChange={onSearch} placeholder={searchPlaceholder} />
                      </Box>
                      <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        {options.map((option, idx) => <MenuItem
                          key={idx} className={classes.listItem}
                          onClick={() => handleCheck(option.value)}>
                          {
                            !single ? <Checkbox
                              key={idx}
                              value={option.value}
                              checked={_.includes(checkedValues, option.value)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            /> : <Radio
                              checked={_.includes(checkedValues, option.value)}
                              value={option.value}
                            />
                          }
                          {option.label}
                        </MenuItem>)
                        }
                      </div>
                    </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
