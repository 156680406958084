import {styled, Typography, Box} from "@mui/material";
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";

export const Text         = styled(Typography)(({theme, spacing}) => ({
  fontFamily: fonts.Medium,
  color: colors.blackSecondary,
  fontSize: 14,
}));
export const ModalTitle   = styled(Text)(({theme, spacing}) => ({
  fontFamily: fonts.Bold,
  color: colors['greyish-brown'],
  fontSize: 20,
}));
export const Label        = styled(Text)(({theme, spacing}) => ({
  fontFamily: fonts.Bold,
  color: colors.black35,
  fontSize: 16,
  fontWeight: 'bold',
}));
export const Label_1      = styled(Label)(({theme, spacing}) => ({
  fontFamily: fonts.Medium,
  color: colors.black35,
  fontSize: 17,
}));
export const ViewText     = styled('span')(({theme, spacing}) => ({
  color: colors.link,
  fontSize: 16,
  textDecoration: 'underline',
  margin: '0 10px',
  cursor: 'pointer',
}));
export const RemoveText   = styled('span')(({theme, spacing}) => ({
  color: colors.yellowDark,
  fontSize: 16,
  textDecoration: 'underline',
  margin: '0 10px',
  cursor: 'pointer',
}));
export const DropZoneContainer  = styled(Box)(({theme, spacing}) => ({
  padding: 20,
  border: '1px dotted #888',
  borderRadius: 3,
  textAlign: 'center',
  margin: '20px 0',
}));