import React, { Fragment } from "react";

import { Box, Dialog, DialogContent, DialogTitle, Grid, TextField, Tooltip } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { AxlTable } from "axl-reactjs-ui";
import produce from "immer";
import { inject, observer } from "mobx-react";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AxlButton from "../../../AxlMUIComponent/AxlButton";
import { UNSUCCESS_STATUSES } from "../../../constants/paymentStatus";
import { TOOLTIP_STATUS } from "../../../constants/status";
import { updateBankInfo } from "../../../stores/api";
import * as E from "../EventList/styles";
import { StyledLabel } from "../styles";
import ErrorReason from "./ErrorReason";
import LabelDateValue from "./LabelDateValue";
import LabelValue from "./LabelValue";
import { InnerPanel } from "./styles";

const headerStyle = { backgroundColor: "#d6d3ff", color: "#5a5a5a", fontWeight: 900, whiteSpace: "nowrap", fontSize: 10, padding: 5 };
const cellStyle = { fontSize: 10, padding: 5 };

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPaymentDetail: 0,
      isOpenPopup: false,
      accountData: {
        bank_account: "",
        bank_routing: "",
      },
    };
  }

  renderRepaidPayments = (paymentDetails) => {
    const { selectedPaymentDetail } = this.state;
    return (
      <E.Table>
        <AxlTable>
          <AxlTable.Header>
            <AxlTable.Row>
              <AxlTable.HeaderCell style={headerStyle}>Repaid On</AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>Status</AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>Repaid By</AxlTable.HeaderCell>
            </AxlTable.Row>
          </AxlTable.Header>
          <AxlTable.Body>
            {paymentDetails.supplement_payments.map((supplementPayment) => {
              let IconTag = VisibilityIcon;
              if (selectedPaymentDetail === supplementPayment.id) {
                IconTag = VisibilityOffIcon;
              }
              return (
                <Fragment key={supplementPayment.id}>
                  <AxlTable.Row>
                    <AxlTable.Cell style={cellStyle}>
                      <Moment interval={0} format="YYYY-MM-DD HH:mm">
                        {supplementPayment._created}
                      </Moment>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={cellStyle}>
                      <Box mr={1} component="span">
                        {supplementPayment.status}
                      </Box>
                      {supplementPayment.requestErrorContent && (
                        <IconTag
                          style={{ verticalAlign: "text-bottom", cursor: "pointer", fontSize: "0.9rem" }}
                          color="primary"
                          fontSize="small"
                          onClick={() => this.setState({ selectedPaymentDetail: supplementPayment.id === selectedPaymentDetail ? null : supplementPayment.id })}
                        />
                      )}
                    </AxlTable.Cell>
                    <AxlTable.Cell style={cellStyle}>{supplementPayment.payer_username}</AxlTable.Cell>
                  </AxlTable.Row>
                  {selectedPaymentDetail === supplementPayment.id && (
                    <AxlTable.Row>
                      <AxlTable.Cell colSpan={3} style={{ backgroundColor: "#f5f5f5", padding: 5 }}>
                        <Box m={1} p={1} style={{ backgroundColor: "#fff" }}>
                          {supplementPayment.requestErrorContent}
                        </Box>
                      </AxlTable.Cell>
                    </AxlTable.Row>
                  )}
                </Fragment>
              );
            })}
          </AxlTable.Body>
        </AxlTable>
      </E.Table>
    );
  };
  onChangeValue = (event, field) => {
    if (event) {
      event.preventDefault();
      const newData = produce(this.state.accountData, (draft) => {
        draft[field] = event.target.value;
      });
      this.setState({ accountData: newData });
    }
  };
  isOpenPopup = (data) => {
    this.setState({ isOpenPopup: data });
  };
  updateBankInfo = (id) => {
    if (id && this.state.accountData.bank_account !== "") {
      updateBankInfo(id, this.state.accountData).then((resp) => {
        if(resp.ok) {
          this.props.paymentStore.getDspPaymentDetails(id);
          this.isOpenPopup(false);
        }
      });
    }
  };

  getTooltipStatus = (status) => {
    return TOOLTIP_STATUS[status] || 'No status description available';
  }

  render() {
    const { paymentDetails } = this.props;

    if (!paymentDetails) {
      return <span>Loading...</span>;
    }

    const { supplement_payments } = paymentDetails;

    return (
      <Grid container spacing={2} direction="column" style={{ backgroundColor: "#fff", marginTop: 0, marginBottom: 0 }}>
        <Grid item>
          <StyledLabel>Transaction Details</StyledLabel>
        </Grid>
        <Grid item>
          <InnerPanel>
            <Grid container direction="column" spacing={1}>
              <Box display={'flex'} alignItems={'center'} style={{gap: 8}} pt={1} pl={0.5}>
                <LabelValue status={true} label={"STATUS"} value={paymentDetails.status} />
                <Tooltip title={this.getTooltipStatus(paymentDetails.status)}>
                  <InfoOutlinedIcon fontSize="small" style={{fontSize: 15, color: '#9b9b9b'}}/>
                </Tooltip>
              </Box>
              {UNSUCCESS_STATUSES.includes(paymentDetails.status) && <ErrorReason value={paymentDetails.error_description ? paymentDetails.error_description : paymentDetails.request_error_content} />}
              <LabelDateValue label={"ISSUED DATE"} value={paymentDetails.created_date} />
              <LabelDateValue label={"LAST UPDATE"} value={paymentDetails.last_updated_date} />
              <LabelValue label={"METHOD"} value={paymentDetails.method} />
              <LabelValue label={"CYCLE TYPE"} value={paymentDetails.type} />
              {/* <LabelValue label={"RUN BY"} value={"worker"} /> */}
            </Grid>
          </InnerPanel>
          <InnerPanel style={{ marginTop: 10 }}>
            <Grid container direction="column" spacing={2}>
              <LabelValue label={"BANK ACCOUNT"} value={paymentDetails.account_number} />
              <LabelValue label={"BANK ROUTING"} value={paymentDetails.routing_number} />
            </Grid>
            {paymentDetails.status !== 'SUCCEEDED' && <Grid style={{ marginTop: 10, textAlign: "center" }}>
              <AxlButton
                spacing={0}
                bgcolor={"primary.periwinkle"}
                color={"primary.white"}
                onClick={() => {
                  this.isOpenPopup(true);
                  this.setState({
                    accountData: {
                      bank_account: paymentDetails.account_number,
                      bank_routing: paymentDetails.routing_number,
                    },
                  });
                }}
              >{`Update`}</AxlButton>
            </Grid>}
          </InnerPanel>
        </Grid>
        <Dialog fullWidth open={this.state.isOpenPopup} onClose={() => this.isOpenPopup(false)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Update Bank Account</DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            <TextField
              style={{ marginTop: 10 }}
              onChange={(e) => this.onChangeValue(e, "bank_account")}
              value={this.state.accountData["bank_account"]}
              variant="outlined"
              label="Bank Account"
              size="small"
              fullWidth
              type="number"
            />
            <TextField
              style={{ marginTop: 10 }}
              onChange={(e) => this.onChangeValue(e, "bank_routing")}
              value={this.state.accountData["bank_routing"]}
              variant="outlined"
              label="Bank Routing"
              size="small"
              fullWidth
              type="number"
            />
            <AxlButton style={{ marginTop: 10 }} spacing={0} bgcolor={"primary.periwinkle"} color={"primary.white"} onClick={() => this.updateBankInfo(paymentDetails.id)}>{`Save`}</AxlButton>
          </DialogContent>
        </Dialog>
        <Grid item>{/* {dsp && supplement_payments && supplement_payments.length > 0 && this.renderRepaidPayments(paymentDetails)} */}</Grid>
      </Grid>
    );
  }
}

export default compose(withRouter, inject("paymentStore"), observer)(TransactionDetails);
