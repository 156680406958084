import {decorate} from 'mobx';
import ObjectListStore from "./ObjectList";

class SearchDspPaymentStore extends ObjectListStore{
  DEFAULT= {
    baseUrl: "/dsp-payments",
    fields: [
      {name: "id", orderField: "id", label: "Payment ID"},
      {name: "amount", orderField: "amount", label: "Amount"},
      {name: "status", orderField: "status", label: "Status"},
      {name: "type", label: "Cycle Type"},
      {name: "_created", label: "Issued Date"},
      {name: "_updated", label: "Last Update"},
      {name: "memo", label: "Memo"},
    ],
    filters: {
      page: 1,
      size: 10,
      order_by: "id",
      desc: true
    },
    result: {
      count: 0,
      items: [],
      total_pages: 0
    },
    listField: "paymentDetails",
    idField: "id"
  }

  constructor(api) {
    super(api);
    this.api = api;
    this.setData(this.DEFAULT);
    this.paymentDetails = null;
  }
}

decorate(SearchDspPaymentStore, {});

export default SearchDspPaymentStore;
