import React, { Component } from 'react';
import styles from './styles';
import _ from 'lodash';
import { AxlInput, AxlPanel, AxlReselect, AxlDateInput, AxlCheckbox, AxlButton } from 'axl-reactjs-ui';
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class ShipmentDropoffDispatchForm extends Component {
  save = (e) => {
    const { shipment, closeForm, store} = this.props;
    const {shipmentStore} = store;
    shipmentStore.updateDropoffLocationInfo(shipment, () => {
      closeForm()
    });
  };

  componentDidMount() {
    const { store, shipment } = this.props;
    const { shipmentDropoffForm } = store.shipmentStore;
    // initial field first load
    shipmentDropoffForm.setField('dropoff_access_code', _.get(shipment, 'dropoff_access_code', ''));
    shipmentDropoffForm.setField('dropoff_additional_instruction', _.get(shipment, 'dropoff_additional_instruction', ''));
  }

  render() {
    const { store } = this.props;
    const { shipmentDropoffForm } = store.shipmentStore;

    return <div style={styles.Container}>
      <div style={styles.HeaderTitle}>{`Override`}</div>
      <AxlPanel>
        <AxlPanel.Row>
          <AxlPanel.Col style={styles.GroupPanel}>
            <AxlPanel.Row align={`center`}>
              <AxlPanel.Col>
                <div style={styles.GroupTitle}>{`Dropoff Access Code`}</div>
                <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('dropoff_access_code', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`e.g: #1234`} name={`dropoff_access_code`} type={`text`} fluid /></div>
                <div style={styles.GroupTitle}>{`Dropoff Additional Instruction`}</div>
                <div style={styles.Field}><AxlInput value={shipmentDropoffForm.getField('dropoff_additional_instruction', '')} onChange={shipmentDropoffForm.handlerInput} placeholder={`e.g: Baby inside, do not ring the bell`} name={`dropoff_additional_instruction`} type={`text`} fluid /></div>
              </AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
        <AxlPanel.Row>
          <AxlPanel.Col>
            <AxlPanel.Row align={`flex-end`}>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`gray`} onClick={this.props.closeForm} style={styles.buttonControl}>{`Cancel`}</AxlButton></div>
              <div style={{...styles.Field, ...styles.FieldButton}}><AxlButton compact bg={`pink`} onClick={this.save} style={styles.buttonControl}>{`Save`}</AxlButton></div>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>
      </AxlPanel>
    </div>
  }
}
export default compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentDropoffDispatchForm);
