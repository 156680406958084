import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { useRecoilState } from 'recoil';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Box, Grid } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';

import AxlButton from 'AxlMUIComponent/AxlButton';
import AxlTable from 'AxlMUIComponent/AxlTable';
import AxlModal from 'AxlMUIComponent/AxlModal';
import AxlConfirmation from 'AxlMUIComponent/AxlConfirmation';
import VehicleAddNew from '../VehicleAddNew';
import VehicleDocument from 'components/VehicleDocument';

import schema from './schema';
import acl from 'constants/acl';
import { getVehicles, vehicleRemove } from 'repositories';
import { vehicleState, vehicleResultState } from './state';

function VehiclesListCompose(props) {
  const { theme = 'main', type = 'DEFAULT', store } = props;

  const [query, setQuery] = useRecoilState(vehicleState);
  const [result, setResult] = useRecoilState(vehicleResultState);

  const [removing, setRemoving] = useState(null);
  const [isDeleteClose, setDeleteClose] = useState(false);
  const [isEditClose, setEditClose] = useState(false);
  const [isDocumentClose, setDocumentClose] = useState(false);

  const { fields } = schema[type];

  const { permissionStore } = store;
  const isDeniedEdit = permissionStore.isDenied(acl.VEHICLES.EDIT);
  const isDeniedDelete = permissionStore.isDenied(acl.VEHICLES.DELETE);

  const getVehicleList = useCallback(() => {
    getVehicles(query).then((res) => {
      if (res.ok && res.status === 200) setResult({ items: res.data.results, size: 10, count: res.data.total_records });
    });
  }, [query, setResult]);

  useEffect(() => {
    getVehicleList();
  }, [isEditClose, getVehicleList]);

  const removeVehicle = (id) => {
    setRemoving(id);
    vehicleRemove(id).then((res) => {
      if (res.ok && res.status === 204) {
        onChange({}, 1);
        setDeleteClose(true);
      }
      setRemoving(false);
    });
  };

  const objectRestructured = (obj) => {
    return _.merge(
      _.pick(obj, [
        'id',
        'color',
        'insurance',
        'registration',
        'vehicle_name',
        'license_plate',
        'license_state',
        'registration_record_issued_ts',
        'registration_record_expired_ts',
        'insurance_card_url',
        'registration_record_url',
        'insurance_card_issued_ts',
        'insurance_card_expired_ts',
      ]),
      _.pick(_.get(obj, 'car'), ['year', 'make', 'model', 'submodel', 'type']),
    );
  };

  const rerender = {
    vehicle_info: (value, item, result) =>
      `${_.get(item, 'car.year', '')} ${_.get(item, 'car.make', '')} ${_.get(item, 'car.model', '')} ${_.get(item, 'car.submodel', '')}`,
    vehicle_type: (value, item, result) => `${_.get(item, 'car.type', '')}`,
    action: (fieldName, item, result) => (
      <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
        {(item['insurance_card_url'] || item['registration_record_url']) && (
          <AxlModal
            isClose={isDocumentClose}
            onRendered={() => setDocumentClose(false)}
            trigger={
              <AxlButton icon spacing={0} padding={0} tooltip={{ title: 'documents' }}>
                <DescriptionIcon
                  fontSize={'small'}
                  style={item['insurance_card_expired'] || item['registration_record_expired'] ? { color: 'red' } : {}}
                />
              </AxlButton>
            }
          >
            <VehicleDocument item={item} />
          </AxlModal>
        )}
        {!isDeniedEdit && (
          <AxlModal
            isClose={isEditClose}
            onRendered={() => setEditClose(false)}
            trigger={
              <AxlButton icon spacing={0} padding={0} tooltip={{ title: 'edit' }}>
                <EditIcon fontSize={'small'} />
              </AxlButton>
            }
          >
            <VehicleAddNew onClose={() => setEditClose(true)} data={objectRestructured(item)} />
          </AxlModal>
        )}
        {!isDeniedDelete && (
          <AxlModal
            isClose={isDeleteClose}
            onRendered={() => setDeleteClose(false)}
            bgcolor={'primary.white'}
            trigger={
              <AxlButton icon spacing={0} padding={0} tooltip={{ title: 'remove' }} disabled={removing === item.id} loading={removing === item.id}>
                <DeleteIcon fontSize={'small'} />
              </AxlButton>
            }
          >
            <AxlConfirmation
              title={`Are you sure to delete vehicle [${_.get(item, 'vehicle_name', 'N/A Name')}] ${_.get(item, 'car.year', '')} ${_.get(item, 'car.make', '')} ${_.get(item, 'car.model', '')} ${_.get(item, 'car.submodel', '')}?`}
              doText={`Delete`}
              loading={removing}
              disabled={removing}
              onClose={() => setDeleteClose(true)}
              onDo={() => removeVehicle(item.id)}
              bgcolor={'primary.white'}
              doProps={{ bgcolor: 'primary.periwinkle', color: 'primary.white' }}
            />
          </AxlModal>
        )}
      </Grid>
    ),
  };

  const onChange = (object, number) => {
    const _query = _.assign({}, query, { page_number: number - 1 });
    setQuery(_query);
  };

  return (
    <Box>
      {result && <AxlTable theme={theme} page={query.page_number + 1} result={result} fields={fields} rerender={rerender} onChange={onChange} />}
    </Box>
  );
}

export default compose(withRouter, inject('store'), observer)(VehiclesListCompose);
