import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import { Box, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {AxlSearchBox, AxlMiniAssignmentBox, AxlMiniGroupAssignments} from "axl-reactjs-ui";
import moment from "moment";
import clsx from "clsx";

import styles from "./styles";

class AssignmentList extends Component {
  constructor(props) {
    super(props);
  }

  onInlineFilter = (value) => {
    const { assignmentStore } = this.props.store;
    assignmentStore.setInlineFilter(value)
  };

  selectAssignment = (a) => {
    const { store, history } = this.props;
    const {assignmentStore, shipmentStore} = store;
    const { selectedAssignment = {}, date } = assignmentStore;
    const assignment = selectedAssignment ? selectedAssignment.assignment : null;
    const region = assignmentStore.regions.length > 0 ? assignmentStore.regions[0] : 'all';

    if (a && (!assignment || a.id !== assignment.id)) {
      history.push(`/routes/${date}/${region}/${a.id}`);
      assignmentStore.selectAssignment(a);
    } else {
      history.push(`/routes/${date}/${region}`);
      assignmentStore.selectAssignment(null);
    }

    shipmentStore.unselectStop();
  };

  render() {
    const {classes, store} = this.props;
    const {assignmentStore} = store;
    const {
      selectedAssignmentId, completedAssignments,
      inProgressAssignments, pendingAssignments, unassignedAssignments,
    } = assignmentStore;
    const axlMiniAssignmentBox = [
      { 'status': 'UNASSIGNED', 'color': '#bebfc0', 'assignments': unassignedAssignments },
      { 'status': 'PENDING', 'color': '#fa6725', 'assignments': pendingAssignments },
      { 'status': 'IN PROGRESS', 'color': '#fbc04f', 'assignments': inProgressAssignments },
      { 'status': 'COMPLETED', 'color': '#4abc4e', 'assignments': completedAssignments }
    ];

    return (
      <Grid container className={classes.container}>
        <Grid item className={classes.gridItem}>
          <Box p={1} pb={0}>
            <AxlSearchBox
              theme="periwinkle"
              placeholder="Search Assignment"
              defaultValue={assignmentStore.inlineFilter}
              style={{width: '100%'}}
              onChange={this.onInlineFilter}
            />
          </Box>
        </Grid>
        <Grid item className={clsx(classes.gridItem, classes.assignmentList)}>
          {axlMiniAssignmentBox.map((assignments, i) => (
            <AxlMiniGroupAssignments
              key={i}
              length={assignments.assignments.length}
              color={assignments.color}
              text={assignments.status}
              style={{width: '100%'}}
            >
              {assignments.assignments.map(a => {
                const assignmentTags = a.aggregated_tags ? a.aggregated_tags.map((tag, i) => {
                  return {
                    text: tag.toLowerCase() === 'commercial' ? <span style={styles.text}><i className="fa fa-clock-o" style={{marginRight: '5px'}}/>{` Commercial`}</span> : <span style={styles.text}>{`${tag}`}</span>,
                    value: i
                  };
                }) : [];

                return (
                  <AxlMiniAssignmentBox
                    key={a.id}
                    tags={assignmentTags}
                    onClick={() => this.selectAssignment(a)}
                    assignment={a}
                    status={assignments.status}
                    style={{opacity: !selectedAssignmentId || selectedAssignmentId === a.id ? 1.0 : 0.5 }}
                  />
                )
              })}
            </AxlMiniGroupAssignments>
          ))}
        </Grid>
      </Grid>
    );
  }
}

const AssignmentListCompose = compose(
  inject("store"),
  observer
) (AssignmentList);

export default withStyles(styles)(withRouter(AssignmentListCompose));