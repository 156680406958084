import React, { useState } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material';

import logo from 'logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 0,
    backgroundColor: '#f0f0f0',
  },
  topAppBar: {
    paddingBottom: 0,
    minHeight: '52px',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      paddingLeft: '10px',
    },
    fontSize: '20px',
    fontFamily: 'AvenirNext-Medium',
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  label: {
    fontFamily: 'AvenirNext',
    fontWeight: 400,
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuItem: {
    textDecoration: 'none',
    color: 'inherit',
    minWidth: 150,
  },
}));

function Header(props) {
  const classes = useStyles();
  const { store } = props;
  const { userStore } = store;

  const { user, account } = userStore;

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?logout=true&next=${encodeURI(window.location.href)}`;
  };

  return (
    <div className={classes.root}>
      <AppBar color="transparent" position="static">
        <Toolbar className={classes.topAppBar}>
          <IconButton color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton>
          <Box>
            <Link to={'/'}>
              <img alt="Jitsu logo" height="40px" src={logo} />
            </Link>
          </Box>

          <Typography className={classes.title} variant="h6" color="inherit" noWrap>
            DSP Portal
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="secondary"
              endIcon={<AccountCircle />}
              classes={{ colorSecondary: classes.label }}
            >
              <span style={{ marginRight: 5, fontSize: 16 }}>{user ? user.name : 'Anonymous'}</span>
              {account && <span style={{ marginRight: 5, fontSize: 12 }}>(@ {account.company})</span>}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem className={classes.menuItem} component={Link} to="/profile" onClick={handleMenuClose}>
          Profile
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={signOut}>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
}

export default compose(inject('store'), observer)(Header);
