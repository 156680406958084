import React, {Component} from 'react';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import withStyles from '@mui/styles/withStyles';
import styles from "../styles";
import AssignmentChart from "../../../../components/AssignmentChart";

class AssignmentsStats extends Component {
  render() {
    const { assignmentStore } = this.props.store;
    const { date, assignmentShipmentStats } = assignmentStore;

    return <AssignmentChart date={date}
                            stats={assignmentShipmentStats}
                            onCollapse={this.props.onCollapse} />
  }
}

const AssignmentsStatsCompose = compose(
  inject("store"),
  observer
) (AssignmentsStats);

export default withStyles(styles)(AssignmentsStatsCompose);