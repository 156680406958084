import React, {Component} from "react";
import {AxlButton, AxlModal, AxlPanel, AxlTable} from "axl-reactjs-ui";
import {Box, Grid, Button, LinearProgress, Container} from "@mui/material";
import { inject, observer } from "mobx-react";
import EventList from "../EventList";
import TransactionDetails from "../TransactionDetails";
import { compose } from "recompose";
import {saveAs} from "file-saver";

class DspTransactionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    const {paymentStore, dsp} = this.props;
    paymentStore.getDspPaymentDetails(this.props.match.params.paymentId);
  }
  downloadPaymentCSV = () => {
    if (this.state.downloadingCSV) return
    const { paymentStore, match } = this.props
    const { params } = match || {}
    const { paymentId } = params || {}
    this.setState({downloadingCSV: true})
    paymentStore.getDspPaymentCSV(paymentId).then(csv => {
      this.setState({downloadingCSV: false})
      if (!csv) return;
        const filename = `${paymentId}.csv`;
        const blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
        saveAs(blob, filename);
      })
  }

  render() {
    const {paymentStore} = this.props;
    const {repayStatus, paymentDetails} = paymentStore;

    return (
        <Container maxwidth={false}>
          <AxlPanel.Row>
            <AxlPanel.Col flex={1} style={{marginRight: "10px"}}>
              <TransactionDetails dsp paymentDetails={paymentDetails} />
              <AxlButton
                tiny
                onClick={this.downloadPaymentCSV}
              >
                Download CSV
              </AxlButton>

            </AxlPanel.Col>
            <AxlPanel.Col flex={3} style={{marginLeft: "10px"}}>
              <EventList pagination paymentDetails={paymentDetails} repayStatus={repayStatus}/>
            </AxlPanel.Col>
          </AxlPanel.Row>
        </Container>
    )
  }
}

export default compose(
  inject('paymentStore'),
  observer,
)(DspTransactionInfo);
