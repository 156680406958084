import validate from 'validate.js';
import moment from "moment";

export const RULES = {
    notEmpty: {
        presence: {allowEmpty: false}
    },
    email: {
        email: true
    },
    integer: {
        numericality: {strict: true}
    },
    phone: {
        presence: true,
        format: {
            pattern: /\+1\s\([\d]{3}\)\s[\d]{3}\-[\d]{4}/,
            message: function(value, attribute, validatorOptions, attributes, globalOptions) {
                return validate.format("^%{num} is not a valid phone number. Format is +1 (xxx) xxx-xxxx", {
                    num: value
                });
            }
        }
    },
    number :{
        numericality: {onlyInteger: true}
    },
    birthday: {
        datetime: {
            dateOnly: true,
            latest: moment.utc().subtract(18, 'years'),
            message: "^You need to be at least 18 years old"
        }
    }
};

