const styles = theme => ({
  dialogPaper: {
    padding: '24px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  dialogContent: {
    margin: '8px 0px',
    color: '#bfbfbf',
    padding: '8px 0px',
  },
  dialogButton: {
    padding: '4px 48px',
  },
  btnControl: {
    minWidth: '120px !important',
  }
});

export default styles;