import styled from "styled-components";
import Moment from "react-moment";

export const MomentValue = styled(Moment)`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #4a4a4a;
`;
