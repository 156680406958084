import React, { Component } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { inject, observer } from "mobx-react";
import moment from "moment-timezone";
import { compose } from "recompose";
import validate from 'validate.js';

import AxlDatePicker from '../../AxlMUIComponent/AxlDatePicker';
import AxlFileUpload from "../../AxlMUIComponent/AxlFileUpload";
import AxlSelect from "../../AxlMUIComponent/AxlSelect";
import DriverStepper from '../../components/drivers/DriverStepper';
import TextInput from '../../components/input/TextInput';
import { REGIONS_STATE } from "../../constants/regions";
import { RULES } from '../../constants/rules';
import { DEFAULT_VEHICLE_TYPE } from "../../constants/vehicleType";
import { getCars } from "../../repositories";
import styles, * as S from './add-styles';

class AddDriverVehicle extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      screen: 1,
      items: null,
      query: null,
      dataError: {},
      initDataError: {},
      confirmation: false
    }    
  }

  componentDidMount() {
    const {store, history} = this.props; 
    if (store.driverStore.step < 1) {
      history.push('/drivers/add-account-info');
    }

    // get list when comeback
    if (this.getValue('vehicle_year')) {
      getCars({'year': this.getValue('vehicle_year')}).then(res => {
        if(res.ok && res.status === 200) {
          this.setState({items: res.data});
        } else {
          alert('Something is wrong in your vehicle data.')
        }
      });

    }

    // Check step
    if (store.driverStore.step < 2 && store.driverStore.step > 1) {
      this.setState({screen: 2});
    }

    // init error
    const initDataError = {};
    for (var i = 0; i < this.vehicleFields.length; i++) {
      this.checkError(this.vehicleFields[i], "init_");
    }

    this.setState({initDataError});
  }

  validates = {
    vehicle_year: ["notEmpty", "integer"],
    license_plate: ["notEmpty"],
    license_state: ["notEmpty"],
    vehicle_make: ["notEmpty"],
    vehicle_model: ["notEmpty"],
    vehicle_type: ["notEmpty"],
    registration_record_expired_ts: ["notEmpty"],
    insurance_card_expired_ts: ["notEmpty"],
  }
  vehicleFields = ["vehicle_year", "license_plate", "vehicle_make", "vehicle_model", "vehicle_type", "license_state", "registration_record_expired_ts", "insurance_card_expired_ts"];

  checkError = (field, prefix) => {
    const {store} = this.props;    
    const {driverData} = store.driverStore;
    if (!prefix) {
      const initErrorField = "init_" + field + "_error";
      this.setState({[initErrorField]: undefined});
    }

    const errorField = prefix ? prefix + field + "_error" : field + "_error";

    const validates = this.validates[field];
    
    if (!validates) return;    
    const value = driverData[field];

    var constraints = {
      [field]: _.assign({}, ..._.values(_.pick(RULES, validates)))
    };

    const result = validate({[field]: value}, constraints, {format: "flat"});

    // process current error 
    if (!result) this.setState({[errorField]: undefined})
    else {
      this.setState({[errorField]: result[0]})
    }   
  }

  handleClose = () => {
    this.setState({confirmation: true})
  };

  onTextFieldChange = (field) => (event) => {
    const {store} = this.props;    
    store.driverStore.updateDriverData(field, event.target.value);
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  }

  onCheckboxChange = (field) => (event) => {
    const {store} = this.props;    
    store.driverStore.updateDriverData(field, event.target.checked);
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  }

  onChangeSelect = (f) => (e) => {
    const {store} = this.props;

    if(f === 'vehicle_year') {
      store.driverStore.updateDriverData('vehicle_make', null);
      store.driverStore.updateDriverData('vehicle_model', null);
      this.checkError('vehicle_make');
      this.checkError('vehicle_model');
      getCars({'year': e.target.value}).then(res => {
        if(res.ok && res.status === 200) {
          this.setState({items: res.data});
        } else {}
      });
    } else if(f === 'vehicle_make') {
      store.driverStore.updateDriverData('vehicle_model', null);
      this.checkError('vehicle_model');
    } else if(f === 'vehicle_model') {

    } else {

    }
    store.driverStore.updateDriverData(f, e.target.value);
    const fieldValidates = this.validates[f];

    if (fieldValidates) {
      this.checkError(f);
    }
  };

  goNext = () => {
    const {history, store} = this.props;
    store.driverStore.setStep(2);
    history.push('/drivers/add-account');
  }

  goBack = () => {
    const {history} = this.props;
    history.push('/drivers/add-account-info');
  }

  getValue = (field, df) => this.props.store.driverStore.driverData[field] ? this.props.store.driverStore.driverData[field]: df ? df : '';

  closeDialog = () => {
    const {store} = this.props;
    store.driverStore.clearDriverData();
    this.setState({confirmation: false})
  }

  closeAddModal = () => {
    const {history, store} = this.props;
    store.driverStore.clearDriverData();
    this.setState({confirmation: false})
    history.replace('/drivers');
  }

  setDate = (f, v) => {
    if (!v) {
      return;
    }
    const {store} = this.props;
    //convert to UNIX
    const value = moment(v).unix();
    store.driverStore.updateDriverData(f, value);
    const fieldValidates = this.validates[f];

    if (fieldValidates) {
      this.checkError(f);
    }
  };

  switchScreen = (v) => {
    const {store} = this.props;
    this.setState({screen: v});
    if (v > 1) {
      store.driverStore.setStep(1.5);
    }
  };

  getDate = (f) => {
    const v = this.getValue(f) ? this.getValue(f) * 1000 : null;

    if (!v) {
      return null;
    }

    return moment(v).valueOf();
  };

  handleUpload = (e) => {
    const {driverStore} = this.props.store;
    const files = Array.from(e.target.files);
    if(!files) {
      driverStore.updateDriverData(e.target.name, null);
      return;
    } else {
      Promise.all(files.map(file => {
        return (new Promise((resolve,reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        }));
      })).then(images => {
        var newFiles = files.map(file => {
          var blob = file.slice(0, file.size, file.type);
          var newFileName = file.name.replace(/\s/g, '');
          return new File([blob], newFileName, {type: file.type});
        });
        driverStore.updateDriverData(e.target.name, newFiles[0]);// Single file
      })
    }
  };

  render() {
    const {classes, store}  = this.props;
    const {items, screen}   = this.state;
    const {driverData}      = store.driverStore;
    const years             = _.range(1990, moment().year() + 1).map(i => ({label: i, value: i}));
    const hasError          = this.vehicleFields.find(field => !!this.state[field + "_error"] || !!this.state["init_" + field + "_error"]);
    const makes             = items && _.uniqBy(items, obj => obj.make).filter(i => i.make).map(i => ({label: i.make, value: i.make})) || [];
    const models            = items && _.uniqBy(items, obj => obj.model).filter(i => i.model && _.includes([this.getValue('vehicle_make')], i.make)).map(i => ({label: i.model, value: i.model})) || [];
    const submodels         = items && _.uniqBy(items, obj => obj.submodel).filter(i => i.submodel && _.includes([this.getValue('vehicle_model')], i.model)).map(i => ({label: i.submodel, value: i.submodel})) || [];
    const vehicles          = items && _.uniqBy(items, obj => obj.type).filter(i => i.type && _.includes([this.getValue('vehicle_model')], i.model)).map(i => ({label: i.type, value: i.type})) || [];
    const vehicleResult     = vehicles.length ? vehicles : Object.keys(DEFAULT_VEHICLE_TYPE).map(v => ({label: v, value: DEFAULT_VEHICLE_TYPE[v]}));

    return (
      <Container className={classes.root}>
          <Modal
            aria-labelledby="add-driver"
            aria-describedby="add-driver"
            open={true}
            onClose={this.handleClose}
            className={classes.modal}          
          >          
            <div className={classes.paper}> 
                <Dialog
                  open={this.state.confirmation}                
                  onClose={this.closeDialog}
                  maxWidth={'md'}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className={classes.confirmDialog}
                >
                <DialogTitle className={classes.confirmDialogTitle} id="alert-dialog-title">
                  <Box style={{fontWeight: 'bold'}}>
                    Are you sure you want to leave? 
                    <br/>
                    The driver being created will not save.
                  </Box>                
                </DialogTitle>
                <DialogActions>
                  <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                    <Button style={{marginLeft: '15px', marginBottom: '10px'}} className={classes.secondaryBtn} variant="contained" onClick={this.closeDialog} color="primary">
                      Back
                    </Button>
                    <Button style={{marginRight: '15px', marginBottom: '10px'}} className={classes.errorBtn} variant="contained"  onClick={this.closeAddModal} color="primary" autoFocus>
                      Leave
                    </Button>
                  </div>                
                </DialogActions>
              </Dialog>
              {screen === 1 && <Box>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={3}>
                    <S.Title>{`Create New Driver`}</S.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <DriverStepper style={{width: '100%'}} currentStep={1} half={1} />
                  </Grid>
                  <Grid item xs={3}>
                    <Box bgcolor="background.paper"color="text.primary"p={2}position="absolute"top={0}right="0"zIndex="modal">
                      <IconButton onClick={this.handleClose} size="large"><CloseIcon /></IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <span className={classes.vehicleInfoText}>Vehicle Information</span>
                    <FormControlLabel control={<Checkbox
                      size="small"
                      checked={this.getValue('company_vehicle')}
                      onChange={this.onCheckboxChange('company_vehicle')}
                      value={driverData['company_vehicle']} />} label="Company Vehicle" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      type='text'
                      value={this.getValue('vehicle_name')}
                      error={this.state['vehicle_name_error']}
                      placeholder={''}
                      onChange={this.onTextFieldChange('vehicle_name')} label='Vehicle Name <i style="font-size: 10px;vertical-align: middle;">(Optional)</i>' />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      value={this.getValue('license_plate')}
                      error={this.state['license_plate_error']}
                      placeholder={''}
                      onChange={this.onTextFieldChange('license_plate')} label='License Plate <i style="font-size: 11px;color:red;vertical-align: top;">*</i>' />
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`License Plate State`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['license_state_error']} name={`license_state`} options={REGIONS_STATE} onChange={this.onChangeSelect('license_state')} value={this.getValue('license_state')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`Year`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['vehicle_year_error']} name={'vehicle_year'} options={years} onChange={this.onChangeSelect('vehicle_year')} value={this.getValue('vehicle_year')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`Make`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['vehicle_make_error']} name={'vehicle_make'} options={makes} onChange={this.onChangeSelect('vehicle_make')} value={this.getValue('vehicle_make')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`Model`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['vehicle_model_error']} name={'vehicle_model'} options={models} onChange={this.onChangeSelect('vehicle_model')} value={this.getValue('vehicle_model')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`Vehicle Type`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['vehicle_type_error']} name={'vehicle_type'} options={vehicleResult} onChange={this.onChangeSelect('vehicle_type')} value={this.getValue('vehicle_type')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.Label>{`Sub-model`} <i style={{fontSize: 10, verticalAlign: 'middle'}}>{`(Optional)`}</i></S.Label>
                      <S.FormControl>
                        <AxlSelect error={this.state['vehicle_submodel_error']} name={'vehicle_submodel'} options={submodels} onChange={this.onChangeSelect('vehicle_submodel')} value={this.getValue('vehicle_submodel')} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <S.FormControl>
                      <S.FormControl>
                        <TextInput
                          value={this.getValue('vehicle_color')}
                          error={this.state['vehicle_color_error']}
                          placeholder={''}
                          onChange={this.onTextFieldChange('vehicle_color')} label='Color <i style="font-size: 10px;vertical-align: middle;">(Optional)</i>' />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                </Grid>
                <br/>
                <Box>
                  <i style={{fontSize: 11, color: 'red'}}>{`* is required`}</i>
                </Box>
                <br/>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Button variant="contained" disableElevation className={classes.secondaryBtn} onClick={this.goBack}>{`Back`}</Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" className={classes.mainBtn} onClick={() => this.switchScreen(2)}>{`Next`}</Button>
                  </Grid>
                </Grid>
              </Box>}
              {screen === 2 && <Box> {/* SCREEN 2 */}
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={3}>
                    <S.Title>{`Create New Driver`}</S.Title>
                  </Grid>
                  <Grid item xs={6}>
                    <DriverStepper style={{width: '100%'}} currentStep={1} />
                  </Grid>
                  <Grid item xs={3}>
                    <Box bgcolor="background.paper"color="text.primary"p={2}position="absolute"top={0}right="0"zIndex="modal">
                      <IconButton onClick={this.handleClose} size="large"><CloseIcon /></IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <S.FormControl>
                      <S.LabelStrong>{`Vehicle Registration`}<S.TextOption>{`(optional)`}</S.TextOption></S.LabelStrong>
                      <S.FormControl>
                        <AxlFileUpload name={'registration'}
                                       defaultValue={this.getValue('registration') || this.getValue('registration_record_url')}
                                       onChange={this.handleUpload} />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs>
                    <S.FormControl>
                      <S.LabelStrong>{`Vehicle Insurance`}<S.TextOption>{`(optional)`}</S.TextOption></S.LabelStrong>
                      <S.FormControl>
                        <AxlFileUpload name={'insurance'}
                                       defaultValue={this.getValue('insurance') || this.getValue('insurance_card_url')}
                                       onChange={this.handleUpload}/>
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <S.FormControl>
                      <S.Label>{`Issued Date`}<S.TextOption>{`(optional)`}</S.TextOption></S.Label>
                      <S.FormControl>
                        <AxlDatePicker
                          onChange={(d) => this.setDate('registration_record_issued_ts', d)}
                          format="MMM DD, YYYY"
                          placeholder="MMM DD, YYYY"
                          value={this.getDate('registration_record_issued_ts') ? moment(this.getDate('registration_record_issued_ts')) : null}
                        />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs>
                    <S.FormControl>
                      <S.Label>{`Issued Date`}<S.TextOption>{`(optional)`}</S.TextOption></S.Label>
                      <S.FormControl>
                        <AxlDatePicker
                          onChange={(d) => this.setDate('insurance_card_issued_ts', d)}
                          format="MMM DD, YYYY"
                          placeholder="MMM DD, YYYY"
                          value={this.getDate('insurance_card_issued_ts') ? moment(this.getDate('insurance_card_issued_ts')) : null}
                        />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <S.FormControl>
                      <S.Label>{`Expired Date`} <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlDatePicker
                          onChange={(d) => this.setDate('registration_record_expired_ts', d)}
                          format="MMM DD, YYYY"
                          placeholder="MMM DD, YYYY"
                          value={this.getDate('registration_record_expired_ts') ? moment(this.getDate('registration_record_expired_ts')) : null}
                        />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                  <Grid item xs>
                    <S.FormControl>
                      <S.Label>{`Expired Date`} <i style={{fontSize: 11, color: 'red'}}>{`*`}</i></S.Label>
                      <S.FormControl>
                        <AxlDatePicker
                          onChange={(d) => this.setDate('insurance_card_expired_ts', d)}
                          format="MMM DD, YYYY"
                          placeholder="MMM DD, YYYY"
                          value={this.getDate('insurance_card_expired_ts') ? moment(this.getDate('insurance_card_expired_ts')) : null}
                        />
                      </S.FormControl>
                    </S.FormControl>
                  </Grid>
                </Grid>
                <br/>
                <Box>
                  <i style={{fontSize: 11, color: 'red', verticalAlign: 'top'}}>{`* is required`}</i>
                </Box>
                <br/>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Button variant="contained" disableElevation className={classes.secondaryBtn} onClick={() => this.switchScreen(1)}>{`Back`}</Button>
                  </Grid>
                  <Grid item>
                    <Button disabled={!!hasError} variant="contained" className={classes.mainBtn} onClick={this.goNext}>{`Next`}</Button>
                  </Grid>
                </Grid>
              </Box>}
            </div>
          </Modal>
        </Container>
    );
  }
}

const AddDriverVehicleCompose = compose(
  inject("store"),
  observer
) (AddDriverVehicle);

export default withStyles(styles)(AddDriverVehicleCompose);