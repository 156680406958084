import React, { Component } from 'react';
import { AxlButton, AxlModal, AxlPanel, AxlPopConfirm, AxlPanelSlider, STATUS_COLOR_CODE, AxlInput, AxlCheckbox, AxlTextArea } from 'axl-reactjs-ui';
import {compose} from "recompose";
import { inject, observer } from 'mobx-react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";

import styles from "./styles";
import AssignmentDetailMap from "./AssignmentDetailMap";
import ShipmentDetail from "./ShipmentDetail";
import AssignmentBreakdown from "../../../components/AssignmentBreakdown";
import ShipmentMessenger from "../../ShipmentMessenger";

class ShipmentDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messengers: [],
      topicLoading: false,
      triggerMsgLoading: false,
      newTopic: false,
      creatingTopic: false,
      assignmentLoading: false,
      _isShowMessenger: false,
      showAddingShipmentDetail: false
    }
  }

  onShowMessenger = () => {
    const { messengerStore, assignmentStore, userStore } = this.props;
    const { selectedAssignmentId, selectedAssignment } = assignmentStore;
    if(!this.state._isShowMessenger) {
      // Allow open topic when assign to driver
      this.setState({
        topicLoading: true,
        assignmentLoading: true
      });
      messengerStore.loadTopicByAssignmentId(selectedAssignmentId, (res) => {
        if(res.status === 200) {
          if(userStore.user &&
            messengerStore.topicSelected &&
            messengerStore.topicSelected.follower_ids.indexOf(userStore.user.id) !== -1) {
            this.props.messengerStore.isFollow = true;
          } else {
            this.props.messengerStore.isFollow = false;
          }
          this.setState({
            topicLoading: false,
            assignmentLoading: false
          });
        } else if(res.status === 404) {
          this.setState({
            newTopic: true,
            topicLoading: false,
            assignmentLoading: false
          });
        }
      });
    } else {
      messengerStore.topicSelected = null;
      messengerStore.topicSelectedId = null;
    }
    this.setState({_isShowMessenger: !this.state._isShowMessenger});
  }

  render() {
    const { classes, store } = this.props;
    const { assignmentStore, shipmentStore } = store;
    const { selectedStop, previewShipment } = shipmentStore;
    const { selectedAssignment } = assignmentStore;
    const _isActiveAssignmentSelected = selectedAssignment && selectedAssignment.assignment && selectedAssignment.assignment.is_active;

    return (
      <Box className={classes.container}>
        <Grid container direction="column" className={classes.gridContainer}>
          <Grid item className={classes.assignmentDetailMap} style={{height: selectedStop ? 225 : 'calc(100% - 400px)'}}>
            <AssignmentDetailMap
              previewShipment={previewShipment}
              assignment={ selectedAssignment }
              locations={ selectedAssignment ? selectedAssignment.locations : null }
              driverLocation={ selectedAssignment ? selectedAssignment.driverLocation : null }
              shipment={ selectedStop ? selectedStop.shipment : null}
            />
          </Grid>
          <Grid item xs className={classes.shipmentDetail}>
            {/*<ShipmentMessenger*/}
            {/*  history={this.props.history}*/}
            {/*  topicLoading={this.state.topicLoading}*/}
            {/*  triggerMsgLoading={this.state.triggerMsgLoading}*/}
            {/*  activeAssignment={_isActiveAssignmentSelected}*/}
            {/*  assignmentLoading={this.state.assignmentLoading}*/}
            {/*/>*/}
            <Route path='*/stops/:stopId' component={ShipmentDetail} />
            {!selectedStop && <AssignmentBreakdown selectedAssignment={assignmentStore.selectedAssignment} filteredStops={assignmentStore.filteredShowingStops}/>}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  inject("store", "messengerStore", "userStore", "assignmentStore"),
  observer
)(ShipmentDetailContainer);
