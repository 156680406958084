import React, {Component} from "react";
import {AxlButton, AxlImageUploadPreView, AxlModal, AxlPanel} from "axl-reactjs-ui";
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {inject, observer} from "mobx-react";
import Slider from "react-slick";

import {images} from "../../constants/images";
import styles, {style} from './styles';
import moment from "moment";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

import acl from "constants/acl";

class PODUploader extends Component {
  render() {
    const { uid } = this.props
    if (!uid) return <span></span>
    const comps = uid.split('_')
    if (comps[0] === 'CS') return <span>Customer</span>
    if (comps[0] === 'DR') return <span>Driver { comps[1]} </span>
    if (comps[0] === 'US') return <span>User { comps[1]} </span>
    return <span>{uid}</span>
  }
}

class ShipmentPODRequirementCompose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPanel: true,
      showShipmentPicture: false,
      showSignaturePicture: false,
      showIdCardPicture: false,
      showImageUploadPreView: false,
    };
  }

  addImage = (shipmentId) => (file) => {
    const { store} = this.props;
    const {shipmentStore} = store;
    const {selectedStop} = shipmentStore;
    shipmentStore.addImage(shipmentId, selectedStop.id, file, (image) => {
      this.setState({showImageUploadPreView: false});
    });
  };

  parsePDFContent = (content) => {
    const lines = content.split("\n");
    const data = {content};
    if (lines && lines.length > 0) {
      lines.forEach(line => {
        if (line.startsWith("DBB")) {
          const dobString = line.substring(3);
          const dob = new Date(dobString.substring(4), dobString.substring(0, 2), dobString.substring(2, 4));
          data['dob'] = dob;
        }

        if (line.startsWith("DCS") || line.startsWith("DAB") || line.startsWith("DBO") ) {
          data['last_name'] = line.substring(3);
        }

        if (line.startsWith("DAC") || line.startsWith("DBP") || line.startsWith("DCT") ) {
          data['first_name'] = line.substring(3);
        }
      })
    }
    return data;
  }

  render() {
    const {isShowPanel, showShipmentPicture, showSignaturePicture, showIdCardPicture, showImageUploadPreView} = this.state;
    const { shipment, store } = this.props;
    const { shipmentStore, permissionStore } = store;
    const {selectedStop, selectedShipment, uploadingImage} = shipmentStore;

    if (!selectedStop || !selectedShipment) return null;

    const info = selectedStop ? selectedStop.info : null;
    const stop_images = info && info.images ? info.images : [];
    const stop_signatures = info && info.signatures ? info.signatures : [];
    const stop_idcards = info && info.idcards ? info.idcards : [];
    const images_count = info ? stop_images.length : 0;
    const signatures_count = info ? stop_signatures.length : 0;
    const idcards_count = info ? stop_idcards.length : 0;
    const photoRequired = Boolean(selectedShipment.delivery_proof_photo_required);
    const signatureRequired = Boolean(selectedShipment.signature_required);
    const idRequired = Boolean(selectedShipment.id_required);
    const requiredBorderStyle = '1px solid #f5a623';

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SliderNextArrow />,
      prevArrow: <SliderPrevArrow />
    };

    const isDeniedUploadPOD = permissionStore.isDenied(acl.ASSIGNMENTS.UPLOAD_POD);

    return (
      <AxlPanel style={styles.panelContainer}>
        <div style={styles.panelHeader}>
          <div style={styles.panelHeaderTitle}>Proof of Delivery:</div>
          <div style={styles.panelHeaderRight}>
            <div style={styles.panelHeaderArrow} onClick={() => this.setState({isShowPanel: !isShowPanel})}>
              <i className={!isShowPanel ? 'fa fa-angle-down' : 'fa fa-angle-up'} />
            </div>
          </div>
        </div>
        {isShowPanel && (
          <div style={styles.buttonGroupWrapper}>
            <div>
              <div style={styles.buttonWrapper}>
                <div style={{border: photoRequired ? requiredBorderStyle : undefined}}>
                  <Tooltip title="View shipment photo">
                    <span>
                      <AxlButton
                        bg={'white'}
                        compact={true}
                        source={images.icon.camera}
                        style={{margin: 0, padding: '0', width: '50px'}}
                        onClick={() => this.setState({showShipmentPicture: true})}
                      >
                        <span style={styles.count}>{ images_count }</span>
                      </AxlButton>
                    </span>
                  </Tooltip>
                  {!isDeniedUploadPOD && (
                    <Tooltip title="Add photo">
                      <span>
                        <AxlButton
                          bg={'white'}
                          style={{margin: 0, padding: '0 6px 0 0', width: '32px'}}
                          compact={true}
                          ico={{className: 'fa fa-plus'}}
                          onClick={() => this.setState({showImageUploadPreView: true})}
                        >
                          <br />
                        </AxlButton>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
              {photoRequired && <div style={styles.requiredText}>(required)</div>}
            </div>
            {Boolean(signatureRequired || signatures_count) && (
              <div>
                <div style={styles.buttonWrapper}>
                  <Tooltip title="View signature">
                    <div style={{border: signatureRequired ? requiredBorderStyle : undefined}}>
                      <AxlButton bg={'white'} compact={true}
                                 source={images.icon.sign}
                                 style={{margin: 0, padding: 0}}
                                 onClick={() => this.setState({showSignaturePicture: true})}
                      >
                        <span style={styles.count}>{ signatures_count }</span>
                      </AxlButton>
                    </div>
                  </Tooltip>
                </div>
                {signatureRequired && <div style={styles.requiredText}>(required)</div>}
              </div>
            )}
            {Boolean(idRequired || idcards_count) && (
              <div>
                <div style={styles.buttonWrapper}>
                  <Tooltip title="View ID card info">
                    <div style={{border: idRequired ? requiredBorderStyle : undefined}}>
                      <AxlButton bg={'white'} compact={true}
                                 source={images.icon.idcard}
                                 style={{margin: 0, padding: 0}}
                                 onClick={() => this.setState({showIdCardPicture: true})}
                      >
                        <span style={styles.count}>{ idcards_count }</span>
                      </AxlButton>
                    </div>
                  </Tooltip>
                </div>
                {idRequired && <div style={styles.requiredText}>(required)</div>}
              </div>
            )}
          </div>
        )}
        {(showShipmentPicture && stop_images.length > 0 ) && (
          <AxlModal
            onClose={() => this.setState({showShipmentPicture: false})}
            style={styles.modalStyle}
            containerStyle={styles.modalContainer}
          >
            <div style={styles.wrapImages}>
              {stop_images && <Slider {...settings}>{stop_images.map((img, index) => <div key={index}>
                <div style={{height: '100%'}}><img src={img.url} style={styles.imageShipment} alt="" /></div>
                <div style={{textAlign: 'center'}}>
                  <span style={{display: 'inline-block', padding: '4px 8px', backgroundColor: '#eee', borderRadius: '4px', marginTop: '4px'}}>Uploaded By: <PODUploader uid={img.uploader} /></span>
                </div>
              </div>)}</Slider>}
            </div>
          </AxlModal>
        )}
        {(showSignaturePicture && stop_signatures.length > 0) && (
          <AxlModal
            onClose={() => this.setState({showSignaturePicture: false})}
            style={styles.modalStyle}
            containerStyle={styles.modalContainer}
          >
            <div style={styles.wrapImages}>
              {stop_signatures && <Slider {...settings}>{stop_signatures.map((img, index) => <div key={index}>
                <div style={{height: 'calc(100% - 20px)'}}>
                  <img src={img.data} style={styles.imageShipment} alt="signature" />
                </div>
                <div style={{textAlign: 'center'}}>
                  <span style={{display: 'inline-block', padding: '4px 8px', backgroundColor: '#eee', borderRadius: '4px', marginTop: '4px'}}>Uploaded By: <PODUploader uid={img.uploader} /></span>
                </div>
              </div>)}</Slider>}
            </div>
          </AxlModal>
        )}
        {(showIdCardPicture && stop_idcards.length > 0) && (
          <AxlModal
            onClose={() => this.setState({showIdCardPicture: false})}
            style={styles.modalStyle}
            containerStyle={styles.modalContainer}
          >
            <div style={styles.wrapImages}>
              {stop_idcards && (
                <Slider {...settings}>
                  {stop_idcards.map((img, index) => {
                    if (!img.data) return null;
                    const info = this.parsePDFContent(img.data);

                    return (
                      <div key={index}>
                        <div style={{height: '100%', backgroundColor: '#fff', padding: 30}}>
                          <table style={{margin: 'auto', borderSpacing: 10, fontSize: 18}}>
                            <tr>
                              <td style={{textAlign: 'right', fontWeight: 'bold'}}>Name:</td>
                              <td style={{textAlign: 'left'}}>{info.first_name || '-'} {info.last_name || '-'}</td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'right', fontWeight: 'bold'}}>Birthday:</td>
                              <td style={{textAlign: 'left'}}>{info.dob ? moment(info.dob).format("YYYY-MM-DD") : '-'}</td>
                            </tr>
                          </table>
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <span style={{display: 'inline-block', padding: '4px 8px', backgroundColor: '#eee', borderRadius: '4px', marginTop: '4px'}}>Uploaded By: <PODUploader uid={img.uploader} /></span>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              )}
            </div>
          </AxlModal>
        )}
        {showImageUploadPreView && (
          <AxlModal
            onClose={() => this.setState({showImageUploadPreView: false})}
            style={{...styles.modalStyle, ...styles.modalImageStyle}}
            containerStyle={styles.modalContainer}
          >
            <AxlImageUploadPreView uploading={uploadingImage} onUpload={this.addImage(shipment.id)} />
          </AxlModal>
        )}
      </AxlPanel>
    );
  }
}
export const ShipmentPODRequirement = compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentPODRequirementCompose);

export default withStyles(style)(ShipmentPODRequirement);

function SliderPrevArrow(props) {
  const { onClick } = props;
  return (
    <div style={{...styles.arrowButton, ...styles.arrowPrev}} onClick={onClick}><i style={styles.arrowIcon} className='fa fa-angle-left' /></div>
  );
}

function SliderNextArrow(props) {
  const { onClick } = props;
  return (
    <div style={{...styles.arrowButton, ...styles.arrowNext}} onClick={onClick}><i style={styles.arrowIcon} className='fa fa-angle-right' /></div>
  );
}
