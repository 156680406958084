import React, {Component} from 'react';
import {AxlPanel, AxlSearchBox} from 'axl-reactjs-ui';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import {clone} from 'lodash';
import AxlMUISelect from "../../../AxlMUIComponent/AxlMUISelect";
// Styles
import styles, {Container, GroupField, Label, Flex, SearchContainer, GroupField2, SmallLabel} from './styles';
import DateTimePkr from "../DateTimePkr";

const statuses = [
  {label: 'All Statuses', value: "ALL"},
  {label: 'Created', value: 'CREATED'},
  {label: 'Failed', value: 'FAILED'},
  {label: 'Pending', value: 'PENDING'},
  {label: 'Request Error', value: 'REQUEST_ERROR'},
  {label: 'Resubmitted', value: 'RESUBMITTED'},
  {label: 'Resubmit Failed', value: 'RESUBMIT_FAILED'},
  {label: 'Processing', value: 'PROCESSING'},
  {label: 'Succeeded', value: 'SUCCEEDED'},
  {label: 'Resubmit Succeeded', value: 'RESUBMIT_SUCCEEDED'},
];

class TransactionFilter extends Component {
  constructor(props) {
    super(props);
    this.setFromDate = this.setFromDate.bind(this);
    this.setToDate = this.setToDate.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.state = {
      isOpenFrom: false,
      isOpenTo: false,
    }
  }

  setFromDate(d) {
    let filter = clone(this.props.filter);
    filter.fromDate = d ? moment(d).format('YYYY-MM-DD') : null;
    this.props.doFilter(filter);
  }

  setToDate(d) {
    let filter = clone(this.props.filter);
    filter.toDate = d ? moment(d).format('YYYY-MM-DD') : null;
    this.props.doFilter(filter);
  }

  setStatus(statuses) {
    let filter = clone(this.props.filter);
    if (statuses.length > 0) {
      if (statuses.includes("ALL")) {
        filter.status = null;
      } else {
        filter.status = statuses[0];
      }
    } else {
      filter.status = null;
    }
    this.props.doFilter(filter);
  }

  render() {
    const {fromDate, toDate, status} = this.props.filter;
    const { isOpenFrom, isOpenTo } = this.state;

    const slotTextField = (field, isOpen) => ({
      onClick: () => this.setState({ [field]: true }),
      onFocus: (e) => {
        e.currentTarget.blur();
        return;
      },
      focused: isOpen,
    })

    const addProps = (field, isOpen) => ({
      localeText:{
        fieldMonthPlaceholder: () => 'MMM',
      },
      onClose: () => this.setState({[field]: false}),
      open: isOpen,
    })

    return (
      <AxlPanel>
        <Container>
          <GroupField2>
            <SmallLabel>{`Overview from `}</SmallLabel>
            <DateTimePkr
              dateOnly
              format="MMM DD, YYYY"
              placeholderInput="Oldest"
              value={fromDate ? new Date(fromDate) : null}
              variant="standard"
              onChange={(date) => this.setFromDate(date)}
              hideIconCalendar={true}
              slotTextField={slotTextField('isOpenFrom', isOpenFrom)}
              addProps={addProps('isOpenFrom', isOpenFrom)}
            />
          </GroupField2>
          <GroupField>
            <Label>{`to`}</Label>
            <DateTimePkr
              dateOnly
              format="MMM DD, YYYY"
              placeholderInput="Most Recent"
              value={toDate ? new Date(toDate) : null}
              variant="standard"
              onChange={(date) => this.setToDate(date)}
              hideIconCalendar={true}
              slotTextField={slotTextField('isOpenTo', isOpenTo)}
              addProps={addProps('isOpenTo', isOpenTo)}
            />
          </GroupField>
          <GroupField>
            <Label>{`with`}</Label>
            <AxlMUISelect
                value={statuses.find(s => s.value === status)}
                defaulValue={statuses.find(s => s.value === status)}
                placeholder="all statuses"
                single
                isSearchable={false}
                options={statuses}
                onChange={(v) => this.setStatus(v)}
                style={{fontSize: '12px'}}
            />
          </GroupField>
        </Container>
      </AxlPanel>
    )
  }
}

export default TransactionFilter
