import {Box, styled, Typography} from "@mui/material";
import fonts from "../../themes/fonts";

export const ResolvedContainer = styled(Box)(({theme}) => ({
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: 380,
  maxWidth: '100%',
}));

export const TitleModal = styled(Typography)(({theme}) => ({
  fontFamily: fonts.DemiBold,
  fontSize: 18,
  letterSpacing: -0.09,
  textAlign: 'center',
  color: theme.palette.primary['greyish-brown'],
  marginBottom: '16px'
}));

export const SubTitleModal = styled(Typography)(({theme}) => ({
  fontFamily: fonts.DemiBold,
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: -0.07,
  textAlign: 'center',
  color: '#707070'
}));