import {Box, styled, Typography} from "@mui/material";
import colors from "../../themes/colors";
import fonts from "../../themes/fonts";

export const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
}));
export const Text = styled(Typography)(({theme}) => ({
  fontFamily: fonts.Regular,
  fontSize: 13,
  color: colors.gray,
}));
export const Title = styled(Text)(({theme}) => ({}));
export const StatusText = styled(Text)(({theme}) => ({
  fontFamily: fonts.MediumItalic,
  fontSize: 12,
  color: colors.blackSecondary,
}));

const styles = theme => ({
  container: {
    fontFamily: 'AvenirNext',
  },
  body: {
    maxHeight: 500,
    overflow: 'auto',
  },
  footerBtnIcon2: {
    width: 16,
    height: 15,
    backgroundSize: 'contain',
  },
  btnControl: {
    width: 130
  }
});

export default styles;