import ObjectListStore from "./ObjectList";
import {observable} from "mobx";
import {API_REQUEST_ERROR, API_REQUEST_SUCCEEDED, API_REQUESTING} from "./api";

class DspEventStore extends ObjectListStore {
  DEFAULT = {
    baseUrl: "/dsp-events",
    fields: [
      {name: "actual_end_ts", label: "Created On"},
      {name: "type", label: "Event Type"},
      {name: "assignment_label", label: "Assignment Label"},
      {name: "shipment_count", label: "No. of Shipments"},
      {name: "notes", label: "Notes"},
      {name: "amount", label: "Amount"},
      {name: "driver_name", label: "Driver"},
      {name: "row_detail", label: ""},
    ],
    filters: {
      page: 0,
      size: 20,
      order_by: "created_date",
      desc: true
    },
    details: [
      {label: "Region", name: "region_code", styles: {padding: '20px 10px', flexGrow: 0, width: '50px'}},
      {label: "Assignment", name: "assignment_id", styles: {padding: '20px 10px', flexShrink: 0}},
      {label: "Delivery Date", name: "delivery_date", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '105px'}},
      {label: "Start Time", name: "start_delivery_ts", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '120px'}},
      {label: "End Time", name: "end_delivery_ts", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '120px'}},
      {label: "Route Rate", name: "route_rate", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '60px'}},
      {label: "Route Split", name: "route_split", styles: {padding: '20px 10px', flex: 0, minWidth: '65px'}},
      {label: "Total Non Perishables", name: "total_non_perishable", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '125px'}},
      {label: "Total Perishables", name: "total_perishable", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '100px'}},
      {label: "Total Est Miles", name: "travel_distance", styles: {padding: '20px 10px', flexShrink: 0, minWidth: '85px'}},
    ],
    result: {
      count: 0,
      items: [],
      total_pages: 0
    },
    listField: "dspTransactions",
    idField: "id"
  };

  @observable addEventStatus = {};
  @observable transactions = [];
  @observable loadingTransactions = false;

  constructor(api) {
    super(api);
    this.api = api;
    this.setData(this.DEFAULT);
    this.paymentDetails = null;
  }

  addEvent(event) {
    this.addEventStatus.status = API_REQUESTING;
    this.api.post(`/dsp-events`, event)
        .then(response => {
          if (response.status === 200) {
            this.addEventStatus.status = API_REQUEST_SUCCEEDED
            this.search();
          } else {
            this.addEventStatus = {status: API_REQUEST_ERROR, message: response.data.errors ? response.data.errors.join(", ") : response.data.message};
          }
        })
  }

  listEvents(contractorId, from, to) {
    this.loadingTransactions = true;
    this.api.get(`/dsp-events/list`, {contractor_id: contractorId, from, to}).then(res => {
      if (res.ok) {
        this.transactions = res.data;
      } else {
        this.transactions = [];
      }
      this.loadingTransactions = false;
    })
  }

  resetEvents() {
    this.transactions = [];
  }

  resetStatuses() {
    this.addEventStatus = {};
  }
}

export default DspEventStore;
