import React, {Component, useEffect, useState} from 'react';
import { Box, Grid, LinearProgress, TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import _ from "lodash";
import clsx from "clsx";
import { FixedSizeList as List } from 'react-window';

import styles from './styles';
import avatarHolder from '../../assets/images/avatar-placeholder.jpg'
import AxlButton from "../../AxlMUIComponent/AxlButton";
import AxlRadio from "../../AxlMUIComponent/AxlRadio";
import AxlInput from "../../AxlMUIComponent/AxlInput";

const Row = ({ data, index, style, classes, selectedDriverId, onSelectDriver }) => {
  const driver = data[index]
  const driverName = _.capitalize(driver.first_name) + ' ' + _.capitalize(driver.last_name);
  const isSelected = driver.id === selectedDriverId;
  const disabled = !driver.background_status || driver.background_status.indexOf('APPROVED') < 0;

  return (
    <Grid item key={index} style={style}>
      <Box className={clsx({
        [classes.cardContainer]: true,
        [classes.cardSelected]: isSelected,
        [classes.cardDisabled]: disabled,
      })}>
        <Grid container spacing={1} onClick={onSelectDriver(driver.id, disabled)}>
          <Grid item xs={2} style={{ alignSelf: 'center' }}>
            <Box>
              <img src={driver.photo || avatarHolder} alt={driverName} className={classes.cardAvatar} />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
              <Box p={0.5} className={classes.cardLabel}>
                {driverName}
              </Box>
              <Box p={0.5} className={clsx(classes.cardSubtitle, classes.grayText)}>
                {driver.assignment_count || 'No'} Assignments
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

class AssignDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDriverId: props.driverIdSelected,
      selectedVehicleId: null,
      selectedVehicleIdAlias: null,
      searchKey: '',
      personalVehicles: [],
      companyVehicles: [],
      personalVehicleLoading: false,
    }
    this.ITEMSIZE = 78
    this.HEIGHT = 390
  }

  componentDidMount() {
    const {driverStore, userStore} = this.props.store;
    const {driverIdSelected} = this.props;

    // Load driver's list
    driverStore.listActiveDrivers();
    // Load vehicle's driver
    this.getPersonalVehicles(driverIdSelected)
    // Load vehicle's company
    this.getCompanyVehicles();
    this.onProcessVehicleSelected();
  }

  onProcessVehicleSelected = () => {
    const selectedVehicles = _.get(this, 'props.store.assignmentStore.selectedAssignment.assignment_driver_vehicles');
    const vehicleSelected = _.filter(selectedVehicles, v => v.driver_vehicle_id)[0];

    if(vehicleSelected) {
      this.setState({selectedVehicleId: vehicleSelected.driver_vehicle_id});
    }
  };

  onSelectDriver = (id, disabled) => () => {
    if (disabled) return;
    this.setState({selectedDriverId: id});
    // Load vehicle's driver
    this.getPersonalVehicles(id);
  };

  onSelectPersoncalVehicle = (id) => {
    this.setState({
      selectedVehicleId: id,
      selectedVehicleIdAlias: `PER_${id}`,
    });
  };

  onSelectCompanyVehicle = (id) => {
    this.setState({
      selectedVehicleId: id,
      selectedVehicleIdAlias: `COM_${id}`,
    });
  };

  onCancel = () => {
    this.props.onCancel && this.props.onCancel();
  };

  onConfirm = () => {
    const {selectedDriverId, selectedVehicleId} = this.state;
    const {driverStore, vehicleStore} = this.props.store;
    const {drivers} = driverStore;
    const driver = drivers.filter(dr => dr.id === selectedDriverId).shift();

    selectedDriverId && this.props.onSelect && this.props.onSelect(driver, '', selectedVehicleId);
    // Assign
    // vehicleStore.assignVehicle(selectedVehicleId, selectedDriverId).then(res => console.log(res));
  };

  onSearchDrivers = (e) => {
    this.setState({searchKey: e.target.value});
  };

  getPersonalVehicles = (id) => {
    const {vehicleStore} = this.props.store;

    if(!id)
      return false;

    // clear vehicle states
    this.setState({
      selectedVehicleId: null,
      selectedVehicleIdAlias: null,
      personalVehicleLoading: true,
    });

    // Load personal vehicle list
    vehicleStore.getPersonalVehicles(id, {size: 10, page_number: 0}).then(res => {
      if(res.ok && res.status === 200 && res.data && res.data.results) {
        this.setState({
          personalVehicles: res.data.results,
          personalVehicleLoading: false,
        });
      }
    })
  }

  getCompanyVehicles = () => {
    const {vehicleStore} = this.props.store;
    const courierId = _.get(this, 'props.store.userStore.account.id');

    if(!courierId)
      return false;

    vehicleStore.getCompanyVehicles(courierId, {size: 10, page_number: 0}).then(res => {
      if(res.ok && res.status === 200 && res.data && res.data.results) {
        this.setState({companyVehicles: res.data.results});
      }
    });
  }

  render() {
    const {selectedDriverId, selectedVehicleId, searchKey, personalVehicles, companyVehicles, selectedVehicleIdAlias, personalVehicleLoading} = this.state;
    const {classes, store, assignment, driverIdSelected, error} = this.props;
    const {assignmentStore, driverStore} = store;
    const {filteredShowingStops} = assignmentStore;
    const {loadingDrivers, drivers} = driverStore;
    const onSelectDriver = this.onSelectDriver
    const cities = filteredShowingStops && filteredShowingStops.map(s => _.capitalize(s.shipment.dropoff_address.city));
    const citiesUniq = cities ? _.uniq(cities) : [];
    const vehicleTypeAndCapacity = [
      {type: 'SEDAN', capacity: 22},
      {type: 'SUV', capacity: 30},
      {type: 'MINIVAN', capacity: 60},
      {type: 'VAN', capacity: 100},
      {type: 'CARGOVAN', capacity: 120},
      {type: 'TRUCK', capacity: 500},
    ];
    const workload = assignment.workload || assignment.shipment_count || 0;
    const suitableVehicles = vehicleTypeAndCapacity.filter(vh => vh.capacity >= workload);
    const recommendVehicle = _.minBy(suitableVehicles, 'capacity');

    if (loadingDrivers) {
      return <Box><LinearProgress style={{ height: '4px' }} color="primary" /></Box>;
    }

    if (!drivers || drivers.length < 1) {
      return <Box>You do not have any drivers. Please create some new one!</Box>
    }

    const filteredDrivers = _.cloneDeep(drivers).map(dr => {
      dr.first_name = _.capitalize(dr.first_name);
      dr.last_name = _.capitalize(dr.last_name);
      return dr;
    }).filter(dr => (dr.first_name + ' ' + dr.last_name).toLowerCase().indexOf(searchKey.trim().toLowerCase()) > -1
      || (dr.id + '').indexOf(searchKey.trim().toLowerCase()) > -1
    );
    const sortedDrivers = _.sortBy(filteredDrivers, ['first_name', 'last_name']);

    return (
      <Box className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box py={1}><strong>Assignment {assignment.label} - {assignment.shipment_count} Shipments</strong></Box>
            <Box py={1} className={classes.grayText}><small>Recommend min vehicle size: <strong>{recommendVehicle.type}</strong></small></Box>
          </Grid>
          <Grid />
          {/*<Grid item xs={6}>*/}
          {/*  <Box py={1}><strong>{citiesUniq.join('/ ')}</strong></Box>*/}
          {/*</Grid>*/}
        </Grid>
        <Box mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.listContainer}>
              <Box className={classes.listWrapper}>
                <Box py={1}>
                  <TextField onChange={this.onSearchDrivers}
                             value={searchKey}
                             variant="outlined"
                             label="Search drivers by name, ID..."
                             size="small"
                             fullWidth
                             style={{backgroundColor: '#fff'}}
                  />
                </Box>
                <Box className={classes.list}>
                  <Grid container direction="column" spacing={1}>
                    <List
                      itemCount={sortedDrivers.length}
                      itemSize={this.ITEMSIZE}
                      itemData={sortedDrivers}
                      height={this.HEIGHT}
                    >
                      {(props) => Row({ ...props, classes, selectedDriverId, onSelectDriver })}
                    </List>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.listContainer}>
              <PersonalVehicles
                driverIdSelected={driverIdSelected}
                loading={personalVehicleLoading}
                vehicles={personalVehicles}
                onSelectVehicle={this.onSelectPersoncalVehicle}
                selectedVehicleId={selectedVehicleId}/>
              <CompanyVehicles
                driverIdSelected={driverIdSelected}
                vehicles={companyVehicles}
                onSelectVehicle={this.onSelectCompanyVehicle}
                selectedVehicleId={selectedVehicleId}/>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              <Box className={classes.error}>{error}</Box>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <AxlButton
                    bgcolor="primary.white"
                    color="primary.graySeventh"
                    disableElevation
                    variant="outlined"
                    onClick={this.onCancel}
                    spacing={0.5}
                  >{`Cancel`}</AxlButton>
                </Grid>
                <Grid item>
                  <AxlButton
                    color="primary.white" loading={this.props.loading}
                    bgcolor={(!selectedDriverId || this.props.loading) ? 'primary.gray400' : 'primary.periwinkle'}
                    variant="contained"
                    disableElevation
                    spacing={0.5}
                    disabled={!selectedDriverId || this.props.loading}
                    onClick={this.onConfirm}>
                    {!driverIdSelected ? 'Assign' : 'Reassign'}
                  </AxlButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

const AssignDriverCompose = compose(
  inject("store"),
  observer
) (AssignDriver);

export default withStyles(styles)(AssignDriverCompose);

function PersonalVehiclesCompose({
                                   loading = false,
                                   classes,
                                   vehicles = [],
                                   selectedVehicleId = null,
                                   onSelectVehicle = () => {},
                                   ...props
}) {

  return <Box className={classes.listWrapperFlexible}>
    <Grid container direction="column" spacing={1}>
      {!loading ? vehicles.length ? vehicles.map((vehicle, i) => <Grid item key={i}>
        <Box className={clsx({
          [classes.cardContainer]: true,
          [classes.cardSelected]: vehicle.id === selectedVehicleId,
        })}>
          <Grid container spacing={1} onClick={() => onSelectVehicle(vehicle.id)}>
            <Grid item xs={11}>
              <Box p={0.5} className={classes.cardLabel}>{_.get(vehicle, 'vehicle_name', _.get(vehicle, 'category', _.get(vehicle, 'license_plate')))}</Box>
              <Box p={0.5} className={clsx(classes.cardSubtitle, classes.grayText)}>
                {[_.get(vehicle, 'car.year', '-'),
                  _.get(vehicle, 'car.make', '-'),
                  _.get(vehicle, 'car.model', '-'),
                  _.get(vehicle, 'car.submodel', '-'),
                ].join(" ")}
              </Box>
            </Grid>
            <Grid item xs={1} style={{alignSelf: 'center'}}>
              <AxlRadio
                checked={vehicle.id === selectedVehicleId}
                name="driver-id"
                color="primary" />
            </Grid>
          </Grid>
        </Box>
      </Grid>) : <Grid item>No vehicles</Grid> : <Grid item>Loading...</Grid>}
    </Grid>
  </Box>;
}

export const PersonalVehicles =  withStyles(styles)(PersonalVehiclesCompose);

function CompanyVehiclesCompose({
                                  classes,
                                  keySearch = ['vehicle_name', 'id', 'color', 'car.year', 'car.make', 'car.model', 'car.submodel', 'car.category', 'car.type'],
                                  vehicles = [],
                                  selectedVehicleId = null,
                                  onSelectVehicle = () => {},
                                  ...props}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(vehicles);
  }, [vehicles]);

  const onSearch = ({ target: { value } }) => {
    const search = _.debounce((v) => {
      let newItems = vehicles;
      if(v) {
        newItems = _.filter(items, (item) => {
          const keySearchMap = keySearch.map(key => _.get(item, key, '')).filter(key => key).join(" ").toLowerCase();

          if(_.includes(keySearchMap, v.toLowerCase()))
            return item;
        });
        console.log(newItems)
        setItems(newItems);
      } else {
        setItems(vehicles);
      }

    }, 300);

    search(value);
  };

  return <Box className={classes.listWrapper}>
    <Box mb={1} bgcolor={'primary.white'}>
      <AxlInput placeholder={`Search company vehicles...`} spacing={0} onChange={onSearch} />
    </Box>
    <Box className={classes.listWrapperScroll}>
      <Grid container direction="column" spacing={1} style={{marginBottom: 16}}>
        {items.map((vehicle, i) => <Grid item key={i}>
          <Box className={clsx({
            [classes.cardContainer]: true,
            [classes.cardSelected]: vehicle.id === selectedVehicleId,
          })}>
            <Grid container spacing={1} onClick={() => onSelectVehicle(vehicle.id)}>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item xs={2} style={{alignSelf: 'center'}}>
                    <Box><img src={`/assets/images/logo.png`} className={classes.cardAvatar} /></Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Box p={0.5} className={classes.cardLabel}>{_.get(vehicle, 'vehicle_name', _.get(vehicle, 'category', _.get(vehicle, 'license_plate')))}</Box>
                    <Box p={0.5} className={clsx(classes.cardSubtitle, classes.grayText)}>
                      {[_.get(vehicle, 'car.year', '-'),
                        _.get(vehicle, 'car.make', '-'),
                        _.get(vehicle, 'car.model', '-'),
                        _.get(vehicle, 'car.submodel', '-'),
                      ].join(" ")}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} style={{alignSelf: 'center'}}>
                <AxlRadio
                  checked={vehicle.id === selectedVehicleId}
                  name="driver-id"
                  color="primary" />
              </Grid>
            </Grid>
          </Box>
        </Grid>)}
      </Grid>
    </Box>
  </Box>;
}

export const CompanyVehicles =  withStyles(styles)(CompanyVehiclesCompose);
