import React, {Component, Fragment} from "react";
import {inject, observer} from "mobx-react";
import Moment from 'react-moment';
import {AxlLoading, AxlPanel, AxlTable} from "axl-reactjs-ui";
import {Box, Grid, Button, DialogTitle, Dialog, DialogContent, CircularProgress} from "@mui/material";
import { Pagination } from '@mui/material';
import {Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon} from "@mui/icons-material";
import moment from "moment";
import {compose} from "recompose";
import withStyles from '@mui/styles/withStyles';
// Styles
import styles, * as E from './styles';
import {API_REQUESTING, api} from "../../../stores/api";
import {withRouter} from "react-router-dom";
import {StyledAxlPanel, StyledLabel} from "../styles";
import {MoreLink} from "./styles";

const headerStyle = {backgroundColor: '#d6d3ff',color: '#5a5a5a', fontWeight: 900, whiteSpace: 'nowrap'}

const stylesMarterial = {
  content: {
    fontFamily: 'AvenirNext',
    padding: '0 24px 16px',
    fontSize: 12,
    position: 'relative',
    '& .MuiGrid-item' : {
      marginTop: 10,
      width: '100%'
    }
  },
  box: {
    paddingTop: 8
  },
  title: {
    fontFamily: 'AvenirNext-Bold',
    fontSize: 18,
    fontWeight: 'bold',
  }
};
class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      size: 20,
      totalResult: 0,
      selectedRouteDetail: null,
      selectedPaymentDetail: null,
    }
    this.assignmentDetailFields = [
      {label: "Assignment", key: "label"},
      {label: "Assignment Date", key: "assignmentDate"},
      {label: "Driver", key: "driver"},
      {label: "Shipments no.", key: "shipmentNo"},
      {label: "Failed Shipments", key: "shipmentFailed"},
      {label: "Clients", key: "clientName"},
    ];
  }

  renderRepaidPayments = (paymentDetails) => {
    const {selectedPaymentDetail} = this.state;
    return (
      <E.Table>
        <AxlTable>
          <AxlTable.Header>
            <AxlTable.Row>
              <AxlTable.HeaderCell style={headerStyle}>
                Repaid On
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Last Updated
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Status
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Repaid By
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Account #
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Routing #
              </AxlTable.HeaderCell>
            </AxlTable.Row>
          </AxlTable.Header>
          <AxlTable.Body>
            {paymentDetails.supplement_payments.map(supplementPayment => {
              let IconTag = VisibilityIcon;
              if (selectedPaymentDetail === supplementPayment.id) {
                IconTag = VisibilityOffIcon;
              }
              return (
                <Fragment key={supplementPayment.id}>
                  <AxlTable.Row>
                    <AxlTable.Cell>
                      <Moment interval={0}
                              format='YYYY-MM-DD HH:mm'
                      >
                        {supplementPayment._created}
                      </Moment>
                    </AxlTable.Cell>
                    <AxlTable.Cell>
                      {supplementPayment._updated ?
                        <Moment interval={0} format='YYYY-MM-DD HH:mm'>{paymentDetails._updated}</Moment> :
                        <span>N/A</span>
                      }
                    </AxlTable.Cell>
                    <AxlTable.Cell>
                      <Box mr={1} component="span">{supplementPayment.status}</Box>
                      {supplementPayment.requestErrorContent && (
                        <IconTag
                          style={{verticalAlign: "text-bottom", cursor: "pointer"}}
                          color="primary" fontSize="small"
                          onClick={() => this.setState({selectedPaymentDetail: supplementPayment.id === selectedPaymentDetail ? null : supplementPayment.id})}
                        />
                      )}
                    </AxlTable.Cell>
                    <AxlTable.Cell>{supplementPayment.payer_username}</AxlTable.Cell>
                    <AxlTable.Cell>{supplementPayment.account_number}</AxlTable.Cell>
                    <AxlTable.Cell>{supplementPayment.routing_number}</AxlTable.Cell>
                  </AxlTable.Row>
                  {selectedPaymentDetail === supplementPayment.id && (
                    <AxlTable.Row>
                      <AxlTable.Cell colSpan={6} style={{backgroundColor: '#f5f5f5'}}>
                        <Box m={1} p={2} style={{backgroundColor: '#fff'}}>
                          {supplementPayment.requestErrorContent}
                        </Box>
                      </AxlTable.Cell>
                    </AxlTable.Row>
                  )}
                </Fragment>
              )
            })}
          </AxlTable.Body>
        </AxlTable>
      </E.Table>
    )
  }

  loadAssignmentDetail(ct) {
    this.setState({ isLoadingAssignment: true });
    api.get(`/dsp/assignments/${ct.assignment_id}/detail`).then(resp => {
      if(resp.ok && resp.data) {
        const { assignmentLabel, clients, driver, assignment, stops} = resp.data;
        const stopFail = stops.filter((s) => s.type === 'DROP_OFF' && s.status === 'FAILED');
        const clientName = clients && clients.length > 0 ? clients.map(c =>c.company).join("\r\n") : "-";
        const detail = {
          id: ct.assignment_id,
          label: assignmentLabel?.prefix || assignment.label || '-',
          assignmentDate: moment(assignment.predicted_start_ts).format("YYYY-MM-DD") || '',
          driver: `${driver.first_name} ${driver.last_name}`,
          shipmentNo: assignment.shipment_count,
          shipmentFailed: stopFail.length,
          clientName,
          type: ct.type,
          amount:ct.amount
        }
        this.setState({selectedRouteDetail: detail, isLoadingAssignment: false});
      }
    });
  }

  viewAssignmentDetails(e, assignmentId) {
    e.preventDefault();
    const {paymentDetails} = this.props;
    const assignment = paymentDetails.assignments.filter(a => a.id === assignmentId).shift();
    const assignmentDate = moment(assignment.predicted_start_ts).format("YYYY-MM-DD");
    this.props.history.push(`/routes/${assignmentDate}/all/${assignmentId}`);
  }

  renderTransactions = (paymentDetails) => {
    const {selectedRouteDetail, page, size} = this.state;
    const {pagination, classes} = this.props;
    const {assignments, drivers, contractor_transactions} = paymentDetails;
    const style = {color: 'inherit'};
    // let viewedAssignment = null;
    let transactions = contractor_transactions;
    // if (selectedRouteDetail) {
    //   viewedAssignment = assignments.filter(a => a.id === selectedRouteDetail.assignment_id).shift();
    // }
    const pageCount = contractor_transactions.length > 0 && Math.ceil(contractor_transactions.length / size);
    if (pagination) {
      const from = Math.max(0, page - 1);
      const to = Math.min(page, pageCount);
      transactions = contractor_transactions.slice(from * size, to * size);
    }

    return (
      <E.Table>
        <AxlTable>
          <AxlTable.Header>
            <AxlTable.Row>
              <AxlTable.HeaderCell style={headerStyle}>
                Id
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Event Type
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Completed Time
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Amount
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Driver
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Note
              </AxlTable.HeaderCell>
              <AxlTable.HeaderCell style={headerStyle}>
                Details
              </AxlTable.HeaderCell>
            </AxlTable.Row>
          </AxlTable.Header>
          <AxlTable.Body>
            {transactions.map(ct => {
              let driver ;
              if(ct.assignment_id) {
                 const assignment = assignments.filter(a => a.id === ct.assignment_id).shift();
                 driver = drivers && drivers.filter(dr => dr.id === assignment.driver_id).shift();
              }
              return (
                <Fragment key={ct.id}>
                  <AxlTable.Row style={{color: ct.type === 'PAYMENT - DEDUCT' ? 'red' : undefined}}>
                    <AxlTable.Cell style={style}>
                      <span>{ct.id}</span>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                      <span>{ct.type}</span>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                      <Moment interval={0} format='YYYY-MM-DD HH:mm'>{ct.actual_end_ts}</Moment>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                      <span>{'$' + ct.amount}</span>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                      <span>{driver && `${driver.first_name} ${driver.last_name}` || ''}</span>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                      <span style={{wordBreak: "break-all"}}>{ct.notes}</span>
                    </AxlTable.Cell>
                    <AxlTable.Cell style={style}>
                    {["OUTVOICE - ROUTE_DRIVING", "OUTVOICE - TIP"].includes(ct.type) && !!ct.assignment_id && (
                        <MoreLink onClick={() => {
                          this.loadAssignmentDetail(ct);
                          this.setState({selectedRouteDetail: selectedRouteDetail && selectedRouteDetail.id === ct.id ? null : ct});
                          }}>
                          {selectedRouteDetail && selectedRouteDetail.id === ct.id ? "Hide" : "Show"} Detail
                        </MoreLink>
                      )}
                    </AxlTable.Cell>
                  </AxlTable.Row>
                </Fragment>
              )
            })}
            <Dialog open={!!selectedRouteDetail} onClose={() => this.setState({selectedRouteDetail:null})} >
              <DialogTitle className={classes.title}>
                Event Detail
              </DialogTitle>
              <DialogContent className={classes.content}>
                {this.state.isLoadingAssignment && <div style={{ position:'absolute',top: '40%', left: '40%' }}>
                  <CircularProgress color="primary" size={30} />
                </div>}
                
                <Grid container>
                  <Grid item xs={8}>Event Type: <b>"{selectedRouteDetail?.type}"</b>
                  </Grid>
                  <Grid item xs={4}  style={{textAlign: 'right'}}>{'$' + selectedRouteDetail?.amount}</Grid>
                  <Grid item>
                    <Grid container style={{backgroundColor: '#f5f5f5', padding: '8px 16px 16px 16px'}}>
                      {this.assignmentDetailFields.map(field => (
                        <Grid key={field.key} item xs={4}>
                          <Box className={classes.box}>{field.label}</Box>
                          <Box className={classes.box}>
                            <strong style={{whiteSpace: ['clientName'].includes(field.key) ? 'pre' : 'unset'}}>{selectedRouteDetail && selectedRouteDetail[field.key] || (field.key === 'shipmentFailed' ? 0 : 'N/A')}</strong>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box style={{textAlign: 'center'}}>
                      <Button size="small" variant="outlined" color="secondary" onClick={(e) => this.viewAssignmentDetails(e, selectedRouteDetail.id)}>
                        View in Dispatch
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </AxlTable.Body>
        </AxlTable>
        {pagination && pageCount > 1 && (
          <Grid container justifyContent="center">
            <Box p={1}>
              <Pagination
                color="primary" size="small" align="center"
                count={pageCount}
                onChange={(e, page) => this.setState({page})}
              />
            </Box>
          </Grid>
        )}
      </E.Table>
    );
  }

  render() {
    const {repayStatus, paymentDetails} = this.props;
    if (!paymentDetails) {
      return null;
    }

    return (
        <StyledAxlPanel style={{marginBottom: 0}}>
          <AxlPanel.Row>
            <AxlPanel.Col>
              <AxlPanel.Row style={{justifyContent: "space-between"}}>
                <AxlPanel.Row col>
                  <StyledLabel>Events</StyledLabel>
                </AxlPanel.Row>
                <AxlPanel.Row col>
                  <AxlPanel.Row>
                    <span>Total amount:</span>
                    <span style={{paddingLeft: "5px", fontWeight: "bold", fontFamily: 'AvenirNext-Bold'}}>{'$' + paymentDetails.amount}</span>
                  </AxlPanel.Row>
                </AxlPanel.Row>
              </AxlPanel.Row>
              <AxlPanel.Row>
                <AxlPanel.Col style={{position: 'relative', backgroundColor: repayStatus === API_REQUESTING ? '#ccc' : undefined}}>
                  {repayStatus === API_REQUESTING &&
                  <AxlLoading color={`#887fff`} thin={1} size={80} style={styles.loadingStyle}/>}
                  {this.renderTransactions(paymentDetails)}
                </AxlPanel.Col>
              </AxlPanel.Row>
            </AxlPanel.Col>
          </AxlPanel.Row>
        </StyledAxlPanel>
    )
  }
}


export default compose(
  withRouter,
  withStyles(stylesMarterial)
)(EventList);
