const styles = theme => ({
  container: {
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '4px',
    position: 'relative',
    overflowY: 'hidden',
  },
  label: {
    display: 'inline-block',
    minWidth: '45px',
    fontSize: '11px',
    color: '#bebfc0',
    fontWeight: 600,
    fontFamily: 'AvenirNext-DemiBold',
    marginRight: '2px'
  },
  label2: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '14px',
    color: '#000000',
    marginRight: '5px'
  },
  label3: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '13px',
    color: '#000000',
    marginRight: '5px'
  },
  button: {
    width: '80px',
    margin: '0 3px',
  },
  driverPhotoContainer: {
    width: '75px',
    height: '75px',
    backgroundColor: '#d8d8d8',
  },
  photo: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  defaultDriverPhoto: {
    width: '100%',
    height: '100%',
    backgroundColor: '#eee',
    cursor: 'pointer',
  },
  itemWrapper: {
    whiteSpace: 'nowrap',
  },
  text: {
    fontFamily: 'AvenirNext',
    fontSize: '12px',
    color: '#000000',
  },
  driverName: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000000'
  },
  driverPhoto: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer'
  },
  modalStyle: {
    maxHeight: '90%',
    overflowY: 'scroll',
  },
  wrapImages: {
    width: '100%',
    textAlign: 'center',
    lineHeight: 0
  },
  imageShipment: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  showLink: {
    fontFamily: 'AvenirNext',
    fontSize: '12px',
    color: '#4a90e2',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  divider: {
    width: '362px',
    maxWidth: '100%',
    height: '1px',
    backgroundColor: '#cfcfcf',
    margin: '10px auto'
  },
  driverRow: {
    marginBottom: 10
  },
  driverInfoLeft: {
    maxWidth: 'calc(100% - 100px)',
  },
  driverInfoRight: {
    maxWidth: 'calc(100% - 100px)',
  },
  unassignedText: {
    fontWeight: 'bold',
    color: '#f00',
  },
  dialogPaper: {
    padding: '24px',
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  dialogContent: {
    margin: '8px 0px',
    color: '#bfbfbf',
  },
  dialogButton: {
    padding: '4px 48px',
  },
});

export default styles;