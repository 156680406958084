import {action, observable} from "mobx";
import {API_REQUEST_ERROR, API_REQUEST_SUCCEEDED, API_REQUESTING} from "./api";

class ContractorStore {
  @observable contractorInfo = null;
  @observable updateBankInfoStatus = null;
  @observable bankTransactionsHistory = [];
  @observable loadingHistory = false;

  constructor(api) {
    this.api = api;
  }

  getContractorDetails(contractorId){
    this.api.get(`/contractors/${contractorId}`)
        .then(response => {
          if (response.status === 200) {
            this.contractorInfo = response.data;
          }
        })
  }

  @action
  getDspDetails(contractorId){
    this.api.get(`/dsp/${contractorId}`)
      .then(response => {
        if (response.status === 200) {
          this.contractorInfo = response.data;
        }
      })
  }

  getContractorBankHistory(contractorId) {
    this.loadingHistory = true;
    this.api.get(`/contractors/bank-history/${contractorId}`).then(res => {
      if (res.status === 200) {
        this.bankTransactionsHistory = res.data || [];
      } else {
        this.bankTransactionsHistory = [];
      }
      setTimeout(() => this.loadingHistory = false, 1000)
    })
  }

  getPaymentBankHistory(paymentId) {
    this.loadingHistory = true;
    this.api.get(`/contractors/bank-history/${paymentId}`, {use_payment_id: true}).then(res => {
      if (res.status === 200) {
        this.bankTransactionsHistory = res.data || [];
      } else {
        this.bankTransactionsHistory = [];
      }
      setTimeout(() => this.loadingHistory = false, 1000)
    })
  }

  requestBankInfoUpdate(paymentIds, methods, message){
    this.updateBankInfoStatus = API_REQUESTING;
    return this.api.post(`/contractors/request_bank_info_update`, {
      payment_ids: paymentIds, methods, message
    }).then(response => {
      if (response.status === 200) {
        this.updateBankInfoStatus = API_REQUEST_SUCCEEDED;
      } else {
        this.updateBankInfoStatus = API_REQUEST_ERROR;
      }
      return response;
    });
  }
}

export default ContractorStore;
