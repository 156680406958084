import colors from "../../themes/colors";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 8,
});

export const mainTheme = createTheme({
  palette: {
    primary: colors,
  },
  overrides: {}
});
