import * as serviceWorker from "./serviceWorker";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";

const rumApplicationId = process.env.REACT_APP_RUM_APPLICATION_ID;
const rumClientToken = process.env.REACT_APP_RUM_CLIENT_TOKEN;

if (rumApplicationId && rumClientToken) {
  datadogRum.init({
    applicationId: rumApplicationId,
    clientToken: rumClientToken,
    site: "datadoghq.com",
    service: "dsp",
    env: process.env.REACT_APP_RUM_ENV,
    version: process.env.REACT_APP_RUM_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
