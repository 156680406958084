import React, { useState, useEffect, useRef } from 'react';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import * as M from '@mui/material';
import AxlButton from '../AxlButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '16px 0',
  },
  form: {
    display: 'flex',
    width: '100%',
  },
  root: {
    display: 'flex',
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    marginRight: '10px',
  },
  input: {
    flex: 1,
    fontFamily: 'AvenirNext',
    fontWeight: 600,
    fontSize: 15,
    color: '#5a5a5a', //aeaeae
    border: 'none',
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: 14,
      color: '#aeaeae', //5a5a5a
    },
  },
  iconButton: {
    padding: '0px 5px',
    color: '#cccccc',
  },
}));

export default function AxlSearchInput({
                                         placeholder = 'Search here...',
                                         searchAction = () => {}
}) {
  const classes = useStyles();
  const searchInputRef = useRef('');

  return (
    <M.Box className={classes.container}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          searchAction(searchInputRef.current.value);
        }}
      >
        <M.Paper className={classes.root}>
          <IconButton className={classes.iconButton} aria-label="menu">
            <SearchIcon fontSize={`small`} />
          </IconButton>
          <M.InputBase
            className={classes.input}
            inputRef={searchInputRef}
            placeholder={placeholder}
          />
        </M.Paper>
        <AxlButton
          type="submit"
          spacing={0}
          bgcolor={'primary.periwinkle'}
          color={'primary.white'}
        >{`SEARCH`}</AxlButton>
      </form>
    </M.Box>
  );
}
