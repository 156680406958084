import React, { Component } from 'react';
import {inject, observer} from "mobx-react";
import { AxlLoading } from 'axl-reactjs-ui';
// Styles
import styles, * as E from './styles';
// Components
import ShareFileDetail from './ShareFileDetail';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {ChatBoxContainer} from "../../containers/ShipmentMessenger/ShipmentSupportPanel/ChatBoxContainer";

export class ShareFilesChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isShowFileDetail: false,
      imageDetail: null
    }
  }

  _isShowFileDetail = (image) => {
    this.setState({
      _isShowFileDetail: !this.state._isShowFileDetail,
      imageDetail: image
    })
  }

  _isResetImgUrl = () => {
    this.setState({imageDetail: null})
  }

  render() {
    const fakeImageUrl = '/assets/images/bg.png';
    const { imageDetail } = this.state;
    const { messengerStore } = this.props.store;
    const { messengers = [] } = messengerStore;
    let images = [];
    messengers.map(m => m.files.map(file => images.push(file)));

    return <E.Container>
      <E.Title>
        {imageDetail && <E.BackButton onClick={this._isResetImgUrl}>
          <i className="fa fa-angle-left" />
        </E.BackButton>}
        {`Shared Files`}
      </E.Title>
      {messengers.length ? <E.Inner>
        {!imageDetail ? <E.Scrollable>
          <E.GridFlex>
            {images.map((image, index) => <E.Flex key={index} onClick={() => this._isShowFileDetail(image)}>
              <E.ImageContainer>
                <E.Image src={image.url} />
              </E.ImageContainer>
            </E.Flex>)}
          </E.GridFlex>
        </E.Scrollable> : <ShareFileDetail imgUrl={imageDetail.url}  />}
      </E.Inner> : <E.LoadingContainer><AxlLoading thin={1} size={75} color={`#000`} /></E.LoadingContainer>}
    </E.Container>;
  }
}

export default compose(
  withRouter,
  inject('store'),
  observer
)(ShareFilesChat);