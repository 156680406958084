import React, {Component} from "react";
import {AxlPanel} from "axl-reactjs-ui";
import {StyledLabel, StyledLink} from "../styles";
import List from "../ListPayment";
import Moment from "react-moment";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {compose} from "recompose";

import TransactionFilter from "../TransactionFilter";

class DspTransactionList extends Component {
  constructor(props) {
    super(props);
    this.filter = {};
  }

  componentDidMount() {
    let {searchDspPaymentStore, store} = this.props;
    const dspId=store.userStore.accountData?.id
    searchDspPaymentStore.setFilter("q", `id: ${dspId}`);
    searchDspPaymentStore.search();
  }

  displayTransactionDetail = (paymentId) => {
    this.props.history.push(`/payments/transactions/${paymentId}`);
  }

  getUrl(baseUrl, filter, withTime) {
    let tmp = baseUrl + "?";
    if (filter.fromDate) {
      tmp += `from=${withTime ? moment(filter.fromDate).startOf('day').utc().toISOString() : filter.fromDate}`
      if (filter.toDate) {
        tmp += `&&to=${withTime ? moment(filter.toDate).endOf('day').utc().toISOString() : filter.toDate}`
      }
      if (filter.status) {
        tmp += `&&status=${filter.status}`
      }
    } else {
      if (filter.toDate) {
        tmp += `to=${withTime ? moment(filter.toDate).utc().toISOString() : filter.toDate}`
        if (filter.status) {
          tmp += `&&status=${filter.status}`
        }
      } else {
        if (filter.status) {
          tmp += `status=${filter.status}`
        }
      }
    }
    return tmp;
  }

  doFilter = (filter, notForceUpdate) => {
    const {history, searchDspPaymentStore, store} = this.props;
    const dspId=store.userStore.accountData?.id
    // const dspId = 19243;
    // const dspId = 23174;
    filter.query = `id:${dspId}`;
    history.push(this.getUrl(`/payments/transactions/`, filter));
    if (filter.status) {
      searchDspPaymentStore.setFilter("status", filter.status);
    } else {
      searchDspPaymentStore.removeFilter("status");
    }
    searchDspPaymentStore.setFilter("from_date", filter.fromDate ? moment(filter.fromDate).startOf('day').utc().toISOString() : null);
    searchDspPaymentStore.setFilter("to_date", filter.toDate ? moment(filter.toDate).endOf('day').utc().toISOString() : null);
    if (filter.query) {
      searchDspPaymentStore.setFilter("q", filter.query);
    } else {
      searchDspPaymentStore.removeFilter("q");
    }
    this.filter = filter;
    if (notForceUpdate) {
      searchDspPaymentStore.search();
    } else {
      this.forceUpdate(() => {
        searchDspPaymentStore.search();
      })
    }
  }

  render() {
    const {searchDspPaymentStore, type, allowSelect, disabledIds, multipleSelect, match, history} = this.props;
    const store = searchDspPaymentStore.getStore(type);
    const renderer = {
      id: (v) => <StyledLink onClick={() => this.displayTransactionDetail(v)}>{v}</StyledLink>,
      amount: (v) => <span>${v}</span>,
      _created: (v) => <Moment interval={0} format='YYYY-MM-DD HH:mm'>{v}</Moment>,
      _updated: (v) => v ? <Moment interval={0} format='YYYY-MM-DD HH:mm'>{v}</Moment> : '',
    };

    if (!store) return null;
    const props = {
      renderer, type, allowSelect, disabledIds,
      pagination: true, multipleSelect, store, alt: true
    };

    return (
        <AxlPanel>
          <AxlPanel.Row>
            <TransactionFilter filter={this.filter} doFilter={this.doFilter}/>
          </AxlPanel.Row>
          <AxlPanel.Row style={{marginTop: "10px"}}>
            <AxlPanel.Col>
              <List {...props} />
            </AxlPanel.Col>
          </AxlPanel.Row>
        </AxlPanel>
    )
  }
}

export default compose(
  withRouter,
  inject('searchDspPaymentStore', 'store'),
  observer
)(DspTransactionList);

