import { api, paymentAPI } from './api';
import ShipmentStore from './ShipmentStore';
import UserStore from './UserStore';
import AssignmentStore from './AssignmentStore';
import DriverStore from './Driver';
import HistoryStore from './History';
import Logger from './Logger';
import MessengerStore from './Messenger';
import LocationStore from './Location';
import ContractorStore from './ContractorStore';
import PaymentStore from './PaymentStore';
import SearchDspPaymentStore from './SearchDspPaymentStore';
import DspEventStore from './DspEventStore';
import PermissionStore from './PermissionStore';

const logger = new Logger();
window.logger = logger;
const assignmentStore = new AssignmentStore(api);

export const stores = {
  logger: window.logger,
  userStore: new UserStore(api),
  assignmentStore,
  shipmentStore: new ShipmentStore(api, assignmentStore),
  driverStore: new DriverStore(api),
  locationStore: new LocationStore(logger, api),
  historyStore: new HistoryStore(api),
  messengerStore: new MessengerStore(api, assignmentStore),
  paymentStore: new PaymentStore(paymentAPI),
  contractorStore: new ContractorStore(paymentAPI),
  dspEventStore: new DspEventStore(paymentAPI),
  searchDspPaymentStore: new SearchDspPaymentStore(paymentAPI),
  permissionStore: new PermissionStore(api),
};
