import {observable, action, decorate} from 'mobx';
import {toast} from "react-toastify";
import {get, set} from "lodash";

class UserStore {
  constructor(appStore) {
    this.appStore = appStore;
    this.api = appStore.api;
  }

  user = {};
  account = {};
  accountData = {};
  bankRequest = {};
  updating = false;

  async getUser() {
    await this.api.get('/me')
      .then(response => {
        if (response.status === 200) {
          this.user = response.data.user;
        }
      })
  }

  getAccountDataValue = (field) => {
    return get(this?.accountData, field, "");
  }

  updateDSPData = (field, value) => {
    set(this.accountData, field, value);
  }

  async getDsp() {
    await this.api.get('/couriers/info')
      .then(response => {
        if (response.status === 200) {
          this.account = response.data;
          this.accountData = response.data;
        } else {
          this.account = {};
          this.accountData = {};
        }
      })
  }

  getLatestBankRequest() {
    this.api.get('/couriers/bank-request/latest').then(res => {
      if (res.ok) {
        this.bankRequest = res.data;
      } else {
        this.bankRequest = {};
      }
    })
  }

  updateDsp(cb) {
    this.updating = true;
    this.api.post('/couriers/info', this.accountData)
      .then(response => {
        this.updating = false;
        if (response.status === 200) {
          this.account = response.data;
          toast.success("Save successfully!");
        } else {
          const msg = response?.data?.message || "Failed to save with status " + response.status;
          toast.error(msg);
        }
        if (cb) cb(response);
      })
  }

  submitBankInfoRequest(cb) {
    if (this.accountData.bank_account == this.account.bank_account
      && this.accountData.bank_routing == this.account.bank_routing
    ) {
      toast.warn("Bank info was not changed");
      return;
    }

    if (this.accountData.bank_account == this.bankRequest?.new_account_number
      && this.accountData.bank_routing == this.bankRequest?.new_routing_number
    ) {
      toast.warn("Bank info is same as previous request");
      return;
    }

    this.updating = true;
    this.api.put('/couriers/bank', this.accountData)
      .then(response => {
        this.updating = false;
        if (response.status === 200) {
          this.bankRequest = response.data;
          toast.success("Submit successfully!");
        } else {
          const msg = response?.data?.message || "Failed to submit with status " + response.status;
          toast.error(msg);
        }
        if (cb) cb(response);
      })
  }
}

decorate(UserStore, {
  user: observable,
  updating: observable,
  account: observable,
  accountData: observable,
  bankRequest: observable,
  getAccountDataValue: action,
  getUser: action,
  getDsp: action,
  updateDSPData: action,
  getLatestBankRequest: action,
  updateDsp: action,
  submitBankInfoRequest: action,
});

export default UserStore;
