import React, {Component} from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import styles from './DriverStepperStyles';
import colors from "../../themes/colors";
import fonts from "../../themes/fonts";

function DriverStepper({
    classes,
    half = null,
    currentStep
}) {
    const steps = ['Profile', 'Vehicle', 'Account'];

    const stepContainerStyles = makeStyles({
        root: {
            backgroundColor: colors.transparent,
            zIndex: 1,
            color: '#fff',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: colors.transparent,
            width: 23,
            height: 23,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundColor: colors.white,
            borderColor: colors.periwinkle,
        },
        completed: {
            backgroundColor: colors.white,
            borderColor: colors.periwinkle,
        },
    });

    const stepIconStyles = makeStyles({
        root: {
            backgroundColor: colors.grayFifth,
            zIndex: 1,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: colors.periwinkle,
            width: 13,
            height: 13,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundColor: colors.periwinkle,
            borderColor: colors.periwinkle,
        },
        completed: {
            backgroundColor: colors.periwinkle,
            borderColor: colors.periwinkle,
        },
    });

    const halfStepIconStyles = makeStyles({
        root: {
            backgroundColor: colors.white,
            zIndex: 1,
            borderWidth: 9,
            borderStyle: 'solid',
            borderColor: colors.periwinkle,
            width: 0,
            height: 0,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftColor: colors.white,
            borderBottomColor: colors.white,
            transform: 'rotate(-135deg)',
        },
    });

    const StepsConnect = withStyles({
        alternativeLabel: {
            left: '-50%',
            right: '50%',
            top: 22,
        },
        active: {
            '& $line': {
                backgroundColor: colors.main,
            },
        },
        completed: {
            '& $line': {
                backgroundColor: colors.main,
            },
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: colors.periwinkle,
            borderRadius: 1,
        },
    })(StepConnector);

    function StepIcons(props) {
        const s1 = stepContainerStyles();
        const s2 = stepIconStyles();
        const { active, completed } = props;

        return (
          <div
            className={clsx(s1.root, {
                [s1.active]: active,
                [s1.completed]: completed,
            })}>
              <div
                className={clsx(s2.root, {
                    [s2.active]: active,
                    [s2.completed]: completed,
                })}></div>
          </div>
        );
    }

    function HalfStepIcon(props) {
        const s1 = stepContainerStyles();
        const s2 = halfStepIconStyles();
        const { active, completed } = props;

        return (
          <div
            className={clsx(s1.root, {
                [s1.active]: active,
                [s1.completed]: completed,
            })}>
              <div
                className={clsx(s2.root, {
                    [s2.active]: active,
                    [s2.completed]: completed,
                })}></div>
          </div>
        );
    }

    const StepsLabelCustom = withStyles({
        alternativeLabel: {
            fontFamily: fonts.Regular,
            fontSize: 16,
            color: colors.grayThird,
            marginTop: `5px !important`,
        },
        active: {
            fontFamily: fonts.DemiBold,
            fontSize: 16,
            color: `${colors.periwinkle} !important`,
        },
        completed: {
            fontFamily: fonts.DemiBold,
            fontSize: 16,
            color: `${colors.periwinkle} !important`,
        },
    })(StepLabel);

    return (
      <Stepper activeStep={currentStep} alternativeLabel connector={<StepsConnect />}>
          {steps.map((label, index) => (
            <Step key={label}>
                <StepsLabelCustom StepIconComponent={half === index ? HalfStepIcon : StepIcons}>{label}</StepsLabelCustom>
            </Step>
          ))}
      </Stepper>
    );
}

export default withStyles(styles)(DriverStepper);