import { InputBase } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import fonts from "../../themes/fonts";
import colors from "../../themes/colors";

export const InputText = withStyles(({palete, spacing}) => ({
  root: {
    border: 'none',
    borderRadius: 8,
  },
  input: {
    border: 'none',
    fontFamily: fonts.Medium,
    fontWeight: 'normal',
    fontSize: 18,
    color: colors["greyish-brown"],
    padding: spacing(0.625, 1),
    '&::placeholder': {
      fontFamily: fonts.Medium,
      fontWeight: 'normal',
      fontSize: 15,
      color: colors.graySeventh,
    },
  },
}))(InputBase);

const styles = theme => ({
  label: {
    color: colors.blackMain,
    fontSize: 17,
    fontFamily: fonts.Medium,
    margin: 0,
  },  
  customInput: {
    // padding: '1.5px 5px',
    width: '100%',
    fontSize: '13px',
    background: 'none',
    fontWeight: 600
  },
  nativeDateInput: {
    display: 'block',
    width: '100%',
    border: 'none',
    borderRadius: 4,
    outline: 'none',
    padding: '8px 10px',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    color: 'inherit',
  },
  prefix: {
    fontSize: 18,
    paddingLeft: 5
  },
});

export default styles;    