import React from 'react';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import makeStyles from '@mui/styles/makeStyles';

import acl from 'constants/acl';
import styles, { Item, Circle, Text } from './styles';

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    maxHeight: 120,
    marginTop: 10,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d8d8d8',
      border: '1px solid transparent',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
    },
  },
});

function NoteContent(props) {
  const classes = useStyles();

  const { notes, addFnc, editFnc, isEnableEdit, title, store } = props;
  const { permissionStore } = store;
  const isDenied = permissionStore.isDenied(acl.ASSIGNMENTS.NOTE);

  if (notes.length === 0) {
    return (
      <div style={styles.contentWrapper}>
        <span style={styles.showLink} onClick={addFnc}>
          + {title}
        </span>
      </div>
    );
  }

  return (
    <div style={styles.noteWrapper}>
      <div style={styles.contentWrapper}>
        <div style={styles.header}>
          <span style={{ ...styles.showLink, ...{ cursor: 'default' } }}>{title}:</span>
          {!isDenied && (
            <div style={styles.btnWrapper}>
              <button style={styles.editBtn} onClick={addFnc}>
                Add
              </button>
              {isEnableEdit && (
                <button style={styles.editBtn} onClick={editFnc}>
                  Edit
                </button>
              )}
            </div>
          )}
        </div>
        <div className={classes.root}>
          {notes.map((note, i) => {
            const username = (note.subject.attributes && note.subject.attributes.username) || '';
            return (
              <Item key={i}>
                <Circle />
                <Text>{note.content}</Text>
                <i style={styles.date}>
                  {moment(note.ts).format('M/D/YYYY H:mm')} - by {username}
                </i>
              </Item>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default compose(inject('store'), observer)(NoteContent);
