import React, { useEffect, useRef, useCallback } from 'react';
import produce from 'immer';
import _ from 'lodash';
import {inject, observer} from "mobx-react";
import ChatBox from "../ChatBox";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { lightTheme } from '../../../../themes';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {MessengerSendLinkForm} from "../../MessengerSendLinkForm";

export class ChatBoxContainer extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      page: 0,
      total: 0,
    }
    this.scrollTopList = React.createRef();
  }

  checkToLoadMore = _.debounce((listRef) => {
    const that = this;
    const {supportDoashboard} = this.props.store;
    const { current } = listRef;
    if ((current.scrollTop <= 70) && (this.state.total > this.state.messages.length)) {
      supportDoashboard.getMessages({ page: _.get(this, 'state.page') + 1 }, _.get(this, 'props.store.messengerStore.topicSelectedId')).then((resp) => {
        if (resp.ok) {
          this.scrollTopList.current = current.scrollHeight;
          const { data } = resp;
          this.setState({
            page: data.page,
            messages: _.merge(this.state.message, data.items),
          })
          _.debounce(() => {
            current.scrollTop = current.scrollHeight - this.scrollTopList.current - 35;
          }, 20)();
        }
      });
    }
  }, 100);

  componentDidMount() {
    const that = this;
    const {supportDoashboard} = this.props.store;
    const shipmentId = _.get(this, 'props.store.messengerStore.stopSelected.shipment_id');
    if(shipmentId) {
      supportDoashboard.getMessages({page: 0}, shipmentId).then(res => {
        if(res.ok && res.status === 200 && res.data) {
          that.setState({
            messages: _.get(res, 'data.items', []),
            total: _.get(res, 'data.total', 0)
          })
        }
      });
    }
  }

  render() {
    const {messages} = this.state;
    const {userStore} = this.props.store;
    const {user} = userStore;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
          <ChatBox
            id={_.get(this, 'props.store.messengerStore.stopSelected.shipment_id')}
            listMsg={messages}
            user={user}
            checkToLoadMore={this.checkToLoadMore}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
};

export default compose(
  withRouter,
  inject('store'),
  observer
)(ChatBoxContainer);