import {observable, action, decorate, computed} from 'mobx';
import FormStore from "./FormStore";
import _ from 'lodash';

const STOP_TYPE = {
  DROPOFF: "DROP_OFF"
};

class ShipmentStore {
  constructor(appStore) {
    this.appStore = appStore;
    this.api = appStore.api;
    this.assignmentStore = appStore.assignmentStore;
    this.feedbackForm = new FormStore(this);
    this.shipmentCustomerForm = new FormStore(this);
    this.shipmentDropoffForm = new FormStore(this);
  }

  selectedStop = null;
  selectedStopId = null;
  selectedShipment = null;
  selectedLabel = null;
  loadingStopInfo = false;
  loadingShipmentHistory = false;
  uploadingImage = false;
  selectedShipmentAssignment = null;
  updating = false;

  getStopDeliveryInfo(stop) {
    if (!stop) {
      this.selectedStop = null;
      this.selectedStopId = null;
      return;
    }
    this.loadingStopInfo = true;

    this.api.get(`/stops/${stop.id}/pod`).then((response) => {
      this.loadingStopInfo = false;
      if (this.selectedStop && this.selectedStop.id === stop.id) {
        if (response.data && response.data.length > 0) {
          const info = {};
          info.images = response.data.filter(item => item.type === 'picture');
          info.signatures = response.data.filter(item => item.type === 'signature');
          info.idcards = response.data.filter(item => item.type === 'idscan');

          this.selectedStop.info = info;
        }
      }
    })
  }

  getShipmentHistory() {
    // TODO: do this later (api not setup yet)
    // return;

    if (!this.selectedShipment) {
      return;
    }
    // getting from api
    this.loadingShipmentHistory = true;
    if (!this.selectedShipment.history)
      this.selectedShipment.history = {};

    this.api.get('/events/shipments/' + this.selectedShipment.id + '?ref=true&rel=true')
      .then(response => {
        this.loadingShipmentHistory = false;
        if (!this.selectedShipment) return;
        if ( !response.data || response.data.length < 1 ) {
          return;
        }
        this.selectedShipment.history = response.data;
      });
  }

  loadStop(stopId) {
    this.selectedStopId = parseInt(stopId);
    this.getStop(stopId).then(s => {
      if (s) {
        this.selectedStop = s;
        this.selectedShipment = s.shipment;
        this.getStopDeliveryInfo(s);
        this.getFeedback(stopId);
        this.getShipmentHistory();
      } else {
        this.selectedStop = null;
        this.selectedStopId = null;
      }
    })
  }

  getStop(stopId) {
    this.loadingStopInfo = true;
    return this.api.get(`/dsp/stops/${stopId}/detail`).then((response) => {
      let data = response.data;
      const { stop, shipment, label, client, info, corresponding_stop } = data;
      if (!stop) {
        this.selectedStop = null;
        this.selectedStopId = null;
        return;
      }
      stop.shipment = shipment;
      stop.label = label;
      stop.client = client;
      stop.info = info;
      stop.corresponding_stop = corresponding_stop;
      this.getFeedback(stop.id);

      return stop
    })
  }

  selectStop(stop) {
    if (!stop) {
      this.selectedStop = null;
      this.selectedStopId = null;
      return;
    }

    this.selectedStop = stop;
    this.selectedStopId = stop.id;
    this.selectedShipment = stop.shipment;
    this.getStopDeliveryInfo(this.selectedStop);
    this.getShipmentHistory();
    this.getFeedback(stop.id);
  }

  unselectStop() {
    this.selectedStop = null;
    this.selectedStopId = null;
    this.selectedShipment = null;
    this.selectedLabel = null;
  }

  addImage(shipmentId, stopId, file, cb) {
    const formData = new FormData();
    formData.append('image', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    this.uploadingImage = true;
    this.api.post(`/dsp/shipments/${shipmentId}/add-image?stop_id=${stopId}`, formData, config)
      .then(response => {
        this.uploadingImage = false;
        if (response.status === 200) {
          if (this.selectedStop && this.selectedStop.info && this.selectedStop.info.images) {
            this.selectedStop.info.images.push(response.data);
          }

          if (cb) {
            cb(response.data);
          }
        }
      })
  }

  splitRoute(assignmentId, shipmentId, data, callback) {
    this.api.put(`/dsp/assignments/${assignmentId}/split/${shipmentId}`, data).then(response => {
      if (callback) callback(response.data);
    });
  }

  getLabel(shipmentId, format, callback) {
    if (format === 'PNG' || format === 'PDF') {
      this.api.get(`/shipments/${shipmentId}/label?format=${format}`).then(callback);
    } else {
      this.api.get(`/shipments/${shipmentId}/label`).then(callback);
    }
  }

  addingFeedback = false;

  getFeedback(stopId) {
    this.api.get(`/stops/${stopId}/get-feedback`).then(response => {
      if (response.status === 200) {
        this.feedbackForm.data = response.data;
      } else {
        this.feedbackForm.data = {};
      }
    });
  }

  addFeedback(stopId, callback) {
    this.addingFeedback = true;
    this.api.post(`/stops/${stopId}/add-feedback`, this.feedbackForm.data).then(response => {
      if (response.status === 200) {
        if (callback) callback(response.data);
      } else {
        this.feedbackForm.data = {};
      }
      this.addingFeedback = false;
    });
  }

  addingTag = false;

  updateTags(shipment, tags, callback) {
    this.addingTag = true;
    const oldTags = shipment.tags;
    this.api.post(`/shipments/${shipment.id}/tags`, tags).then(response => {
      this.addingTag = false;

      if (response.status === 200) {
        this.selectShipment.tags = tags;
        if (this.assignmentStore.selectedAssignment && this.assignmentStore.selectedAssignment.stops) {
          this.assignmentStore.selectedAssignment.stops.forEach(stop => {
            if (stop.shipment && stop.shipment.id === shipment.id) {
              stop.shipment.tags = tags;
            }
          })

          // update assignment tags too
          if (this.assignmentStore.assignments) {
            this.assignmentStore.assignments.forEach(a => {
              if (a.id === shipment.assignment_id) {
                if (!a.aggregated_tags) {
                  a.aggregated_tags = tags;
                } else {
                  let newTags = a.aggregated_tags.filter(t => !oldTags || !oldTags.includes(t)).concat(tags);
                  newTags = _.uniq(newTags);
                  a.aggregated_tags = newTags;
                }
              }
            })

          }
        }
        if (callback) callback(response.data);
      }
    });
  }

  selectShipment(shipment) {
    this.selectedShipment = shipment
    if (!shipment || !shipment.id) {
      this.selectedShipment = null;
      return
    }
    this.selectedShipmentAssignment = null;
    this.selectedStop = null
    this.loadShipment(shipment.id)
  }

  loadShipment(shipmentId, cb) {
    this.selectedShipment = null;
    if (!shipmentId) {
      return
    }
    this.loadingShipment = true;
    this.api.get(`/shipments/${shipmentId}`).then((response) => {
      this.loadingShipment = false;
      this.selectedShipment = response.data;
      const shipment = response.data;
      if (this.selectedShipment && this.selectedShipment.assignment_id) {
        this.loadAssignment(this.selectedShipment.assignment_id)
      }
      this.shipmentDropoffForm.data = {
        address: shipment.dropoff_address,
        note: shipment.dropoff_note,
        dropoff_access_code: shipment.dropoff_access_code,
        dropoff_additional_instruction: shipment.dropoff_additional_instruction
      };
      this.getShipmentHistory()
      if(cb) cb(response);
    })
  }

  loadAssignment(id) {
    this.selectedShipmentAssignment = null;
    if (!id) {
      return
    }
    this.loadingAssignment = true;
    this.api.get(`/dsp/assignments/${id}/detail?show_soft_deleted=true`).then((response) => {
      this.loadingAssignment = false;
      this.selectedShipmentAssignment = this.assignmentStore.processAssignmentDetail(response.data)
      if (this.selectedShipmentAssignment.stops && this.selectShipment) {
        const stops = this.selectedShipmentAssignment.stops.filter(s => s.shipment_id === this.selectedShipment.id)
        if (stops && stops.length > 0) {
          this.selectedStop = _.last(stops)
          this.getStopDeliveryInfo(this.selectedStop)
        }
      }
    })
  }

  normalizeFormData(fd) {
    const fdClone = _.clone(fd);
    Object.keys(fdClone).forEach(k => {
      if (typeof fdClone[k] === 'string'){
        fdClone[k] = (fdClone[k] && fdClone[k].trim() !== '') ? fdClone[k].trim() : null;
      }
    });
    return fdClone;
  }

  updateShipmentCustomer(shipment, cb) {
    this.updating = true;
    this.api.put(`/shipments/${shipment.id}/customer`, this.normalizeFormData(this.shipmentCustomerForm.data)).then((response) => {
      this.updating = false;
      // take some properties
      this.selectedStop.shipment = response.data;
      this.assignmentStore.updateStop(this.selectedStop);
      if (cb) {
        cb(response.data);
      }
    })
  }

  updateDropoffLocationInfo(shipment, cb) {
    // this.updating = true;
    console.log('hello', this.shipmentDropoffForm.data);
    this.api.put(`/shipments/${shipment.id}/dropoff-location-info`, this.normalizeFormData(this.shipmentDropoffForm.data)).then((response) => {
      // this.updating = false;
      this.selectedStop.shipment = response.data;
      this.assignmentStore.updateStop(this.selectedStop);
      if (cb) {
        cb(response.data);
      }
    })
  }
}

decorate(ShipmentStore, {
  updating: observable,
  selectedShipmentAssignment: observable,
  addingTag: observable,
  addingFeedback: observable,
  selectedStop: observable,
  selectedStopId: observable,
  selectedShipment: observable,
  selectedLabel: observable,
  loadingStopInfo: observable,
  loadingShipmentHistory: observable,
  uploadingImage: observable,
  getStopDeliveryInfo: action,
  getShipmentHistory: action,
  loadStop: action,
  getStop: action,
  selectStop: action,
  unselectStop: action,
  addImage: action,
  splitRoute: action,
  getLabel: action,
  getFeedback: action,
  addFeedback: action,
  updateTags: action,
  selectShipment: action,
  loadShipment: action,
  loadAssignment: action,
  updateShipmentCustomer: action,
});

export default ShipmentStore;