export const DEFAULT_VEHICLE_TYPE = {
  "Sedan": "Sedan",
  "Hatchback": "Hatchback",
  "Coupe": "Coupe",
  "Convertible": "Convertible",
  "Minivan": "Minivan",
  "SUV": "SUV",
  "Wagon": "Wagon",
  "Truck": "Truck",
  "Van": "Van",
};
