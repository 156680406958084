import React from 'react';
import {Box} from "@mui/material";
import {FileName} from './styles';

export default function AxlFileUpload({
                                        accept = "application/pdf, image/*",
                                        defaultValue = null,
                                        ...props
}) {
  const getFileName = (value) => {
    if(!value) return null;

    switch (typeof value) {
      case 'string':
        return value.split(/.*[\/|\\]/)[1];
      case 'object':
        return value.name;
      default:
        return null;
    }
  }

  return <Box my={1}>
    <label>
      <input {...props} accept={accept} type={'file'} style={{}} />
      <FileName>{getFileName(defaultValue)}</FileName>
    </label>
  </Box>
}