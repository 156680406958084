import { create } from 'apisauce';
import DriverStore from './DriverStore';
import UserStore from './UserStore';
import AssignmentStore from './AssignmentStore';
import ShipmentStore from './ShipmentStore';
import MessengerStore from './Messenger';
import { paymentAPI, dashboardAPI } from './api';
import ContractorStore from './ContractorStore';
import PaymentStore from './PaymentStore';
import SearchDspPaymentStore from './SearchDspPaymentStore';
import DspEventStore from './DspEventStore';
import VehicleStore from './VehicleStore';
import HistoryStore from './History';
import SupportDoashboard from './SupportDoashboard';
import TicketStore from './Ticket';
import LocationStore from './Location';
import PermissionStore from './PermissionStore';

class AppStore {
  constructor() {
    this.api = create({ baseURL: process.env.REACT_APP_API_ROOT, withCredentials: true });

    this.api.addResponseTransform((response) => {
      if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
        window.location.href = `${process.env.REACT_APP_OAUTH_LOGIN_URL}?next=${encodeURI(window.location.href)}`;
      }
    });

    const locationStore = new LocationStore(null, this.api);
    const assignmentStore = new AssignmentStore(this);

    this.userStore = new UserStore(this);
    this.driverStore = new DriverStore(this);
    this.assignmentStore = new AssignmentStore(this);
    this.shipmentStore = new ShipmentStore(this);
    this.messengerStore = new MessengerStore(this.api, assignmentStore);
    this.paymentStore = new PaymentStore(paymentAPI);
    this.contractorStore = new ContractorStore(paymentAPI);
    this.dspEventStore = new DspEventStore(paymentAPI);
    this.searchDspPaymentStore = new SearchDspPaymentStore(paymentAPI);
    this.vehicleStore = new VehicleStore(this);
    this.historyStore = new HistoryStore(this.api);
    this.supportDoashboard = new SupportDoashboard(dashboardAPI);
    this.ticketStore = new TicketStore(null, this.api, locationStore);
    this.permissionStore = new PermissionStore(this.api);
  }
}

export default AppStore;
