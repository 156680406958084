import React, {Component} from "react";

import {AxlPanel} from "axl-reactjs-ui";
import Moment from "react-moment";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import { Box, Grid, IconButton, Link, Tooltip } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { toast } from "react-toastify";
import moment from 'moment';
import {isEmpty } from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {StyledAmount, StyledBold} from "../styles";
import List from "../ListPayment";
import { formatNumber } from "../../../Utils/number";
import { formatCurrency } from "../../../Utils/currency";

const styles = theme => ({
  editRate: {
    fontWeight: 900,
    color: '#4a9eff',
    textDecoration: 'underline',
    cursor: 'pointer',
  }
});

const ROUTE_RATES = [
  {label: "NORMAL", value: "NORMAL"},
  {label: "EMERGENCY", value: "EMERGENCY"},
];

class DspPreAuthorizedEventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      showEditRouteRate: false,
      changing: false,
      changedRate: "",
      error: "",
    }
  }


  componentDidMount() {
    const {dspEventStore} = this.props;
    dspEventStore.setFilter("contractor_id", 0);
    dspEventStore.search();
  }

  viewAssignmentDetails = (item) => {
    const assignmentDate = moment(item.predicted_start_ts).format("YYYY-MM-DD");
    this.props.history.push(`/routes/${assignmentDate}/all/${item.assignment_id}`);
  }

  editRouteRate = () => {
    const {selectedItem, changedRate} = this.state;
    const {dspEventStore} = this.props;

    if (!selectedItem) return;

    this.setState({changing: true, error: ""});
    dspEventStore.updateRouteRate(selectedItem.id, changedRate, res => {
      if (res.ok) {
        this.closeEditRouteRate();
      } else {
        this.setState({error: res.data.message})
      }
      this.setState({changing: false});
    });
  }


  openEditRouteRate = (item) => {
    if (!item) return;
    this.setState({showEditRouteRate: true, selectedItem: item, changing: false});
  }

  closeEditRouteRate = () => {
    this.setState({showEditRouteRate: false, selectedItem: null});
  }

  handleDownloadCSV = async () => {
    const {contractorStore} = this.props;
    const { contractorInfo } = contractorStore;
    if(isEmpty(contractorInfo)) return;

    window.open(`${process.env.REACT_APP_API_PAYMENT}/reports/dsp-transactions/${contractorInfo.id}`);
  }


  render() {
    const {dspEventStore, contractorStore, type, allowSelect, disabledIds, multipleSelect} = this.props;
    const store = dspEventStore.getStore(type);
    const {contractorInfo: payeeInfo} = contractorStore;
    const renderer = {
      amount: (v) => <span>${v}</span>,
      actual_end_ts: (v) => <Moment interval={0} format='YYYY-MM-DD HH:mm'>{v}</Moment>,
      delivery_date: (v, item) => <Moment interval={0} format='ddd MM/DD/YYYY'>{v || item.predicted_start_ts}</Moment>,
      start_delivery_ts: (v, item) => <Moment interval={0} format='HH:mm - MM/DD/YYYY'>{v || item.predicted_start_ts}</Moment>,
      end_delivery_ts: (v, item) => <Moment interval={0} format='HH:mm - MM/DD/YYYY'>{v || item.predicted_start_ts}</Moment>,
      route_rate: (v, item) => (
        <Box>
          <Box mr={1} component="span">{v}</Box>
        </Box>
      ),
      assignment_id: (v, item) => <span>{v} [{item.assignment_label}]</span>,
      assignment_label: (v, item) => (
        <Box display={'flex'} alignItems={'end'} style={{gap: 8}}>
          <Link onClick={() => this.viewAssignmentDetails(item)} underline="always" style={{cursor: 'pointer', color: '#4A90E2'}}>{v}</Link>
          {item.assignment_id && 
            <Tooltip title="View in dispatch">
              <OpenInNewIcon style={{fontSize: '1rem', cursor: 'pointer'}} onClick={() => this.viewAssignmentDetails(item)}/>
            </Tooltip>
          } 
        </Box>),
      region_code: (v) => <span>{v || 'N/A'}</span>,
      route_split: (v) => <span>{v ? 'Yes' : 'No'}</span>,
      total_non_perishable: (v, item) => <span>{`${formatNumber(v)} - ${formatCurrency(item.non_perishable_per_box)}/box`}</span>,
      total_perishable: (v, item) => <span>{`${formatNumber(v)} - ${formatCurrency(item.perishable_per_box)}/box`}</span>,
      travel_distance: (v, item) => <span>{`${formatNumber(v)} - ${formatCurrency(item.mileage_rate)}/mile`}</span>,
    };

    if (!store) return null;
    const props = {
      renderer, type, allowSelect, disabledIds,
      pagination: true, multipleSelect, store, alt: true,
      styleContainer: {maxWidth: 'calc(100vw - 32px)', overflow: 'auto'}
    };

    let totalAmount = 0;
    let cycleType = "N/A";
    let cyclePeriod = "N/A";
    if (payeeInfo != null) {
      totalAmount = (payeeInfo.pre_auth_amount || 0) - (payeeInfo.pre_deduct_amount || 0);
      cycleType = payeeInfo.cycle_type;
      cyclePeriod = payeeInfo.period;
    }

    return (
      <Box>
        <Box border={1} borderColor={'grey.300'} p={2}>
          <Grid container spacing={2}>
            <Grid item>
              <div>
                <span>{`Next pay cycle: `}</span>
                <strong>{cycleType} ({cyclePeriod})</strong>
              </div>
            </Grid>
            <Grid item xs />
            <Grid item>
              {payeeInfo && (
                <div>
                  <span style={{marginRight: 5}}>Total amount:</span>
                  <StyledBold>${totalAmount.toFixed(2)}</StyledBold>
                  (<StyledAmount style={{color: 'green'}}>${payeeInfo.pre_auth_amount}</StyledAmount>-<StyledAmount style={{color: 'red'}}>${payeeInfo.pre_deduct_amount}</StyledAmount>)
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <h3>{`TRANSACTION LIST`}</h3>
          <Tooltip title="Download CSV">
            <IconButton size="medium" onClick={() => this.handleDownloadCSV()}>
              <GetAppIcon/>
            </IconButton>
          </Tooltip>
        </Box>
        <AxlPanel>
          <AxlPanel.Row style={{marginTop: "10px"}}>
            <AxlPanel.Col>
              <List {...props} />
            </AxlPanel.Col>
          </AxlPanel.Row>
        </AxlPanel>
      </Box>
    )
  }
}

export default compose(
  inject('dspEventStore', 'contractorStore', 'store'),
  withRouter,
  observer
)(DspPreAuthorizedEventList);
