import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as M from "@mui/material";
import {mainTheme} from './theme';
const mapToTheme = {
  // light: lightTheme,
  main: mainTheme,
};

export default function AxlRadio ({
  value,
  theme='main',
  ...props
}) {
  const [_value, setValue] = React.useState(value);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return <M.ThemeProvider theme={mapToTheme[theme]}>
    <FormControlLabel value={_value} control={<Radio {...props} />} />
  </M.ThemeProvider>
}