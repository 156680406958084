import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, StyledEngineProvider,
} from "@mui/material";
import * as themes from "./theme";
import THEME_DEFINED from "../../constants/theme";
import _ from "lodash";
import AxlPagination from "../AxlPagination";
import React, {useEffect, useState} from "react";

export default function AxlTable({
                                   theme = 'main',
                                   fields = [],
                                   page = 1,
                                   result = {
                                     page: 0,
                                     count: 0,
                                     size: 10,
                                     items: [],
                                   },
                                   isPagination = true,
                                   rerender = [],
                                   onChange = () => {},
}) {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes[THEME_DEFINED[theme]]}>
        <Box>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  {fields.map((field, idx) => <TableCell key={idx}>{_.get(field, 'label', '-')}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!result.items.length && result.items.map((item, x) => (
                  <TableRow key={x}>
                    {fields.map((field, y) => {
                      return <TableCell key={y} component="th" scope="row">
                        {!rerender[field.name] ? _.defaultTo(item[field.name], '-') : rerender[field.name](item[field.name], item, result)}
                      </TableCell>
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isPagination && <Box py={1} display="flex" justifyContent="center">
            <AxlPagination onChange={onChange} count={Math.ceil(result.count/result.size)} page={page} />
          </Box>}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}