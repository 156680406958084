import { createTheme } from "@mui/material";
import colors from "../../themes/colors";

export const mainTheme = createTheme({
  components: {
    MuiPagination: {},
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          '&.Mui-selected': {
            color: colors.white,
            backgroundColor: colors.periwinkle,
          },
        },
      }
    },
  },
});
