import React from 'react';
import _ from 'lodash';
import {inject, observer} from "mobx-react";
import { AxlPanel, AxlCheckbox, AxlTextArea, AxlButton } from 'axl-reactjs-ui';
import {IconButton, Box} from "@mui/material";
import {ThumbDown as ThumbDownIcon, ThumbUp as ThumbUpIcon} from "@mui/icons-material";

import styles, * as E from './styles';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";

class ShipmentFeedback extends React.Component {

  addFeedBack = (e) => {
    const {toggleFeedback} = this.props;
    const {feedbackForm, selectedStop} = this.props.store.shipmentStore;

    if(!selectedStop) return;

    this.props.store.shipmentStore.addFeedback(selectedStop.id, () => toggleFeedback());
  };

  render() {
    const {feedbackForm, addingFeedback} = this.props.store.shipmentStore;
    const tagValues = [
      "Late due to Driver",
      "Inapropriate behavior",
      "Confirmed delivery",
      "Damaged - Jitsu",
      "Instructions not followed",
      "Delivered to wrong address",
      "Unconfirmed delivery"
    ];
    const thumb = feedbackForm.getField('thumb', undefined);

    return <E.Container>
      <E.Inner>
        <E.Row>
          <E.Label>{`Rate:`}</E.Label>
          <E.Content>
            {/*<Rating initialRating={feedbackForm.getField('rating', 0)} onChange={feedbackForm.handlerRating('rating')} emptySymbol={<img src="/assets/images/star-periwinkle-empty.png" />} fullSymbol={<img src="/assets/images/star-periwinkle-full.png" />} />*/}
            <Box component="span" px={1}>
              <IconButton size="small" onClick={() => feedbackForm.handlerRating('thumb')(true)}>
                <ThumbUpIcon fontSize="small" style={_.isUndefined(thumb) ? {} : {color: !!thumb ? '#4abc4e' : ''}} />
              </IconButton>
            </Box>
            <Box component="span" px={1}>
              <IconButton size="small" onClick={() => feedbackForm.handlerRating('thumb')(false)}>
                <ThumbDownIcon fontSize="small" style={_.isUndefined(thumb) ? {} : {color: !thumb ? '#d0021b' : ''}} />
              </IconButton>
            </Box>
          </E.Content>
        </E.Row>
        <E.Row>
          <E.Label>{`Tags:`}</E.Label>
          <E.Content>
            <AxlPanel>
              <AxlPanel.Row>
                <AxlPanel.Col>
                  {tagValues.slice(0, 4).map((v, i) => <E.Control key={i}>
                    <AxlCheckbox key={i} checked={feedbackForm.arrayContains("tags", v)} onChange={feedbackForm.handlerCheckboxes} name='tags' value={v} theme={`main`} color={`white`}>{v}</AxlCheckbox>
                  </E.Control>)}
                </AxlPanel.Col>
                <AxlPanel.Col>
                  {tagValues.slice(4).map((v, i) => <E.Control key={i} >
                    <AxlCheckbox checked={feedbackForm.arrayContains("tags", v)} onChange={feedbackForm.handlerCheckboxes} name='tags' value={v} theme={`main`} color={`white`}>{v}</AxlCheckbox>
                  </E.Control>)}
                </AxlPanel.Col>
              </AxlPanel.Row>
            </AxlPanel>
          </E.Content>
        </E.Row>
        <E.Row>
          <E.Label>{`Comment:`}</E.Label>
          <E.Content>
            <AxlTextArea onChange={feedbackForm.handlerTextarea} name='comment' value={feedbackForm.getField('comment', '')} placeholder={`Add comment...`} style={styles.textarea} fluid />
          </E.Content>
        </E.Row>
        <E.Row>
          <E.Label />
          <E.Content>
            <AxlButton loading={addingFeedback} onClick={this.addFeedBack} compact bg={`bluish`}>{`Add Feedback`}</AxlButton>
            <AxlButton onClick={() => this.props.onClose && this.props.onClose() } compact bg={`borBluish`}>{`Cancel`}</AxlButton>
          </E.Content>
        </E.Row>
      </E.Inner>
    </E.Container>
  }
}
export default compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentFeedback);
