import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Switch } from 'react-router-dom';
import { Container } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Header from './Header';
import Navigation from './Navigation';
import Account from './account';
import Payment from './Payment';
import DriverList from './drivers';
import DispatchScreen from './dispatch';
import Loading from 'components/Loading';
import VehicleScreen from 'screens/VehicleScreen';

import acl from 'constants/acl';
import { lightTheme } from 'themes';
import { ProtectedRoute } from 'providers';

function AppContainer(props) {
  const { store } = props;
  const { userStore, permissionStore } = store;
  const { user } = userStore;
  const { permissionLoaded } = permissionStore;

  useEffect(() => {
    userStore.getUser();
    userStore.getDsp();
  }, [userStore]);

  if (isEmpty(user) || !permissionLoaded) return <Loading />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={lightTheme}>
        <Container disableGutters maxWidth={false}>
          <Header />
          <Navigation />
        </Container>
        <Switch>
          <ProtectedRoute path="/vehicles" deniedPatterns={acl.VEHICLES.LIST}>
            <VehicleScreen />
          </ProtectedRoute>
          <ProtectedRoute path="/drivers" deniedPatterns={acl.DRIVERS.LIST}>
            <DriverList />
          </ProtectedRoute>
          <ProtectedRoute path="/profile">
            <Account />
          </ProtectedRoute>
          <ProtectedRoute path="/payments" deniedPatterns={[...acl.PAYMENTS.EVENT_LIST, ...acl.PAYMENTS.TRANSACTION_LIST]}>
            <Payment />
          </ProtectedRoute>
          <ProtectedRoute path="/routes/:day/:region/:assignmentId" deniedPatterns={acl.ASSIGNMENTS.LIST}>
            <DispatchScreen />
          </ProtectedRoute>
          <ProtectedRoute path="/routes/:day/:region" deniedPatterns={acl.ASSIGNMENTS.LIST}>
            <DispatchScreen />
          </ProtectedRoute>
          <ProtectedRoute path="/routes/:day" deniedPatterns={acl.ASSIGNMENTS.LIST}>
            <DispatchScreen />
          </ProtectedRoute>
          <ProtectedRoute path="/routes" deniedPatterns={acl.ASSIGNMENTS.LIST}>
            <DispatchScreen />
          </ProtectedRoute>
          <ProtectedRoute path="/" deniedPatterns={acl.ASSIGNMENTS.LIST}>
            <DispatchScreen />
          </ProtectedRoute>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default compose(inject('store'), observer)(AppContainer);
