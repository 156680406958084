import React, {useEffect, useState} from 'react';
import {Box, Grid} from "@mui/material";
import {AxlModalBox} from "../../AxlMUIComponent/AxlBox";
import * as S from './styles';
import AxlInput from "../../AxlMUIComponent/AxlInput";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {getCars, vehicleUpsert} from "../../repositories";
import {REGIONS_STATE} from "../../constants/regions";
import AxlButton from "../../AxlMUIComponent/AxlButton";
import AxlSelect from "../../AxlMUIComponent/AxlSelect";
import _ from 'lodash';
import AxlFileUpload from "../../AxlMUIComponent/AxlFileUpload";
import { AxlDateInput } from 'axl-reactjs-ui';
import moment from "moment-timezone";

function VehicleAddNew({
                         data = null,
                         ...props
}) {
  // global
  // local
  const [items, setItems]               = useState(null);
  const [makes, setMakes]               = useState([]);
  const [models, setModels]             = useState([]);
  const [types, setTypes]               = useState([]);
  const [subModels, setSubModels]       = useState([]);
  const [query, setQuery]               = useState({year: data ? _.get(data, 'year') : null});
  const [field, setField]               = useState(data ? data : {
    'license_state': '',
    'year': '',
    'make': '',
    'model': '',
    'type': '',
    'submodel': ''
  });
  const [fieldUpdated, setFieldUpdated] = useState({});
  const [loading, setLoading]           = useState(false);
  const [screen, setScreen]             = useState(1);
  const [error, setError]               = useState(null);
  const [fieldNeedValid, setFieldValid] = useState(['vehicle_name','license_plate','license_state','year','make','model', 'type']);
  // variables
  let years                             = [];
  for (var i = 1990; i <= moment().year(); i++) {
    years.push({label: i, value: i});
  }
  const regisIssue                      = {
                                            defaultValue: _.get(field, 'registration_record_issued_ts'),
                                            defaultDate: 'today',
                                            dateFormat: 'MMM DD, Y',
                                            placeHolder: 'today',
                                            enableTime: false,
                                            altInput: true,
                                            clickOpens: false,
                                          };
  const insureIssue                     = {
                                            defaultValue: _.get(field, 'insurance_card_issued_ts'),
                                            defaultDate: 'today',
                                            dateFormat: 'MMM DD, Y',
                                            placeHolder: 'today',
                                            enableTime: false,
                                            altInput: true,
                                            clickOpens: false,
                                          };
  const regisExpired                    = {
                                            defaultValue: _.get(field, 'registration_record_expired_ts'),
                                            defaultDate: 'today',
                                            dateFormat: 'MMM DD, Y',
                                            placeHolder: 'today',
                                            enableTime: false,
                                            altInput: true,
                                            clickOpens: false,
                                          };
  const insureExpired                   = {
                                            defaultValue: _.get(field, 'insurance_card_expired_ts'),
                                            defaultDate: 'today',
                                            dateFormat: 'MMM DD, Y',
                                            placeHolder: 'today',
                                            enableTime: false,
                                            altInput: true,
                                            clickOpens: false,
                                          };
  const isValid                         = field && !(fieldNeedValid.filter(fieldName => !_.includes(Object.keys(field), fieldName) || !field[fieldName]).length);

  useEffect(() => {
    if(query && query.year) {
      getCars(query).then(res => {
        if(res.ok && res.status === 200) {
          const data = res && res.data || [];
          setItems(data);
          setMakes(_.uniqBy(data, obj => obj.make)?.map(m => ({value: m.make, label: m.make})) || [])
          setModels(_.uniqBy(data, obj => obj.model)?.filter(f => f.make === field.make)?.map(m => ({value: m.model, label: m.model})) || []);
          const findTypes = findItems(data, field.model, 'model', 'type');
          setTypes(findTypes);
          if(findTypes.length === 1) {
            setField(prev => ({...prev, 'type': findTypes[0].value}))
          }
          else {
            setField(prev => ({...prev, 'type': field.type || ''}))
          }

          const findSubModels = findItems(data, field.model, 'model', 'submodel');
          setSubModels(findSubModels);
          if(findSubModels.length === 1) {
            setField(prev => ({...prev, 'submodel': findSubModels[0].value}))
          }
          else {
            setField(prev => ({...prev, 'submodel': field.submodel || ''}))
          }
        }
      });
    }
  }, [query]);

  useEffect(() => {
    handleChangePropertyName();
  }, [field]);

  const findItems = (list, value, filterProp, mapProp) => {
    return  _.unionBy(list?.filter(f => f[filterProp] === value), o => o[mapProp])?.map(m => ({value: m[mapProp], label: m[mapProp]})) || [];
  }

  const onChange = ({target: {name, value}}) => {
    let _field = _.assign({}, field);
    switch (name) {
      case 'year':
        _field = _.omit(_field, ['make', 'model', 'submodel', 'type', 'color']);
        _field = _.assign({}, _field, {[name]: value});
        setField(_field);
        // query data
        setQuery({year: value});
        break;
      case 'make':
        const findModels = findItems(items, value, 'make', 'model');
        setModels(findModels);
        if(findModels.length === 1) {
          setField(prev => ({...prev, [name]: value, 'model': findModels[0].value, 'type': '', 'submodel': '', 'color': ''}))
        }
        else {
          setField(prev => ({...prev, [name]: value, 'model': '', 'type': '', 'submodel': '', 'color': ''}))
        }
        break;
      case 'model':
        const findTypes = findItems(items, value, 'model', 'type');
        setTypes(findTypes);
        if(findTypes.length === 1) {
          setField(prev => ({...prev, 'type': findTypes[0].value, 'submodel': '', 'color': ''}))
          subModel(value, findTypes[0].value);
        }
        else {
          setField(prev => ({...prev, 'type': '', 'submodel': '', 'color': ''}))
          setSubModels([])
        }
        setField(prev => ({...prev, [name]: value}));
        break;
      case 'type': 
        if(!field || !field.model) {
          setField(prev => ({...prev, 'submodel': ''}))
        }
        else {
          subModel(field.model, value);
        }
        setField(prev => ({...prev, [name]: value}));
        break;
      case 'submodel':
        _field = _.assign({}, _field, {[name]: value});
        setField(_field);
        break;
      default:
        _field = _.assign({}, _field, {[name]: value});
        setField(_field);
        break;
    }
  };

  const subModel = (valueModel, valueType) => {
    const filterSubmodels = items.filter(f => f.model === valueModel && f.type === valueType);
    const findSubModels = findItems(filterSubmodels, valueType, 'type', 'submodel');
    setSubModels(findSubModels);
    if(findSubModels.length === 1) {
      setField(prev => ({...prev, 'submodel': findSubModels[0].value}))
    }
    else {
      setField(prev => ({...prev, 'submodel': ''}))
    }
  }

  const setDate = (name, value) => {
    const _field = _.assign({}, field, {[name]: moment(value).unix()*1000});
    setField(_field);
  };

  const handleUpload = (e) => {
    const files = Array.from(e.target.files);
    if(!files) {
      const _field = _.omit(field, [e.target.name]);
      setField(_field);
      return;
    } else {
      Promise.all(files.map(file => {
        return (new Promise((resolve,reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        }));
      })).then(images => {
        var newFiles = files.map(file => {
          var blob = file.slice(0, file.size, file.type);
          var newFileName = file.name.replace(/\s/g, '');
          return new File([blob], newFileName, {type: file.type});
        });
        const _field = _.assign({}, field, {[e.target.name]: newFiles[0]}); // Single file
        setField(_field);
      })
    }
    // need to valid when image is uploaded
    setFieldValid(fieldNeedValid.concat([e.target.name]));
  };

  const addNewVehicle = () => {
    if(!fieldUpdated) return;

    setLoading(true);

    vehicleUpsert(fieldUpdated).then(res => {
      if(res.ok && res.status === 200) {
        props.onClose();
        setLoading(false);
      } else {
        setError(_.get(res, 'data.message'));
        setLoading(false);
      }
    });
  };

  const renderMarkRequired = (name) => {
    if(_.includes(fieldNeedValid, name)) {
      return <S.TextRequired>{`*`}</S.TextRequired>;
    } else
      return false;
  };

  const handleChangePropertyName = () => {
      const _field = _.mapKeys(field, function(v, k) {
        if (_.isEqual(k, 'registration_record_url')) {
          if (!v) {
            return 'registration';
          } else {
            return;
          }
        } else if (_.isEqual(k, 'insurance_card_url')) {
          if (!v) {
            return 'insurance';
          } else {
            return;
          }
        } else if (_.includes([
          'registration_record_expired',
          'insurance_card_expired',
        ], k)) {
          return;
        } else {
          return k;
        }
      });

    setFieldUpdated(_field);
  };

  const handleBack = () => {
    setScreen(1);
    setError(null);
  }

  return <AxlModalBox width={650}>
    <S.Title>{(field && field.id) ? `Edit Vehicle` : `Add New Vehicle`}</S.Title>
    {screen === 1 && <Box>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Vehicle Name`} {renderMarkRequired('vehicle_name')}</S.Label>
            <S.FormControl>
              <AxlInput name={`vehicle_name`} spacing={0} onChange={onChange} value={_.get(field, 'vehicle_name')}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`License Plate`} {renderMarkRequired('license_plate')}</S.Label>
            <S.FormControl>
              <AxlInput name={`license_plate`} spacing={0} onChange={onChange} value={_.get(field, 'license_plate')}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`License Plate State`} {renderMarkRequired('license_state')}</S.Label>
            <S.FormControl>
              <AxlSelect name={`license_state`} options={REGIONS_STATE} onChange={onChange} value={_.get(field, 'license_state')}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Year`} {renderMarkRequired('year')}</S.Label>
            <S.FormControl>
              <AxlSelect name={'year'} options={years} onChange={onChange} value={_.get(field, 'year')}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Make`} {renderMarkRequired('make')}</S.Label>
            <S.FormControl>
              <AxlSelect name={'make'} options={makes} onChange={onChange} value={makes.length > 0 ? _.get(field, 'make') : ''} disabled={makes.length < 2}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Model`} {renderMarkRequired('model')}</S.Label>
            <S.FormControl>
              <AxlSelect name={'model'} options={models} onChange={onChange} value={models.length > 0 ? _.get(field, 'model') : ''} disabled={models.length < 2}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Vehicle Type`} {renderMarkRequired('type')}</S.Label>
            <S.FormControl>
              <AxlSelect name={'type'} options={types} onChange={onChange} value={types.length > 0 ? _.get(field, 'type') : ''} disabled={types.length < 2}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Sub-model`}<i>{`(optional)`}</i></S.Label>
            <S.FormControl>
              <AxlSelect name={'submodel'} options={subModels} onChange={onChange} value={subModels.length > 0 ? _.get(field, 'submodel') : ''} disabled={subModels.length < 2}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Color`}<S.TextOption>{`(optional)`}</S.TextOption></S.Label>
            <S.FormControl>
              <AxlInput name={`color`} spacing={0} onChange={onChange} value={_.get(field, 'color')}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'}>
        <Grid item xs><S.TextRequired>{`* required`}</S.TextRequired></Grid>
        <Grid item>
          <Grid container>
            <AxlButton variant={'outlined'} color={"primary.blackSecondary"} onClick={props.onClose}>{`Cancel`}</AxlButton>
            <AxlButton variant={'contained'}
                       bgcolor={!isValid ? 'primary.gray' : 'primary.periwinkle'}
                       color={"primary.white"} onClick={() => setScreen(2)} disabled={!isValid}>{`Next`}</AxlButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>}
    {screen === 2 && <Box>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.LabelStrong>{`Vehicle Registration`}<S.TextOption>{`(optional)`}</S.TextOption></S.LabelStrong>
            <S.FormControl>
              <AxlFileUpload name={'registration'}
                             defaultValue={_.get(field, 'registration') || _.get(field, 'registration_record_url')}
                             onChange={handleUpload} />
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.LabelStrong>{`Vehicle Insurance`}<S.TextOption>{`(optional)`}</S.TextOption></S.LabelStrong>
            <S.FormControl>
              <AxlFileUpload name={'insurance'}
                             defaultValue={_.get(field, 'insurance') || _.get(field, 'insurance_card_url')}
                             onChange={handleUpload}/>
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Issued Date`}<i>{`(optional)`}</i></S.Label>
            <S.FormControl>
              <AxlDateInput
                theme={'main'}
                arrow clear={false}
                name={'registration_record_issued_ts'}
                onChange={(d) => setDate('registration_record_issued_ts', d)}
                options={regisIssue}
              />
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Issued Date`}<i>{`(optional)`}</i></S.Label>
            <S.FormControl>
              <AxlDateInput
                theme={'main'} arrow
                name={'insurance_card_issued_ts'}
                clear={false}
                onChange={(d) => setDate('insurance_card_issued_ts', d)}
                options={insureIssue}
              />
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Expired Date`} {renderMarkRequired('registration')}</S.Label>
            <S.FormControl>
              <AxlDateInput
                theme={'main'} arrow
                name={'registration_record_expired_ts'}
                clear={false}
                onChange={(d) => setDate('registration_record_expired_ts', d)}
                options={regisExpired}
              />
            </S.FormControl>
          </S.FormControl>
        </Grid>
        <Grid item xs>
          <S.FormControl>
            <S.Label>{`Expired Date`} {renderMarkRequired('insurance')}</S.Label>
            <S.FormControl>
              <AxlDateInput
                theme={'main'} arrow
                name={'insurance_card_expired_ts'}
                clear={false}
                onChange={(d) => setDate('insurance_card_expired_ts', d)}
                options={insureExpired}
              />
            </S.FormControl>
          </S.FormControl>
        </Grid>
      </Grid>
      <Box mt={3} />
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <AxlButton spacing={0} variant={'outlined'} color={"primary.blackSecondary"} onClick={handleBack}>{`Back`}</AxlButton>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <AxlButton spacing={0} variant={'outlined'} color={"primary.blackSecondary"} onClick={props.onClose}>{`Cancel`}</AxlButton>
            </Grid>
            <Grid item>
              <AxlButton spacing={0} variant={'outlined'}
                         bgcolor={(loading || !isValid) ? 'primary.gray' : 'primary.periwinkle'}
                         color={"primary.white"}
                         onClick={addNewVehicle}
                         loading={loading} disabled={loading || !isValid}>{(field && field.id) ? `Save` : `Add`}</AxlButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3} />
      <Grid container>
        <Grid item xs>
          {error && <S.Text style={{color: 'red'}}>{error}</S.Text>}
        </Grid>
      </Grid>
    </Box>}
  </AxlModalBox>
}

export default compose(
  withRouter,
)(VehicleAddNew);