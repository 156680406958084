import styled from "styled-components";

export const Label = styled.span`
  color: #737273;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

export const Value = styled.span`
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${props => {
    if (props.status) {
      if (props.children) {
        if (props.children.indexOf('ERROR') > -1 || props.children.indexOf('FAILED') > -1) {
          return '#f00';
        } else if (props.children.indexOf('SUCCEEDED') > -1) {
          return '#4abc4e';
        } else if (props.children.indexOf('PROCESSING') > -1) {
          return '#2c93ff'
        } else if (props.children.indexOf('APPROVED') > -1) {
          return '#887fff'
        } else {
          return "#000";
        }
      }
    } else {
      return "#000";
    }
}};
`;
