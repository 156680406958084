import React, {Component, Fragment} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import AxlButton from "../../AxlMUIComponent/AxlButton";

class ReasonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      open: false
    }
  }

  onChange = ({ target: { value } }) => {
    this.setState({text: value});
    this.props.onChange(value);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {loading, open} = this.props;

    if (open !== prevProps.open) {
      this.setState({open: open});
    }
    // close dialog when loading completed
    if (!loading && loading !== prevProps.loading) {
      this.setState({open: false});
    }
  }

  render() {
    const {open} = this.state;
    const {title, classes, reason, onClose, onConfirm, onChange, confirmText = 'Ok'} = this.props;

    return (
      <Dialog open={open}
              maxWidth="lg"
              onClose={onClose}
              PaperProps={{className: classes.dialogPaper}}
      >
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField multiline
                     label="Reason..."
                     variant="outlined"
                     rows={5}
                     fullWidth
                     value={reason}
                     onChange={this.onChange}
                     margin="dense"
          />
        </DialogContent>
        <Grid container>
          <Grid item xs></Grid>
          <AxlButton variant="outlined"
                     bgcolor="primary.white"
                     color="primary.gray"
                     disableElevation
                     className={classes.dialogButton}
                     onClick={onClose}
                     className={classes.btnControl}
          >{`Cancel`}</AxlButton>
          <AxlButton variant="contained"
                     bgcolor="primary.periwinkle"
                     color="primary.white"
                     disableElevation
                     loading={this.props.loading}
                     className={classes.dialogButton}
                     disabled={!reason}
                     onClick={onConfirm}
                     className={classes.btnControl}
          >{confirmText}</AxlButton>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReasonDialog);