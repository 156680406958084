import React, { Component } from 'react';
import { AxlSearchBox, AxlMiniStopBox } from 'axl-reactjs-ui';
import styles from './styles';
import { inject, observer } from 'mobx-react';
import {compose} from "recompose";

class ShipmentList extends Component {
    selectStop(stop) {
      this.props.onSelectStop && this.props.onSelectStop(stop)
    }

    updateTimer = null

    componentDidMount() {
      const { assignmentStore } = this.props;
      assignmentStore.filter = "";
        this.updateTimer = setInterval(() => {
            const { assignmentStore } = this.props
            assignmentStore.ping()
        }, 10000)
    }

    componentWillUnmount() {
        if (!this.updateTimer) {
            clearInterval(this.updateTimer)
        }
    }

    changeSearch = (v) => {
      const { assignmentStore } = this.props;
      assignmentStore.filter = v;
    };

    render() {
        const { assignmentStore, shipmentStore } = this.props
        const { filteredShowingStops } = assignmentStore
        const { selectedStopId } = shipmentStore;

        return (<div style={{...styles.container,...this.style}}>
            <div style={styles.search}>
                <AxlSearchBox placeholder='Search Shipment' style={{width: '100%'}} theme={`periwinkle`} onChange={this.changeSearch} />
            </div>
            <div style={styles.list} className={'momentumScroll'}>
                { filteredShowingStops && filteredShowingStops.filter(s => !!s.shipment).map((stop) => {
                    const tags = stop && stop.shipment && stop.shipment.tags ? stop.shipment.tags.map((tag, i) => {
                        return {
                            text: <span style={styles.text}>{`${tag}`}</span>,
                            value: i
                        };
                    }) : [];
                    return <AxlMiniStopBox onClick={() => this.selectStop(stop)}
                        key={stop.id}
                        style={{opacity: !selectedStopId || selectedStopId === stop.id ? 1.0 : 0.5}}
                        stop={stop}
                        tags={tags} />
                })}
            </div>
        </div>)
    }
}

export default compose(
  inject("store"),
  observer
) (ShipmentList);
