import React, { Component, Fragment } from 'react';
import styles, * as E from './styles';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { AxlPanel, AxlButton, AxlModal, AxlInput, AxlCheckbox, AxlPopConfirm } from 'axl-reactjs-ui';
import { inject, observer } from 'mobx-react';
import {Tooltip} from "@mui/material";

// Utils
import { shipmentRedefinedStatus } from '../../constants/shipmentStatus';

// Components
import ShipmentCustomerForm from '../ShipmentCustomerForm/'
import ShipmentPickupForm from '../ShipmentPickupForm/'
import ShipmentDropoffForm from '../ShipmentDropoffForm/'
import ShipmentDropoffDispatchForm from '../ShipmentDropoffDispatchForm/'
import AssignmentHistoryDetail from '../AssignmentHistoryDetail'

import { images } from '../../constants/images';
import {Route, Switch, withRouter} from "react-router-dom";
import { INBOUND_STATUS_COLOR_CODE } from 'axl-reactjs-ui';
import ShipmentPickupInfo from "../ShipmentPickupInfo";
import ShipmentDropoffInfo from "../ShipmentDropoffInfo";
import ShipmentHistoryInfo from "../ShipmentHistoryInfo";
import ShipmentDropoffStatusForm from "../ShipmentDropoffStatusForm";
import PickupStatusFrom from "../PickupStatusForm";
import {HistoryListComponent} from "../HistoryList";
import ShipmentWorkloadInfo from "../ShipmentWorkloadInfo";

import ParcelProvider from '../../providers/Parcel';
import ShipmentPODRequirement from "../ShipmentPODRequirement";
import ShipmentNote from '../ShipmentNote';
import moment from "moment";
import {compose} from "recompose";

class ShipmentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showInternalId: false,
          showShipmentCustomerForm: false,
          showPickupInfoForm: false,
          showPickupStatusForm: false,
          showDropoffInfoForm: false,
          showDropoffStatusForm: false,
          showAccessCodeForm: false,
          showHistoryModal: false,
          showInboundNotes: false,
          splitAssignmentLabel: '',
          splitRelabelShipment: false,
        }
        this.removeShipment = this.removeShipment.bind(this)
    }

    openForm = (name) => () => {
      const formStateName = `show${name}Form`;
      this.setState({[formStateName]: true});
    };

    closeForm = (name) => () => {
      const formStateName = `show${name}Form`;
      this.setState({[formStateName]: false});
    };


    showShipmentDetail = () => {
        // console.log('history is: ', this.props.match.url);
        this.props.history.push(`${this.props.match.url}/edit`)
    };

    onEditShipmentPickup = () => {
        this.props.history.push(`${this.props.match.url}/edit/shipment-pickup`)
    }

    onEditShipmentDropoff = () => {
        this.props.history.push(`${this.props.match.url}/edit/shipment-dropoff`)
    }

    requestSplitRoute = () => {
      this.setState({showSplitConfirmation: true});
    }

    cancelSplitRoute = () => {
      this.setState({showSplitConfirmation: false});
    }

    removeShipment(shipmentId) {
      const { assignmentStore, shipmentStore, messengerStore } = this.props.store;
      const { history } = this.props;
      const {topicSelected} = messengerStore;
      assignmentStore.removeShipment(shipmentId, (res) => {
        if(topicSelected) {
          this.props.onRemoveShipment(topicSelected);
        }
      });
      return false;
    }

    discardStop = (stopId) => {
      const { assignmentStore } = this.props.store;
      assignmentStore.discardStop(stopId);
    }

    splitRoute = () => {
      const {shipment, dropoff, history} = this.props;
      const {shipmentStore, assignmentStore} = this.props.store;
      const {selectedAssignment} = assignmentStore;
      const assignmentLabel = this.state.splitAssignmentLabel && this.state.splitAssignmentLabel != '' ?  this.state.splitAssignmentLabel :
            selectedAssignment && selectedAssignment.assignmentLabel ? selectedAssignment.assignmentLabel.prefix : ''

      const data = {
        assignment_label: assignmentLabel,
        relabel_shipment: this.state.splitRelabelShipment
      };

      shipmentStore.splitRoute(shipment.assignment_id, shipment.id, data, (newAssignment) => {
        assignmentStore.appendSplitedAssignment(shipment.assignment_id, newAssignment);

        const {history} = this.props;
        const path = history.location.pathname;
        // process to new path
        const newPath = path.replace(/[\d]+\/stops/ig, `${newAssignment.id}/stops`);
        history.push(newPath);
        this.setState({showSplitConfirmation: false, splitAssignmentLabel: '', splitRelabelShipment: false});
      });
    }

    onChangeAssignmentLabel = (e) => {
      this.setState({splitAssignmentLabel: e.target.value});
    }

    onChangeRelabelShipment = (e) => {
      this.setState({splitRelabelShipment: e.target.checked});
    }

    componentWillReceiveProps(nextProps) {
      if(this.props.shipment.id !== nextProps.shipment.id) {
        this.setState({showInboundNotes: false})
      }
    }

    render() {
        const { showInternalId } = this.state
        const { shipment, dropoff, pickup, label, client, isEdit, stop, downloadLabel } = this.props;
        const {assignmentStore, shipmentStore, userStore} = this.props.store;
        const {selectedAssignment} = assignmentStore;
        const {splitingRoute} = shipmentStore;
        const { customer } = shipment;
        const inboundStatus = shipment.inbound_status || 'UNSCANNED';
        const inboundStatusColor = INBOUND_STATUS_COLOR_CODE[inboundStatus];

        const shipmentPickup = {
            title: 'Pickup Info:',
            status: pickup ? pickup.status : null,
            id: shipment.id,
            address: shipment.pickup_address,
            notes: shipment.pickup_note,
            is_cancelled: shipment.is_cancelled,
            remark: pickup ? pickup.remark : '-'
        };
        const calendarStrings = {
          lastDay : '[Yesterday at] LT',
          sameDay : '[Today at] LT',
          nextDay : '[Tomorrow at] LT',
          lastWeek : '[last] dddd [at] LT',
          nextWeek : 'dddd [at] LT',
          sameElse : 'L'
        };
        const isReattemptStop = Boolean(dropoff && dropoff.attributes && dropoff.attributes.is_reattempt === 'true');
        const isPickupFailed = Boolean(pickup && pickup.status === 'FAILED');
        const assignmentLabel = this.state.splitAssignmentLabel && this.state.splitAssignmentLabel != '' ?  this.state.splitAssignmentLabel :
          selectedAssignment && selectedAssignment.assignmentLabel ? selectedAssignment.assignmentLabel.prefix : ''
        let inboundTs = shipment.inbound_scan_ts || shipment.inbound_lock_ts;
        if (inboundStatus === 'MISSING') {
          if (!shipment.inbound_scan_ts) {
            inboundTs = shipment.inbound_lock_ts;
          } else if (!shipment.inbound_lock_ts) {
            inboundTs = shipment.inbound_scan_ts;
          } else {
            inboundTs = moment(shipment.inbound_lock_ts).isAfter(shipment.inbound_scan_ts) ? shipment.inbound_lock_ts : shipment.inbound_scan_ts;
          }
        }

        return (
          <AxlPanel style={styles.container}>
              <AxlPanel.Row col={true} style={{width: '100%', height: '100%'}}>
                  <AxlPanel.Col flex={0} style={{padding: '15px 20px 0'}}>
                      <AxlPanel.Row>
                          <AxlPanel.Col>
                              <div style={styles.inboundInfo}>{`Inbound Status:`}</div>
                              <div style={{...styles.status, cursor: 'pointer', color: inboundStatusColor, textDecoration: this.state.showInboundNotes ?'none' : 'underline'}}
                                   onClick={() => this.setState({showInboundNotes: !this.state.showInboundNotes})}
                              >
                                {inboundStatus.replace(/[_-]/g, " ")}
                              </div>
                              {this.state.showInboundNotes && (
                                <Fragment>
                                  <div style={{...styles.status, color: inboundStatusColor}}>{shipment.inbound_notes}</div>
                                  {inboundTs && <div style={{...styles.status, color: inboundStatusColor}}><Moment interval={0} calendar={calendarStrings}>{inboundTs}</Moment></div>}
                                </Fragment>
                              )}
                          </AxlPanel.Col>
                          <AxlPanel.Col center style={{paddingLeft: '15px', paddingRight: '15px', position: 'relative', zIndex: 9 }}>
                              <div style={styles.name}>{ label ? label.driver_label + ': ' : ' ' }{ customer ? customer.name : '' }</div>
                              <div style={styles.company}><code style={styles.code}>{shipment.id}</code></div>
                          </AxlPanel.Col>
                          <AxlPanel.Col style={{position: 'relative', zIndex: 9, textAlign: 'right'}}>
                              <Tooltip title="View shipment history">
                                <span>
                                  <AxlButton bg={`gray`} compact={true} ico={{className: 'fa fa-history'}} onClick={() => this.setState({showHistoryModal: !this.state.showHistoryModal})} />
                                </span>
                              </Tooltip>
                              {/*{(userStore.isAdmin || userStore.isSuperAdmin || userStore.isLeadDispatcher) && assignmentStore.isNotTheFirstStop(shipment) && assignmentStore.havePendingStop(shipment) && (*/}
                              {/*  <Tooltip title="Split route">*/}
                              {/*    <span>*/}
                              {/*      <AxlButton bg={`gray`} compact={true} ico={{className: 'fa fa-scissors'}} onClick={this.requestSplitRoute} />*/}
                              {/*    </span>*/}
                              {/*  </Tooltip>*/}
                              {/*)}*/}
                              {isReattemptStop && isPickupFailed && (
                                <AxlPopConfirm
                                  trigger={<Tooltip title="Discard stop"><span><AxlButton bg={`gray`} compact ico={{className: 'fa fa-times'}} /></span></Tooltip>}
                                  titleFormat={<div>Discard this stop?</div>}
                                  textFormat={<div><strong>Please confirm that you want to discard this stop!</strong></div>}
                                  okText={`CONFIRM`}
                                  onOk={() => this.discardStop(dropoff.id) }
                                  cancelText={`CANCEL`}
                                  onCancel={() => console.log('onCancel')}
                                />
                              )}
                              {/*<AxlPopConfirm*/}
                              {/*  trigger={<Tooltip title="Remove from route"><span><AxlButton bg={`gray`} compact ico={{className: 'fa fa-trash'}} /></span></Tooltip>}*/}
                              {/*  titleFormat={<div>{`Remove Shipment from Assignment`}</div>}*/}
                              {/*  textFormat={<div>Please confirm you want to re-move this shipment from this route!<br /><strong style={{color: '#422'}}>It will affect the ETA as well as pay-rate of the assignment!</strong></div>}*/}
                              {/*  okText={`CONFIRM`}*/}
                              {/*  onOk={() => this.removeShipment(shipment.id) }*/}
                              {/*  cancelText={`CANCEL`}*/}
                              {/*  onCancel={() => console.log('onCancel')}*/}
                              {/*/>*/}
                          </AxlPanel.Col>
                      </AxlPanel.Row>
                  </AxlPanel.Col>
                  <div style={{position: 'relative', flex: 1, marginTop: 5}}>
                    { !this.state.showHistoryModal ? <div>
                      <AxlPanel.Col flex={1} style={{padding: '0 20px'}}>
                        <AxlPanel.Row style={{margin: '15px 0'}}>
                          <AxlPanel.Col style={styles.email}>
                            <div style={styles.label2}>{`Client Info:`}</div>
                            <div style={styles.company}>{ client ? client.company : '-' }</div>
                            {shipment.internal_id && <div>
                              {!showInternalId && <div style={styles.showLink} onClick={() => this.setState({showInternalId: true})}>{`Show internal ID`}</div>}
                              { showInternalId && <div style={styles.company}>{shipment.internal_id}</div>}
                            </div>}
                          </AxlPanel.Col>
                          <AxlPanel.Col>
                            <AxlPanel.Row>
                              <AxlPanel.Col>
                                <div style={styles.label2}>{`Customer Info:`}</div>
                                <div style={styles.phone}>{ customer.phone_number }</div>
                                <div style={styles.email}>{ customer.email ? customer.email : '' }</div>
                              </AxlPanel.Col>
                              <AxlPanel.Col flex={0}>
                                {isEdit && !dropoff._deleted && <AxlButton tiny bg={`none`} onClick={this.openForm('ShipmentCustomer')}>{`Edit`}</AxlButton>}
                              </AxlPanel.Col>
                            </AxlPanel.Row>
                          </AxlPanel.Col>
                        </AxlPanel.Row>
                        <ShipmentPODRequirement shipment={shipment} />
                        <ShipmentNote shipmentId={shipment.id} userId={userStore.user.id}/>
                        <ParcelProvider><ShipmentWorkloadInfo shipmentId={shipment.id} workload={shipment.workload} downloadLabel={downloadLabel}></ShipmentWorkloadInfo></ParcelProvider>
                        <ShipmentPickupInfo pickup={pickup} dropoff={dropoff} shipment={shipment} openForm={this.openForm} isEdit={this.props.isEdit} />
                        <ShipmentDropoffInfo pickup={pickup} dropoff={dropoff} shipment={shipment} closeForm={this.closeForm} openForm={this.openForm} isOpen isEdit={this.props.isEdit} />
                      </AxlPanel.Col>
                    </div> : <E.HistoryInfoContainer>
                      <ShipmentHistoryInfo shipment={shipment} shipmentHistory={this.props.shipmentHistory} isOpen />
                    </E.HistoryInfoContainer> }
                  </div>
              </AxlPanel.Row>

              {this.state.showShipmentCustomerForm && <AxlModal onClose={this.closeForm('ShipmentCustomer')} style={styles.shipmentDetailModal}>
                <ShipmentCustomerForm shipment={shipment} dropoff={dropoff} closeForm={this.closeForm('ShipmentCustomer')}/>
              </AxlModal>}

              {this.state.showDropoffInfoForm && <AxlModal onClose={this.closeForm('DropoffInfo')} style={styles.shipmentDetailModal}>
                <ShipmentDropoffForm shipment={shipment} dropoff={dropoff} closeForm={this.closeForm('DropoffInfo')} />
              </AxlModal>}

              {this.state.showDropoffStatusForm && <AxlModal onClose={this.closeForm('DropoffStatus')} style={styles.shipmentDetailModal}>
                <ShipmentDropoffStatusForm shipment={shipment} dropoff={dropoff} closeForm={this.closeForm('DropoffStatus')} />
              </AxlModal>}

              {this.state.showPickupStatusForm && <AxlModal onClose={this.closeForm('PickupStatus')} style={styles.shipmentDetailModal}>
                <PickupStatusFrom shipment={shipment} pickup={pickup} dropoff={dropoff} closeForm={this.closeForm('PickupStatus')} />
              </AxlModal>}

              {this.state.showPickupInfoForm && <AxlModal onClose={this.closeForm('PickupInfo')} style={styles.shipmentDetailModal}>
                <ShipmentPickupForm shipment={shipment} pickup={pickup} dropoff={dropoff} closeForm={this.closeForm('PickupInfo')} />
              </AxlModal>}

              {this.state.showAccessCodeForm && <AxlModal onClose={this.closeForm('AccessCode')} style={styles.shipmentDetailModal}>
                <ShipmentDropoffDispatchForm shipment={shipment} pickup={pickup} dropoff={dropoff} closeForm={this.closeForm('AccessCode')} />
              </AxlModal>}

              {this.state.showSplitConfirmation && <AxlModal style={{width: '600px', height: '220px', paddingBottom: '60px', paddingLeft: '16px', paddingRight: '16px'}}>
                <h3>Split confirmation</h3>
                <div>
                  Do you want to split assignment <strong>{selectedAssignment && selectedAssignment.assignmentLabel ? selectedAssignment.assignmentLabel.prefix : (selectedAssignment.label || '')}</strong> from shipment <strong>{label ? label.driver_label: ''}</strong>?
                </div>
                <div style={{position: 'absolute', top: '50px', left: '8px', right: '8px', bottom: '80px', overflow: 'auto', padding: '10px'}}>
                  <div style={{textAlign: 'left'}}>
                    <div style={styles.header}>New Asignment Label</div>
                    <div>
                      <AxlInput style={{width: '100%'}} value={assignmentLabel} onChange={this.onChangeAssignmentLabel} />
                    </div>
                    <div style={styles.header}><AxlCheckbox checked={this.state.splitRelabelShipment} onChange={this.onChangeRelabelShipment} /> Relabel Shipment</div>
                  </div>
                </div>
                <div>
                    <strong>NOTE</strong>: We provide the ability to adjust and reconfigure routes as an advanced optional feature for our DSP partners.
                    Use of this feature cannot be used to increase compensation.
                </div>

                <div style={{textAlign: 'center', position: 'absolute', bottom: '20px', left: 0, right: 0, paddingTop: '12px', height: '60px'}}>
                  <AxlButton loading={splitingRoute} style={{width: '160px'}} onClick={ this.splitRoute }>Yes</AxlButton>
                  <AxlButton bg={'red'} style={{width: '160px'}} onClick={this.cancelSplitRoute}>Cancel</AxlButton>
                </div>
              </AxlModal>}

              { /*this.state.showHistoryModal && <AxlModal style={styles.axlModal} onClose={() => this.setState({showHistoryModal: false})} >
                <HistoryListComponent baseUrl={`/histories/by-ref/shipment/${shipment.id}`} type='shipment' />
              </AxlModal>*/ }
          </AxlPanel>
        );
    }
}

ShipmentInfo.defaultProps = {
    isEdit: true
}

ShipmentInfo.propTypes = {
    isEdit: PropTypes.bool
}

export default compose(
  withRouter,
  inject('store'),
  observer
)(ShipmentInfo);
