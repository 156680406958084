import { Colors } from 'axl-reactjs-ui';

export const Status = {
  DEFAULT: Colors.veryLightPink,
  PENDING: Colors.reddishOrange,
  DELIVERED: Colors.reddishOrange,
  DROPOFF_SUCCEEDED: Colors.darkPastelGreen,
  SUCCEEDED: Colors.darkPastelGreen,
  FAILED: Colors.dustyRed
};

const styles = theme => ({
  container: {
    marginBottom: '16px',
  },
  panelContainer: {
    backgroundColor: '#f4f4f4',
    boxShadow: 'none',
  },
  panelHeader: {
    padding: '8px',
    minHeight: 'auto !important',
    '& > .MuiExpansionPanelSummary-content': {
      margin: 0,
    }
  },
  panelHeaderTitle: {
    flex: 1,
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '14px',
    color: '#000'
  },
  panelHeaderStatus: {
    fontSize: '12px',
    fontStyle: 'italic',
    margin: '0 5px'
  },
  panelToggleIcon: {
    padding: '4px 12px',
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  panelBody: {
    padding: '8px',
  },
  noteLabel: {
    fontFamily: 'AvenirNext',
    fontSize: '10px',
    color: '#bec6d8'
  },
  noteContent: {
    borderRadius: '5px',
    border: 'solid 1px #bec6d8',
    padding: '7px 10px',
    fontStyle: 'italic'
  },
  text: {
    fontSize: '12px',
    color: '#000'
  },
  text2: {
    fontSize: '9px'
  },
  remarkHeader: {
    fontSize: 10,
  },
  remarkHeaderStatus: {
    fontFamily: 'AvenirNext-DemiBold',
    flex: 1
  },
  remarkHeaderTime: {
    fontFamily: 'AvenirNext-Medium'
  },
  remarkContent: {
    padding: '8px 12px',
    borderRadius: '5px',
    border: '1px solid #cfcfcf',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
});

export default styles;