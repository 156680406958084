import React from "react";
import {AxlPanel} from "axl-reactjs-ui";
import {Container, Value} from "./styles";

class ErrorReason extends React.Component {
  render() {
    return (
        <AxlPanel.Row>
            <Container>
              <Value>{this.props.value}</Value>
            </Container>
        </AxlPanel.Row>
    )
  }
}

export default ErrorReason;
