import React from "react";
import * as M from "@mui/material";
import _ from "lodash";
import * as S from "./styles";
import { useTheme } from "@mui/styles";
import colors from "../../themes/colors";

const Button = M.styled(M.Button)(({ theme }) => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  lineHeight: "1.3em",
}));

const primaryColors = {
  primary: colors,
};

export default function AxlButton(props) {
  const {
    icon,
    tooltip,
    spacing = 1,
    padding,
    noRadius,
    color,
    bgcolor,
    loading = false,
    style,
    opacity = 1,
    ...cloneProps
  } = props;
  const theme = useTheme();
  const customizeStyle = {
    padding: padding || "8px",
    color: _.get(primaryColors, color) || "inherit",
    backgroundColor: _.get(primaryColors, bgcolor) || "inherit",
    opacity,
    ...(noRadius ? { borderRadius: 0 } : {}),
    ...(props.variant === "outlined"
      ? {
          border: `1px solid ${_.get(primaryColors, color)}`,
        }
      : {}),
    ...(icon
      ? {
          minWidth: "auto",
          position: "relative",
          padding: padding || "8px 10px",
        }
      : { minWidth: cloneProps.minWidth || 80 }),
    ...style,
  };

  return (
    <M.Box m={spacing}>
      {props.tooltip ? (
        <M.Tooltip style={{ minWidth: icon ? "auto" : 80 }} {...tooltip}>
          <Button style={customizeStyle} {...cloneProps}>
            {loading ? (
              <M.CircularProgress
                style={{ color: _.get(primaryColors, color) }}
                size={15}
                thickness={2}
              />
            ) : (
              cloneProps.children
            )}
          </Button>
        </M.Tooltip>
      ) : (
        <Button style={customizeStyle} {...cloneProps}>
          {loading ? (
            <M.CircularProgress
              style={{ color: _.get(primaryColors, color) }}
              size={15}
              thickness={2}
            />
          ) : (
            cloneProps.children
          )}
        </Button>
      )}
    </M.Box>
  );
}
