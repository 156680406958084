import { createTheme } from '@mui/material/styles';
import purple from '@mui/material/colors/purple';
import green from '@mui/material/colors/green';
import {placeholder} from "./pseudo";
import colors from "./colors";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#887fff',
    },
    secondary: {
      main: '#ccc',
    },
    text: {
      main: '#4a4a4a'
    }
  },
  status: {
    danger: 'orange',    
  },
  typography: {
    fontFamily: [
      'AvenirNext',
      'AvenirNext-Italic',
      'AvenirNext--DemiBold'
    ].join(','),
  },
  colors: {
    secondaryBar: '#2a2444'
  }
});

// Palette
const palette = {
  primary: colors,
  secondary: {
    main: '#2a2444',
    subsidiary: '#ccccc'
  },
  third: {
    main: '#6c62f5'
  },
  text: {
    primary: '#4f4c75',
    secondary: '#6c62f5',
    third: '#4a4a4a',
    select: '#737273'
  },
  background: {
    paper: "#FFF",
    list: "#f4f3ff"
  },
  color: colors
};

//Typography
const typography = {
  fontFamily: 'AvenirNext',
  h1: {},
  h2: {},
  h3: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 26,
    fontWeight: 600,
    color: '#4a4a4a'
  },
  h4: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: 16,
    fontWeight: 600,
    color: '#5e5b78'
  },
  h5: {},
  h6: {},
  body1: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 13.5,
    fontWeight: 500,
    color: '#393060',
    lineHeight: 1.3
  },
  body2: {
    fontFamily: 'AvenirNext-Medium',
    fontSize: 10,
    fontWeight: 500
  },
  subtitle1: {
    lineHeight: '1.3em'
  },
  button: {}
};

// eslint-disable-next-line import/prefer-default-export
export const lightTheme = createTheme({
  palette: palette,
  typography: typography,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.blackdrop
        },
        invisible: {}
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          // color: colors.white,
        },
        text: {
          fontFamily: 'AvenirNext-DemiBold',
          fontSize: 12,
          fontWeight: 600,
          textTransform: 'none',
          whiteSpace: 'nowrap'
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colors.periwinkle,
            opacity: 0.6,
          }
        },
        outlinedPrimary: {
          '&:hover': {
            borderColor: '#0000003b',
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.graySeventh,
          '&$checked': {
            color: colors.graySeventh,
          }
        },
        // label: {
        //   color: colors.blueEyes
        // },
        colorPrimary: {
          color: colors.gray,
          '&$checked': {
            color: colors.gray,
          }
        },
        colorSecondary: {
          color: colors.grayMain,
          '&$checked': {
            color: colors.periwinkle,
          }
        },
      }
    },
    MuiFormControlLabel: {
      styleOverrides:{
        label: {
          color: colors.graySeventh,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          border: `1px solid ${colors.grayMain}`,
          paddingLeft: '8px',
          paddingRight: '8px',
          "&.Mui-error": {
            borderColor: colors.red,
          }
        },
        input: {
          paddingTop: '9px',
          paddingBottom: '9px',
          ...placeholder({
            fontFamily: 'AvenirNext-Medium',
            fontSize: 10,
            fontWeight: 500,
            color: colors.blackSecondary,
            opacity: 1,
          })
        },
        inputMultiline: {
          paddingLeft: '8px',
          paddingRight: '8px',
          ...placeholder({
            fontFamily: 'AvenirNext-Medium',
            fontSize: 10,
            fontWeight: 500,
            color: colors.blackSecondary,
            opacity: 1,
          })
        },
        colorSecondary: {
          backgroundColor: colors.whiteTwo
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'none',
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: 13,
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      }
    }
  },
});
