import * as M from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles, withStyles } from '@mui/styles';

import colors from 'themes/colors';

export const SelectButtonContainer = M.styled(M.Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  borderBottom: `1px solid ${colors.periwinkle}`,
  borderRadius: 0,
  minWidth: 120,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const SelectButton = M.styled('div')(({theme, fontSize}) => ({
  fontFamily: 'AvenirNext',
  fontWeight: 900,
  fontSize: fontSize,
  color: colors.periwinkle,
  marginRight: 10,
  marginLeft: 5,
}));

export const ClearButton = withStyles(({theme, spacing}) => ({
  root: {
    padding: spacing(0),
    color: '#0000008a'
  },
}))(ClearIcon);

export default makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    minWidth: 80,
  },
  paper: {
    marginRight: spacing(2),
  },
  iconButton: {
    padding: spacing(0),
  },
  clearButton: {
    marginLeft: spacing(1),
    marginRight: spacing(1),
  },
  menuList: {
    minWidth: 300,
  },
  listItem: {
    padding: spacing(0),
    fontSize: 13,
  }
}));
