const styles = theme => ({
  container: {
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    flexWrap: 'nowrap',
  },
  name: {
    fontFamily: 'AvenirNext-DemiBold',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#3b3b3b'
  },
  phone: {
    fontSize: '13px'
  },
  email: {
    fontSize: '13px',
    textAlign: 'left'
  },
  company: {
    fontFamily: 'AvenirNext-Medium'
  },
  square: {
    display: 'inline-block',
    width: '6px',
    height: '6px',
    backgroundColor: '#aaa',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '2px'
  },
  label: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '11px',
    fontWeight: '600',
    color: '#bebfc0',
    marginBottom: '3px'
  },
  label2: {
    fontFamily: 'AvenirNext-DemiBold',
    fontSize: '13px',
    color: '#000000',
    fontWeight: 'bold'
  },
  note: {
    fontSize: '13px',
    fontStyle: 'italic'
  },
  code: {
    fontSize: '11px',
    background: '#fff0cf',
    padding: '3px 5px',
    borderRadius: '3px',
    color: '#8c8c8c'
  },
  inboundInfo: {
    fontFamily: 'AvenirNext-Italic',
    fontSize: '10px',
    color: '#848484'
  },
  status: {
    fontFamily: 'AvenirNext-Italic',
    fontSize: '10px',
    fontStyle: 'italic',
  },
  shipmentDetail: {
    maxHeight: 'calc(100% - 60px)',
    overflow: 'auto',
  },
  shipmentActions: {
    backgroundColor: '#f4f4f4',
  },
  actionsWrapper: {
    '& > .MuiBox-root:not(:last-child)': {
      marginRight: 10,
    }
  },
  actionGroup: {
    display: 'inline-flex',
    backgroundColor: '#fff',
    border: '1px solid #e4e4e4',
    '& > .MuiBox-root:not(:last-child)': {
      borderRight: '1px solid #e4e4e4',
    }
  },
  action: {
    display: 'inline-block',
    padding: '4px',
  },
  footerButton: {
    minWidth: 'auto',
    whiteSpace: 'nowrap',
    margin: '4px',
    fontFamily: 'AvenirNext-Medium',
    color: '#aaaaaa',
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'none',
    borderColor: '#0000003b',
  },
  actionIcon: {
    "&:hover": {
      backgroundColor: "transparent",
    }
  },
  dialogPaper: {
    padding: '24px',
    minWidth: 600,
  },
  dialogTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  dialogContent: {
    margin: '8px 0px',
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonLoading: {
    position: 'absolute',
    top: 'calc(50% - 15px)',
    left: 'calc(50% - 15px)',
  },
  wrapImages: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'relative',
    zIndex: 9999
  },
  imageShipment: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 120px)',
    margin: 'auto',
    backgroundColor: '#FFF'
  },
  arrowButton: {
    width: '30px',
    height: '30px',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 10000,
    cursor: 'pointer'
  },
  arrowPrev: {
    left: 0
  },
  arrowNext: {
    right: 0
  },
  arrowIcon: {
    fontSize: '25px',
    color: '#fff'
  },
  boxWrapper: {
    minWidth: 750,
    maxHeight: 500,
    overflow: 'auto',
  },
  box: {
    border: '1px solid #ccc',
    marginBottom: 5,
    cursor: 'pointer',
    textAlign: 'center',
  },
  boxSelected: {
    borderColor: '#887fff',
    backgroundColor: '#dddfff',
  },
  body: {
    position: 'absolute',
    bottom: '70px',
    left: 0,
    right: 0,
    top: 0,
    overflowY: 'scroll',
    background: '#FFF',
    zIndex: 1,
  },
  footerScroll: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  freeBtnStyle: {
    minWidth: 'auto',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '4.8px',
    paddingBottom: '4.8px',
  },
  footerBtnIcon: {
    width: 16,
    height: 15,
    backgroundSize: 'contain',
    marginRight: 8,
  },
  footerBtnIcon2: {
    width: 16,
    height: 15,
    backgroundSize: 'contain',
  },
  btnControl: {
    width: 130
  }
});

export default styles;