import colors from "../../themes/colors";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 8,
});

export const mainTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '8px',
          display: 'flex',
          // lineHeight: 1,
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        select: {
          '&:focus': {
            backgroundColor: colors.transparent,
          }
        },
      },
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      }
    },
  }
});